import { useEffect } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Scrollbar from 'components/scrollbar';
import Text from 'components/text/Text';
import { Flex, VStack } from 'layouts/box/Box';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const StyledVStack = styled(VStack)`
  overflow: visible;
  height: 100%;
  width: 100%;
  border-left: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

const StyledFlex = styled(Flex)<{ selected: boolean }>`
  cursor: pointer;
  user-select: none;
  width: 100%;
  height: 28px;
  padding-inline: 8px;
  justify-content: flex-start;
  :hover {
    background-color: ${({ selected, theme }) =>
      selected ? theme.palette.dina.onSelected : theme.palette.dina.hoverOverlay};
  }
  ${({ selected, theme }) =>
    selected
      ? css`
          background-color: ${theme.palette.dina.onSelected};
          span {
            color: ${theme.palette.dina.whiteHighEmphasis};
          }
        `
      : ''}
`;

interface MonthSelectorProps {
  month: number;
  onMonthSelect: (month: number) => void;
}

function MonthSelector({ month, onMonthSelect }: Readonly<MonthSelectorProps>) {
  useEffect(() => {
    const el = document.getElementById(`ms-${months[month]}`);
    el?.scrollIntoView({ block: 'nearest' });
  }, [month]);

  return (
    <StyledVStack overflow="visible">
      <Scrollbar top={5} bottom={5}>
        {months.map((m, index) => (
          <StyledFlex
            id={`ms-${m}`}
            key={m}
            selected={month === index}
            onClick={() => {
              onMonthSelect(index);
            }}
          >
            <Text variant="button" color="mediumEmphasis">
              {m}
            </Text>
          </StyledFlex>
        ))}
      </Scrollbar>
    </StyledVStack>
  );
}

export default MonthSelector;
