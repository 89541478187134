import React from 'react';
import PropTypes from 'prop-types';
import { BodyWrapper, SettingsMenuWrapper, SettingsDetailsWrapper } from '../../../styled';
import UsersSettingsMenu from './menu';
import UsersSettingsDetails from './details';

const UserSettings = ({ handleCloseSettings, groupPolicies, systemSettings }) => (
  <BodyWrapper>
    <SettingsMenuWrapper>
      <UsersSettingsMenu />
    </SettingsMenuWrapper>
    <SettingsDetailsWrapper>
      <UsersSettingsDetails
        handleCloseSettings={handleCloseSettings}
        groupPolicies={groupPolicies}
        systemSettings={systemSettings}
      />
    </SettingsDetailsWrapper>
  </BodyWrapper>
);

UserSettings.propTypes = {
  handleCloseSettings: PropTypes.func,
  groupPolicies: PropTypes.array,
  systemSettings: PropTypes.object,
};

UserSettings.defaultProps = {
  handleCloseSettings: () => {},
  groupPolicies: [],
  systemSettings: {},
};

export default UserSettings;
