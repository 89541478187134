import { ApolloClient } from '@apollo/client';
import gql from 'graphql-tag';

import { CrudActionEnum, MemberType } from 'types/graphqlTypes';

const GET_MESSAGES_OF_CONVERSATION_SHORT = gql`
  query getMessagesOfConversation(
    $input: GetMessagesOfConversationInput
    $limit: Int
    $nextToken: String
  ) {
    getMessagesOfConversation(input: $input, limit: $limit, nextToken: $nextToken) {
      items {
        mId
        mRefId
        mContent
      }
      nextToken
    }
  }
`;

interface MessagesOfConversation {
  getMessagesOfConversation: {
    items: MemberType[];
  };
}

const updateChatCache = (
  proxy: ApolloClient<unknown>,
  convoMessage: MemberType,
  variables: { input: { mId?: string }; limit: number },
) => {
  const data = proxy.readQuery<MessagesOfConversation>({
    query: GET_MESSAGES_OF_CONVERSATION_SHORT,
    variables,
  });

  const newMessageList = [];
  const getMessagesOfConversation = data?.getMessagesOfConversation ?? {
    items: [],
    __typename: 'PaginatedMessageType',
  };
  const { items = [] } = getMessagesOfConversation;
  const { mContent, crudAction } = convoMessage;

  switch (crudAction) {
    case CrudActionEnum.Delete:
    case CrudActionEnum.Remove:
      newMessageList.push(...items.filter((message) => message.mRefId !== convoMessage.mRefId));
      break;
    case CrudActionEnum.Insert:
      items.forEach((item) => {
        newMessageList.push({
          ...item,
          mContent: item.mRefId === convoMessage.mRefId ? mContent : item.mContent,
        });
      });
      break;
    default: // default == null, which means insert
      if (items[0]?.mRefId === convoMessage.mRefId) return;
      newMessageList.push(convoMessage, ...items);
      break;
  }

  proxy.writeQuery({
    query: GET_MESSAGES_OF_CONVERSATION_SHORT,
    variables,
    data: {
      getMessagesOfConversation: { ...getMessagesOfConversation, items: newMessageList },
    },
  });
};

const updateMessageInConversation = (proxy: ApolloClient<unknown>, item: MemberType) => {
  try {
    const variables = {
      input: {
        mId: item?.mId,
      },
      limit: 25,
    };
    updateChatCache(proxy, item, variables);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

export default updateMessageInConversation;
