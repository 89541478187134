import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';

import configCtx from 'contexts/configContext';
import memberTypes from 'operations/memberTypes';
import { getMembersOfQuery, getMembersOfTypeQuery } from 'operations/queryVariables';
import { editorLockInteractionKey } from 'screens/main/components/header/navbar/settings/components/settingsTabs/userSettings/details/preferences/UserPreferences';
import { useRundownGridViewList } from 'screens/rundown/store';
import {
  useBlockForms,
  useDinaTheme,
  useDoubleClickToLockEditor,
  useEmojiData,
  useExternalUrls,
  useFeedSources,
  useMembers,
  usePolicies,
  useScheduleResources,
  useSignedCookie,
  useUnreadConversations,
  useUserConfig,
} from 'store';
import UserSettingsView from 'utils/settings/schemas/userSettingsView';
import { emptySignedCookiePromise, isProductionMode } from 'utils/signedCookie';

import GET_CONFIGS from '../queries/getConfigs';
import GET_DEPARTMENTS from '../queries/getDepartments';
import GET_MEMBER from '../queries/getMember';
import GET_MEMBERS_FROM_MID from '../queries/getMembersFromId';
import GET_MEMBERSOF from '../queries/getMembersOf';
import GET_METADATA_FORMS from '../queries/getMetadataForms';
import GET_POLICY from '../queries/getPolicy';
import GET_SCHEDULE_RESOURCES from '../queries/getScheduleResources';
import GET_SETTINGS from '../queries/getSettings';
import GET_SIGNED_COOKIE from '../queries/getSignedCookie';
import GET_STATUS_VIEWS from '../queries/getStatusViews';
import GET_TEAMS from '../queries/getTeams';
import GET_UNREAD_CONVERSATIONS from '../queries/getUnreadConversations';
import GET_USERS from '../queries/getUsers';
import GET_PLATFORMS from '../queries/getPlatforms';

import {
  calculateUserRights,
  getBlockForms,
  getGridViewForms,
  saveAutoTempOnContext,
  saveFeedOnContext,
  saveKanbanBoardConfigOnContext,
  saveMetaFormOnContext,
  saveSettingsOnContext,
  setExtUrls,
} from './utils';
import { usePriorities } from 'screens/main/components/header/navbar/settings/atomsTs';
import { useFixPlatformPermissions } from './useFixPlatformPermissions';

async function InitialQueries(mId, groups = [], setInitialLoading) {
  const [skip, setSkip] = useState(false);

  const config = useContext(configCtx);
  const [, setMembers] = useMembers();
  const [, setUnreadConversations] = useUnreadConversations();
  const [, setScheduleResources] = useScheduleResources();
  const [, setFeedSources] = useFeedSources();
  const [, setPolicies] = usePolicies();
  const [, setExternalUrls] = useExternalUrls();
  const [, setSignedCookie] = useSignedCookie();
  const [, setBlockForms] = useBlockForms();
  const [, setEmojiData] = useEmojiData();
  const [, setDinaTheme] = useDinaTheme();
  const [, setDoubleClickToLockEditor] = useDoubleClickToLockEditor();
  const [, setRundownGridViewList] = useRundownGridViewList();
  const [, setPriorities] = usePriorities();
  const [userConfig, setUserConfig] = useUserConfig();
  const { i18n } = useTranslation();
  const fixPermission = useFixPlatformPermissions();

  useEffect(() => {
    setSkip(true);
  }, []);

  const client = useApolloClient();

  if (skip) return;

  const fetchPolicy = !skip ? 'network-only' : 'cache-only';

  const policyPromise = client.query({
    query: GET_POLICY,
    variables: { input: { mId: 'groupPolicy' } },
    fetchPolicy,
    skip,
  });

  const extUrlPromise = client.query({
    query: GET_CONFIGS,
    variables: { input: { mId: 'externalurl' } },
    fetchPolicy,
    skip,
  });

  const automationPromise = client.query({
    query: GET_CONFIGS,
    variables: { input: { mId: 'automation' } },
    fetchPolicy,
    skip,
  });

  const feedPromise = client.query({
    query: GET_CONFIGS,
    variables: { input: { mId: 'feed' } },
    fetchPolicy,
    skip,
  });

  const metadataPromise = client.query({
    query: GET_METADATA_FORMS,
    variables: { input: { mId: 'form' } },
    fetchPolicy,
    skip,
  });

  const getUserPromise = client.query({
    query: GET_USERS,
    variables: getMembersOfTypeQuery(memberTypes.USER),
    fetchPolicy,
    skip,
  });

  const teamPromise = client.query({
    query: GET_TEAMS,
    variables: getMembersOfTypeQuery(memberTypes.TEAM),
    fetchPolicy,
    skip,
  });

  const deptPromise = client.query({
    query: GET_DEPARTMENTS,
    variables: getMembersOfTypeQuery(memberTypes.DEPARTMENT),
    fetchPolicy,
    skip,
  });

  const settingsPromise = client.query({
    query: GET_SETTINGS,
    variables: {
      generalSettingsInput: { mId: 'settings', mRefId: 'general' },
      userSettingsInput: { mId: 'settings', mRefId: mId },
    },
    fetchPolicy,
    skip,
  });

  const medtopPromise = client.query({
    query: GET_MEMBERS_FROM_MID,
    variables: { input: { mId: 'medtop' } },
    fetchPolicy,
    skip,
  });

  const scheduleResourcesPromise = client.query({
    query: GET_SCHEDULE_RESOURCES,
    variables: { input: { mId: '' } },
    fetchPolicy,
    skip,
  });

  const userPromise = client.query({
    query: GET_MEMBERSOF,
    variables: getMembersOfQuery(mId, memberTypes.USER),
    fetchPolicy,
    skip,
  });

  const teamUserPromise = client.query({
    query: GET_MEMBERSOF,
    variables: getMembersOfQuery(mId, memberTypes.TEAM_USER),
    fetchPolicy,
    skip,
  });

  const deptUserPromise = client.query({
    query: GET_MEMBERSOF,
    variables: getMembersOfQuery(mId, memberTypes.DEPARTMENT_USER),
    fetchPolicy,
    skip,
  });

  const unreadConverationsPromise = client.query({
    query: GET_UNREAD_CONVERSATIONS,
    variables: { input: { mId } },
    fetchPolicy,
    skip,
  });

  const kanbanBoardConfigPromise = client.query({
    query: GET_STATUS_VIEWS,
    variables: { input: { mId: 'states' } },
    fetchPolicy,
    skip,
  });

  const signedCookiePromise = isProductionMode
    ? client.query({
        query: GET_SIGNED_COOKIE,
        fetchPolicy,
      })
    : emptySignedCookiePromise;

  const emojiDataPromise = fetch('https://cdn.jsdelivr.net/npm/@emoji-mart/data');

  const priorityPromise = client.query({
    query: GET_MEMBER,
    variables: { input: { mId: 'priority', mRefId: 'priority' } },
    fetchPolicy,
    skip,
  });

  const platformsPromise = client.query({
    query: GET_PLATFORMS,
    fetchPolicy,
    skip,
  });

  const promises = [
    platformsPromise,
    policyPromise,
    extUrlPromise,
    automationPromise,
    feedPromise,
    metadataPromise,
    getUserPromise,
    teamPromise,
    deptPromise,
    settingsPromise,
    medtopPromise,
    scheduleResourcesPromise,
    unreadConverationsPromise,
    kanbanBoardConfigPromise,
    signedCookiePromise,
    emojiDataPromise,
    priorityPromise,
    userPromise,
    teamUserPromise,
    deptUserPromise,
  ];

  const responses = await Promise.allSettled(promises);

  const [
    platformsResult,
    policyResult,
    extUrlResult,
    automationResult,
    feedResult,
    metadataResult,
    getUserResult,
    teamResult,
    deptResult,
    settingsResult,
    medtopResult,
    scheduleResourcesResult,
    unreadConverationsResult,
    kanbanBoardConfigResult,
    signedCookieResult,
    emojiDataResult,
    priorityResult,
  ] = responses.map((response) => response.value);

  if (
    !platformsResult?.loading &&
    !policyResult?.loading &&
    !extUrlResult?.loading &&
    !automationResult?.loading &&
    !feedResult?.loading &&
    !metadataResult?.loading &&
    !getUserResult?.loading &&
    !teamResult?.loading &&
    !deptResult?.loading &&
    !settingsResult?.loading &&
    !medtopResult?.loading &&
    !scheduleResourcesResult?.loading &&
    !unreadConverationsResult?.loading &&
    !kanbanBoardConfigResult?.loading &&
    !signedCookieResult?.loading &&
    !priorityResult?.loading
  ) {
    const updatedPermissions = await fixPermission(
      policyResult?.data?.getMemberFromId,
      platformsResult?.data?.getPlatforms,
    );

    // Save policies on context

    const userRights = calculateUserRights(groups, updatedPermissions);
    setPolicies(userRights);

    // set externalUrls Atom

    setExtUrls(extUrlResult, setExternalUrls);

    // save automation config on context

    saveAutoTempOnContext(automationResult, config);

    // save feed config on context
    saveFeedOnContext(feedResult, config);
    setFeedSources(feedResult);

    // save default metadata config on context
    saveMetaFormOnContext(metadataResult, config);

    // save block forms on atom
    setBlockForms(getBlockForms(metadataResult));

    setRundownGridViewList(getGridViewForms(metadataResult));

    // save settings on context
    saveSettingsOnContext(settingsResult, config);

    // save signed cookie on store
    setSignedCookie(signedCookieResult?.data?.getSignedCookie);

    // set emoji data
    setEmojiData((await emojiDataResult?.json()) || {});

    // save user theme atom from the user settings mMetaData
    const theme = settingsResult?.data?.userSettings?.mMetaData.find(
      (metadata) => metadata.key === 'theme',
    );
    const themeDefault = UserSettingsView.categories?.find(({ title }) => title === 'theme');

    setDinaTheme(theme?.value || themeDefault?.defaultValue);

    const doubleClickToLockEditorMeta = settingsResult?.data?.userSettings?.mMetaData.find(
      (metadata) => metadata.key === editorLockInteractionKey,
    );

    setDoubleClickToLockEditor(doubleClickToLockEditorMeta?.value === 'true');

    // set display language of Dina
    const displayLanguage = settingsResult?.data?.userSettings?.mMetaData.find(
      (metadata) => metadata.key === 'user.displayLanguage',
    )?.value;
    if (displayLanguage && i18n.language !== displayLanguage) i18n.changeLanguage(displayLanguage);

    // save unread conversations on store
    const { getUnreadConversations } = unreadConverationsResult.data;
    setUnreadConversations(getUnreadConversations);

    // save users, teams, departements & external contacts on store
    setMembers({
      [memberTypes.USER]: getUserResult.data.getMembersOftype,
      [memberTypes.TEAM]: teamResult.data.getMembersOftype,
      [memberTypes.DEPARTMENT]: deptResult.data.getMembersOftype,
    });

    // save kanban board views and instance states on context
    saveKanbanBoardConfigOnContext(kanbanBoardConfigResult, config);

    // save config to user config
    setUserConfig({ ...userConfig, ...config, groups });

    // save priorities in atom
    try {
      if (priorityResult?.data?.getMember?.metadata) {
        setPriorities(JSON.parse(priorityResult?.data?.getMember?.metadata));
      }
    } catch (e) {
      /* empty */
    }

    // saving scheduleResources data for the initial load.
    setScheduleResources(scheduleResourcesResult?.data?.getScheduleResources || []);
    setInitialLoading(false);
  }
}

export default InitialQueries;
