import { memo } from 'react';

import useDateTimeUtils from 'hooks/useDateTimeUtils';
import {
  useCurrentTabArchived,
  useCurrentTabSelectedDate,
  useCurrentTabType,
  useToolbar,
} from 'store';

import { StyledAppBar as AppBar, StyledToolbar as ToolBar } from './styled';

function Toolbar() {
  const { isToday } = useDateTimeUtils();
  const [toolbar] = useToolbar();
  const [currentTabType] = useCurrentTabType();
  const [currentTabSelectedDate] = useCurrentTabSelectedDate();
  const [currentTabArchived] = useCurrentTabArchived();

  return (
    <AppBar
      position="relative"
      $mType={currentTabType}
      $isToday={currentTabType === 'rundown' && isToday(currentTabSelectedDate)}
      $isStory={currentTabType === 'story'}
      $isArchived={currentTabArchived}
    >
      <ToolBar>{toolbar}</ToolBar>
    </AppBar>
  );
}

export default memo(Toolbar);
