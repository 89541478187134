import { Document, Page, Text, View } from '@react-pdf/renderer';

import useDateTimeUtils from 'hooks/useDateTimeUtils';
import { EditorValue } from 'types';

import CommonDocFooter from '../components/CommonDocFooter';
import DailyNoteIcon from '../components/DailyNoteIcon';
import { styles } from '../styles';

import { getDocumentComponent } from './utils';

const NoteDocHeader = ({ date }: { date: Date }) => {
  const { format } = useDateTimeUtils();
  return (
    <View style={styles.header} fixed>
      <Text style={styles.name}>Daily Note</Text>
      <View style={[styles.headerContent, { alignItems: 'center' }]}>
        <DailyNoteIcon />
        <Text style={styles.title}>{format(date, 'ddd. MMMM DD, YYYY')}</Text>
      </View>
      <View style={[styles.horizontalLine, { paddingTop: '8' }]} />
    </View>
  );
};

const NoteContents = ({ content }: { content: EditorValue }) => {
  const { document = [] } = content;

  if (document && document.length > 0) {
    return (
      <View style={[styles.column, styles.content]}>
        {document.map((doc) => {
          return getDocumentComponent({ doc });
        })}
      </View>
    );
  }

  return null;
};

interface Props {
  content: EditorValue;
  date: Date;
}

const DailyNotePrintDoc = ({ content, date }: Props) => (
  <Document>
    <Page style={styles.body}>
      <NoteDocHeader date={date} />
      <NoteContents content={content} />
      <CommonDocFooter />
    </Page>
  </Document>
);

export default DailyNotePrintDoc;
