/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable react/display-name */
import { memo, useCallback, useEffect, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import { ScopeProvider } from 'jotai-molecules';

import DebouncedLoadingIndicator from 'components/debouncedLoadingIndicator/DebouncedLoadingIndicator';
import Scrollbar from 'components/scrollbar';
import { VStack } from 'layouts/box/Box';
import useCopyInstanceIdsToClipboard from 'screens/rundown/api/useCopyInstanceIdsToClipboard';
import useGetRundown from 'screens/rundown/api/useGetRundown';
import useLoadInstances from 'screens/rundown/api/useLoadInstances';
import useRundownListHasFocus from 'screens/rundown/hooks/useRundownListFocus';
import Group from 'screens/storybox/components/group';

import Header from './header';
import DraggableInstanceItem from './instanceItem';
import { DetailsScope, useDetailsMolecule } from './store';

const Groups = memo(
  ({
    label,
    itemCount,
    filteredOrders,
    order,
    onPreviewClose,
  }: Readonly<{
    label: string;
    itemCount: number;
    filteredOrders: string[];
    order: string;
    onPreviewClose: () => void;
  }>) => {
    const Row = useCallback(
      ({ index, style, data }: ListChildComponentProps<string[]>) => {
        const mId = data[index];

        return (
          <div key={mId} style={style}>
            <DraggableInstanceItem
              onPreviewClose={onPreviewClose}
              key={mId}
              mId={mId}
              index={index}
              order={order}
            />
          </div>
        );
      },
      [order, onPreviewClose],
    );

    return (
      <Group label={label} itemCount={itemCount} stickyHeader>
        <List
          height={filteredOrders.length * 48}
          itemCount={filteredOrders.length}
          itemData={filteredOrders}
          width="100%"
          itemSize={48}
        >
          {Row}
        </List>
      </Group>
    );
  },
);

interface DetailsProps {
  previewRundown: { id: string; title: string };
  setPreviewRundown: (val: null) => void;
}
const DetailsViewWrapper = memo(
  ({ previewRundown: { id, title }, setPreviewRundown }: Readonly<DetailsProps>) => {
    const ref = useRef<string | null>(null);
    const { useSelectedRundown, useFilteredMOrder, useFilteredMPreOrder, useSelectedIdsValue } =
      useDetailsMolecule();

    const { data, error, loading } = useGetRundown({ mId: id });
    const { loadInstances } = useLoadInstances({ rundownId: id });

    const [selectedRundown, setSelectedRundown] = useSelectedRundown();
    const [filteredMOrder] = useFilteredMOrder();
    const [filteredMPreOrder] = useFilteredMPreOrder();
    const selectedIds = useSelectedIdsValue();
    const [copyInstanceIds] = useCopyInstanceIdsToClipboard();
    const [groupRef, hasFocus, setHasFocus] = useRundownListHasFocus();

    useHotkeys(
      'mod+c',
      async (event) => {
        // ideally the event should be triggered on ctrl+c on windows
        // but the event is triggered when copying something from editor and press ctrl
        // after selecting instance from grid so checking if c is also pressed with ctrl
        // to avoid unwanted copy of instance to clipboard
        if (event.repeat || (event.ctrlKey && event.key?.toLowerCase() !== 'c')) return;
        const selectedItems = Object.values(selectedIds);
        await copyInstanceIds(selectedItems);
      },
      {
        preventDefault: true,
        enabled: hasFocus,
      },
      [selectedIds, hasFocus],
    );

    const onPreviewClose = useCallback(() => {
      //force focus inside the list
      groupRef?.current?.focus();
      setHasFocus(true);
    }, [groupRef, setHasFocus]);

    useEffect(() => {
      if (selectedRundown?.mRefId && selectedRundown?.mRefId !== ref.current) {
        ref.current = selectedRundown?.mRefId;
        loadInstances([
          ...(selectedRundown?.mOrder ?? []),
          ...(selectedRundown?.mPreorder ?? []),
        ]).then(
          () => {},
          () => {},
        );
      }
    }, [
      loadInstances,
      selectedRundown?.mOrder,
      selectedRundown?.mPreorder,
      selectedRundown?.mRefId,
    ]);

    useEffect(() => {
      // @ts-expect-error - Need to work on the rundown typing.
      setSelectedRundown(data?.getRundown ?? null);
    }, [data?.getRundown, setSelectedRundown]);

    if (error) return <div>{error.message}</div>;

    if (loading) return <DebouncedLoadingIndicator />;

    return (
      <VStack height="100%" ref={groupRef}>
        <Header
          title={title}
          mThumbnailKey={selectedRundown?.mThumbnailKey}
          publishingtime={selectedRundown?.mPublishingAt}
          backButtonClicked={() => setPreviewRundown(null)}
        />
        <Scrollbar>
          <>
            <Groups
              label="Ready To Air"
              order="mOrder"
              filteredOrders={filteredMOrder}
              itemCount={filteredMOrder.length}
              onPreviewClose={onPreviewClose}
            />
            <Groups
              label="Preparing"
              order="mPreOrder"
              filteredOrders={filteredMPreOrder}
              itemCount={filteredMPreOrder.length}
              onPreviewClose={onPreviewClose}
            />
          </>
        </Scrollbar>
      </VStack>
    );
  },
);

function Details(props: Readonly<DetailsProps>) {
  return (
    <ScopeProvider scope={DetailsScope} value={props.previewRundown.id}>
      <DetailsViewWrapper {...props} />
    </ScopeProvider>
  );
}

export default memo(Details);
