import { useState } from 'react';
import styled from '@emotion/styled';
import capitalize from 'lodash/capitalize';

import Divider from 'components/divider';
import useDateTimeUtils from 'hooks/useDateTimeUtils';
import { Flex } from 'layouts/box/Box';

import Button from './TextButton';

const informationText = (
  isSelfMessage: boolean,
  timeDistance: string,
  mCreatedAt: string,
  mUpdatedAt: string,
) => {
  const timingInfo = isSelfMessage ? `${capitalize(timeDistance)}` : timeDistance;
  return mCreatedAt === mUpdatedAt ? timingInfo : `${timingInfo} (edited)`;
};

const VDivider = styled(Divider)`
  height: 16px;
  margin: 0 6px;
`;
VDivider.defaultProps = {
  orientation: 'vertical',
};

const InfoWrapper = styled(Flex)<{ isSelfMessage: boolean }>`
  ${({ theme }) => theme.typography.dina.caption};
  margin-left: ${({ isSelfMessage }) => (isSelfMessage ? 0 : '35px')};
  margin-bottom: 2px;
  flex-direction: ${({ isSelfMessage }) => (isSelfMessage ? 'row-reverse' : 'row')};
  justify-content: space-between;
`;

interface InfoRowProps {
  isSelfMessage: boolean;
  onDelete: () => Promise<void>;
  onEdit: () => void;
  onOpen: () => void;
  senderName?: string;
  mCreatedAt?: string;
  mUpdatedAt?: string;
  showEditButton?: boolean;
  showDeleteButton?: boolean;
  canOpenStory?: boolean;
}

function InfoRow({
  isSelfMessage,
  onDelete,
  onEdit,
  onOpen,
  senderName,
  mCreatedAt = '',
  mUpdatedAt = '',
  showEditButton,
  showDeleteButton,
  canOpenStory,
}: Readonly<InfoRowProps>) {
  const { distanceInWordsToNow } = useDateTimeUtils();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const confirmDelete = () => {
    setShowDeleteConfirm(false);
    void onDelete();
  };

  const handleCancelClick = () => {
    setShowDeleteConfirm(false);
  };

  const handleDeleteClick = () => {
    setShowDeleteConfirm(true);
  };

  const timeDistance = distanceInWordsToNow(mCreatedAt, { addSuffix: true });

  return (
    <InfoWrapper isSelfMessage={isSelfMessage}>
      <Flex flexDirection={isSelfMessage ? 'row' : 'row-reverse'}>
        {canOpenStory && (
          <>
            <Button text="Open..." onClick={onOpen} type="emphasis" />
            <VDivider />
          </>
        )}
        <div>{informationText(isSelfMessage, timeDistance, mCreatedAt, mUpdatedAt)}</div>
        {isSelfMessage && (
          <Flex>
            {showDeleteConfirm ? (
              <>
                <VDivider />
                <Button text="Cancel" onClick={handleCancelClick} />
                <VDivider />
                <Button text="Confirm Delete" onClick={confirmDelete} type="danger" />
              </>
            ) : (
              <>
                {showEditButton && (
                  <>
                    <VDivider />
                    <Button text="Edit" onClick={onEdit} />
                  </>
                )}
                {showDeleteButton && (
                  <>
                    <VDivider />
                    <Button text="Delete" onClick={handleDeleteClick} />
                  </>
                )}
              </>
            )}
          </Flex>
        )}
        {!isSelfMessage && senderName && (
          <Flex flexDirection={isSelfMessage ? 'row' : 'row-reverse'}>
            <VDivider />
            {senderName}
          </Flex>
        )}
      </Flex>
    </InfoWrapper>
  );
}

export default InfoRow;
