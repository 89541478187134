import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import InputField from 'components/inputField/InputField';
import Popper from 'components/shared/popper';
import Calendar from 'components/calendar';
import { InputAdornment, IconButton, ClickAwayListener } from '@material-ui/core';
import Popover from 'components/popover/Popover';
import useDateTimeUtils from 'hooks/useDateTimeUtils';
import useCustomDateTimeUtils from 'hooks/useCustomDateTimeUtils';
import DateTimePicker from 'components/dateTimePicker';
import useStyles from './picker-styles';

const WithPopover = ({ children, handleClose, anchorEl }) => (
  <Popover onClose={handleClose} anchorEl={anchorEl}>
    <ClickAwayListener onClickAway={handleClose}>
      <div>{children}</div>
    </ClickAwayListener>
  </Popover>
);

const Picker = ({
  Icon,
  SecondaryIcon,
  displaySecondaryIcon,
  type,
  label,
  timeValue,
  disable,
  onChange,
  blankDisplayValue,
}) => {
  const classes = useStyles();
  const { format, isThisMinute, isPast } = useDateTimeUtils();
  const { getRelativeDate } = useCustomDateTimeUtils();
  const [anchorEl, setAnchorEl] = useState(null);
  const [displayValue, setDisplayValue] = useState(blankDisplayValue);
  const formatString = type === 'time' ? 'HH:mm' : 'YYYY-MM-DD';

  useEffect(() => {
    if (timeValue) {
      !disable && type === 'time' && setDisplayValue(format(timeValue, 'HH:mm'));
      !disable && (isThisMinute(timeValue) || isPast(timeValue)) && setDisplayValue('Now');
      type === 'date' &&
        setDisplayValue(capitalize(getRelativeDate(timeValue, 'ddd. D MMM. YYYY')));
    }
  }, [disable, timeValue, type]);

  const openPicker = (event) => {
    !disable && setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Handles change of date
   * @param {Date} date - date object with zero time component (midnight)
   */
  const handleOnChangeDate = (date) => {
    const { startDate = new Date().toISOString() } = date;
    const nDate = new Date(startDate);
    onChange(nDate);
    setAnchorEl(null);
  };

  /**
   * Handles change of the time component
   * @param {string} timeStr - new time value: "HH:mm"
   */
  const handleTimeChange = (timeStr) => {
    const [hours, minutes] = timeStr.split(':');
    const newTime = new Date(timeValue || new Date());
    newTime.setHours(hours, minutes, 0, 0);
    onChange(newTime);
    setAnchorEl(null);
  };

  return (
    <div>
      <InputField
        onClick={openPicker}
        label={label}
        type="text"
        readOnly
        usage="editor"
        value={displayValue}
        endAdornment={
          <InputAdornment position="end">
            <IconButton className={classes.iconButton} tabIndex="0">
              {displaySecondaryIcon ? <SecondaryIcon /> : <Icon />}
            </IconButton>
          </InputAdornment>
        }
      />
      {type === 'date' ? (
        <Popper {...{ anchorEl }} placement="left">
          <ClickAwayListener onClickAway={handleClose}>
            <div className={classes.calendarContainer}>
              <Calendar
                selectedDateRange={
                  timeValue ? { startTime: timeValue } : { startTime: new Date().toDateString }
                }
                changeSelectedDateRange={handleOnChangeDate}
                hideUnscheduleButton
                onClose={handleClose}
              />
            </div>
          </ClickAwayListener>
        </Popper>
      ) : (
        <WithPopover
          {...{
            handleClose,
            anchorEl,
          }}
        >
          <DateTimePicker
            type={type}
            defaultDate={format(timeValue || new Date(), formatString)}
            onBlur={(event) => handleTimeChange(event.target.value)}
            autoFocus
          />
        </WithPopover>
      )}
    </div>
  );
};

Picker.prototype = {
  /** Icon to be shown as end adornment of the input */
  Icon: PropTypes.node,
  /** Icon to be shown instead of first icon */
  SecondaryIcon: PropTypes.node,
  /** boolean to show secondary icon instead of first */
  displaySecondaryIcon: PropTypes.bool,
  /** Type of Picker */
  type: PropTypes.oneOf(['date', 'time']),
  /** Label of the input field */
  label: PropTypes.string,
  /** time to be changed */
  timeValue: PropTypes.string,
  /** Disables opening the popover */
  disable: PropTypes.bool,
  /** Callback to be invoked when time is changed. Value given as Date()  */
  onChange: PropTypes.func,
  /** String to show if there is no time value */
  blankDisplayValue: PropTypes.string,
};

Picker.defaultProps = {
  Icon: '',
  SecondaryIcon: '',
  displaySecondaryIcon: false,
  type: 'date',
  label: 'Date',
  timeValue: undefined,
  disable: false,
  onChange: () => {},
  blankDisplayValue: 'n/a',
};

export default Picker;
