import { useCallback, useEffect, useMemo, useState } from 'react';

import { useGetTypedOptionList } from 'api/optionLists/useGetOptionList';
import { useGetOptionLists } from 'api/optionLists/useGetOptionLists';
import { Button } from 'components/buttons';
import Dialog from 'components/dialogs/DialogBuilder';
import ChoiceField from 'components/mdfEditor/fields/choice/ChoiceField';
import { HStack } from 'layouts/box/Box';
import { Alternative, FieldTypeEnum, TreeChoiceOptionList } from 'types/graphqlTypes';

import { createTree, revertTree, TreeNode as TreeNodeProps } from '../utils';

import EditTreeChoiceAlternatives from './EditTreeChoiceAlternatives';

interface Props {
  open: boolean;
  setOpen: (val: boolean) => void;
  treeAlternatives: string[][];
  savedOptionListId: string | null;
  fieldName: string;
  doUpdate: (list: TreeChoiceOptionList | null, treeAlts: string[][]) => void;
  openOptionList: (id: string) => void;
}

function EditTreeChoiceDialog({
  open,
  setOpen,
  treeAlternatives,
  savedOptionListId,
  fieldName,
  doUpdate,
  openOptionList,
}: Readonly<Props>) {
  const [tree, setTree] = useState<readonly TreeNodeProps[]>(createTree(treeAlternatives));
  const [selectedListId, setSelectedListId] = useState<string | null>(savedOptionListId);
  const { optionTrees } = useGetOptionLists();
  const { optionList: externalOptionList, loading } = useGetTypedOptionList(
    selectedListId,
    'treechoice',
    true,
  );

  useEffect(() => {
    setTree(createTree(loading ? [] : externalOptionList?.treeAlternatives ?? treeAlternatives));
  }, [loading, treeAlternatives, externalOptionList]);

  useEffect(() => {
    setSelectedListId(savedOptionListId);
  }, [savedOptionListId]);

  const optionListAlternatives: Alternative[] = useMemo(() => {
    return optionTrees.map((list) => {
      return {
        id: list.id,
        value: list.id,
        label: list.label,
      };
    });
  }, [optionTrees]);

  const onDone = useCallback(() => {
    const arr = revertTree(tree);
    doUpdate(externalOptionList, arr);
    setOpen(false);
  }, [doUpdate, tree]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      style={{ minWidth: '800px', maxWidth: '800px' }}
    >
      <Dialog.Header>Configure Tree Choice for &apos;{fieldName}&apos;</Dialog.Header>
      <Dialog.Body bodyHeight="50vh">
        <EditTreeChoiceAlternatives
          tree={tree}
          updateTree={setTree}
          isExternal={!!selectedListId}
          name={fieldName}
        >
          <HStack gap="10px" alignItems="end">
            <ChoiceField
              editorId="AlternativesDialog"
              fieldModel={{
                fieldId: 'optionLists',
                type: FieldTypeEnum.choice,
                defaultValue: { value: null },
                alternatives: optionListAlternatives,
              }}
              fieldSettings={null}
              defaultFieldSettings={{
                fieldId: 'optionLists',
                label: 'External option tree',
                visible: true,
                hint: '',
              }}
              value={selectedListId}
              setValue={(val) => setSelectedListId(val as string | null)}
              errorMessage={undefined}
              style={{ width: '240px' }}
              view="default"
            />
            {selectedListId && (
              <Button
                width={90}
                height={32}
                variant="outlined"
                usage="outlined"
                onClick={() => openOptionList(selectedListId)}
              >
                Go to tree
              </Button>
            )}
          </HStack>
        </EditTreeChoiceAlternatives>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton />
        <Dialog.ConfirmButton label="Confirm" onClick={onDone} disabled={loading} />
      </Dialog.Footer>
    </Dialog>
  );
}

export default EditTreeChoiceDialog;
