import React, { useState, memo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CalendarOff } from 'assets/icons/systemicons/calendar_off.svg';
import { ReactComponent as CalendarOn } from 'assets/icons/systemicons/calendar_on.svg';
import { ReactComponent as TimeIcon } from 'assets/icons/systemicons/time.svg';
import Picker from 'components/instanceCard/components/header/components/publishSettings/components/picker';
import Divider from 'components/divider';
import Popover from 'components/popover';
import InputField from 'components/inputField';
import {
  DateTimeWrapper,
  DatePickerWrapper,
  TimePickerWrapper,
  ButtonWrapper,
  CancelButton,
  OkButton,
} from './styled';
import useDateTimeUtils from 'hooks/useDateTimeUtils';

const Input = memo(InputField);

/**
 * Sets the date component of given timestamp
 * @param {string} timeStamp - timestamp (ISO string)
 * @param {Date} date - date with date information
 * @returns updated timestamp (ISO string)
 */
const setDateIsoString = (timeStamp, date) => {
  const resultDate = new Date(timeStamp);
  const dateValue = new Date(date);
  resultDate.setFullYear(dateValue.getFullYear());
  resultDate.setMonth(dateValue.getMonth());
  resultDate.setDate(dateValue.getDate());
  return resultDate.toISOString();
};

/**
 * Sets the time component of given timestamp
 * @param {string} timeStamp - timestamp (ISO string)
 * @param {Date} timeValue - date with time information
 * @returns updated timestamp (ISO string)
 */
const setHoursIsoString = (timeStamp, timeValue) => {
  const resultTime = new Date(timeStamp);
  const dateValue = new Date(timeValue);
  resultTime.setHours(
    dateValue.getHours(),
    dateValue.getMinutes(),
    dateValue.getSeconds(),
    dateValue.getMilliseconds(),
  );
  return resultTime.toISOString();
};

const PickerView = ({ dateTime, type, description, label, onOK, readOnly, direction }) => {
  const { format } = useDateTimeUtils();
  const [anchorEl, setAnchorEl] = useState();

  const getInitialTimeISOString = useCallback(() => {
    const initialDateString = dateTime;
    if (!initialDateString) return new Date().toISOString();
    const resultDate = new Date(initialDateString);
    return resultDate.toISOString();
  }, [dateTime]);

  const [newDateTime, setNewDateTime] = useState(getInitialTimeISOString());

  useEffect(() => {
    if (anchorEl) setNewDateTime(getInitialTimeISOString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl]);

  const handleDateChange = useCallback(
    (value) => {
      if (!value) return;
      setNewDateTime(setDateIsoString(newDateTime, value));
    },
    [newDateTime],
  );

  const handleTimeChange = useCallback(
    (value) => {
      if (!value) return;

      setNewDateTime(setHoursIsoString(newDateTime, value));
    },
    [newDateTime],
  );

  const handleCancel = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setNewDateTime(dateTime);
      setAnchorEl(null);
    },
    [dateTime],
  );

  const handleOK = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      const outputObject = {};
      outputObject[type] = newDateTime;
      onOK(outputObject);
      setAnchorEl(null);
    },
    [newDateTime, onOK, type],
  );

  const handleOpenPopover = useCallback(
    (event) => {
      if (!readOnly) setAnchorEl(event.currentTarget);
    },
    [readOnly],
  );

  useEffect(() => {
    setNewDateTime(getInitialTimeISOString());
  }, [getInitialTimeISOString]);

  return (
    <>
      <div role="presentation" onClick={handleOpenPopover}>
        <Input
          value={dateTime ? format(dateTime, 'D-MMM-YYYY HH:mm:ss') : ''}
          usage="editor"
          label={label}
          description={description}
          disabled={readOnly}
          direction={direction}
          onClear={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setNewDateTime('');
            const outputObject = {};
            outputObject[type] = '';
            onOK(outputObject);
          }}
        />
      </div>
      <Popover anchorEl={anchorEl} position="publish-setting-custom" onClose={handleCancel}>
        <>
          <DateTimeWrapper>
            <DatePickerWrapper>
              <Picker
                Icon={CalendarOff}
                SecondaryIcon={CalendarOn}
                type="date"
                label="Publish Date"
                timeValue={newDateTime}
                onChange={handleDateChange}
              />
            </DatePickerWrapper>
            <TimePickerWrapper>
              <Picker
                Icon={TimeIcon}
                type="time"
                label="Publish Time"
                timeValue={newDateTime}
                onChange={handleTimeChange}
              />
            </TimePickerWrapper>
          </DateTimeWrapper>
          <div>
            <Divider />
            <ButtonWrapper>
              <CancelButton onClick={handleCancel}>Cancel</CancelButton>

              <OkButton onClick={handleOK}>Ok</OkButton>
            </ButtonWrapper>
          </div>
        </>
      </Popover>
    </>
  );
};

PickerView.propTypes = {
  /** selected time in the picker */
  dateTime: PropTypes.string,
  /** field type of picker */
  type: PropTypes.string.isRequired,
  /** description under the input */
  description: PropTypes.string,
  /** label for the input */
  label: PropTypes.string,
  /** callback to choose a date from the picker */
  onOK: PropTypes.func,
  /** boolean to disable the picker */
  readOnly: PropTypes.bool,
};

PickerView.defaultProps = {
  dateTime: undefined,
  description: '',
  label: '',
  onOK: () => {},
  readOnly: false,
};

export default memo(PickerView);
