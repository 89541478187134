import { useEffect, useState } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import { atom, useAtom } from 'jotai';

import Calendar from 'components/calendar';
import Popper from 'components/shared/popper';
import { RangeBy } from 'types/graphqlTypes';

import { CalendarContainer } from './styled';

export interface DateRange {
  startDate: string;
  endDate: string | null;
  rollingDate?: string;
}

export interface OpenDatePicker {
  anchorEl: null | HTMLElement;
  selectRange?: boolean;
  hideUnscheduleButton?: boolean;
  dateType?: keyof RangeBy;
  showTypeSelect?: boolean;
  showRollingDate?: boolean;
  startValue?: DateRange | string; // If string is supplied, it is assumed to be only the s
  selectDate?: (val: DateRange, type?: keyof RangeBy) => void;
  onUnschedule?: () => void;
  onCancel?: () => void;
  showTimePicker?: boolean;
}

const defaults: OpenDatePicker = {
  anchorEl: null,
  showTimePicker: true,
};

const datePicker = atom<OpenDatePicker>({
  ...defaults,
});

export const useDatePicker = () => useAtom(datePicker);

const getDateRange = (val: string | DateRange | undefined) => {
  if (typeof val === 'object') return val;
  if (val) {
    return typeof val === 'string' ? { startDate: val, endDate: null } : undefined;
  }
};

export function DatePicker() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [state, setState] = useDatePicker();
  const [type, setType] = useState<keyof RangeBy | null>(null);

  const startDate = getDateRange(state.startValue);

  useEffect(() => {
    if (state.anchorEl) {
      setAnchorEl(state.anchorEl);
    }
    if (state.dateType) {
      setType(state.dateType ?? 'createdAt');
    }
  }, [state]);

  const cleanupAndClose = () => {
    setAnchorEl(null);
    setState({
      anchorEl: null,
      selectDate: undefined,
      onCancel: undefined,
    });
  };

  const onChange = (dates: DateRange) => {
    if (state.selectDate) {
      state.selectDate(dates, type ?? undefined);
    }
    cleanupAndClose();
  };

  const onCancel = () => {
    if (state.onCancel) {
      state.onCancel();
    }
    cleanupAndClose();
  };

  const onUnschedule = () => {
    if (state.onUnschedule) {
      state.onUnschedule();
    }
    cleanupAndClose();
  };

  return (
    <Popper anchorEl={anchorEl} position="bottom">
      <ClickAwayListener onClickAway={onCancel}>
        <CalendarContainer>
          <Calendar
            changeSelectedDateRange={onChange}
            onUnschedule={onUnschedule}
            selectedDateRange={startDate}
            hideUnscheduleButton={state.hideUnscheduleButton ?? true}
            disableRangeSwitch={true}
            showTimePicker={state.showTimePicker ?? true}
            showRollingDate={state.showRollingDate}
            selectRange={state.selectRange ?? false}
            onClose={onCancel}
            dateType={type}
            showTypeSelect={state.showTypeSelect}
            onTypeSelect={(updatedType: keyof RangeBy) => setType(updatedType)}
          />
        </CalendarContainer>
      </ClickAwayListener>
    </Popper>
  );
}
