import { useCallback, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { uniqMembers } from 'utils/member/uniqMembers';

import { Queries } from './queries';
import { GetInput, SearchProps, SearchResult } from './types';
import { getInput } from './utils';

export const useSearch = ({
  skip,
  searchString,
  searchableKey,
  perPagelimit = 25,
  metadataFilter,
  mdfId,
  toolbarState,
  doPoll,
  pollInterval = 15000,
  queryType = 'default',
  forceMetadataMTypes,
  fetchAll = false,
  mIds,
  aggregations: aggregationInputs,
}: SearchProps) => {
  const input = useMemo(
    () =>
      getInput(
        searchString,
        searchableKey,
        toolbarState,
        perPagelimit,
        metadataFilter ?? {},
        mdfId,
        forceMetadataMTypes,
        mIds,
        aggregationInputs,
      ),
    [
      searchString,
      searchableKey,
      toolbarState,
      perPagelimit,
      metadataFilter,
      mdfId,
      forceMetadataMTypes,
      mIds,
      aggregationInputs,
    ],
  );

  const searchResult = useQuery<SearchResult, GetInput>(Queries[queryType], {
    variables: {
      input,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
    skip,
  });

  const { data, error, loading, fetchMore, startPolling, stopPolling, refetch } = useMemo(
    () => searchResult,
    [searchResult],
  );

  useEffect(() => {
    if (doPoll) startPolling(pollInterval);

    return () => {
      if (doPoll) stopPolling();
    };
  }, [doPoll, pollInterval]);

  const items = useMemo(() => data?.searchItem?.items ?? [], [data?.searchItem?.items]);
  const aggregations = useMemo(
    () => data?.searchItem?.aggregations ?? [],
    [data?.searchItem?.aggregations],
  );

  const handleLoadMore = useCallback(async () => {
    if (data?.searchItem.nextToken && items.length < data?.searchItem.total) {
      await fetchMore({
        variables: {
          input: {
            ...input,
            searchAfter: data?.searchItem.nextToken,
          },
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return {
            searchItem: {
              items: uniqMembers([
                ...previousResult.searchItem.items,
                ...fetchMoreResult.searchItem.items,
              ]),
              nextToken: fetchMoreResult?.searchItem.nextToken,
              total: fetchMoreResult?.searchItem.total,
              __typename: 'PaginatedItemType',
            },
          };
        },
      });
    }
  }, [data?.searchItem.nextToken, data?.searchItem.total, items.length, fetchMore, input]);

  useEffect(() => {
    if (fetchAll && data?.searchItem.nextToken && items.length < data?.searchItem.total)
      handleLoadMore().catch(() => {});
  }, [data?.searchItem.nextToken, data?.searchItem.total, fetchAll, handleLoadMore, items.length]);

  const hasMore =
    typeof data?.searchItem.total === 'number' ? items.length < data?.searchItem.total : false;

  return {
    items,
    error,
    loading,
    total: data?.searchItem?.total ?? 0,
    handleLoadMore,
    hasMore,
    refetch,
    aggregations,
  };
};
