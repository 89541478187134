import { Fragment, MouseEvent, useEffect, useRef, useState } from 'react';
import { BaseSelection, Editor, Range, Transforms } from 'slate';
import { ReactEditor, useFocused, useSlate } from 'slate-react';

import { useGetAiPrompts } from 'api/config/useGetAiPrompts';
import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add_small.svg';
import { useAddInstanceDialog } from 'components/addInstanceDialog/AddInstanceDialog';
import { StyledCreateButton } from 'components/createStoryItems/styled';
import Divider from 'components/divider';
import movePortalToViewPort from 'components/editor/utils/movePortalToViewPort';
import stopAllPropagation from 'components/editor/utils/stopAllPropagation';
import { CloseIcon } from 'components/orderFormDialog/styled';
import Portal from 'components/portal';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip/Tooltip';
import useCheckUserRight from 'hooks/useCheckUserRight';
import getTime from 'screens/rundown/components/editor/utils/getTime';
import { CustomElement } from 'types';
import accessibleOnClick from 'utils/accessibleOnClick';

import { useEditorMolecule } from '../../store';
import getWords from '../../utils/getWords';

import AskAI from './AskAI';

import { AIButton, AIContainer, AIIcon, Container, More, MoreItem, TextButton } from './styled';

const getWordCount = (nodes: CustomElement[]) => getWords(nodes, true).length;

function HoveringTooltip({
  hostReadSpeed,
  writeLock,
}: Readonly<{
  hostReadSpeed: number;
  writeLock: boolean;
}>) {
  const editor = useSlate();
  const inFocus = useFocused();
  const { prompts } = useGetAiPrompts();
  const [checkUserRight] = useCheckUserRight();
  const [, setAddInstance] = useAddInstanceDialog();
  const { useShowHoveringToolbar } = useEditorMolecule();

  const [showMoreOptions, setShowMoreOptions] = useState<boolean>(false);
  const [textReadSpeed, setTextReadSpeed] = useState('0');
  const [isAsking, setIsAsking] = useState(false);
  const [question, setQuestion] = useState('');
  const portalRef = useRef<HTMLDivElement | null>(null);
  const selectionRef = useRef<BaseSelection | null>(null);
  const [showHoveringToolbar, setShowHoveringToolbar] = useShowHoveringToolbar();

  const canUseAI = checkUserRight('feature', 'ChatGPT');
  const showMore = prompts.length > 4;
  const selectedText = window?.getSelection()?.toString();

  const { selection } = editor;

  const doDismiss = (e?: MouseEvent<SVGSVGElement>) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const el = portalRef.current;
    if (el) el.removeAttribute('style');
  };

  const onDone = () => {
    setQuestion('');
    setShowMoreOptions(false);
    setIsAsking(false);
  };

  useEffect(() => {
    if (!showHoveringToolbar) {
      doDismiss();
      setShowHoveringToolbar(true);
    }
  }, [setShowHoveringToolbar, showHoveringToolbar]);

  useEffect(() => {
    if (!selection) return;
    onDone();
  }, [selection]);

  useEffect(() => {
    const el = portalRef.current;
    if (!el) return;

    if (
      !selection ||
      !inFocus ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ''
    ) {
      el.removeAttribute('style');
      return;
    }

    const nodes = Editor.fragment(editor, selection) as CustomElement[];

    const wordCount = getWordCount(nodes);
    const wordsPerSecond = hostReadSpeed / 60;
    const speakDuration = Math.ceil(wordCount / wordsPerSecond);
    setTextReadSpeed(getTime(speakDuration));

    movePortalToViewPort(el);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedText, inFocus, hostReadSpeed]);

  const doCreate = (ev: MouseEvent<HTMLButtonElement>) => {
    stopAllPropagation(ev);
    selectionRef.current = selection;
    setAddInstance({
      text: selectedText ?? null,
      onCancel: () => {
        if (selectionRef.current) {
          ReactEditor.focus(editor as ReactEditor);
          Transforms.select(editor, selectionRef.current);
          selectionRef.current = null;
        }
      },
    });
  };

  if (isAsking)
    return (
      <AskAI
        question={question}
        canReplace={writeLock}
        isAsking={isAsking}
        selection={selection}
        editor={editor}
        selectedText={selectedText ?? ''}
        onDone={onDone}
        setAddInstance={setAddInstance}
      />
    );

  return (
    <Portal>
      <Container
        ref={portalRef}
        onMouseDown={(e) => {
          // prevent toolbar from taking focus away from editor
          e.preventDefault();
        }}
      >
        <AIContainer>
          <Tooltip title="Create instance from text">
            <StyledCreateButton
              aria-haspopup="true"
              aria-owns="create-menu"
              onClick={doCreate}
              $notAllowed={false}
              $size={24}
              $margin="0px"
            >
              <AddIcon />
            </StyledCreateButton>
          </Tooltip>
          {canUseAI && (
            <>
              <AIButton {...accessibleOnClick(() => setIsAsking(true))}>
                <AIIcon className="skipOverride" />
                Ask AI
              </AIButton>
              <Divider orientation="vertical" flexItem />
              {(showMore ? prompts.slice(0, 3) : prompts).map((prompt) => (
                <Fragment key={prompt.id}>
                  <TextButton
                    onClick={() => {
                      setQuestion(prompt.value);
                      setIsAsking(true);
                    }}
                  >
                    {prompt.label}
                  </TextButton>
                  <Divider orientation="vertical" flexItem />
                </Fragment>
              ))}
              {showMore && (
                <>
                  <TextButton onClick={() => setShowMoreOptions(!showMoreOptions)}>
                    More...
                    <More showMoreOptions={showMoreOptions}>
                      {prompts.slice(3).map((prompt) => (
                        <MoreItem
                          key={prompt.id}
                          button={true}
                          onClick={() => {
                            setQuestion(prompt.value);
                            setIsAsking(true);
                          }}
                        >
                          {prompt.label}
                        </MoreItem>
                      ))}
                    </More>
                  </TextButton>
                  <Divider orientation="vertical" flexItem />
                </>
              )}
            </>
          )}
          <Text variant="listItemLabel" color="highEmphasis">
            {textReadSpeed}
          </Text>
          <Tooltip title="Dismiss">
            <CloseIcon onClick={doDismiss} />
          </Tooltip>
        </AIContainer>
      </Container>
    </Portal>
  );
}

export default HoveringTooltip;
