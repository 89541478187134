import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/buttons';

import DateTimeInput from '../dateTimeInput/DateTimeInput';

import {
  ButtonWrapper,
  FooterWrapper,
  DTInputsWrapper,
  InputDivider as Divider,
  DTInputWithLabel,
  DateLabel,
  DateTypeLabel,
} from './styled';
import ChoiceField from 'components/mdfEditor/fields/choice/ChoiceField';
import { FieldTypeEnum } from 'types/graphqlTypes';
import { Box } from 'layouts/box/Box';

const typeField = {
  fieldId: 'dateType',
  type: FieldTypeEnum.choice,
  required: true,
  defaultValue: { value: 'createdAt' },
  alternatives: [
    {
      id: 'createdAt',
      label: 'Search by created date',
      value: 'createdAt',
    },
    {
      id: 'updatedAt',
      label: 'Search by updated date',
      value: 'updatedAt',
    },
    {
      id: 'scheduledAt',
      label: 'Search by scheduled date',
      value: 'scheduledAt',
    },
  ],
};

const layoutSettingsType = {
  visible: true,
  fieldId: 'dateType',
  hint: '',
  label: '',
};

const Footer = ({
  allowTimeSelection,
  onUnschedule,
  hideUnscheduleButton,
  onApply,
  onClose,
  isDateChanged,
  startDate,
  startTime,
  endDate,
  endTime,
  selectRange,
  showTimePicker,
  interval,
  format,
  onTimeSelect,
  onDateSelect,
  dateType,
  onTypeSelect,
  handleRangeSelect,
  showTypeSelect,
  disabled,
  rollingDate,
}) => (
  <FooterWrapper>
    <DTInputsWrapper $withTimePicker={showTimePicker}>
      <DTInputWithLabel>
        <DateLabel>
          {selectRange ? 'Start Date' : 'Date'}{' '}
          {startDate && (
            <DateTypeLabel $rollingDate={rollingDate}>
              ({rollingDate ? 'Rolling' : 'Exact'})
            </DateTypeLabel>
          )}
        </DateLabel>
        <DateTimeInput
          date={startDate}
          time={startTime}
          showTimePicker={showTimePicker}
          interval={interval}
          format={format}
          onTimeSelect={onTimeSelect}
          onDateSelect={onDateSelect}
          otherDateRange={endDate}
          otherTimeRange={endTime}
          selectRange={selectRange}
          handleRangeSelect={handleRangeSelect}
          allowTimeSelection={allowTimeSelection}
          timeFor="date"
        />
      </DTInputWithLabel>
      {selectRange && (
        <>
          <Divider />
          <DTInputWithLabel>
            <DateLabel>
              End Date{' '}
              {endDate && (
                <DateTypeLabel $rollingDate={rollingDate}>
                  ({rollingDate ? 'Rolling' : 'Exact'})
                </DateTypeLabel>
              )}
            </DateLabel>
            <DateTimeInput
              date={endDate}
              time={endTime}
              showTimePicker={showTimePicker}
              interval={interval}
              format={format}
              onTimeSelect={onTimeSelect}
              onDateSelect={onDateSelect}
              otherDateRange={startDate}
              otherTimeRange={startTime}
              selectRange={selectRange}
              handleRangeSelect={handleRangeSelect}
              allowTimeSelection={allowTimeSelection}
              timeFor="endDate"
            />
          </DTInputWithLabel>
        </>
      )}
    </DTInputsWrapper>
    <ButtonWrapper>
      <Box flex="1">
        {showTypeSelect && (
          <Box width="196px">
            <ChoiceField
              editorId="calenderFooter"
              fieldModel={typeField}
              fieldSettings={null}
              defaultFieldSettings={layoutSettingsType}
              value={dateType}
              setValue={onTypeSelect}
              errorMessage={undefined}
              setError={() => {}}
            />
          </Box>
        )}
        {!hideUnscheduleButton && (
          <Button onClick={onUnschedule} usage="warning" width={120}>
            Unschedule
          </Button>
        )}
      </Box>
      <Button onClick={onClose} variant="outlined" usage="outlined" width={110} height={32}>
        Cancel
      </Button>
      <Button onClick={onApply} disabled={disabled || !isDateChanged} width={110} height={32}>
        Apply
      </Button>
    </ButtonWrapper>
  </FooterWrapper>
);

Footer.propTypes = {
  onUnschedule: PropTypes.func,
  onClose: PropTypes.func,
  onApply: PropTypes.func,
  hideUnscheduleButton: PropTypes.bool,
  disabled: PropTypes.bool,
  rollingDate: PropTypes.bool,
};

Footer.defaultProps = {
  onUnschedule: () => {},
  onClose: () => {},
  onApply: () => {},
  hideUnscheduleButton: false,
  disabled: false,
  rollingDate: null,
};

export default Footer;
