import { memo, useCallback, useMemo } from 'react';

import useGetFeed from 'api/useGetFeed';
import { useCreateMemberDialog } from 'components/createNewV3/CreateNewDialog';
import LoadingIndicator from 'components/loadingIndicator';
import Scrollbar from 'components/scrollbar';
import List from 'features/feedViewer/components/list';
import { ExtendedFeedItem } from 'features/feedViewer/FeedViewer';
import useCreateRow from 'screens/feeds/components/table/useCreateRow';
import { MemberType } from 'types/graphqlTypes';
import { FilterValueType } from 'types/widget';

import ListItems from './components/items';
export interface ProviderType
  extends Pick<MemberType, 'mRefId' | 'mTitle' | 'accessToken' | 'useProxy' | 'mMetaData'> {}
interface FeedDeckProps {
  providers?: { mRefId: string }[];
  allProviders?: ProviderType[];
  filters?: FilterValueType;
  openInModal?: (item: ExtendedFeedItem) => void;
  freeText?: string;
}

const Loading = () => (
  <div
    style={{
      position: 'absolute',
      bottom: '50px',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }}
  >
    <LoadingIndicator />
  </div>
);

function FeedDeck({
  providers = [],
  allProviders,
  filters,
  openInModal = undefined,
  freeText,
}: Readonly<FeedDeckProps>) {
  const { createRow } = useCreateRow();
  const [, showCreateMemberDialog] = useCreateMemberDialog();
  const { items, loading, fetchMore } = useGetFeed({
    selectedProviders: providers,
    limit: 25,
    filters,
    freeText,
  });

  const rows = useMemo(
    () =>
      items.map((item) => {
        const provider = allProviders?.find((p) => p.mRefId === item.provider);
        const accessToken = provider?.accessToken ?? '';
        const useProxy = provider?.useProxy;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return createRow(item, accessToken, useProxy);
      }),
    [JSON.stringify(items), providers?.length],
  );

  const fetchMoreItems = useCallback(async () => {
    if (!loading && items?.length > 0) await fetchMore();
  }, [fetchMore, loading, items?.length]);

  return (
    <>
      {loading && <Loading />}
      <Scrollbar closeToBottom={fetchMoreItems}>
        <List>
          <List.Body>
            <ListItems
              items={rows}
              openInModal={openInModal}
              showCreateMemberDialog={showCreateMemberDialog}
            />
          </List.Body>
        </List>
      </Scrollbar>
    </>
  );
}

export default memo(FeedDeck);
