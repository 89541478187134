import { Button, type ButtonProps } from 'components/buttons';

interface LoadingButtonProps extends ButtonProps {
  title?: string | React.ReactElement;
}

/** Icon button, exported with the most used styles. Override them if needed. */
export function IconButton({
  usage = 'text',
  variant = 'discreet',
  size = 'icon',
  children,
  ...rest
}: LoadingButtonProps) {
  return (
    <Button usage={usage} variant={variant} size={size} {...rest}>
      {children}
    </Button>
  );
}
