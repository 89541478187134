import { gql } from '@apollo/client';

// ─── Fragments ───────────────────────────────────────────────────────────────

const MEMBER_FIELDS = gql`
  fragment MemberTypeFields on MemberType {
    mId
    mRefId
    mPlannedDuration
    metadata
    mType
    mTitle
    mDescription
    mdfId
    mUpdatedAt
    mCreatedAt
    mCreatedById
    mPublishingAt
    mState
    mStoryId
    mProperties {
      __typename
      ... on PlatformType {
        id
        platform
        platformKind
      }
    }
    mAssignedMembers {
      mId
    }
  }
`;

const COLUMN_FIELDS = gql`
  fragment ColumnFields on ColumnType {
    id
    title
    members {
      ...MemberTypeFields
    }
  }
  ${MEMBER_FIELDS}
`;

const BOARD_FIELDS = gql`
  fragment BoardFields on BoardType {
    mId
    mRefId
    columns {
      ...ColumnFields
    }
  }
  ${COLUMN_FIELDS}
`;

const BOARD_UPDATED_FIELDS = gql`
  fragment BoardUpdatedFields on BoardUpdatedType {
    boardId
    crudAction
    memberIds
    memberId
    columnId
    member {
      ...MemberTypeFields
    }
  }
  ${MEMBER_FIELDS}
`;

// ─── Queries ─────────────────────────────────────────────────────────────────

export const GET_BOARD = gql`
  query GetBoard($input: GetBoardInput!) {
    getBoard(input: $input) {
      ...BoardFields
    }
  }
  ${BOARD_FIELDS}
`;

// ─── Mutations ───────────────────────────────────────────────────────────────

export const UPDATE_BOARD = gql`
  mutation UpdateBoard($input: UpdateBoardInput) {
    updateBoard(input: $input) {
      ...BoardUpdatedFields
    }
  }
  ${BOARD_UPDATED_FIELDS}
`;

// ─── Subscriptions ───────────────────────────────────────────────────────────

export const BOARD_UPDATED_SUBSCRIPTION = gql`
  subscription UpdatedBoard($boardId: String!) {
    updatedBoard(boardId: $boardId) {
      ...BoardUpdatedFields
    }
  }
  ${BOARD_UPDATED_FIELDS}
`;
