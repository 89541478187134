import { memo, useMemo } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import tooltipEnum from 'components/editor/constants/tooltipEnum';
import Tooltip from 'components/tooltip';
import transientOptions from 'theme/helpers';

interface ContainerProps {
  $isActive: boolean;
  $disabled: boolean;
}
const Container = styled('div', transientOptions)<ContainerProps>`
  background-color: ${({ $isActive, theme }) => $isActive && theme.palette.dina.iconDisabled};
  flex-shrink: 0;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  svg {
    * {
      fill: ${({ theme }) => theme.palette.dina.highEmphasis};
      fill-opacity: ${({ $isActive }) => {
        if ($isActive) return 0.87;
        return 0.7;
      }};
    }
  }
  :hover {
    svg {
      * {
        fill-opacity: 1;
      }
    }
  }
  ${({ $disabled, theme }) =>
    $disabled
      ? css`
          cursor: auto;
          background-color: transparent;
          opacity: 0.36;
          path {
            fill: ${theme.palette.dina.mediumEmphasis};
            fill-opacity: 0.7;
          }
          :hover {
            path {
              fill-opacity: 0.7;
            }
          }
        `
      : ''}
`;

interface ButtonBaseProps {
  IconComponent: React.FC<React.SVGProps<SVGSVGElement>>;
  type: string;
  isActive?: boolean;
  disabled?: boolean;
  onMouseDown?: React.MouseEventHandler<SVGSVGElement>;
}

function ButtonBase({
  IconComponent = () => null,
  type,
  isActive = false,
  disabled = false,
  onMouseDown = () => {},
}: ButtonBaseProps) {
  const renderIcon = useMemo(
    () => (
      <Tooltip title={tooltipEnum[type]}>
        <Container $isActive={isActive} $disabled={disabled}>
          <IconComponent className="skipOverride" onMouseDown={onMouseDown} />
        </Container>
      </Tooltip>
    ),
    [onMouseDown, type, isActive],
  );

  return renderIcon;
}

export default memo(ButtonBase);
