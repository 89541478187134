/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import differenceBy from 'lodash/differenceBy';
import truncate from 'lodash/truncate';

import useFeedViewer from 'hooks/useFeedViewer';
import { useSelectedConversationId } from 'store';
import { useRightHidden, useRightSelection } from 'store/sidebar';
import useGetUser from 'hooks/useGetUser';
import memberTypes from 'operations/memberTypes';
import Alert from 'components/alert';
import infoImage from 'assets/icons/systemicons/info_off.svg';
import checkIsSystemMessage from 'utils/messageHub/checkIsSystemMessage';
import conversationTypes from 'utils/constants/conversationTypes';
import useDinaNavigate from 'hooks/useDinaNavigate';
import useSelectRundownInstance from 'hooks/useSelectRundownInstance';

import {
  extractNotificationProperties,
  extractMessageFromSlateObj,
  getAlertTitle,
  openMessageHub,
  getAlertAvatar,
} from './utils';
import useStyles from './alert-styles';
import useDateTimeUtils from 'hooks/useDateTimeUtils';

const AlertView = ({ notifications, onOpenMessageHub, removeNotificationMessageCount }) => {
  const { differenceInSeconds } = useDateTimeUtils();
  const { navigateTo } = useDinaNavigate();
  const classes = useStyles();
  const timeOut = useRef(null);
  const [notificationList, setNotificationList] = useState([]);
  const [dismissedNotifications, setDismissedNotifications] = useState([]);
  const [, setSelectedConversationId] = useSelectedConversationId();
  const [, openFeedViewer] = useFeedViewer();
  const [, setRightHidden] = useRightHidden();
  const [, setRightSelection] = useRightSelection();
  const { selectRundownInstance } = useSelectRundownInstance();

  const { getUser } = useGetUser();

  useEffect(() => {
    const recentNotifications = notifications.filter(
      ({ time }) => differenceInSeconds(new Date(), time) < 15,
    );

    const recentDismissedNotifications = dismissedNotifications.filter(
      ({ time }) => differenceInSeconds(new Date(), time) < 15,
    );

    const alertsToShow = differenceBy(recentNotifications, recentDismissedNotifications, 'time');
    setDismissedNotifications(recentDismissedNotifications);
    setNotificationList(alertsToShow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  useEffect(() => {
    if (timeOut.current) {
      clearTimeout(timeOut.current);
      timeOut.current = null;
    }
    timeOut.current =
      notificationList.length &&
      setTimeout(() => {
        setNotificationList([]);
      }, 15000);
    return () => {
      if (timeOut.current) {
        clearTimeout(timeOut.current);
        timeOut.current = null;
      }
    };
  }, [notificationList, setNotificationList]);

  const handleDismiss = (notification) => {
    const filteredNotifications = notificationList.filter(
      ({ mId, time }) => notification.mId !== mId || notification.time !== time,
    );
    setDismissedNotifications([...dismissedNotifications, notification]);
    setNotificationList(filteredNotifications);
  };

  const handleOpenContent = (notificationObject) => {
    const { mId, mStoryId, mType, mRefId, mTemplateId } = notificationObject;

    const isInstance = mType === memberTypes.INSTANCE;
    if (mTemplateId) {
      navigateTo('rundown', mStoryId);
    } else {
      navigateTo('story', mStoryId, {
        tab: isInstance ? 'instances' : 'content',
        entityId: isInstance ? mRefId : undefined,
      });
    }
    removeNotificationMessageCount(mId, mStoryId);
    handleDismiss(notificationObject);
  };

  const handleOpenMessageHub = (notificationObject) => {
    openMessageHub(
      notificationObject,
      () => {
        setSelectedConversationId(notificationObject.mId);
        onOpenMessageHub();
      },
      () => {
        handleOpenContent(notificationObject);
      },
    );
  };

  const handleOpenFeed = (notificationObject) => {
    openFeedViewer(notificationObject.provider, notificationObject.mId);
    handleDismiss(notificationObject);
  };

  const handleOnOk = (canOpenStory, notification) => {
    const { mType, convoType } = notification;

    if (mType === 'breaking') {
      handleOpenFeed(notification);
      return;
    }

    const {
      instanceId,
      storyId,
      mTemplateId,
      isTemplateInstance,
      mType: entityType,
      mId,
      mRefId,
      mResourceType,
    } = canOpenStory ? extractNotificationProperties(notification.mContent) : {};

    if (entityType === 'block' || entityType === 'order') {
      if (mResourceType === 'instance') {
        if (mTemplateId) {
          navigateTo('rundown', storyId, {
            tab: 'instances',
            entityId: `${mId}:${entityType === 'block' ? 'planning' : 'tasks'}:${mRefId}:${true}`,
          });
        } else {
          navigateTo('story', storyId, {
            tab: 'instances',
            entityId: `${mId}:${entityType === 'block' ? 'planning' : 'tasks'}:${mRefId}:${true}`,
          });
        }
      } else {
        navigateTo(mResourceType === 'story' ? 'story' : 'pitch', mId, {
          tab: 'content',
          entityId: `${entityType === 'block' ? 'planning' : 'tasks'}:${mRefId}:${true}`,
        });
      }
    } else if (entityType === 'instance') {
      if (mTemplateId) {
        navigateTo('rundown', storyId, {
          tab: 'instances',
          entityId: `${mId}:::${true}`,
        });
      } else {
        navigateTo('story', storyId, { tab: 'instances', entityId: `${mRefId}:::${true}` });
      }
    } else if (storyId) {
      if (mTemplateId || isTemplateInstance) {
        navigateTo('rundown', storyId);
        if (instanceId) {
          selectRundownInstance([instanceId]);
        }
      } else {
        navigateTo('story', storyId, {
          tab: instanceId ? 'instances' : 'content',
          entityId: instanceId,
        });
      }
      if (convoType === conversationTypes.MENTION) {
        setRightHidden(false);
        setRightSelection('chat');
      }
    } else handleOpenMessageHub(notification);

    handleDismiss(notification);
  };

  const getPropsFromNotificationObj = (notification) => {
    const isSystemMessage = checkIsSystemMessage(notification);
    if (isSystemMessage) {
      const {
        instanceId,
        storyId,
        alertTitle,
        mAvatarUrl,
        highlightedText,
        directMessageVariant,
        text,
      } = extractNotificationProperties(notification.mContent);
      const mContent = text ?? extractMessageFromSlateObj(notification.mContent);
      return {
        isSystemMessage,
        instanceId,
        storyId,
        alertTitle,
        mAvatarUrl,
        mContent,
        highlightedText,
        directMessageVariant,
      };
    }
    const { storyId } = extractNotificationProperties(notification.mContent);

    return {
      alertTitle: getAlertTitle(notification),
      avatarDetails: getAlertAvatar(notification, getUser),
      mContent: notification.mContent,
      storyId: notification.convoType === 'mention' ? storyId : '',
    };
  };

  return (
    <div className={classes.list}>
      {notificationList.map((notification) => {
        const { alertTitle, avatarDetails, mContent, storyId, highlightedText } =
          getPropsFromNotificationObj(notification);

        return (
          <Alert
            key={`${notification.mId}_${notification.time}`}
            mCreatedAt={notification.time}
            variant={notification.mType}
            alertTitle={alertTitle ?? 'System Message'}
            avatar={avatarDetails ?? infoImage}
            canOpenStory={!!storyId}
            contentValue={mContent}
            contentHighlightedText={highlightedText ?? notification.itemTitle}
            user={notification.mUpdatedById}
            updatedBy={notification.updatedBy}
            onCancel={() => handleDismiss(notification)}
            onOk={(canOpenStory) => handleOnOk(canOpenStory, notification)}
            assignmentMessage={truncate(notification.messageAssign)}
          />
        );
      })}
    </div>
  );
};

AlertView.propTypes = {
  /** list of notification alerts */
  notifications: PropTypes.arrayOf(PropTypes.shape({})),
  /** callback to open the messageHub component */
  onOpenMessageHub: PropTypes.func,
};

AlertView.defaultProps = {
  notifications: [],
  onOpenMessageHub: () => {},
};

export default AlertView;
