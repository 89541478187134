import gql from 'graphql-tag';

export default gql`
  mutation DeleteAssetMutation($input: DeleteAssetInput) {
    deleteAsset(input: $input) {
      mId
      mRefId
      mAssetId
      mProvider
      message
      usages {
        mId
        mRefId
        mTitle
        mType
        mState
        mUpdatedAt
        mCreatedAt
        mAvatarKey
        mStoryId
        mThumbnailKey
        mContentKey
        mDefaultContentKey
        mThumbnailUrl
        mDescription
        mProperties {
          __typename
          ... on NoteProperties {
            pinned
            labelId
          }
          ... on PlatformType {
            platform
            id
            account {
              id
              accountId
              accountRefId
              accountUrl
              accountLogo
              accountTitle
              orderType
            }
            provider {
              id
              requestId
              state
              message
              embeddedEndpoint
              previewEndpoint
              updateEndpoint
            }
          }
        }
        mMetaData {
          key
          value
          manual
          autoValue
        }
      }
      isSuccessful
    }
  }
`;
