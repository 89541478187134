import { Validator } from 'jsonschema';
import { keyBy } from 'lodash';
import { v4 } from 'uuid';

import mdfSchema from 'assets/schemas/mdf.schema.json';
import { FieldTypeEnum, Mdf, MdfField, MdfPermission, Views, ViewTypes } from 'types/graphqlTypes';

import { NameMapping, NameMappings } from './conversion';
import { ExistingFieldInfo } from './mdf-utils';

const mdfValidator = new Validator();

function ensureIsMdf(data: unknown): Mdf {
  const validationResult = mdfValidator.validate(data, mdfSchema);
  if (validationResult.errors.length) {
    throw new Error(
      `The provided data is not valid MDF. First reported error: "${
        validationResult.errors[0].message
      }" at ${validationResult.errors[0].path.map((i) => String(i)).join('.')}`,
    );
  }
  return data as Mdf;
}

function removeUnknownGroups(groups: string[], knownGroups: Readonly<Set<string>>) {
  for (let index = groups.length; index >= 0; --index) {
    if (!knownGroups.has(groups[index])) {
      groups.splice(index, 1);
    }
  }
}

function ensureFieldTypeConsistency(
  field: MdfField,
  existingFieldInfoMap: Readonly<Record<string, ExistingFieldInfo>>,
) {
  const existingFieldInfo = existingFieldInfoMap[field.fieldId];
  if (existingFieldInfo && existingFieldInfo.fieldType !== field.type) {
    // Ensure that we use the same type for all fields with the same field ID (across MDFs)
    throw new Error(
      // eslint-disable-next-line max-len
      `The imported schema has a field with ID '${field.fieldId}' and type ${field.type}.\nThis is incompatible with the ${existingFieldInfo.fieldType} type that is used for a field with this ID in the schema named '${existingFieldInfo.mdfLabel}'.`,
    );
  }
}

function ensureNoUnknownOptionListId(field: MdfField, nameMappings: NameMappings) {
  const { optionLists, optionTrees } = nameMappings;
  if (field.optionListId) {
    // Ensure that we don't refer to an option list or tree that does not exist
    const mapping = field.type === FieldTypeEnum.treechoice ? optionTrees : optionLists;
    if (!(field.optionListId in mapping)) {
      const collectionType =
        field.type === FieldTypeEnum.treechoice ? 'option tree' : 'option list';
      throw new Error(
        // eslint-disable-next-line max-len
        `The imported schema references an ${collectionType} with ID ${field.optionListId}. There is no ${collectionType} with that ID in this setup.`,
      );
    }
  }
}

function fixSubTypeFieldWithAlternatives(
  field: MdfField,
  views: Views,
  subtypes: NameMapping,
  findUniqueSubTypeId: (subTypeName: string) => string | undefined,
) {
  if (!field.alternatives?.length) return;

  // We have exported the alternatives with subtype ID as value and subtype name as label
  // Before we address #5184, we must convert back.
  const newAlternatives = field.alternatives.map((alt) => {
    const subTypeId = alt.value in subtypes ? alt.value : findUniqueSubTypeId(alt.label);
    if (!subTypeId) {
      throw new Error(
        // eslint-disable-next-line max-len
        `The imported schema contains a subtype field named ${field.fieldId} that references a subtype named ${alt.label} that cannot be found in this system.`,
      );
    }
    const label = subtypes[subTypeId];
    // After #5184: return { label, value: subTypeId, id: subTypeId };
    return { label, value: label, id: label };
  });
  if (field.alternatives.some((alt, index) => alt.label !== newAlternatives[index].label)) {
    // We have renamed one or more subtype alternatives (bound to a subtype with another name)
    // so we must update the corresponding color mappings in views
    Object.values(views).forEach((view) =>
      view.forEach((settings) => {
        if (!settings.colors) return;
        const newColors: Record<string, string> = {};
        Object.entries(settings.colors).forEach(([altLabel, color]) => {
          const index = field.alternatives?.findIndex(({ label }) => label === altLabel) ?? -1;
          if (index >= 0 && index < newAlternatives.length) {
            newColors[newAlternatives[index].label] = color;
          }
        });
        settings.colors = newColors;
      }),
    );
  }
  if (field.defaultValue.value) {
    const altIndex = field.alternatives.findIndex((alt) => alt.value === field.defaultValue.value);
    if (altIndex < 0) {
      throw new Error(
        // eslint-disable-next-line max-len
        `The default value of the field '${field.fieldId}' in the imported schema is invalid.`,
      );
    }
    // #5184: `... = newAlternatives[altIndex].value;` (we must still update since we may have
    // mapped (by matching labels) to a schema with a different ID)
    field.defaultValue.value = newAlternatives[altIndex].label;
  }
  field.alternatives = newAlternatives;
}

/**
 * Import MDF from JSON. Fix various stuff, so that it conforms to how MDFs are expected internally:
 * * Ensure that the JSON represents a valid MDF (contains the necessary properties and that these
 *   properties have legal values)
 * * Ensure that field alternatives have unique values
 * * Alternatives have `id` properties (different for subtype fields and choice/multiplechoice
 *   fields)
 * * Internally (before #5184) subtype fields use subtype schema label instead of subtype schema ID
 *   as value. Mimir and exported JSON use the subtype schema ID.
 * * For subtype fields make sure that we update the alternatives so that the labels are equal
 *   to the labels currently used for the subtype schemas.
 * * Since color mappings maps from alternative label to color and subtype schemas may be renamed
 *   between export and import, we must make sure that we map colors from the updated schema names
 * * Choices, Multi-choices and tree-choices that refers to not existing option lists/trees will be
 *   accepted, but their option-list/tree reference will be removed (alternatives/treeAlternatives
 *   will be used instead).
 * @param json                 The JSON to try to import a MDF schema from
 * @param nameMappings         Mappings from ID to label/name for objects that may be referenced
 *                             from in the schema (subtypes, option lists and option trees)
 * @param existingFieldInfoMap Information about field types used for field IDs in the system
 * @returns                    The validated MDF
 * @throws                     `Error` if the JSON does not represent a valid MDF
 */
export function jsonToMdf(
  json: string,
  nameMappings: NameMappings,
  existingFieldInfoMap: Readonly<Record<string, ExistingFieldInfo>>,
  groups: readonly string[],
): Mdf {
  const { subtypes } = nameMappings;
  const subTypeEntries = Object.entries(subtypes);
  function findUniqueSubTypeId(subTypeName: string): string | undefined {
    const index = subTypeEntries.findIndex(([, name]) => name === subTypeName);
    if (index < 0) return undefined;
    const lastIndex = subTypeEntries.findLastIndex(([, name]) => name === subTypeName);
    return index === lastIndex ? subTypeEntries[index][0] : undefined;
  }

  const mdf = ensureIsMdf(JSON.parse(json));
  mdf.fields.forEach((field) => {
    ensureFieldTypeConsistency(field, existingFieldInfoMap);
    ensureNoUnknownOptionListId(field, nameMappings);
    if (field.type === FieldTypeEnum.subtype) {
      fixSubTypeFieldWithAlternatives(field, mdf.views, subtypes, findUniqueSubTypeId);
    } else {
      // We have removed the ID from the alternatives on export. Reintroduce it!
      field.alternatives?.forEach((alt) => {
        alt.id = v4();
      });
    }
    if (field.alternatives?.length) {
      const repeatedValueIndex = field.alternatives.findIndex(
        (outer, index, array) => array.findIndex((inner) => inner.value === outer.value) < index,
      );
      if (repeatedValueIndex >= 0) {
        const repeatedValue = field.alternatives[repeatedValueIndex].value;
        const firstIndex = field.alternatives.findIndex((alt) => alt.value === repeatedValue);
        throw new Error(
          // eslint-disable-next-line max-len
          `The field named ${field.fieldId} in the imported schema contains the same value in two alternatives (#${firstIndex} and #${repeatedValueIndex}).`,
        );
      }
    }
  });

  // Ensure that we have default settings for all fields
  if (!mdf.views.default) {
    throw new Error('The imported schema does not contain default layout settings.');
  }
  mdf.fields.forEach((field) => {
    if (!mdf.views.default.find((s) => s.fieldId === field.fieldId)) {
      throw new Error(
        `The imported schema does not contain default layout for the ${field.fieldId} field.`,
      );
    }
  });

  // Remove view settings for fields that are no
  Object.keys(mdf.views).forEach((viewName: string) => {
    const type = viewName as ViewTypes;
    mdf.views[type] = mdf.views[type].filter(
      (settings) => !!mdf.fields.find((field) => field.fieldId === settings.fieldId),
    );
  });

  // Clean up permissions, so that they don't contain unknown fields or groups
  const fieldById = keyBy(mdf.fields, (f) => f.fieldId);
  const knownGroups = new Set(groups);
  (['read', 'write'] as (keyof MdfPermission)[]).forEach((privilege) => {
    const fieldGroups = mdf.permissions[privilege];
    const ids = Object.keys(fieldGroups);
    for (const id of ids) {
      if (id in fieldById) {
        removeUnknownGroups(fieldGroups[id], knownGroups);
      } else {
        delete fieldGroups[id]; // The field doesn't exist so remove it from permissions
      }
    }
  });

  return mdf;
}
