import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CalendarSrc } from 'assets/icons/systemicons/calendar_small_on.svg';
import { ReactComponent as FailedSrc } from 'assets/icons/systemicons/status/error.svg';
import useDateTimeUtils from 'hooks/useDateTimeUtils';
import useStyles from './datetime-indicator-styles';

const DatetimeIndicator = ({ datetime, onClick, format, isFailedState, isDisabled }) => {
  const { format: fnsFormat, isToday, isTomorrow } = useDateTimeUtils();
  const classes = useStyles({ isFailedState, isDisabled });
  const dateObject = new Date(datetime);

  let datetimeToShow = fnsFormat(new Date(dateObject), format);

  if (isToday(dateObject)) datetimeToShow = `Today / ${fnsFormat(new Date(dateObject), 'HH:mm')}`;
  if (isTomorrow(dateObject))
    datetimeToShow = `Tomorrow / ${fnsFormat(new Date(dateObject), 'HH:mm')}`;

  return (
    <div className={classes.root} {...{ onClick }} role="presentation">
      {isFailedState ? (
        <FailedSrc className={classes.iconFailed} />
      ) : (
        <CalendarSrc className={classes.iconCalender} />
      )}
      <div className={classes.content}>{datetimeToShow}</div>
    </div>
  );
};

DatetimeIndicator.propTypes = {
  /** Currently selected datetime */
  datetime: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  /** Date-fns format string */
  format: PropTypes.string,
  /** Callback to be invoked when indicator is clicked */
  onClick: PropTypes.func,
  /** boolean that indicated a failed state */
  isFailedState: PropTypes.bool,
  /** boolean to indicate that the button is disabled */
  isDisabled: PropTypes.bool,
};

DatetimeIndicator.defaultProps = {
  datetime: new Date(),
  format: 'ddd. D MMM. YYYY',
  onClick: () => {},
  isFailedState: false,
  isDisabled: false,
};

export default DatetimeIndicator;
