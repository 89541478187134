import { memo, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import FeedItemDialogs from 'components/feeds/feedItemDialogs';
import ListItem from 'components/listItems/feed';
import useCustomDateTimeUtils from 'hooks/useCustomDateTimeUtils';

const stopEventBubble = (event) => {
  event.preventDefault();
  event.stopPropagation();
};

const commonFunc = (event, callbackFn, data) => {
  stopEventBubble(event);
  callbackFn(event.currentTarget, data);
};

function FeedDeckListItems({ items, openInModal, showCreateMemberDialog }) {
  const { isoToLocaleShort } = useCustomDateTimeUtils();
  const [selected, setSelected] = useState(null);
  const ref = useRef();
  const showMemberDialog = (e, item) => {
    showCreateMemberDialog({
      anchorEl: e.currentTarget,
      mTitle: item?.mTitle,
      provider: item.provider,
      mId: item.mId,
      useFeedMutation: true,
    });
  };
  const getDateString = useCallback(({ firstcreated, versioncreated }) => {
    const created = isoToLocaleShort(firstcreated);
    const version = isoToLocaleShort(versioncreated);

    const isSameDate = firstcreated === versioncreated;
    return isSameDate ? created : `${created} (updated ${version})`;
  }, []);
  return (
    <>
      {items.map((item) => (
        <ListItem
          item={item}
          key={item.mRefId}
          onClick={() => openInModal(item)}
          priority={item.priority}
          setSelected={setSelected}
          selected={selected?.mId === item.mId}
        >
          <ListItem.Thumbnail src={item.thumbnail} />
          <ListItem.Content>
            <ListItem.Metadata
              priority={item.priority}
              provider={item.provider}
              section={item.section}
              revision={item.revision}
            >
              <ListItem.Updated>{getDateString(item)}</ListItem.Updated>
            </ListItem.Metadata>
            <ListItem.Text>
              <ListItem.Title>{item?.mTitle || ''}</ListItem.Title>
            </ListItem.Text>
            <ListItem.Associations
              item={item}
              onCreateClick={(event) => showMemberDialog(event, item)}
              onStoryCountClick={(event) =>
                commonFunc(event, ref?.current?.onStoryCountClick, item)
              }
            />
          </ListItem.Content>
        </ListItem>
      ))}
      <FeedItemDialogs ref={ref} />
    </>
  );
}

FeedDeckListItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  openInModal: PropTypes.func,
};

FeedDeckListItems.defaultProps = {
  items: [],
  openInModal: () => {},
};

export default memo(FeedDeckListItems);
