import { useCallback, useEffect, useMemo } from 'react';
import { GroupingState, SortingState, VisibilityState } from '@tanstack/react-table';
import { omit } from 'lodash';

import type { CommandToolbarProps, DefaultMdfTypes } from 'features/command/command-types';
import { toolbarFilterDefaults } from 'features/command/command-utils';
import { StorageState } from 'features/grids/common/useDataTable';
import { useLocalStorage } from 'hooks/useLocalStorage';
import type { Metadata, NewFieldValue } from 'types/forms/forms';
import { SearchItemTypeEnum } from 'types/graphqlTypes';
import { defaultTabs } from 'utils/planningViews';

import { useStoryHubMolecule } from './storyHub';

export interface StoryHubDayViewURL {
  hide?: string[];
  sort?: SortingState;
  group?: GroupingState;
}

export const toVisibilityState = (visibilityHidden: string[]) => {
  const newVisibilityState: VisibilityState = {};
  visibilityHidden.forEach((vHidden) => (newVisibilityState[vHidden] = false));
  return newVisibilityState;
};

const EMPTY_OBJECT = {};

/**
 * Takes care of providing state to the storyhub search string, with automatic
 * sync to URL parameters.
 */
export const useSearchString = () => {
  const { useStoryHubParams } = useStoryHubMolecule();
  const [params, setStoryHubParams] = useStoryHubParams();

  const searchString = params.searchParams?.get('sS') ?? '';
  const setSearchString = useCallback(
    (newValue: string) => {
      setStoryHubParams((prevValue) => {
        const newParams = new URLSearchParams(prevValue.searchParams);
        newParams.set('sS', newValue);
        return {
          ...prevValue,
          searchParams: newParams,
        };
      });
    },
    [setStoryHubParams],
  );

  return [searchString, setSearchString] as const;
};

/**
 * Takes care of providing access to the storyhub selected mdfId string, with automatic
 * sync to URL parameters.
 */
export const useMdfId = () => {
  const { useStoryHubParams } = useStoryHubMolecule();
  const [params, setStoryHubParams] = useStoryHubParams();
  const mdfId = params.searchParams?.get('mdfId') ?? null;
  const setMdfId = useCallback(
    (newMdfId: string | null) => {
      setStoryHubParams((prev) => {
        const newParams = new URLSearchParams(prev.searchParams);
        if (newMdfId) {
          newParams.set('mdfId', newMdfId);
        } else {
          newParams.delete('mdfId');
        }
        newParams.delete('bmId');
        return {
          ...prev,
          searchParams: newParams,
        };
      });
    },
    [setStoryHubParams],
  );
  return [mdfId, setMdfId] as const;
};

/**
 * Takes care of providing access to the storyhub selected mTypes, with automatic
 * sync to URL parameters.
 */
export const useTypes = () => {
  const { useStoryHubParams, useStoryHubToolbarState } = useStoryHubMolecule();
  const [params] = useStoryHubParams();
  const typesParam = params.searchParams?.get('types') ?? null;
  const types = typesParam
    ? (JSON.parse(decodeURI(typesParam) ?? '[]') as SearchItemTypeEnum[])
    : [];

  const [, setToolbarState] = useStoryHubToolbarState();

  const setTypes = useCallback(
    (newTypes: SearchItemTypeEnum[]) => {
      setToolbarState((prevState) => {
        return {
          ...prevState,
          mTypes: newTypes,
        };
      });
    },
    [setToolbarState],
  );
  return [types, setTypes] as const;
};

/**
 * Takes care of metadata filter state in the story hub, and will automatically sync
 * the object with stringify & URI encoding back and forth to the UI.
 *
 * In addition some quality of life functions.
 */
export const useMetadataFilter = () => {
  const { useStoryHubParams } = useStoryHubMolecule();
  const [params, setStoryHubParams] = useStoryHubParams();

  const metadataFilter: Metadata = useMemo(() => {
    const mdParam = params.searchParams?.get('md');
    if (mdParam && decodeURI(mdParam)) {
      return JSON.parse(decodeURI(mdParam)) as Metadata;
    }
    return EMPTY_OBJECT;
  }, [params]);

  const updateFieldValues = useCallback(
    (val: NewFieldValue[]) => {
      const updatedMd: Metadata = {};
      for (const update of val) {
        updatedMd[update.fieldId] = update.value;
      }
      setStoryHubParams((prevValue) => {
        const newParams = new URLSearchParams(prevValue.searchParams);
        const newValue = {
          ...metadataFilter,
          ...updatedMd,
        };
        newParams.set('md', encodeURI(JSON.stringify(newValue)));
        newParams.delete('bmId');
        return {
          ...prevValue,
          searchParams: newParams,
        };
      });
    },
    [setStoryHubParams, metadataFilter],
  );

  const clearFieldValue = useCallback(
    (fieldId: string) => {
      const newMd = { ...omit(metadataFilter, fieldId) };
      setStoryHubParams((prevValue) => {
        const newParams = new URLSearchParams(prevValue.searchParams);
        newParams.set('md', encodeURI(JSON.stringify(newMd)));
        newParams.delete('bmId');
        return {
          ...prevValue,
          searchParams: newParams,
        };
      });
    },
    [setStoryHubParams, metadataFilter],
  );

  const clearValues = useCallback(() => {
    setStoryHubParams((prev) => {
      const newParams = new URLSearchParams(prev.searchParams);
      newParams.delete('md');
      newParams.delete('bmId');
      return {
        ...prev,
        searchParams: newParams,
      };
    });
  }, [setStoryHubParams]);

  return { metadataFilter, updateFieldValues, clearValues, clearFieldValue } as const;
};

export const toolbarDefaults: CommandToolbarProps = {
  ...toolbarFilterDefaults,
};

// Meant to keep properties small and optional of what
// goes to the URL to keep a handle on URL length.
export interface ToolbarUrlState {
  aIds?: string[]; // assignedIds
  cIds?: string[]; // createdByIds
  dMdfId?: DefaultMdfTypes; // defaultMdfId
  mdfId?: string; // mdfId
  types?: SearchItemTypeEnum[]; // mTypes
  pTypes?: string[]; // platform types
  status?: string[]; // instance+rundown status
  showRes?: boolean;
  resOnly?: boolean;
}

/**
 * Sync URL state back to toolbar state.
 */
export const toToolbarState = (urlState: ToolbarUrlState): CommandToolbarProps => {
  return {
    ...toolbarDefaults,
    assignedIds: urlState.aIds ?? [],
    createdByIds: urlState.cIds ?? [],
    defaultMdfId: urlState.dMdfId ?? null,
    mdfId: urlState.mdfId ?? null,
    mTypes: urlState.types ?? [],
    platformTypes: urlState.pTypes ?? [],
    statusFilter: urlState.status ?? [],
    showRestricted: urlState.showRes ?? true,
    restrictedItemsOnly: urlState.resOnly ?? false,
  };
};

/**
 * Intended to reduce the size of the URL as much as possible.
 */
export const toUrlState = (fullPayload: CommandToolbarProps): ToolbarUrlState => {
  const {
    assignedIds,
    createdByIds,
    defaultMdfId,
    mdfId,
    mTypes,
    platformTypes,
    statusFilter,
    restrictedItemsOnly,
    showRestricted,
  } = fullPayload;
  return {
    ...(assignedIds?.length && { aIds: assignedIds }),
    ...(createdByIds?.length && { cIds: createdByIds }),
    ...(defaultMdfId && { dMdfId: defaultMdfId }),
    ...(mdfId && { mdfId: mdfId }),
    ...(mTypes?.length && { types: mTypes }),
    ...(platformTypes?.length && { pTypes: platformTypes }),
    ...(statusFilter?.length && { status: statusFilter }),
    ...(!showRestricted && { showRes: showRestricted }),
    ...(restrictedItemsOnly && { resOnly: restrictedItemsOnly }),
  };
};

/**
 * A hook purely for keeping the date range & tab state of the storyhub synced
 * properly to the URL and back. If access is required to these states, use the individual
 * atoms from the story hub molecule, and not state derived in this hook.
 */
export const useDateTabSyncEffect = () => {
  const { useStoryHubParams, useStoryHubDate, useStoryHubTab } = useStoryHubMolecule();
  const [params, setStoryHubParams] = useStoryHubParams();
  const [tab, setTab] = useStoryHubTab();
  const [date, setDate] = useStoryHubDate();
  const [storedState, setStoredState] = useLocalStorage<StorageState>({
    key: 'storyhub-dayView',
    defaultValue: {},
    dispatchEvent: true,
  });

  // Sync initial url params
  useEffect(() => {
    const curDate = params.searchParams?.get('date');
    const curTab = params.searchParams?.get('tab');
    const curDayView = params.searchParams?.get('dayView');

    if (curTab) {
      const tabModel = defaultTabs.find((t) => t.label === curTab);
      if (tabModel) {
        setTab(tabModel);
      }
    }

    if (curDate) {
      setDate(decodeURI(curDate));
    }
    if (curDayView) {
      const dayViewStates = JSON.parse(decodeURI(curDayView) ?? '{}') as StoryHubDayViewURL;
      const newStoredState = { ...storedState };
      newStoredState.columnVisibility = dayViewStates.hide
        ? toVisibilityState(dayViewStates.hide)
        : undefined;
      newStoredState.grouping = dayViewStates.group;
      newStoredState.sorting = dayViewStates.sort;
      setTimeout(() => {
        setStoredState(newStoredState);
      }, 500);
    }
  }, []);

  useEffect(() => {
    setStoryHubParams((prevValue) => {
      const newParams = new URLSearchParams(prevValue.searchParams);
      newParams.set('date', encodeURI(date));
      newParams.set('tab', tab.label);
      return {
        ...prevValue,
        searchParams: newParams,
      };
    });
  }, [date, setStoryHubParams, tab]);
};

/**
 * Use to reset all parameters.
 */
export const useResetState = () => {
  const { useStoryHubParams, useStoryHubToolbarState, useStoryHubTab, useStoryHubDate } =
    useStoryHubMolecule();
  const [, setStoryHubParams] = useStoryHubParams();
  const [, setToolbarState] = useStoryHubToolbarState();
  const [date] = useStoryHubDate();
  const [tab] = useStoryHubTab();
  const [storedState] = useLocalStorage<StorageState>({
    key: 'storyhub-dayView',
    defaultValue: {},
  });

  return useCallback(() => {
    setStoryHubParams((prevValue) => {
      const params = new URLSearchParams();
      params.set('date', encodeURI(date));
      params.set('tab', tab.label);

      const props: StoryHubDayViewURL = {};
      const columnVisibility = storedState?.columnVisibility;
      if (columnVisibility) {
        const hiddenColumns = Object.keys(columnVisibility).filter(
          (key) => columnVisibility[key] === false,
        );
        props.hide = hiddenColumns;
      }
      props.sort = storedState?.sorting;
      props.group = storedState?.grouping;
      params.set('dayView', encodeURI(JSON.stringify(props)));

      return {
        ...prevValue,
        searchParams: params,
      };
    });
    setToolbarState({ ...toolbarDefaults });
  }, [
    setStoryHubParams,
    setToolbarState,
    date,
    tab.label,
    storedState?.columnVisibility,
    storedState?.sorting,
    storedState?.grouping,
  ]);
};

export const useSyncURLOnStorageChange = () => {
  const { useStoryHubParams } = useStoryHubMolecule();
  const [, setStoryHubParams] = useStoryHubParams();

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'storyhub-dayView') {
        const newValue = event.newValue;
        if (!newValue) return;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const storedState = JSON.parse(newValue) as StorageState;
        const props: StoryHubDayViewURL = {};
        const columnVisibility = storedState?.columnVisibility;
        if (columnVisibility) {
          const hiddenColumns = Object.keys(columnVisibility).filter(
            (key) => columnVisibility[key] === false,
          );
          props.hide = hiddenColumns;
        }
        props.sort = storedState?.sorting;
        props.group = storedState?.grouping;
        setStoryHubParams((prevValue) => {
          const newParams = new URLSearchParams(prevValue.searchParams);
          newParams.set('dayView', encodeURI(JSON.stringify(props)));
          return {
            ...prevValue,
            searchParams: newParams,
          };
        });
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [setStoryHubParams]);
};
