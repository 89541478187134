import { ReactComponent as InfoIcon } from 'assets/icons/systemicons/appMenu/info_off.svg';
import { ReactComponent as LegalIcon } from 'assets/icons/systemicons/appMenu/legal_icon.svg';
import { ReactComponent as QuestionIcon } from 'assets/icons/systemicons/appMenu/question.svg';
import { ReactComponent as Changelog } from 'assets/icons/systemicons/celebration.svg';
import { ReactComponent as MailIcon } from 'assets/icons/systemicons/email.svg';
import { ReactComponent as MobilePortrait } from 'assets/icons/systemicons/mobile_portait.svg';
import { ReactComponent as Settings } from 'assets/icons/systemicons/settings_on.svg';
import ListItem from 'components/listItem';
import useCheckUserRight from 'hooks/useCheckUserRight';
import { Box } from 'layouts/box/Box';
import URLS from 'utils/constants/urls';

interface Props {
  setShowQR: (val: boolean) => void;
  onOpenSettingsClick: () => void;
}

const Info = ({ setShowQR, onOpenSettingsClick }: Readonly<Props>) => {
  const [checkUserRight] = useCheckUserRight();
  const mobileApp = checkUserRight('feature', 'mobile-app');
  const { ABOUT, TERMS_CONDITIONS, CHANGELOG_URL, HELP_CENTER_URL, SEND_FEEDBACK_URL } = URLS;

  const openNewTab = (url: string) => window.open(url, '_blank')?.focus();

  return (
    <Box container flexDirection="column" padding="4px 0">
      <ListItem key={1} icon={<Settings />} text="Settings" onClick={onOpenSettingsClick} />
      <ListItem
        key={1}
        icon={<QuestionIcon />}
        text="Dina Help Center"
        onClick={() => openNewTab(HELP_CENTER_URL)}
      />
      <ListItem
        key={2}
        icon={<MailIcon />}
        text="Send us Feedback"
        onClick={() => openNewTab(SEND_FEEDBACK_URL)}
      />
      {mobileApp && (
        <ListItem
          key={3}
          icon={<MobilePortrait />}
          text="Dina Mobile Configuration"
          onClick={() => setShowQR(true)}
        />
      )}
      <ListItem
        key={4}
        icon={<Changelog />}
        text="Release History"
        onClick={() => openNewTab(CHANGELOG_URL)}
      />
      <ListItem
        key={5}
        icon={<LegalIcon />}
        text="Terms &amp; Conditions"
        onClick={() => openNewTab(TERMS_CONDITIONS)}
      />
      <ListItem key={6} icon={<InfoIcon />} text="About Dina" onClick={() => openNewTab(ABOUT)} />
    </Box>
  );
};

export default Info;
