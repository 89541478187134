import { useCallback, useMemo } from 'react';

import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import { IconButton } from 'components/buttons';
import Divider from 'components/divider';
import Tabs from 'components/tabs/regular/Tabs';
import useCheckUserRight from 'hooks/useCheckUserRight';
import { Box } from 'layouts/box/Box';
import {
  useActiveTab,
  useSelectedLeftMenu,
  useSelectedOrganization,
} from 'screens/main/components/header/navbar/settings/atomsTs';
import { PanelType } from 'types';
import { InstanceStateType, MemberType, MetadataFormType } from 'types/graphqlTypes';
import { PlatformInstanceType } from 'types/groupPermissions/permissions';

import Footer from './components/footer';
import { Integrations } from './components/integrations/Integrations';
import { MdfSchemas } from './components/mdf/MdfSchemas';
import { OrderConfigs } from './components/orderConfig/OrderConfigs';
import SystemSettings from './components/settingsTabs/systemSettings';
import UsersAndGroups from './components/settingsTabs/usersAndGroups';
import UserSettings from './components/settingsTabs/userSettings';
import { GetRundownTemplates } from './hooks/useGetAllRundownTemplates';
import { GetSettings } from './hooks/useGetSettings';
import { DEFAULT_TABS, SettingsTab, TABS } from './hooks/useOpenSettings';
import { useSelectedGroupName } from './atoms';

import { RootWrapper, TabsContent, TabsListWrapper } from './styled';

interface SettingsProps {
  handleCloseSettings: () => void;
  groupPolicies: MemberType[];
  systemSettings: GetSettings;
  form: MetadataFormType | undefined;
  onSaveAndClose: () => void;
  onDiscardAndClose: () => void;
  onSave: () => Promise<void>;
  onCloseConfirmDialog: () => void;
  disabled: boolean;
  isSaving: boolean;
  instanceTypes: PlatformInstanceType[];
  links: MetadataFormType | undefined;
  webhooks: MemberType[];
  kanbanStates: InstanceStateType[];
  panels: PanelType[];
  rundowns: GetRundownTemplates | undefined;
}

const Settings = ({
  groupPolicies,
  systemSettings,
  form,
  handleCloseSettings,
  disabled,
  isSaving,
  onSaveAndClose,
  onDiscardAndClose,
  onSave,
  onCloseConfirmDialog,
  instanceTypes,
  links,
  webhooks,
  kanbanStates,
  panels,
  rundowns,
}: SettingsProps) => {
  const [activeTab, setActiveTab] = useActiveTab();
  const [checkUserRight] = useCheckUserRight();
  const settingsAccessPermission = checkUserRight('feature', 'settings');
  const accessibleTabs = useMemo(
    () => (settingsAccessPermission ? TABS : DEFAULT_TABS),
    [settingsAccessPermission],
  );
  const [selectedLeftMenu, setSelectedLeftMenu] = useSelectedLeftMenu();
  const [selectedOrganizationMenu, setSelectedOrganizationMenu] = useSelectedOrganization();
  const [selectedGroupName, setSelectedGroupName] = useSelectedGroupName() as [
    string,
    (value: string) => void,
  ];
  const handleOrganizationTabSelected = () => {
    if (!selectedOrganizationMenu) setSelectedOrganizationMenu('group');
    if (groupPolicies.length > 0) {
      const { mRefId, mTitle } = groupPolicies[0];
      if (!selectedLeftMenu && mRefId) setSelectedLeftMenu(mRefId);
      if (!selectedGroupName && mTitle) setSelectedGroupName(mTitle);
    }
  };
  const handleTabClick = useCallback(
    (label: string) => {
      setActiveTab(label as SettingsTab);
      if (label === 'Organization') {
        handleOrganizationTabSelected();
      }
    },
    [setActiveTab, handleOrganizationTabSelected],
  );

  return (
    <RootWrapper>
      <Tabs
        defaultTab={accessibleTabs[0]}
        activeTab={activeTab}
        direction="vertical"
        setActiveTab={handleTabClick}
      >
        <TabsListWrapper>
          <Tabs.List>
            {accessibleTabs.map((tab) => (
              <Tabs.Trigger key={tab} id={tab} title={tab} />
            ))}
            <IconButton
              onClick={handleCloseSettings}
              style={{ marginLeft: 'auto' }}
              usage="contained"
              variant="contained"
              height={38}
              disableEnhancedIconOpacity
            >
              <Close />
            </IconButton>
          </Tabs.List>
        </TabsListWrapper>

        <Divider />

        <Box flex="1" height="100%">
          <TabsContent value="User settings">
            <UserSettings
              handleCloseSettings={handleCloseSettings}
              groupPolicies={groupPolicies}
              systemSettings={systemSettings}
            />
          </TabsContent>

          <TabsContent value="System settings">
            <SystemSettings
              systemSettings={systemSettings}
              form={form}
              instanceTypes={instanceTypes}
              links={links}
              webhooks={webhooks}
              panels={panels}
              rundowns={rundowns}
            />
          </TabsContent>

          <TabsContent value="Organization">
            <UsersAndGroups
              groupPolicies={groupPolicies}
              instanceTypes={instanceTypes}
              kanbanStates={kanbanStates}
            />
          </TabsContent>

          <TabsContent value="Integrations">
            <Integrations />
          </TabsContent>

          <TabsContent value="Schemas">
            <MdfSchemas />
          </TabsContent>

          <TabsContent value="Tasks">
            <OrderConfigs />
          </TabsContent>
        </Box>
      </Tabs>
      <Footer
        onSaveAndClose={onSaveAndClose}
        onDiscardAndClose={onDiscardAndClose}
        onCloseSettings={handleCloseSettings}
        onSave={onSave}
        disabled={disabled}
        isSaving={isSaving}
        onCloseConfirmDialog={onCloseConfirmDialog}
      />
    </RootWrapper>
  );
};

export default Settings;
