/* eslint-disable sort-imports */
import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';

import { SupportedBlockTypes } from 'components/editor/componentsV2/elementComponents';
import { GetMemberInput, MdfType } from 'types/graphqlTypes';

import { STANDARD_MDF_KEYS } from '../commonKeys';

import { toMdfDto } from './useCreateMdf';

export const GET_MDF = gql`
  query GetMdf($input: GetMemberInput) {
    getMdf(input: $input) {
      ${STANDARD_MDF_KEYS}
    }
  }
`;

interface GetMdf {
  getMdf: MdfType;
}

interface GetMemberInputType {
  input: GetMemberInput;
}

export const blockMdfLabel: Record<string, string> = {
  image: 'Image',
};

// Which v2 block types support metadata system.
export const supportsMdf: SupportedBlockTypes[] = ['image'];

export const useGetMdf = (mId: string | undefined | null) => {
  const { data, error, loading, refetch } = useQuery<GetMdf, GetMemberInputType>(GET_MDF, {
    variables: {
      input: {
        mId: mId ?? '',
      },
    },
    fetchPolicy: 'cache-first',
    skip: !mId,
  });

  const mdf = useMemo(() => {
    return data?.getMdf ? toMdfDto(data.getMdf) : undefined;
  }, [data]);

  return { mdf, error, loading, refetch };
};

export const useGetInstanceBlockMdf = (type: SupportedBlockTypes | undefined) => {
  const { mdf } = useGetMdf(type && supportsMdf.includes(type) ? `block-${type}` : undefined);
  return { mdf };
};
