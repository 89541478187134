const blockColors = {
  infoBackground: 'rgba(150, 80, 232, 0.08)',
  warningBackground: 'rgba(255, 152, 0, 0.08)',
  errorBackground: 'rgba(220, 50, 50, 0.08)',
  infoBorder: 'rgba(168, 55, 255, 1)',
  warningBorder: 'rgba(255, 152, 0, 1)',
  errorBorder: 'rgba(242, 72, 34, 1)',
  blocksBackgroundResting: 'rgba(24, 32, 40, 0.04)',
};

const buttonColors = {
  buttonBorderOutlinedBlack: 'rgba(15, 20, 26, 1)',
  buttonBorderOutlined: 'rgba(0, 0, 0, 0.25)',
  buttonBackgroundOutlined: 'rgba(24, 32, 40, 0.08)',
  whiteButtonBorderOutlined: 'rgba(255, 255, 255, 0.25)',
  whiteButtonBackgroundOutlined: 'rgba(255, 255, 255, 0.08)',
  buttonBackgroundHighEmphasis: '#A837FF',
  buttonBackgroundHighEmphasisHover: '#9430E0',
  buttonBackgroundSignificantAction: '#008A00',
  buttonBackgroundSignificantActionHover: '#007900',
  buttonBackgroundWarning: '#FF9800',
  buttonBackgroundWarningHover: '#E08600',
  buttonBackgroundDangerousAction: '#DC3232',
  buttonBackgroundDangerousActionHover: '#C22C2C',
  buttonBackgroundCyan: '#00BCD4',
  fabBackground: ' #5E728A',
  blackInactive: 'rgba(0, 0, 0, 0.54)',
  buttonBackgroundPitchHover: '#06c675',
  buttonBackgroundStoryHover: '#096ad8',
  avatarBorder: 'rgba(255, 255, 255, 0.25)',
  avatarBackground: 'rgba(0, 0, 0, 0.35)',
  avatarBackdrop: 'rgba(255, 255, 255, 0.4)',
};

const tabColors = {
  tabResting: 'rgba(15, 20, 26, 0.65)',
  tabSelected: '#0A73EB',
  tabDisabled: 'rgba(15, 20, 26, 0.35)',
};

const dividerColors = {
  dividerLight: 'rgba(147, 157, 168, 0.4)',
  dividerDark: 'rgba(0, 0, 0, 0.15)',
  eventBorder: 'rgba(242, 69, 61, 0.65)',
};

const mediaThumbnails = {
  audio: 'rgba(167, 89, 228, 0.2)',
  imageNoSource: 'rgba(226, 114, 131, 0.3)',
  image: '#0F141A',
  text: 'rgba(160, 219, 175, 0.2)',
  video: 'rgba(137, 182, 249, 0.25)',
};

const textColors = {
  highEmphasis: '#0F141A',
  mediumEmphasis: 'rgba(15, 20, 26, 0.75)',
  lowEmphasis: 'rgba(15, 20, 26, 0.45)',
  blackHighEmphasis: '#0F141A',
  blackMediumEmphasis: 'rgba(15, 20, 26, 0.75)',
  whiteHighEmphasis: '#ffffff',
  whiteMediumEmphasis: 'rgba(255, 255, 255, 0.75)',
  whiteInactive: 'rgba(255, 255, 255, 0.54)',
  whiteWatermark: 'rgba(255, 255, 255, 0.35)',
  inactive: 'rgba(15, 20, 26, 0.54)',
  disabled: 'rgba(15, 20, 26, 0.35)',
  watermark: 'rgba(15, 20, 26, 0.35)',
  matchedHighlightedText: 'rgb(11, 170, 50)',
  datePickerHoliday: 'rgba(255, 102, 102, 1)',
  isFixed: '#FF9800',
  errorText: '#FF6666',
  danger: '#ff0000',
  url: '#3963D6',
  textUrl: '#8CC1F2',
};

const statusColors = {
  statusApproved: '#008A00',
  statusOnFocused: '#0A73EB',
  statusWarning: '#FF9800',
  statusError: '#DC3232',
  statusPitch: '#19593E',
  statusPitchHover: '#164E37',
  statusSpace: '#2A666F',
  statusInstance: '#A837FF',
  statusRundown: '#5E377C',
  onSelected: '#0A73EB',
  onHover: 'brightness(92%)',
  onFocus: '#0A73EB',
  onFocusedBackground: 'rgba(25, 126, 235, 0.10)',
  onFocusOpacity: 'rgba(57, 151, 255, 0.8)',
  outlineButtonOnHover: 'rgba(0, 0, 0, 0.08)',
  inputBorderResting: 'rgba(0, 0, 0, 0.25)',
  inputBorderFocused: '#0A73EB',
  statusOnPress: 'rgb(0, 0, 0)',
  statusOnFocusedDiscreetFill: 'rgba(57, 151, 255, 0.1)',
  selectionControlTrackInactive: 'rgba(141, 155, 174, 0.5)',
  statusOnHover: 'rgba(0, 0, 0, 0.08)',
  storyCardHover: 'rgba(0, 0, 0, 0.08)', // '#424D5B',
  storyTimelineCurrentTimeIndicator: 'rgba(57, 151, 255, 0.35)',
  switchTrackInactive: 'rgba(141, 155, 174, 0.5)',
  gridFloat: 'rgba(174, 105, 255, 0.30)',
  gridFloatHover: 'rgba(174, 105, 255, 0.50)',
  prioBreaking: '#DC3232',
  prioBreakingRow: 'rgba(242, 72, 34, 0.3)',
  prio1: '#FF9800',
  prio2: '#008A00',
  prio3: '#A837FF',
  statusStory: '#0A73EB',
  statusStoryMarket: '#07D77F',
  storyMarketOpacity: 'rgba(7, 215, 127, 0.08)',
  backgroundHover: 'rgba(0, 0, 0, 0.12)',
  borderHover: 'rgba(0, 0, 0, 0.16)',
  blockBorderHover: 'rgba(0, 0, 0, 0.16)',
  borderResting: 'rgba(0, 0, 0, 0.16)',
  backgroundSelected: 'rgba(0, 30, 85, 0.16)',
  statusSelectedRundownTab: '#A837FF',
  selectedNotActive: 'rgba(10, 115, 235, 0.25)',
  info: '#C983FF',
};

const surfaceColors = {
  tooltip: 'rgba(32, 42, 54, 0.8)',
  hoveringTooltip: '#E5E7E9',
  surfacePaper: '#E5E7E9',
  surfaceAppBackgroundMain: '#FAFAFA',
  surfaceAppBackgroundNavLevel1: '#E5E7E9',
  surfaceAppBackgroundNavLevel1Dark: '#202A36',
  surfaceAppBackgroundNavLevel2: '#F3F5F6',
  surfaceCard: '#FFFFFF',
  surfaceCardDark: '#FFFFFF',
  surfaceDialogs: '#FFFFFF',
  hoverOverlay: 'rgba(0, 0, 0, 0.10)', // New Item
  whiteHoverOverlay: 'rgba(255, 255, 255, 0.08)',
  blackHoverOverlay: 'rgba(0, 0, 0, 0.08)',
  headerFooterOverlay: 'rgba(15, 20, 26, 0.13)',
  blackMediumEmphasis: 'rgba(15, 20, 26, 0.7)',
  paletteAccentCyan: '#00BCD4',
  paletteAccentCyanOpacity: 'rgba(0, 188, 212, 0.35)',
  paletteAccentOrange: '#FF9800',
  paletteAccentRed: '#DC3232',
  paletteAccentPurple: '#9650E8',
  statusWarningBackground: 'rgba(255, 152, 0, 0.08)',
  storyTimelineBackground: 'rgba(63, 75, 94, 0.25)',
  storyTimelineBackgroundNoBackdrop: 'rgba(63, 75, 94, 0.8)',
  storyTimelineTickDefault: 'rgba(0, 0, 0, 0.08)', // 'rgba(255, 255, 255, 0.08)',
  thumbGraphicsBackground: 'rgba(50, 62, 77, 0.75)',
  maskBackground: 'rgba(50, 62, 77, 0)',
  kanbanInProgress: '#BE95FF',
  kanbanTodo: '#FE81A0',
  kanbanReady: '#64D983',
  kanbanApproved: '#64D983',
  kanbanInReview: '#FFC372',
  kanbanPublished: '#73BCFF',
  kanbanError: '#DC3232',
  kanbanPending: '#3875BC',
  kanbanArchived: '#787878',
  kanbanTodoBackground: '#F1C7D7',
  kanbanInProgressBackground: '#DBCDF8',
  kanbanInReviewBackground: '#F1DEC7',
  kanbanReadyBackground: '#BBE6CD',
  kanbanApprovedBackground: '#BBE6CD',
  kanbanPublishedBackground: '#C0DCF8',
  kanbanPendingBackground: '#ACC3E1',
  kanbanErrorBackground: '#EDB3AD',
  kanbanArchivedBackground: '#EAEDF5',
  surfaceToolBarStoryView: '#0A73EB',
  chipBackground: 'rgba(94, 114, 136, 0.5)',
  inputBackground: 'rgba(15, 20, 26, 0.13)',
  inputBackgroundFocused: 'rgba(10, 115, 235, 0.08)',
  tableHeader: 'rgba(75,91,111,1)',
  primary700: '#415063',
  backgroundResting: 'rgba(0, 0, 0, 0.08)',
  scheduleEventBackground: 'rgba(242, 69, 61, 0.2)',
  resourceTimelineItemBackground: 'rgba(242, 69, 61, 0.5)',
  statusSearchMatchBG: '#CCFF00',
  boxShadowDark: 'rgba(0, 0, 0, 0.25)',
  boxShadowDarker: 'rgba(0, 0, 0, 0.55)',
  tableAlternatingRowOverlay: 'rgba(0, 0, 0, 0.04)',
  tooltipWarningBackground: 'rgba(255, 152, 0, 0.8)',
  dialogBackdropOverlay: 'rgba(0, 0, 0, 0.25)',
  notesBackdropOverlay: 'rgba(0, 0, 0, 0.25)', // 'rgba(0, 0, 0, 0.65)',
  feedsTableHeaderRow: '#F3F5F6', // '#0f1419',
  tooltipBorder: 'rgba(255, 255, 255, 0.25)',
  draftBackground: 'rgb(99, 110, 123)',
  section: '#5E6973',
  scrollbar: 'rgba(255, 255, 255, 0.12)',
  scrollbarHover: 'rgba(255, 255, 255, 0.16)',
  overlaysHeaderFooter: 'rgba(15, 20, 26, 0.25)',
};

const iconColors = {
  iconActive: 'rgba(15, 20, 26, 0.87)',
  iconInactive: 'rgba(15, 20, 26, 0.54)',
  iconInactiveUltraDiscreet: 'rgba(0, 0, 0, 0.12)',
  iconSelectedWhite: '#FFFFFF',
  iconSelected: '#0A73EB',
  iconDisabled: 'rgba(15, 20, 26, 0.25)',
  timelineCamera: '#A0DBAF',
  timelineVideoClip: '#89B6F9',
  timelineLive: '#E459CE',
  timelineGraphic: '#FFBE55',
  timelineBreak: '#E8E8E8',
  timelineSecondaryAccessory: '#ACB3BD',
  placeholderIcon: '#516379',
};

const bookingStatus = {
  planning: '#B7DCFF',
  pencil: '#62ABEE',
  firmBooking: '#0085FF',
  mcr1: '#E0A5F5',
  mcr2: '#E772FA',
  readyToRoster: '#6CE880',
  confirmed: '#00AB11',
  completed: '#FFF500',
  pendingAction: '#FF9900',
  reconciled: '#B4700A',
  canceled: '#7C7C7C',
};

const notesLabel = {
  blue: '#62ABEE',
  pink: '#E772FA',
  green: '#6CE880',
  yellow: '#FFF500',
  orange: '#FF9900',
};

const colors = {
  ...blockColors,
  ...buttonColors,
  ...tabColors,
  ...dividerColors,
  ...iconColors,
  ...mediaThumbnails,
  ...statusColors,
  ...surfaceColors,
  ...textColors,
  ...bookingStatus,
  ...notesLabel,
};

export default colors;
