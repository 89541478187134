import { useContext } from 'react';

import AppMenu from 'components/appMenu';
import { DisableSlideInPopover } from 'components/appMenu/components/header/styled';
import Popover from 'components/dialogs/PopoverBuilder';
import DigitalClock from 'components/digitalClock/DigitalClock';
import { useAuthContext } from 'contexts/AuthContext';
import { NotificationContext } from 'contexts/NotificationContext';
import UserCtx from 'contexts/UserContext';
import MyOrdersButton from 'features/orderForm/components/MyOrdersButton';
import useCheckUserRight, { useCanSeeOrderManagement } from 'hooks/useCheckUserRight';
import { Conversation } from 'types/messageHub';

import Links from '../linksPanel';
import MessageHub from '../messageHub/MessageHub';
import Scratchpad from '../scratchPad';
import Settings from '../settings';
import { useSettingsOpen } from '../settings/atomsTs';
import UserAvatar from '../settings/UserAvatar';

import {
  OrdersButtonWrapper,
  ProfileWrapper,
  StyledTabDivider,
  UserContentWrapper,
} from './styled';

const UserContent = () => {
  // Fix when useAuthContext() is typed
  const { logout } = useAuthContext() as unknown as { logout: (userId: string) => Promise<void> };
  const [settingOpen, setSettingOpen] = useSettingsOpen();
  const { attributes } = useContext(UserCtx);

  // Fix when NotificationContext is typed
  const { notificationMessage } = useContext(NotificationContext) as unknown as {
    notificationMessage: Conversation[];
  };

  const { canAccessOrderManagement } = useCanSeeOrderManagement();
  const [checkUserRight] = useCheckUserRight();
  const { mTitle, mAvatarKey, mId } = attributes || {};
  const linksPanelFeatureFlag = checkUserRight('feature', 'links-panel');

  const onLogout = async () => {
    if (mId) await logout(mId);
  };

  return (
    <UserContentWrapper>
      {canAccessOrderManagement && mId && (
        <OrdersButtonWrapper>
          <MyOrdersButton userId={mId} />
        </OrdersButtonWrapper>
      )}

      {linksPanelFeatureFlag && <Links />}
      <Scratchpad />
      <MessageHub messages={notificationMessage} />
      <StyledTabDivider orientation="vertical" flexItem />
      <DigitalClock copyOnClick />
      <StyledTabDivider orientation="vertical" flexItem />
      <Popover>
        <Popover.Trigger asChild>
          <div>
            <UserAvatar />
          </div>
        </Popover.Trigger>
        <DisableSlideInPopover asChild sideOffset={10} alignOffset={20} side="left">
          <ProfileWrapper>
            <AppMenu
              avatar={mAvatarKey}
              username={mTitle}
              onLogout={onLogout}
              onOpenSettingsClick={() => {
                setSettingOpen(true);
              }}
            />
          </ProfileWrapper>
        </DisableSlideInPopover>
      </Popover>
      {settingOpen && <Settings settingOpen={settingOpen} setSettingOpen={setSettingOpen} />}
    </UserContentWrapper>
  );
};

export default UserContent;
