import { memo, useCallback, useRef, useState } from 'react';

import { ReactComponent as ArrowDown } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_down.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_right.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/systemicons/close_small.svg';
import { ReactComponent as Add } from 'assets/icons/systemicons/plus_small.svg';
import { ReactComponent as PlusSmallCircle } from 'assets/icons/systemicons/plus_ultraSmall_circle.svg';
import { ReactComponent as SortDown } from 'assets/icons/systemicons/sort_down.svg';
import { Button, ExportButton, IconButton } from 'components/buttons';
import ImportButton from 'components/buttons/ImportButton';
import { HStack, VStack } from 'layouts/box/Box';
import {
  focusFirstChildListItem,
  focusInputOfRelatedListItem,
  focusLastChildListItem,
  focusNextListItem,
  focusSiblingOrParentBeforeDelete,
  getEnclosingListItem,
} from 'utils/dom/ulTree';
import { getKeyboardShortcutText } from 'utils/keyboardShortcuts';

import {
  ButtonContainer,
  FlexTreeViewWrapper,
  InputField,
  NodeBody,
  StyledTreeNode,
  StyledTreeView,
} from '../styled';
import {
  addNode,
  createTree,
  getTreeAlternativesFromJson,
  removeNode,
  renameNode,
  revertTree,
  sortTree,
  TreeNode as TreeNodeProps,
} from '../utils';

const NESTING_LIMIT = 10;

interface Props {
  tree: readonly TreeNodeProps[];
  updateTree: React.Dispatch<React.SetStateAction<readonly TreeNodeProps[]>>;
  isExternal: boolean;
  name: string;
  children: React.ReactElement;
}

interface TreeAndNodeProps {
  node: TreeNodeProps;
  parentPathText: string;
  depth: number;
  readOnly: boolean;
  isDuplicate: (id: string, value: string) => boolean;
  handleAddNode: (path: string[], where: 'asChild' | 'asSibling') => void;
  handleRemoveNode: (path: string[]) => void;
  handleRenameNode: (path: string[], newName: string) => void;
}

function getPath(pathText: string) {
  return pathText.split('▸');
}

const MemoTreeNode = memo(function TreeNode({
  node,
  parentPathText,
  depth,
  readOnly,
  isDuplicate,
  handleAddNode,
  handleRemoveNode,
  handleRenameNode,
}: Readonly<TreeAndNodeProps>) {
  const [localValue, setLocalValue] = useState(node.value);
  const [localError, setLocalError] = useState<string>('');
  const pathText = parentPathText ? `${parentPathText}▸${node.value}` : node.value;
  const [isExpanded, setIsExpanded] = useState(false);

  const hasChildren = !!node.children.length;
  const disableReason = readOnly
    ? 'External lists can only be edited from the list itself'
    : undefined;

  const isDuplicateChildValue = useCallback(
    (id: string, value: string) => !!node.children.find((n) => n.id !== id && n.value === value),
    [node.children],
  );

  const handleExpandClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (!hasChildren) return;
      setIsExpanded((value) => !value);
      focusInputOfRelatedListItem(getEnclosingListItem(e.currentTarget), (el) => el, false);
    },
    [setIsExpanded, hasChildren],
  );

  const addRelatedNode = useCallback(
    (li: HTMLLIElement | null, where: 'asChild' | 'asSibling') => {
      handleAddNode(getPath(pathText), where);
      if (where === 'asChild') {
        setIsExpanded(true);
        focusLastChildListItem(li, true);
      } else {
        focusNextListItem(li, true);
      }
    },
    [handleAddNode, pathText, setIsExpanded],
  );

  const handleValueChange = (value: string) => {
    setLocalValue(value);
    if (value === '') {
      setLocalError("Value can't be empty");
    } else if (value.includes('▸')) {
      setLocalError("'▸' is reserved. Can't be used as value");
    } else if (isDuplicate(node.id, value)) {
      setLocalError('Duplicate value');
    } else {
      setLocalError('');
    }
  };

  const saveNode = () => {
    if (localError?.length) {
      handleValueChange(node.value);
    } else if (localValue !== node.value) {
      handleRenameNode(getPath(pathText), localValue);
    }
  };

  return (
    <StyledTreeNode>
      <NodeBody>
        {
          <IconButton
            title={hasChildren ? 'Expand/collapse (Shift+Space)' : undefined}
            height={35}
            width={24}
            usage="text"
            style={!node.children.length ? { opacity: '0', cursor: 'inherit' } : undefined}
            onClick={handleExpandClick}
            tabIndex={-1}
          >
            {isExpanded ? <ArrowDown /> : <ArrowRight />}
          </IconButton>
        }
        <InputField
          value={localValue}
          variant="filled"
          placeholder="Enter choice name..."
          onChange={(e) => handleValueChange(e.target.value)}
          error={!!localError.length}
          helperText={localError?.length ? localError : undefined}
          disabled={readOnly}
          title={disableReason}
          fullWidth
          inputProps={{
            onBlur: saveNode,
            onKeyDown: (e) => {
              if (e.key === 'Enter') {
                if (e.shiftKey) {
                  addRelatedNode(
                    getEnclosingListItem(e.currentTarget),
                    e.altKey ? 'asChild' : 'asSibling',
                  );
                  e.stopPropagation();
                }
                saveNode();
              } else if (e.key === ' ' && e.shiftKey && node.children.length) {
                const wasExpanded = isExpanded;
                setIsExpanded(!isExpanded);
                e.stopPropagation();
                e.preventDefault();
                if (!wasExpanded) {
                  focusFirstChildListItem(getEnclosingListItem(e.currentTarget), true);
                }
              } else if (e.key === 'Backspace' && e.shiftKey) {
                focusSiblingOrParentBeforeDelete(getEnclosingListItem(e.currentTarget), !!depth);
                handleRemoveNode(getPath(pathText));
                e.preventDefault();
              }
            },
          }}
        />
        <IconButton
          title={
            disableReason ??
            (depth < NESTING_LIMIT
              ? `Add nested option (${getKeyboardShortcutText('Shift+Option+Enter')})`
              : 'Reached maximum nesting limit of 10')
          }
          height={35}
          width={24}
          usage="text"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            handleAddNode(getPath(pathText), 'asChild');
            saveNode();
            setIsExpanded(true);
            focusLastChildListItem(getEnclosingListItem(e.currentTarget), true);
          }}
          disabled={readOnly || depth >= NESTING_LIMIT || !!localError?.length}
          tabIndex={-1}
        >
          <PlusSmallCircle />
        </IconButton>
        <IconButton
          title={disableReason ?? `Remove option  (${getKeyboardShortcutText('Shift+Backspace')})`}
          height={35}
          width={24}
          iconSize={12}
          usage="text"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            focusSiblingOrParentBeforeDelete(getEnclosingListItem(e.currentTarget), !!depth);
            handleRemoveNode(getPath(pathText));
          }}
          disabled={readOnly}
          tabIndex={-1}
        >
          <DeleteIcon />
        </IconButton>
      </NodeBody>
      {isExpanded && hasChildren && (
        <StyledTreeView>
          {node.children.map((child) => (
            <MemoTreeNode
              key={child.id}
              node={child}
              parentPathText={pathText}
              depth={depth + 1}
              readOnly={readOnly}
              isDuplicate={isDuplicateChildValue}
              handleAddNode={handleAddNode}
              handleRemoveNode={handleRemoveNode}
              handleRenameNode={handleRenameNode}
            />
          ))}
        </StyledTreeView>
      )}
    </StyledTreeNode>
  );
});

function EditTreeChoiceAlternatives({
  tree,
  updateTree,
  isExternal,
  name,
  children,
}: Readonly<Props>) {
  const selfRef = useRef<HTMLDivElement | null>(null);
  const disableReason = isExternal
    ? 'External lists can only be edited from the list itself'
    : undefined;

  const isDuplicateTopLevelValue = useCallback(
    (id: string, value: string) => !!tree.find((n) => n.id !== id && n.value === value),
    [tree],
  );

  const handleSortTree = useCallback(() => {
    updateTree((prevTree) => sortTree(prevTree));
  }, [updateTree]);

  const onImportAsync = useCallback(
    async (file: File) => {
      const fileText = await file.text();
      const alternatives = getTreeAlternativesFromJson(JSON.parse(fileText));
      const updatedTree = createTree(alternatives);
      updateTree(updatedTree);
    },
    [updateTree],
  );

  const handleAddNode = useCallback(
    (path: string[], where: 'asChild' | 'asSibling') =>
      updateTree((prevTree) => addNode(prevTree, path, where)),
    [updateTree],
  );

  const handleRemoveNode = useCallback(
    (path: string[]) => updateTree((prevTree) => removeNode(prevTree, path)),
    [updateTree],
  );

  const handleRenameNode = useCallback(
    (path: string[], newName: string) =>
      updateTree((prevTree) => renameNode(prevTree, path, newName)),
    [updateTree],
  );

  return (
    <VStack ref={selfRef} height="100%" overflow="hidden" width="100%" maxHeight="100%">
      <HStack
        justifyContent="space-between"
        flex="0 0 auto"
        width="100%"
        padding="0 0 10px"
        alignItems="end"
      >
        {children}
        <ButtonContainer>
          <ExportButton
            width={90}
            height={32}
            getExportData={() => ({
              fileName: `${name}-options.json`,
              content: JSON.stringify(revertTree(tree)),
            })}
          />{' '}
          <ImportButton
            title={disableReason ?? 'Import options from file'}
            confirmMessage={
              tree.length
                ? 'Existing alternatives will be overwritten. Are you sure you want to import?'
                : undefined
            }
            onImportAsync={onImportAsync}
            disabled={isExternal}
            width={90}
            height={32}
          />
          <Button
            width={90}
            height={32}
            variant="outlined"
            usage="outlined"
            title={disableReason ?? 'Sort tree'}
            onClick={handleSortTree}
            disabled={isExternal}
          >
            <SortDown className="skipOverride" />
            Sort
          </Button>
          <Button
            width={90}
            height={32}
            variant="outlined"
            usage="outlined"
            onClick={() => {
              handleAddNode([], 'asChild');
              focusLastChildListItem(selfRef.current, true);
            }}
            title={disableReason ?? 'Add root choice'}
            disabled={isExternal}
          >
            <Add className="skipOverride" />
            Add
          </Button>
        </ButtonContainer>
      </HStack>
      <FlexTreeViewWrapper>
        {
          <StyledTreeView>
            {tree.map((child) => (
              <MemoTreeNode
                key={child.id}
                node={child}
                parentPathText={''}
                depth={1}
                readOnly={isExternal}
                isDuplicate={isDuplicateTopLevelValue}
                handleAddNode={handleAddNode}
                handleRemoveNode={handleRemoveNode}
                handleRenameNode={handleRenameNode}
              />
            ))}
          </StyledTreeView>
        }
      </FlexTreeViewWrapper>
    </VStack>
  );
}

export default memo(EditTreeChoiceAlternatives);
