import styled from '@emotion/styled';

import { ReactComponent as Back } from 'assets/icons/systemicons/arrows/disclosurearrow_left.svg';
import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import Popover from 'components/dialogs/PopoverBuilder';

export const DisableSlideInPopover = styled(Popover.Content)`
  animation: none !important;
`;

export const StyledClose = styled(Close)`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 0px;
  &:active {
    top: 1px;
  }
  &:hover path {
    fill-opacity: 1;
  }
`;

export const BackIcon = styled(Back)`
  height: 40px;
  width: 40px;
  padding: 8px;
  cursor: pointer;
  :hover {
    path {
      fill-opacity: 1;
    }
  }
  :focus {
    path {
      fill-opacity: 1;
    }
  }
`;
