/**
 *  Hook for creating a new story and attach it to a user.
 *  It takes the mId of the user and the title of the story .
 *  Returns the story id of the created story.
 */

import { useState } from 'react';
import { useMutation } from '@apollo/client';

import useGetArchiveTime from 'hooks/useGetArchiveTime';
import useNewItems from 'hooks/useNewItems';
import useSettingsValue from 'hooks/useSettingsValue';
import useUpdateLeftSidebarCache from 'hooks/useUpdateLeftSidebarCache';
import CREATE_STORY from 'operations/mutations/createStory';
import { MemberType, MemberTypeEnum } from 'types/graphqlTypes';
import { AssignedMember, MTypeToMdfId } from 'types/members';

export interface CreateStoryInput {
  mId: string;
  mTitle: string;
  type: 'story' | 'pitch';
  messageAssign: string | undefined;
  isRestricted: boolean;
  mCreatedById: string;
  relationType?: RelatedType;
  mPublishingAt?: string;
  mPublishingEnd?: string;
  mAssignedMembers?: Pick<AssignedMember, 'mId' | 'mType'>[];
  metadata?: string;
  mContent?: string;
  mPriority?: string;
  // Front end props only.
  keepOpen: boolean;
  openStory: boolean;
}

interface CreateStoryVariables {
  input: {
    mId: string;
    mTitle: string;
    mType: MemberTypeEnum;
    messageAssign: string | undefined;
    isRestricted: boolean;
    mCreatedById: string;
    mPublishingAt?: string;
    mPublishingEnd?: string;
    mAssignedMembers?: Pick<AssignedMember, 'mId' | 'mType'>[];
    isSyncActive?: boolean;
    ttl?: number;
    metadata?: string;
    mdfId?: string;
    mContent?: string;
    mPriority?: string;
  };
}

interface StoryCreateMutation {
  createStoryForMember: MemberType;
}

export type RelatedType = MemberTypeEnum.Team | MemberTypeEnum.Department;

const getRelationType = (relatedType: RelatedType | undefined, type: 'pitch' | 'story') => {
  switch (relatedType) {
    case MemberTypeEnum.Team:
      return type === 'pitch' ? MemberTypeEnum.TeaPitch : MemberTypeEnum.TeaStr;
    case MemberTypeEnum.Department:
      return type === 'pitch' ? MemberTypeEnum.DepPitch : MemberTypeEnum.DepStr;
    default:
      return type === 'pitch' ? MemberTypeEnum.UsrPitch : MemberTypeEnum.UsrStr;
  }
};

const useCreateStory = () => {
  const [createStoryForUser] = useMutation<StoryCreateMutation>(CREATE_STORY);
  const [loading, setLoading] = useState(false);
  const [updateCache] = useUpdateLeftSidebarCache();
  const [getSettingsValue] = useSettingsValue();
  const [getAutoArchiveTime] = useGetArchiveTime();
  const { addItem } = useNewItems();
  let createdStoryId: string | undefined;

  const create = async (createInput: CreateStoryInput) => {
    const {
      mId,
      mTitle,
      type,
      relationType,
      messageAssign,
      isRestricted,
      mCreatedById,
      mPublishingAt,
      mPublishingEnd,
      mAssignedMembers,
      metadata,
      mContent,
      mPriority,
    } = createInput;
    const isSyncActive = getSettingsValue('story.disableAutoSync') !== 'true';
    const isPitch = type === 'pitch';
    const mType = getRelationType(relationType, type);
    const isAutoArchiveStory = getSettingsValue('app.autoArchiveStory') === 'true';
    const variables: CreateStoryVariables = {
      input: {
        mId,
        mTitle,
        messageAssign,
        isRestricted,
        mCreatedById,
        mType,
        mPriority,
      },
    };
    if (mPublishingAt) {
      variables.input.mPublishingAt = mPublishingAt;
    }
    if (mPublishingEnd) {
      variables.input.mPublishingEnd = mPublishingEnd;
    }

    if (mContent) {
      variables.input.mContent = mContent;
    }

    if (mAssignedMembers) {
      variables.input.mAssignedMembers = mAssignedMembers;
    }

    if (!isPitch && isSyncActive) {
      variables.input.isSyncActive = isSyncActive;
    }
    if (mPublishingAt && isAutoArchiveStory && !isPitch) {
      variables.input.ttl = getAutoArchiveTime(mPublishingAt);
    }

    if (metadata) {
      variables.input.metadata = metadata;
      variables.input.mdfId = type === 'pitch' ? MTypeToMdfId.pitch : MTypeToMdfId.story;
    }
    setLoading(true);
    await createStoryForUser({
      variables,
      update: (_, mutationResult) => {
        if (mutationResult.data) {
          const { createStoryForMember } = mutationResult.data;
          createdStoryId = createStoryForMember.mId;
          updateCache(createStoryForMember, mType);
          addItem(createStoryForMember);
        }
      },
    });
    setLoading(false);

    return createdStoryId;
  };

  return { create, loading };
};

export default useCreateStory;
