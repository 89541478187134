import { OrderFormMemberType } from 'types/memberTypes/order_form';

export const getStatusColors = (form: OrderFormMemberType) => {
  const formConfigs = form.configs;
  const statusesConfig = formConfigs?.find((config) => config.key === 'status_colors');
  const colorMap: Record<string, string> = {};
  (statusesConfig?.alternatives ?? []).forEach((alt) => {
    colorMap[alt.id] = alt.value;
  });
  return colorMap;
};

export const getStatusOption = (form: OrderFormMemberType) => {
  const formConfigs = form.configs;
  const statusesConfig = formConfigs?.find((config) => config.key === 'statuses');
  return (
    statusesConfig?.alternatives?.map((alt) => ({
      ...alt,
      title: alt.label,
      value: alt.value.split('#')[1],
    })) ?? []
  );
};
