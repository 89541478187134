import { useEffect, useMemo, useState } from 'react';

import { useGetTypedOptionList } from 'api/optionLists/useGetOptionList';
import { useGetOptionLists } from 'api/optionLists/useGetOptionLists';
import { Button } from 'components/buttons';
import Dialog from 'components/dialogs/DialogBuilder';
import ChoiceField from 'components/mdfEditor/fields/choice/ChoiceField';
import { HStack } from 'layouts/box/Box';
import { Alternative, FieldTypeEnum, OptionList } from 'types/graphqlTypes';

import { alternativesAreDeeplyEqual } from '../utils';

import EditAlternatives from './EditAlternatives';

interface Props {
  open: boolean;
  setOpen: (val: boolean) => void;
  alternatives: Alternative[];
  savedOptionList: string | null;
  fieldName: string;
  doUpdate: (list: OptionList | null, alts: Alternative[]) => void;
  openOptionList: (id: string) => void;
}

export function EditAlternativesDialog({
  open,
  setOpen,
  alternatives,
  savedOptionList,
  fieldName,
  openOptionList,
  doUpdate,
}: Readonly<Props>) {
  const [options, setOptions] = useState<Alternative[]>([]);
  const [selectedListId, setSelectedListId] = useState<string | null>(savedOptionList);
  const { optionLists } = useGetOptionLists();
  const { optionList, loading } = useGetTypedOptionList(selectedListId, 'choice', true);

  const hasMadeChanges = useMemo(
    () => selectedListId !== savedOptionList || !alternativesAreDeeplyEqual(alternatives, options),
    [selectedListId, savedOptionList, options, alternatives],
  );

  useEffect(() => {
    setOptions(alternatives);
  }, [alternatives]);

  useEffect(() => {
    setSelectedListId(savedOptionList);
  }, [savedOptionList]);

  const optionListAlternatives: Alternative[] = useMemo(() => {
    return optionLists.map((list) => {
      return {
        id: list.id,
        value: list.id,
        label: list.label,
      };
    });
  }, [optionLists]);

  const confirmChanges = () => {
    doUpdate(optionList, options);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      style={{ minWidth: '800px', maxWidth: '800px' }}
    >
      <Dialog.Header>Configure options for &apos;{fieldName}&apos;</Dialog.Header>
      <Dialog.Body bodyHeight="70vh">
        <EditAlternatives
          options={optionList?.alternatives ?? options}
          name={fieldName}
          updateOptions={setOptions}
          isExternal={!!optionList}
        >
          <HStack gap="10px" alignItems="end">
            <ChoiceField
              editorId="AlternativesDialog"
              fieldModel={{
                fieldId: 'optionLists',
                type: FieldTypeEnum.choice,
                defaultValue: { value: null },
                alternatives: optionListAlternatives,
              }}
              fieldSettings={null}
              defaultFieldSettings={{
                fieldId: 'optionLists',
                label: 'External option list',
                visible: true,
                hint: '',
              }}
              value={selectedListId}
              setValue={(val) => setSelectedListId(val as string | null)}
              errorMessage={undefined}
              style={{ width: '240px' }}
              view="default"
            />
            {optionList && (
              <Button
                width={120}
                height={32}
                variant="outlined"
                usage="outlined"
                onClick={() => openOptionList(optionList.id)}
              >
                Go to list
              </Button>
            )}
          </HStack>
        </EditAlternatives>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton />
        <Dialog.ConfirmButton
          label="Confirm"
          onConfirm={confirmChanges}
          disabled={loading || !hasMadeChanges}
        />
      </Dialog.Footer>
    </Dialog>
  );
}
