import { memo, useCallback } from 'react';
import { Editor, Element, Transforms } from 'slate';
import { useSlate } from 'slate-react';

import { ReactComponent as CheckListIcon } from 'assets/icons/systemicons/editor/checklist.svg';
import isList from 'components/editor/components/list/utils/isList';
import { elementTypes } from 'components/editor/constants';
import { isElementActive } from 'components/editor/utils';

import ButtonBase from '../buttonBase';

const toggleCheckList = (editor: Editor, isActive: boolean) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && isList(n),
    split: true,
  });
  Transforms.setNodes(editor, {
    type: isActive ? elementTypes.PARAGRAPH : elementTypes.CHECKLIST,
  });
};

function CheckListButton({ disabled }: { disabled?: boolean }) {
  const editor = useSlate();
  const isActive = isElementActive(editor, elementTypes.CHECKLIST);

  const onMouseDown = useCallback(
    (event: React.MouseEvent<SVGElement>) => {
      if (disabled) return;
      event.preventDefault();
      event.stopPropagation();
      toggleCheckList(editor, isActive);
    },
    [isActive, editor, disabled],
  );

  return (
    <ButtonBase
      isActive={isActive}
      type={elementTypes.CHECKLIST}
      IconComponent={CheckListIcon}
      onMouseDown={onMouseDown}
      disabled={disabled}
    />
  );
}

export default memo(CheckListButton);
