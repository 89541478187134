import { PlatformAccount } from './members';

export type WithRequired<Type, Key extends keyof Type> = Type & {
  [Property in Key]-?: Type[Property];
};

export type WithOptional<Type, Key extends keyof Type> = Omit<Type, Key> & Partial<Pick<Type, Key>>;

/**
 * Checks if type `T` is the `unknown` type.
 */
export type IsUnknown<T> = unknown extends T ? ([T] extends [null] ? false : true) : false;

/**
 * Checks if type `T` is the `never` type.
 */
export type IsNever<T> = [T] extends [never] ? true : false;

/**
 * Checks if type `T` is the `any` type.
 */
// https://stackoverflow.com/a/49928360/3406963
export type IsAny<T> = 0 extends 1 & T ? true : false;

/**
 * Checks if type `T` is possibly null or undefined.
 */
export type IsNullable<T> = Extract<T, null | undefined> extends never ? false : true;

/**
 * Checks if type `T` has the specified type `U`.
 */
export type Has<T, U> = IsAny<T> extends true
  ? true
  : IsAny<U> extends true
  ? false
  : Extract<T, U> extends never
  ? false
  : true;

/**
 * Checks if type `T` does not have the specified type `U`.
 */
export type NotHas<T, U> = Has<T, U> extends false ? true : false;

type AnyToBrand<T> = IsAny<T> extends true ? AnyBrand : T;
type AnyBrand = { __conditionalTypeChecksAny__: undefined };

type TupleMatches<T, U> = Matches<[T], [U]>;
type Matches<T, U> = T extends U ? (U extends T ? true : false) : false;

type DeepPrepareIsExact<T, VisitedTypes = never> = {
  // make optional properties required
  [P in keyof T]-?: IsAny<T[P]> extends true
    ? AnyBrand
    : DeepPrepareIsExactProp<T[P], T, VisitedTypes>;
};

type DeepPrepareIsExactProp<Prop, Parent, VisitedTypes> = Prop extends VisitedTypes
  ? // recursive, bail
    Prop
  : // not recursive, keep going and add the parent type as a visited type
    DeepPrepareIsExact<Prop, VisitedTypes | Parent>;

export type IsExact<T, U> = TupleMatches<AnyToBrand<T>, AnyToBrand<U>> extends true
  ? TupleMatches<DeepPrepareIsExact<T>, DeepPrepareIsExact<U>> extends true
    ? true
    : false
  : false;

/**
 * Useful in switch statements to make them exhaustive; eg if a new
 * item is added to an enum or type that is used in a switch, using
 * this function in the default clause will cause typescript to
 * complain if you do not explicitly implement all possible cases.
 */
export const assertUnreachable = (val: never): never => {
  throw new Error('Should never get here', val);
};

export type DateRange = {
  startDate: Date | null;
  endDate: Date | null;
  rollingDate?: string | null;
};

export type KanbanStatus = {
  id: string;
  name: string;
  description: string;
  icon: string;
  backgroundColor?: string;
  opacity?: number;
};

export interface DestinationAccount extends PlatformAccount {
  publishingAt?: string;
  platformKind?: string;
  rundownTemplateId?: string;
}

export type Destination = {
  id: string | null;
  value: string;
  title: string;
  publishingTime?: string | null;
  startTime?: string;
  timeZone?: string;
  account?: DestinationAccount;
  rundownTemplateId?: string;
};

export type AutomationItem = {
  itemId: string;
  templateType: string;
  templateVariant: string;
  title: string;
};

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type CreateStringUnionType<T extends string[]> = T[number];
