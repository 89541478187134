import styled from '@emotion/styled/macro';

import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import transientOptions from 'theme/helpers';

export const RowWrapper = styled('div')`
  svg {
    margin: 4px 0;
  }
  display: flex;
  align-items: top;
  margin-bottom: 3px;
  margin-top: 3px;
  gap: 8px;
`;

export const Remove = styled(Close, transientOptions)<{ $disabled?: boolean }>`
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
  opacity: ${({ $disabled }) => ($disabled ? '0.3' : '0.7')};
  &:hover {
    ${({ $disabled }) => ($disabled ? '0.3' : '1')};
  }
`;
