import { useMemo } from 'react';
import { keyBy } from 'lodash';

import { useGetTypedOptionList } from 'api/optionLists/useGetOptionList';
import Tooltip from 'components/tooltip';
import { MdfField } from 'types/graphqlTypes';

import { InlineTagWrapper, Tag, TagText } from './styled';

interface ChoiceTagProps {
  fieldModel: MdfField;
  colors: Record<string, string>;
  value: string[];
  onClick: () => void;
}

export function ChoiceTags({ value, onClick, fieldModel, colors }: Readonly<ChoiceTagProps>) {
  const { optionListId } = fieldModel;
  const { optionList } = useGetTypedOptionList(optionListId, 'choice');
  const alternativesByValue = useMemo(() => {
    const alts = optionList?.alternatives ?? fieldModel.alternatives ?? [];
    return keyBy(alts, (alt) => alt.value);
  }, [optionList, fieldModel]);

  return (
    <Tooltip title="Edit value">
      <InlineTagWrapper onClick={onClick}>
        {value.map((t) => (
          <Tag
            key={t}
            $color={colors[alternativesByValue[t]?.label ?? t]}
            style={{ height: '21px', lineHeight: '21px', padding: '0 4px' }}
          >
            <TagText>{alternativesByValue[t]?.label ?? t}</TagText>
          </Tag>
        ))}
      </InlineTagWrapper>
    </Tooltip>
  );
}
