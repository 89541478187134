import styled from '@emotion/styled';

import Divider from 'components/divider';

export const ProfileWrapper = styled('div')`
  width: 360px;
  border-radius: 8px;
  overflow: hidden;
  margin: -8px 0 -8px;
`;

export const UserContentWrapper = styled.div`
  margin-left: auto;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const StyledTabDivider = styled(Divider)`
  margin: 4px;
`;

export const OrdersButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 32px;
  width: 24px;
`;
