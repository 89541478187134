import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { ReactComponent as Gif } from 'assets/icons/systemicons/editor/gif.svg';
import { ReactComponent as Photo } from 'assets/icons/systemicons/editor/photo.svg';
import Tooltip from 'components/tooltip';
import insertGifBlock from 'components/editor/components/gif/utils/insertGifBlock';
import insertImage from 'components/editor/components/image/utils/insertImage';
import insertImageBlock from 'components/editor/components/image/utils/insertImageBlock';
import tooltipEnum from 'components/editor/constants/tooltipEnum';
import { useEditorContext } from 'components/editor/EditorContext';
import normalizeOnListFocus from 'components/editor/utils/normalizeOnListFocus';
import useImageUpload from 'hooks/useImageUpload';
import defaultImageTypes from 'utils/constants/imageTypes';
import transientOptions from 'theme/helpers';

const Container = styled('div', transientOptions)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  ${({ $disabled, theme }) =>
    $disabled
      ? css`
          cursor: auto;
          opacity: 0.36;
        `
      : ''}
`;

const ImageButton = ({ isGif, disabled }) => {
  const editor = useSlate();
  const { update, isAllowed } = useEditorContext();

  const onImageLoad = useCallback(
    (file, fileUrl) => insertImage(editor, update, file, fileUrl),
    [editor, update],
  );

  const imageTypes = isGif ? ['gif'] : defaultImageTypes;

  const uploadImage = useImageUpload({
    disableResize: true,
    imageTypes,
    onImageLoad,
  });

  const onMouseDown = useCallback(
    (event) => {
      if (!disabled) {
        event.preventDefault();
        normalizeOnListFocus(editor);
        if (isAllowed) {
          if (isGif) insertGifBlock(editor, update);
          else insertImageBlock(editor, update);
        } else uploadImage();
      }
    },
    [editor, isAllowed, isGif, update, uploadImage, disabled],
  );

  const IconComponent = isGif ? Gif : Photo;
  const title = isGif ? tooltipEnum.GIF : tooltipEnum.IMAGE;

  return (
    <Tooltip title={title}>
      <Container $disabled={disabled}>
        <IconComponent onMouseDown={onMouseDown} />
      </Container>
    </Tooltip>
  );
};

ImageButton.propTypes = {
  /** Specifies if the image is GIF of not */
  isGif: PropTypes.bool,
  disabled: PropTypes.bool,
};

ImageButton.defaultProps = {
  isGif: false,
  disabled: false,
};

export default memo(ImageButton);
