import { useCallback, useMemo } from 'react';

import useDateTimeUtils from 'hooks/useDateTimeUtils';
import { TimeVariant, timeVariants } from 'utils/planningViews';

const useTimeVariants = () => {
  const { addDays, subDays, addWeeks, subWeeks, addMonths, subMonths, addYears, subYears } =
    useDateTimeUtils();

  const memoizedTimeVariants = useMemo(() => {
    return {
      [timeVariants.DAY]: {
        increment: addDays,
        decrement: subDays,
      },
      [timeVariants.WEEK]: {
        increment: addWeeks,
        decrement: subWeeks,
      },
      [timeVariants.MONTH]: {
        increment: addMonths,
        decrement: subMonths,
      },
      [timeVariants.YEAR]: {
        increment: addYears,
        decrement: subYears,
      },
    };
  }, []);

  const getTimeVariantFunc = useCallback(
    (timeVariant: TimeVariant) => {
      return memoizedTimeVariants[timeVariant];
    },
    [memoizedTimeVariants],
  );

  return { getTimeVariantFunc };
};

export default useTimeVariants;
