import { useCallback } from 'react';

import useDateTimeUtils from 'hooks/useDateTimeUtils';

const useGetUTCDate = () => {
  const { startOfDay } = useDateTimeUtils();

  const getUTCDateString = useCallback((date?: Date): string => {
    const startOfDate = startOfDay(date ? new Date(date) : new Date());

    const year: number = startOfDate.getUTCFullYear();
    const month: number = startOfDate.getUTCMonth() + 1; // Month is zero-based, so we add 1
    const day: number = startOfDate.getUTCDate();

    // Pad single-digit months and days with leading zeros if needed
    const formattedMonth: string = month < 10 ? '0' + month : String(month);
    const formattedDay: string = day < 10 ? '0' + day : String(day);

    // Construct the UTC date string in the format YYYY-MM-DD
    const dateString: string = `${year}-${formattedMonth}-${formattedDay}`;

    return dateString;
  }, []);

  return { getUTCDateString };
};

export default useGetUTCDate;
