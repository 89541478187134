import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { cloneDeep } from 'lodash';

import { useGetTypedOptionList } from 'api/optionLists/useGetOptionList';
import Dialog from 'components/dialogs/DialogBuilder';
import Text from 'components/text';
import Tooltip from 'components/tooltip';
import { Color } from 'features/reusableStyled';
import { Box } from 'layouts/box/Box';
import { Alternative, LayoutSettings } from 'types/graphqlTypes';

import { PopoverColors } from './PopoverColors';

interface Props {
  open: boolean;
  optionListId: string | undefined;
  alternatives: Alternative[];
  setOpen: (val: boolean) => void;
  settings: LayoutSettings;
  onUpdateSettings: (settings: LayoutSettings) => void;
}

const PlaceholderColor = styled('div')`
  border: 1px solid transparent;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  cursor: pointer;
`;

const Label = styled(Box)`
  width: 100%;
  :hover .placeholder-color {
    border-color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  }
`;

const StyledText = styled(Text)`
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
`;

const OptionsWrapper = styled('div')`
  overflow: auto;
  height: 100%;
  max-height: calc(75vh - 120px);
`;

export function ConfigColors({
  settings,
  open,
  setOpen,
  onUpdateSettings,
  alternatives,
  optionListId,
}: Readonly<Props>) {
  const [colors, setColors] = useState(cloneDeep(settings.colors ?? {}));
  const { optionList } = useGetTypedOptionList(optionListId, 'choice', true);

  const options = useMemo(() => {
    return optionList?.alternatives ?? alternatives;
  }, [optionList, alternatives]);

  useEffect(() => {
    if (open) {
      setColors(cloneDeep(settings.colors ?? {}));
    }
  }, [open]);

  const updateColorMap = useCallback(() => {
    onUpdateSettings({ ...settings, colors: colors });
    setOpen(false);
  }, [onUpdateSettings, colors]);

  const setColor = useCallback(
    (opt: Alternative, color: string | null) => {
      if (color) {
        setColors((prevValue) => {
          return {
            ...prevValue,
            [opt.label]: color,
          };
        });
      } else {
        const copy = { ...colors };
        delete copy[opt.label];
        setColors(copy);
      }
    },
    [setColors, colors],
  );

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      style={{ width: '300px', maxHeight: '75vh' }}
    >
      <Dialog.Header>Configure colors</Dialog.Header>
      <Dialog.Body bodyHeight="100%">
        <OptionsWrapper>
          {options.map((opt) => {
            return (
              <Box container key={opt.id} gap="10px" justifyContent="start" width="100%">
                <PopoverColors
                  onColorChoice={(color: string) => setColor(opt, color)}
                  onClearColor={() => setColor(opt, null)}
                  selectedColor={colors[opt.label]}
                >
                  <Label
                    flexDirection="row"
                    gap="8px"
                    container
                    justifyContent="start"
                    margin="0 0 2px 0"
                  >
                    {colors[opt.label] === undefined ? (
                      <PlaceholderColor className="placeholder-color" />
                    ) : (
                      <Color $color={colors[opt.label]} $size={12} />
                    )}

                    <Tooltip title="Set color">
                      <StyledText variant="listItemLabel">{opt.label}</StyledText>
                    </Tooltip>
                  </Label>
                </PopoverColors>
              </Box>
            );
          })}
        </OptionsWrapper>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton />
        <Dialog.ConfirmButton label="Confirm" onConfirm={updateColorMap} />
      </Dialog.Footer>
    </Dialog>
  );
}
