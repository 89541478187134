import { memo, useContext, useRef } from 'react';

import Avatar from 'components/avatar/Avatar';
import TourPopup from 'components/tourPopup';
import UserCtx from 'contexts/UserContext';
import { tours } from 'store';

function UserAvatar() {
  const { attributes } = useContext(UserCtx);
  const { mAvatarKey } = attributes || {};
  const notificationRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <TourPopup
        position="bottom"
        anchor={notificationRef}
        tourStep={tours.MainTour.steps.Mobile}
        tourId={tours.MainTour.id}
      />
      <div ref={notificationRef}>
        <Avatar size={32} onClick={() => {}} imageKey={mAvatarKey} variant="user" />
      </div>
    </>
  );
}

export default memo(UserAvatar);
