import { useQuery } from '@apollo/client';

import GET_RUNDOWN_TEMPLATES from 'operations/queries/getAllRundownTemplates';
import { Rundown } from 'types/graphqlTypes';

export interface GetRundownTemplates {
  getAllRundownTemplates: Rundown[];
}

const useGetAllRundownTemplates = () => {
  const { data, error, loading } = useQuery<GetRundownTemplates>(GET_RUNDOWN_TEMPLATES, {
    fetchPolicy: 'cache-and-network',
  });
  return [data, loading, error] as const;
};

export default useGetAllRundownTemplates;
