import { useUpdateBoardColumns } from 'api/gridAndKanban/useUpdateBoardColumns';
import { MemberContextMenu } from 'components/contextMenu/MemberContextMenu';
import MemberDropzone from 'features/dnd/MemberDropzone';
import { DataTableToolbar } from 'features/grids/common/components/toolbar/DataTableToolbar';
import { groomMemberMetadata } from 'features/grids/common/hooks/useGroomData';
import { DataTableFilterField } from 'features/grids/common/types';
import { useDataTable } from 'features/grids/common/useDataTable';
import { ParsedMemberType } from 'types/members';

import { GridWidgetDataTable } from './components/GridWidgetDataTable';
import { useGetWidgetColumns } from './hooks/useGetColumns';
import { useGetTableMeta } from './hooks/useGetTableMeta';
import { GridWidgetContainerType, GridWidgetType } from './types';

const filterFields: DataTableFilterField<ParsedMemberType>[] = [
  {
    label: 'Title',
    value: 'mTitle',
    placeholder: 'Filter by title...',
  },
];

export function GridWidget({
  data,
  columns,
  meta,
  boardId,
  groupId,
  itemIds = [],
}: Readonly<GridWidgetType>) {
  const { addMember } = useUpdateBoardColumns(boardId);

  // ─── Initiate Table Instance w/ Data, Columns And Meta ────────────────
  const { table } = useDataTable({
    data,
    columns,
    meta,
    storageKey: `gridWidget-${boardId}`,
    getRowId: (originalRow) => `${originalRow.mId}`,
  });

  return (
    <>
      <MemberDropzone itemGroupId={groupId} itemIds={itemIds} onDrop={addMember}>
        <GridWidgetDataTable table={table} itemIds={itemIds} boardId={boardId} groupId={groupId}>
          <DataTableToolbar table={table} filterFields={filterFields} />
        </GridWidgetDataTable>
      </MemberDropzone>
      <MemberContextMenu menuId={`gridItemMenu-${boardId}`} />
    </>
  );
}

/**
 * Set up the data, columns and meta for the table.
 */
export function GridWidgetContainer({
  data,
  boardId,
  groupId,
  mdfs,
  subTypes,
  ...rest
}: Readonly<GridWidgetContainerType>) {
  // ─── Groom Data ───────────────────────────────────────────────────────
  const members = groomMemberMetadata(data, mdfs, subTypes);

  // ─── Columns ─────────────────────────────────────────────────────────
  const columns = useGetWidgetColumns(data, mdfs);

  // ─── Table Meta ──────────────────────────────────────────────────────
  const meta = useGetTableMeta({ boardId, groupId });

  return (
    <GridWidget
      data={members}
      groupId={groupId}
      columns={columns}
      meta={meta}
      boardId={boardId}
      {...rest}
    />
  );
}
