import { gql } from '@apollo/client';

export default gql`
  mutation CreateStoryFromFeed($input: CreateStoryFromFeedInput) {
    createStoryFromFeed(input: $input) {
      mId
      mRefId
      mTitle
      mDescription
      mContentKey
      mThumbnailUrl
      mCreatedAt
      mUpdatedAt
      mState
      mPublishingAt
      mType
      mPriority
      isRestricted
      mMetaData {
        key
        value
      }
      mAssignedMembers {
        mId
        mType
      }
      mPriority
      mContent
    }
  }
`;
