import { useCallback, useContext } from 'react';
import { ApolloClient } from '@apollo/client';

import UserCtx from 'contexts/UserContext';
import updateMessageInConversation from 'operations/utils/cache/notification/updateMessageInConversation';
import { UnreadChatCount, useUnreadChatNotification } from 'store/chat';
import { MemberType } from 'types/graphqlTypes';

interface Props {
  client: ApolloClient<unknown>;
  data?: {
    data?: {
      updateContentChat: MemberType;
    };
  };
}

const useChatSubscriptionUpdate = () => {
  const [unreadChatState, setUnreadChatState] = useUnreadChatNotification();
  const { mId: userId } = useContext(UserCtx);

  const handleChatSubscriptionUpdate = useCallback(
    ({ client, data }: Props) => {
      const item = data?.data?.updateContentChat;
      if (!item?.mId || item?.mUpdatedById === userId) return;
      const { mId, crudAction } = item;
      const unreadState: UnreadChatCount = unreadChatState[mId]
        ? { ...unreadChatState[mId] }
        : {
            number: 0,
            timeStamp: new Date().toISOString(),
          };

      if (unreadState.number === 0) {
        unreadState.firstUnreadMessageId = item.mRefId;
      }

      // Naively only update on insert. We do not track which items are new; so we cannot
      // easily reduce the number on removal
      if (crudAction === null) {
        unreadState.number += 1;
      }

      unreadState.timeStamp = new Date().toISOString();

      setUnreadChatState((prevValue) => {
        return {
          ...prevValue,
          [mId]: { ...unreadState },
        };
      });
      updateMessageInConversation(client, item);
    },
    [unreadChatState, setUnreadChatState, userId],
  );

  return handleChatSubscriptionUpdate;
};

export default useChatSubscriptionUpdate;
