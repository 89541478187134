import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TextField } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';

import transientOptions from 'theme/helpers';

export const TextWrapper = styled('div')`
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  width: 100%;
  > :first-of-type {
    margin-bottom: 4px;
  }
`;

export const Field = styled('span')``;

export const StyledTextField = styled(TextField, transientOptions)`
  margin-top: 2px;
  svg path {
    fill: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
  .MuiFormHelperText-contained {
    margin: 2px 0 -2px 2px;
    color: ${({ theme }) => theme.palette.dina.statusWarning};
  }
  .MuiFilledInput-root {
    padding: 2px !important;
    width: 100%;
    display: inline-flex;
    flex-flow: row wrap;
    min-height: 24px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.palette.dina.inputBackground};
    border: 1px solid ${({ theme }) => theme.palette.dina.inputBorderResting};
    &:hover {
      background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
    }
    &.Mui-focused {
      ${({ theme }) => css`
        background: ${theme.palette.dina.statusOnFocusedDiscreetFill};
        border-color: ${theme.palette.dina.statusOnFocused} !important;
      `}
    }
    &.Mui-error {
      border-color: ${({ theme }) => theme.palette.dina.warningBorder};
    }
  }
  input,
  textarea {
    width: 100%;
    font-size: 14px;
    padding: 4px !important;
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
      color: ${({ theme }) => theme.palette.dina.disabled};
    }
  }
  .MuiInputBase-inputAdornedEnd {
    padding: 4px 32px 4px 4px !important;
  }
  .MuiFilledInput-underline {
    &:hover:not($disabled):not($focused):not($error):before {
      border-bottom: none;
    }
    &:after {
      border-color: transparent;
    }
    &:before {
      border-color: transparent;
    }
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;
