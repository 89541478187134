import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { atom, useAtom } from 'jotai';

import { StoryTabValue } from 'store';

import { useStoryMolecule } from './store/story';

export interface Pane {
  tab: StoryTabValue;
  tId?: string;
}
/**
 * Represents a search param pane.
 * @description This type is a string that follows the pattern `${StoryTab}.${string}`.
 * StoryTab represents tab ('content', 'notes') and the string after dot is selected item id (tId).
 * Example: 'notes.123456_note_987' or 'content.'
 */

type SearchParamPane = `${StoryTabValue}.${string}`;
const storyTabState = atom<SearchParamPane[]>([]);

const useStoryTabState = () => useAtom(storyTabState);

/**
 * Responsible for reading out URL state and keeping internal state in sync.
 *
 * This effect must only run once, and therefore has been pulled out of the useStoryPanes() hook
 * which is imported many places.
 */
export const useSyncWithUrl = () => {
  const [searchParams] = useSearchParams();
  const [storyTabs, setStoryTabs] = useStoryTabState();
  const { useQueryTagValue } = useStoryMolecule();
  const queryTag = useQueryTagValue();

  useEffect(() => {
    const params = searchParams.get(queryTag);
    if (storyTabs.length === 0) {
      if (params) setStoryTabs(params.split(',') as SearchParamPane[]);
      else {
        setStoryTabs(['content.']);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const useStoryPanes = () => {
  const [searchParams] = useSearchParams();
  const [storyTabs, setStoryTabs] = useStoryTabState();
  const { useQueryTagValue } = useStoryMolecule();
  const queryTag = useQueryTagValue();

  const storyPanes: Pane[] = useMemo(
    () =>
      (storyTabs ?? []).map((tabStr) => {
        const [tab, tId] = tabStr.split('.');
        return { tab: tab ?? 'content', tId } as Pane;
      }),
    [storyTabs],
  );

  const syncToURL = useCallback(
    (newPanes: string[]) => {
      searchParams.set(queryTag, newPanes.join(','));
      window.history.replaceState({}, '', '?' + searchParams.toString());
    },
    [queryTag, searchParams],
  );

  const addStoryPane = useCallback(
    (tab?: StoryTabValue, tId?: string) => {
      setStoryTabs((prev) => {
        const tempSPTabs = [...storyTabs, `${tab ?? 'content'}.${tId ?? ''}`];
        syncToURL(tempSPTabs);
        return [...prev, `${tab ?? 'content'}.${tId ?? ''}`];
      });
    },
    [syncToURL, setStoryTabs],
  );

  const updateStoryPane = useCallback(
    (index: number, tab: StoryTabValue, tId?: string) => {
      setStoryTabs((prev) => {
        if (index < prev.length) {
          const tempSPTabs = [...prev];
          tempSPTabs[index] = `${tab}.${tId ?? ''}`;
          syncToURL(tempSPTabs);
          return tempSPTabs;
        }
        return prev;
      });
    },
    [syncToURL, setStoryTabs],
  );

  const removeStoryPane = useCallback(
    (index: number) => {
      setStoryTabs((prev) => {
        if (prev.length > 1 && index < prev.length) {
          const tempSPTabs = prev.filter((_t, i) => i !== index);
          syncToURL(tempSPTabs);
          return tempSPTabs;
        }
        return prev;
      });
    },
    [syncToURL, setStoryTabs],
  );

  return {
    storyPanes,
    addStoryPane,
    updateStoryPane,
    removeStoryPane,
  };
};

export default useStoryPanes;
