import { memo } from 'react';
import { useSlate } from 'slate-react';

import { elementTypes, markTypes } from 'components/editor/constants';

import { ToolbarContainer } from '../../styled';
import { ButtonGroup } from '../ButtonGroup';
import CheckListButton from '../checkListButton/CheckListButton';
import ColorButton from '../colorButton';
import ElementButton from '../elementButton';
import ImageButton from '../imageButton';
import LinkButton from '../linkButton';
import ListButton from '../listButton';
import MarkButton from '../markButton';
import UppercaseButton from '../uppercaseButton/UppercaseButton';
import VideoButton from '../videoButton';

const { BOLD, ITALIC, UNDERLINE, STRIKE_THROUGH } = markTypes;

const { UNORDERED_LIST, HEADING_FOUR, HEADING_THREE, HEADING_TWO, ORDERED_LIST } = elementTypes;

function NotesToolbar() {
  const { selection } = useSlate();

  const disabled = selection
    ? [0, 1].includes(selection?.anchor.path[0]) || [0, 1].includes(selection?.focus.path[0])
    : false;

  return (
    <ToolbarContainer>
      <ButtonGroup>
        <ElementButton type={HEADING_TWO} disabled={disabled} />
        <ElementButton type={HEADING_THREE} disabled={disabled} />
        <ElementButton type={HEADING_FOUR} disabled={disabled} />
      </ButtonGroup>
      <ButtonGroup>
        <MarkButton type={BOLD} />
        <MarkButton type={ITALIC} />
        <MarkButton type={UNDERLINE} />
        <MarkButton type={STRIKE_THROUGH} />
        <UppercaseButton />
      </ButtonGroup>
      <ButtonGroup>
        <ListButton type={UNORDERED_LIST} disabled={disabled} />
        <ListButton type={ORDERED_LIST} disabled={disabled} />
        <CheckListButton disabled={disabled} />
      </ButtonGroup>
      <ButtonGroup>
        <ColorButton />
      </ButtonGroup>
      <ButtonGroup>
        <VideoButton disabled={disabled} />
        <ImageButton disabled={disabled} />
        <ImageButton isGif disabled={disabled} />
      </ButtonGroup>
      <ButtonGroup>
        <LinkButton disabled={disabled} />
      </ButtonGroup>
    </ToolbarContainer>
  );
}

export default memo(NotesToolbar);
