import { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import UPDATE_USER_DATA from 'operations/mutations/UpdateUserData';
import { useSeenHelp } from 'store';
import useHighlightRectangle from 'hooks/useHighlightRectangle';
import Text from 'components/text/Text';

import Checkbox from 'components/checkbox';
import { Button } from 'components/buttons';
import PopperWithArrow from 'components/shared/popperWithArrow/PopperWithArrow';

import { Content, Wrapper, Bottom, Image, TextWrapper, CheckboxWrapper } from './styled';

/**
 * Unique identifier to be used as the id prop sent in to this component.
 * This will be used to store server side whether a user has permanently dismissed a notification.
 * Remove old references to notifications we no longer need here, so below logic
 * can lazy-remove these over time from server side state.
 *
 * Note that tour identifiers are handled centrally and should not be added here.
 */
export const notificationIdentifiers = {
  PinTemplateFolder: 'PinTemplateFolder',
  ReorderTemplates: 'ReorderTemplates',
  GridSwitch: 'GridSwitch',
  DeckCreate: 'DeckCreate',
  LightTheme: 'lightTheme',
  RndInstanceDoubleClick: 'RundownInstanceDoubleClick',
  ShareStoryButton: 'ShareStoryButton',
  RundownGrouping: 'RundownGrouping',
  RundownGroupingMaster: 'RundownGroupingMaster',
  MetadataSlide: 'MetadataSlide',
  TitleAndOption: 'TitleAndOption',
  Convert: 'Convert',
  Duplicate: 'Duplicate',
  ExternalContacts: 'ExternalContacts',
  StoryHubVersion: 'StoryHubVersion',
};

const rememberSeen = async (seenHelpObj, id, updateFn) => {
  const { mId, mRefId, mType, mSeen } = seenHelpObj;

  // Only keep active identifiers saved serverside
  const activeIds = Object.values(notificationIdentifiers);
  const seenNotifications = mSeen.filter((savedId) => activeIds.includes(savedId));

  if (seenNotifications.includes(id)) return seenNotifications;

  seenNotifications.push(id);
  const input = {
    mId,
    mRefId,
    mType,
    mSeen: seenNotifications,
  };

  await updateFn({
    variables: {
      input,
    },
  });

  return seenNotifications;
};

function Notification({
  anchor,
  id,
  title,
  text,
  position,
  imageUrl,
  height,
  isVisible,
  useHighlight,
  children = undefined,
}) {
  if (!id) {
    throw new Error('Invalid identifier in NotificationPopup');
  }

  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [visible, setVisible] = useState(false);
  const [seenHelpObj, setSeenHelp] = useSeenHelp();
  const [updateSeenHelp] = useMutation(UPDATE_USER_DATA);

  const doRememberSeen = () => {
    if (dontShowAgain) {
      rememberSeen(seenHelpObj, id, updateSeenHelp).then((newSeenHelp) => {
        setSeenHelp({
          ...seenHelpObj,
          mSeen: newSeenHelp,
        });
      });
    }
    setVisible(false);
  };

  // If undefined default to true since data won't have loaded yet. We don't want old popups
  // to appear because the client does not yet know whether the user has dismissed it or not.
  const hasBeenSeen = seenHelpObj.mSeen?.includes(id) ?? true;
  const ref = anchor?.current ?? anchor;
  const rect = ref?.getBoundingClientRect ? ref.getBoundingClientRect() : null;
  const showHighlight = !!useHighlight && visible;

  useHighlightRectangle(rect, showHighlight);

  useLayoutEffect(() => {
    if (typeof isVisible === 'boolean') {
      setTimeout(() => setVisible(isVisible), 0);
    } else setTimeout(() => setVisible(!hasBeenSeen), 150);
  }, [hasBeenSeen, ref, isVisible]);

  return (
    <PopperWithArrow open={visible} position={position} anchor={anchor}>
      <Wrapper>
        <Content>
          {imageUrl && <Image imageUrl={imageUrl} />}
          <TextWrapper height={height} hasImage={!!imageUrl}>
            <Text variant="h6" color="highEmphasis">
              {title}
            </Text>
            <Text variant="caption" color="highEmphasis">
              {text}
            </Text>
          </TextWrapper>
        </Content>
        {children ? (
          { ...children }
        ) : (
          <Bottom>
            <CheckboxWrapper>
              <Checkbox
                checked={dontShowAgain}
                onChange={(event) => setDontShowAgain(event.target.checked)}
                id="dontShowAgain"
              />
              <Text
                variant="listItemLabel"
                htmlFor="dontShowAgain"
                as="label"
                style={{ userSelect: 'none', cursor: 'pointer' }}
              >
                Don't show this again
              </Text>
            </CheckboxWrapper>
            <Button width={120} height={32} onClick={doRememberSeen}>
              Got it
            </Button>
          </Bottom>
        )}
      </Wrapper>
    </PopperWithArrow>
  );
}

Notification.propTypes = {
  height: PropTypes.number,
  id: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  position: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.string,
  isVisible: PropTypes.bool,
  useHighlight: PropTypes.bool,
  anchor: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.shape({
      current: PropTypes.node,
    }),
  ]),
};

Notification.defaultProps = {
  height: null,
  imageUrl: null,
  position: 'bottom',
  isVisible: null,
  useHighlight: false,
  anchor: null,
  text: '',
  title: '',
};

export default Notification;
