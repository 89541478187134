import PropTypes from 'prop-types';

import { Button } from 'components/buttons';
import Dialog from 'components/dialog';

import { useIsOpenConfirmDialog } from '../../atoms';
import ConfirmDialog from '../confirmDialog/confirmDialog-view';

import { RootWrapper } from './footer-styled';
import LoadingIndicator from 'components/loadingIndicator';
import { useDisableSaveError } from '../../atomsTs';
import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { VStack } from 'layouts/box/Box';

const Footer = ({
  onCloseSettings,
  onDiscardAndClose,
  disabled,
  isSaving,
  onSaveAndClose,
  onSave,
  onCloseConfirmDialog,
}) => {
  const [isOpenConfirmDialog] = useIsOpenConfirmDialog();
  const [disableSaveErrors] = useDisableSaveError();

  const errorTooltip = useMemo(() => {
    if (isEmpty(disableSaveErrors)) return '';
    return (
      <VStack>
        {Object.values(disableSaveErrors).map((error) => (
          <div key={error}>{error}</div>
        ))}
      </VStack>
    );
  }, [disableSaveErrors]);

  return (
    <RootWrapper>
      <Button height={32} width={104} variant="outlined" usage="outlined" onClick={onCloseSettings}>
        Cancel
      </Button>
      <Button
        title={errorTooltip}
        height={32}
        disabled={disabled || !isEmpty(disableSaveErrors)}
        onClick={onSave}
        variant="contained"
        width={104}
      >
        {isSaving ? <LoadingIndicator height={24} width={24} /> : 'Save'}
      </Button>
      <Button
        disabled={disabled || isSaving}
        height={32}
        width={104}
        variant="contained"
        usage="pitch"
        onClick={onSaveAndClose}
      >
        Save & Close
      </Button>
      <Dialog open={isOpenConfirmDialog} onClose={onCloseConfirmDialog}>
        <ConfirmDialog
          onSaveAndClose={onSaveAndClose}
          onDiscardAndClose={onDiscardAndClose}
          onClose={onCloseConfirmDialog}
        />
      </Dialog>
    </RootWrapper>
  );
};

Footer.propTypes = {
  onCloseSettings: PropTypes.func,
  onDiscardAndClose: PropTypes.func,
  onSaveAndClose: PropTypes.func,
  onSave: PropTypes.func,
  disabled: PropTypes.bool,
};

Footer.defaultProps = {
  onCloseSettings: () => {},
  onDiscardAndClose: [],
  onSaveAndClose: {},
  onSave: () => {},
  disabled: false,
};

export default Footer;
