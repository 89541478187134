import { useMemo } from 'react';
import { Dictionary, keyBy } from 'lodash';

import { useGetOrderForms } from 'api/order_forms/useGetOrderForms';
import { Instance } from 'types';
import { MemberTypeEnum } from 'types/graphqlTypes';
import { OrderFormMemberType } from 'types/memberTypes/order_form';

export const supportedResourceMTypes: MemberTypeEnum[] = [
  MemberTypeEnum.Story,
  MemberTypeEnum.Pitch,
  MemberTypeEnum.Instance,
  MemberTypeEnum.ResStory,
  MemberTypeEnum.ResPitch,
];

export type Resource = {
  mId: string;
  mTitle: string;
  mType: MemberTypeEnum;
  // for instance mProperties and mStoryId will exist
  mProperties?: Instance['mProperties'];
  mStoryId?: string;
  mUpdatedAt?: string;
};

export type ResourceDetails = {
  resource: Resource;
  orderFormMap?: Dictionary<OrderFormMemberType>;
};
interface ResourceProperties {
  resource?: Resource | null;
}

const useResourceDetails = ({ resource }: ResourceProperties): ResourceDetails | undefined => {
  const { orderForms } = useGetOrderForms();

  const orderFormMap = useMemo(() => {
    return keyBy(orderForms, (orderForm) => orderForm.mRefId);
  }, [orderForms]);

  if (!resource) return;

  return {
    resource: resource,
    orderFormMap,
  };
};

export default useResourceDetails;
