import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import GET_AUDITS from 'operations/queries/getVersionAudits';
import { useUsersMap } from 'store';
import { Version } from 'types';
import { AuditType } from 'types/graphqlTypes';

import useDateTimeUtils from './useDateTimeUtils';

const allowedAuditActionIds = ['version', 'stateChanged', 'created', 'assigned'];

/**
 * Custom hook to fetch version history for a specific instance.
 *
 * @param {string} instanceId - the ID of the instance
 * @return an object containing the versions, loading state, and a function to refetch
 */
const useVersionHistory = (instanceId: string) => {
  const { format } = useDateTimeUtils();
  const usersMap = useUsersMap();

  const { data, loading, refetch } = useQuery<{
    getAudits: AuditType[];
  }>(GET_AUDITS, {
    variables: {
      input: {
        mId: instanceId,
      },
    },
    fetchPolicy: 'network-only',
  });

  const currentVersions = useMemo(
    () =>
      (data?.getAudits
        .filter((audit: AuditType) => allowedAuditActionIds.includes(audit.mActionId ?? ''))
        .map((audit: AuditType) => ({
          id: audit?.mPayload?.mId,
          title: audit?.mPayload?.mTitle,
          type: audit?.mPayload?.mType,
          eventId: audit?.mEventId,
          timestamp: format(audit?.mUpdatedAt ?? '', 'MMM. DD YYYY, HH:mm'),
          updatedBy: audit.mUpdatedBy ? usersMap[audit.mUpdatedBy]?.mTitle ?? '' : '',
          updatedById: audit.mUpdatedBy,
          contentKey: audit?.mPayload?.mContentKey,
          actionId: audit.mActionId,
          labels: audit?.mLabels?.map((label) => label.mMessage),
          oldState: audit?.mPayload?.oldState,
          newState: audit?.mPayload?.newState,
        })) ?? []) as Version[],
    [data],
  );

  return { versions: currentVersions, versionsLoading: loading, refetchVersions: refetch };
};

export default useVersionHistory;
