import { useCallback, useContext, useRef, useState } from 'react';

import { ReactComponent as ConvertIcon } from 'assets/icons/systemicons/convert.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/systemicons/copy.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/systemicons/warning.svg';
import NotificationPopup, { notificationIdentifiers } from 'components/notificationPopup';
import useToast from 'components/toast/useToast';
import USER_CONTEXT from 'contexts/UserContext';
import useCreateStoryFromPitch from 'hooks/useCreateStoryFromPitch';
import useDinaNavigate from 'hooks/useDinaNavigate';
import useSettingsValue from 'hooks/useSettingsValue';
import { Flex } from 'layouts/box/Box';
import { Story } from 'types';

import { TwoStepButton } from './TwoStepButton';

const duplicateValues = {
  label1: 'Duplicate as Story',
  label2: 'Confirm Duplicate',
  popupTitle: 'Duplicate Pitch as Story',
  popupBody:
    'Duplicating the pitch as a story will retain the original pitch. ' +
    'The story will be linked to the original pitch for reference',
  icon1: <CopyIcon />,
  icon2: <WarningIcon />,
};

const convert = {
  label1: 'Convert to Story',
  label2: 'Confirm Convert',
  popupTitle: 'Convert Pitch to Story',
  popupBody:
    'Converting the pitch to a story will remove the original pitch. Once converted, ' +
    'there will be no reference to the original pitch.',
  icon1: <ConvertIcon />,
  icon2: <WarningIcon />,
};

interface ButtonsProps {
  pitch: Story;
  onComplete?: () => void;
}

/** Trigger copy or convert pitch to story */
export function PitchToStoryButton({ pitch, onComplete }: ButtonsProps) {
  const [creatingStory, setCreatingStory] = useState(false);

  const popupRef = useRef(null);

  const { mId: userId } = useContext(USER_CONTEXT);

  const [getSettingsValue] = useSettingsValue();
  const isConvertToStory = getSettingsValue('app.convertPitchToStory') === 'true';

  const { navigateTo } = useDinaNavigate();
  const createStoryFromPitch = useCreateStoryFromPitch();
  const { errorToast } = useToast();

  const createStory = useCallback(async () => {
    setCreatingStory(true);

    const storyId = (await createStoryFromPitch(
      pitch?.mId,
      userId,
      pitch?.mPublishingAt,
      isConvertToStory,
    ).catch((err: unknown) => {
      errorToast(err);
    })) as string;

    setCreatingStory(false);
    navigateTo('story', storyId);
    onComplete?.();
  }, [
    navigateTo,
    createStoryFromPitch,
    pitch?.mId,
    pitch?.mPublishingAt,
    userId,
    isConvertToStory,
  ]);

  const options = isConvertToStory ? convert : duplicateValues;

  return (
    <Flex ref={popupRef}>
      <NotificationPopup
        position="bottom"
        anchor={popupRef}
        id={isConvertToStory ? notificationIdentifiers.Convert : notificationIdentifiers.Duplicate}
        title={options.popupTitle}
        text={options.popupBody}
      />
      <TwoStepButton
        onConfirm={() => void createStory()}
        usage1={'story'}
        usage2={isConvertToStory ? 'danger' : 'warning'}
        label1={options.label1}
        label2={options.label2}
        icon1={options.icon1}
        icon2={options.icon2}
        disabled={creatingStory}
        loading={creatingStory}
      />
    </Flex>
  );
}
