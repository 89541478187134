import { ReactComponent as NumberedList } from 'assets/icons/systemicons/editor/listOrdered_off.svg';
import { ReactComponent as BulletedList } from 'assets/icons/systemicons/editor/listUnordered_off.svg';
import { elementTypes } from 'components/editor/constants';

const { UNORDERED_LIST, ORDERED_LIST } = elementTypes;

const iconComponents = {
  [UNORDERED_LIST]: BulletedList,
  [ORDERED_LIST]: NumberedList,
};

export default iconComponents;
