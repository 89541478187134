import { useCallback, useState } from 'react';

import Footer from './components/footer/Footer';
import Header from './components/header/AppMenuHeader';
import Info from './components/info';
import MobileConfig from './components/mobileConfig';

import { StyledVStack } from './styled';

interface AppMenuProps {
  username?: string;
  avatar?: string;
  onLogout: () => Promise<void>;
  onOpenSettingsClick: () => void;
}

export default function AppMenu({
  avatar = '',
  username = '',
  onLogout,
  onOpenSettingsClick,
}: AppMenuProps) {
  const [showQR, setShowQR] = useState(false);

  const qrBackButtonPressed = useCallback(() => {
    setShowQR(false);
  }, [setShowQR]);

  return (
    <StyledVStack>
      <Header
        avatar={avatar}
        username={username}
        showQR={showQR}
        qrBackButtonPressed={qrBackButtonPressed}
      />

      {showQR ? (
        <MobileConfig />
      ) : (
        <Info setShowQR={setShowQR} onOpenSettingsClick={onOpenSettingsClick} />
      )}
      <Footer onLogout={onLogout} />
    </StyledVStack>
  );
}
