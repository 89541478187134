import React, { memo, useCallback } from 'react';
import { useSlate } from 'slate-react';

import { isElementActive, toggleElement } from 'components/editor/utils';

import ButtonBase from '../buttonBase';

import iconComponents from './constants/iconComponents';

const ElementButton = ({ type, disabled }: { type: string; disabled?: boolean }) => {
  const editor = useSlate();
  const isActive = isElementActive(editor, type);

  const onMouseDown = useCallback(
    (event: React.MouseEvent) => {
      if (disabled) return;
      event.preventDefault();
      toggleElement(editor, type, isActive);
    },
    [editor, isActive, type, disabled],
  );

  const IconComponent = iconComponents[type];

  return (
    <ButtonBase
      type={type}
      isActive={isActive}
      IconComponent={IconComponent}
      onMouseDown={onMouseDown}
      disabled={disabled}
    />
  );
};

export default memo(ElementButton);
