import { useCallback } from 'react';

import fallbackImage from 'assets/images/default/defaultThumbnail.png';
import useCustomDateTimeUtils from 'hooks/useCustomDateTimeUtils';
import { FeedItem } from 'types/graphqlTypes';

const useCreateRow = () => {
  const { isoToLocaleShort } = useCustomDateTimeUtils();
  const createRow = useCallback(
    (
      {
        mId,
        mTitle,
        assets,
        provider,
        infosource,
        priority,
        mPublishedAt,
        versioncreated,
        firstcreated,
        mDescription,
        mContent,
        byline,
        revision,
        href,
        ...rest
      }: FeedItem,
      accessToken: string,
      useProxy: boolean | undefined,
    ) => {
      let thumbnail = fallbackImage;
      const assetTypes: string[] = [];
      let assetTypeCounter = 0;
      if (assets && assets.length > 0) {
        assets.forEach((asset) => {
          const { type, renditions } = asset;

          if (type?.toLowerCase() === 'image' || type?.toLowerCase() === 'coverimage') {
            if (renditions && renditions.length > 0) {
              const { href: newHref, thumbnailUri } = renditions[0];
              thumbnail = thumbnailUri ? thumbnailUri + accessToken : newHref + accessToken;
              if (assetTypeCounter < 2) {
                assetTypes.push('image');
                assetTypeCounter += 1;
              }
            }
          } else if (type === 'video') {
            const thumb = renditions?.find(({ thumbnailUri }) => thumbnailUri);
            if (thumb) thumbnail = `${thumb.thumbnailUri}${accessToken}`;
            if (assetTypeCounter < 2) {
              assetTypes.push('video');
              assetTypeCounter += 1;
            }
          } else if (type === 'audio' || type === 'sound file') {
            const thumb = renditions?.find(({ thumbnailUri }) => thumbnailUri);
            if (thumb) thumbnail = `${thumb.thumbnailUri}${accessToken}`;
            if (assetTypeCounter < 2) {
              assetTypes.push('audio');
              assetTypeCounter += 1;
            }
          } else if (type === 'application') {
            const thumb = renditions?.find(({ thumbnailUri }) => thumbnailUri);
            if (thumb) thumbnail = `${thumb.thumbnailUri}${accessToken}`;
            if (assetTypeCounter < 2) {
              assetTypes.push('document');
              assetTypeCounter += 1;
            }
          }
        });
      }

      return {
        mId,
        mTitle,
        thumbnail,
        assets,
        assetTypes,
        provider,
        infosource,
        date: isoToLocaleShort(mPublishedAt),
        firstcreated: firstcreated ?? mPublishedAt,
        versioncreated: versioncreated ?? mPublishedAt,
        priority,
        mDescription,
        mContent,
        mPublishedAt,
        byline,
        href,
        revision,
        accessToken,
        useProxy,
        ...rest,
      };
    },
    [],
  );

  return { createRow };
};

export default useCreateRow;
