import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';

import { ButtonProps } from './types';

type StyleProps = {
  palette: Theme['palette'];
  typography: Theme['typography'];
  $autoWidth?: boolean;
} & ButtonProps;

const getWidth = (props: StyleProps) => {
  if (props.width) {
    return `${props.width}px`;
  }

  return '100%';
};

const getFlex = (props: StyleProps) => {
  if (props.width) {
    return '0 1 auto';
  }
  return '1 1 auto';
};

export const styles = (props: StyleProps) => css`
  ${props.typography.dina.button};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  transition: background-color 0.2s ease;
  height: 32px;
  padding-inline: 8px;
  user-select: none;
  position: relative;
  white-space: nowrap;

  svg {
    min-height: 14px;
    min-width: 14px;
    path {
      fill-opacity: 1;
    }
  }

  &:hover {
    cursor: pointer;
    transition: background-color 0.2s ease;
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0px 0px 0px 2px ${props.palette.dina.onFocus};
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.35;
    cursor: not-allowed;
  }

  &:disabled:focus-visible {
    /* outline: none; */
    box-shadow: none;
  }

  /** Variants */
  &[data-variant='contained'] {
    border: 1px solid transparent;
  }

  &[data-variant='dashed'] {
    border: 1px dashed ${props.palette.dina.buttonBorderOutlined};
  }

  &[data-variant='discreet'] {
    border: 1px solid transparent;
  }

  &[data-variant='outlined'] {
    border: 1px solid ${props.palette.dina.buttonBorderOutlined};
  }

  /** Usage */
  &[data-usage='text'] {
    color: ${props.palette.dina.mediumEmphasis};
    background-color: transparent;
    svg {
      path {
        fill: ${props.palette.dina.iconInactive};
      }
    }
  }

  &[data-usage='text']:hover {
    background-color: ${props.palette.dina.outlineButtonOnHover};
    svg {
      path {
        fill: ${props.dark
          ? props.palette.dina.blackHighEmphasis
          : props.palette.dina.highEmphasis};
      }
    }
  }

  &[data-usage='outlined'] {
    color: ${props.dark ? props.palette.dina.blackHighEmphasis : props.palette.dina.highEmphasis};
    background-color: ${props.palette.dina.blackHoverOverlay};
    svg {
      path {
        fill: ${props.dark
          ? props.palette.dina.blackHighEmphasis
          : props.palette.dina.highEmphasis};
      }
    }
  }

  &[data-usage='outlined']:hover {
    background-color: ${props.palette.dina.outlineButtonOnHover};
  }

  &[data-usage='cta'] {
    color: ${props.palette.dina.whiteHighEmphasis};
    background-color: ${props.palette.dina.buttonBackgroundHighEmphasis};
    svg {
      path {
        fill: ${props.palette.dina.iconSelectedWhite};
      }
    }
  }

  &[data-usage='cta']:hover {
    background-color: ${props.palette.dina.buttonBackgroundHighEmphasisHover};
  }

  &[data-usage='significant'] {
    color: ${props.palette.dina.whiteHighEmphasis};
    background-color: ${props.palette.dina.buttonBackgroundSignificantAction};
    svg {
      path {
        fill: ${props.palette.dina.iconSelectedWhite};
      }
    }
  }

  &[data-usage='significant']:hover {
    background-color: ${props.palette.dina.buttonBackgroundSignificantActionHover};
    color: ${props.palette.dina.whiteHighEmphasis};
  }

  &[data-usage='warning'] {
    color: ${props.palette.dina.blackHighEmphasis};
    background-color: ${props.palette.dina.buttonBackgroundWarning};
    border: 1px solid ${props.palette.dina.blackHighEmphasis};
    svg {
      path {
        fill: ${props.palette.dina.blackInactive};
      }
    }
  }

  &[data-usage='warning']:hover {
    background-color: ${props.palette.dina.buttonBackgroundWarningHover};
    color: ${props.palette.dina.blackHighEmphasis};
  }

  &[data-usage='danger'] {
    color: ${props.palette.dina.whiteHighEmphasis};
    background-color: ${props.palette.dina.buttonBackgroundDangerousAction};
    svg {
      path {
        fill: ${props.palette.dina.iconSelectedWhite};
      }
    }
  }

  &[data-usage='danger']:hover {
    background-color: ${props.palette.dina.buttonBackgroundDangerousActionHover};
  }

  &[data-usage='story'] {
    color: ${props.palette.dina.whiteHighEmphasis};
    background-color: ${props.palette.dina.statusStory};
    svg {
      path {
        fill: ${props.palette.dina.iconSelectedWhite};
      }
    }
  }

  &[data-usage='story']:hover {
    background-color: ${props.palette.dina.buttonBackgroundStoryHover};
  }

  &[data-usage='pitch'] {
    color: ${props.palette.dina.whiteHighEmphasis};
    background-color: ${props.palette.dina.buttonBackgroundSignificantAction};
    svg {
      path {
        fill: ${props.palette.dina.iconSelectedWhite};
      }
    }
  }

  &[data-usage='pitch']:hover {
    background-color: ${props.palette.dina.buttonBackgroundSignificantActionHover};
  }

  &[data-usage='white'] {
    color: ${props.palette.dina.blackHighEmphasis};
    background-color: ${props.palette.dina.whiteHighEmphasis};
    svg {
      path {
        fill: ${props.palette.dina.blackHighEmphasis};
      }
    }
  }

  &[data-usage='white']:hover {
    background-color: ${props.palette.dina.whiteMediumEmphasis};
  }

  /* sizing */
  &[data-size='sm'] {
    height: ${props.height ? props.height : 28}px;
    width: ${props.width ? `${props.width}px` : 'auto'};
  }

  &[data-size='md'] {
    height: ${props.height ? props.height : 32}px;
    width: ${props.width ? `${props.width}px` : 'auto'};
    min-width: ${props.width ? `${props.width}px` : 'auto'};
  }

  &[data-size='lg'] {
    height: ${props.height ? props.height : 40}px;
    width: ${props.width ? `${props.width}px` : 'auto'};
  }

  &[data-size='full'] {
    height: ${props.height ? props.height : 32}px;
    width: ${getWidth(props)};
    flex: ${getFlex(props)};
    padding: ${props.padding ? props.padding : '0 8'}px;
  }

  &[data-size='icon'] {
    height: ${props.height ? props.height : 32}px;
    width: ${props.width ? props.width : 32}px;
    padding: ${props.padding ? props.padding : 0}px;
  }

  /** Border radius */
  &[data-radius='default'] {
    border-radius: 6px;
  }

  &[data-radius='full'] {
    border-radius: 50%;
  }

  &[data-radius='none'] {
    border-radius: 0;
  }

  &[data-wrap='true'] {
    white-space: normal;
  }

  /** Selected state */
  &[data-selected='true'] {
    background-color: ${props.palette.dina.onFocus};
    :hover {
      background-color: ${props.palette.dina.buttonBackgroundStoryHover};
    }
    :focus-visible {
      border: 1px solid ${props.palette.dina.whiteMediumEmphasis};
    }
  }

  ${props.align === 'left' && 'justify-content: flex-start; text-align: left;'}
  ${props.required && ` border: 1px solid ${props.palette.dina.statusWarning} !important;`}
`;

export const DisableWrapper = styled('span')`
  width: fit-content;
`;
