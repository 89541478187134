import { useCallback } from 'react';

import { useGetTypedOptionList } from 'api/optionLists/useGetOptionList';
import EditAlternatives from 'components/editMdfDialog/components/EditAlternatives';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import { Box } from 'layouts/box/Box';
import { Alternative, ChoiceOptionList } from 'types/graphqlTypes';

interface OptionListProps {
  list: ChoiceOptionList;
  listIsUpdated: boolean;
  onChange: (updatedList: ChoiceOptionList, originalList: ChoiceOptionList) => void;
}

type OptionsUpdater = (old: Alternative[]) => Alternative[];

export function OptionListComponent({ list, listIsUpdated, onChange }: Readonly<OptionListProps>) {
  const { optionList, loading, error } = useGetTypedOptionList(list.id, 'choice', true);

  const options = listIsUpdated ? list.alternatives : optionList?.alternatives;

  const setOptions = useCallback(
    (arg: Alternative[] | OptionsUpdater) => {
      const alternatives = typeof arg === 'function' ? arg(options) : arg;
      onChange({ ...list, alternatives }, optionList);
    },
    [onChange, options, optionList],
  );
  if (loading) {
    return (
      <div>
        <LoadingIndicator />
      </div>
    );
  }

  if (error) {
    return <div>Something went wrong: {error.message}</div>;
  }

  return (
    <Box maxWidth="600px" height="100%" padding="10px" maxHeight="100%">
      <EditAlternatives
        options={options}
        updateOptions={setOptions}
        isExternal={false}
        name={list.label}
      >
        <span />
      </EditAlternatives>
    </Box>
  );
}
