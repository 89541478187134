import styled from '@emotion/styled/macro';

export const SideBarWrapper = styled('div')<{ height: number }>`
  height: ${({ height }) => `${height}px`};
  overflow: auto;
`;

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const FormsWrapper = styled('div')`
  width: 100%;
  height: 100%;
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
  & > first-child:not(:last-child) {
    flex: 0 0 auto;
  }
  & > :last-child:not(:first-child) {
    flex: 1 1 0;
  }
  & > :first-child:last-child {
    height: 100%;
  }
`;
