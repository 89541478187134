import React, { memo, useCallback, useEffect, useState } from 'react';

import Text from 'components/text';
import { ProviderType } from 'features/spaceDeck/SpaceDeck';
import useDebounce from 'hooks/useDebounce';
import { VStack } from 'layouts/box/Box';
import { AssetType, FilterValueType } from 'types/widget';

import AssetFilter from './AssetsFilter';
import LanguageFilter from './LanguagesFilter';
import MediaTopicsFilter from './MediaTopicsFilter';
import Priorities from './PriorityFilter';
import ProviderFilter from './ProvidersFilter';
import TextFieldFilter from './TextFieldFilter';

interface FiltersProps {
  filters?: FilterValueType;
  updateFilters: (filters: FilterValueType) => void;
  providers?: ProviderType[];
  selectedProviders?: ProviderType[];
  updateProviders?: (providers: ProviderType[]) => void;
}

/** Each component rendered with a title string, and input as children */
const FilterComponent = ({
  title,
  children,
}: {
  title: string | React.ReactNode;
  children: React.ReactNode;
}) => (
  <span>
    <Text variant="overline" style={{ userSelect: 'none', marginLeft: '2px' }} color="highEmphasis">
      {title}
    </Text>
    {children}
  </span>
);

function Filters({
  filters,
  updateFilters,
  providers = [],
  selectedProviders = [],
  updateProviders = () => {},
}: Readonly<FiltersProps>) {
  const [inputs, setInputs] = useState<FilterValueType>(filters ?? {});
  const debouncedInputs = useDebounce(inputs);

  const handleChange = useCallback(({ name, value }: FilterValueType) => {
    setInputs((prev: FilterValueType) => ({ ...prev, [name as string]: value }));
  }, []);

  useEffect(() => {
    updateFilters(debouncedInputs);
  }, [debouncedInputs]);

  return (
    <VStack alignItems="stretch" gap="16px" overflow="unset">
      {providers?.length > 0 && (
        <FilterComponent title="Feed Providers to show">
          <ProviderFilter
            providers={providers}
            selectedProviders={selectedProviders}
            onChange={updateProviders}
          />
        </FilterComponent>
      )}

      <FilterComponent title="Choose priorities to show">
        <Priorities priorityValues={inputs?.priorities as string[]} onChange={handleChange} />
      </FilterComponent>

      <FilterComponent title="Asset types to show">
        <AssetFilter assets={inputs?.assets as AssetType[]} onChange={handleChange} />
      </FilterComponent>

      <FilterComponent title="Languages to show">
        <LanguageFilter languages={inputs?.languages as string[]} onChange={handleChange} />
      </FilterComponent>

      <FilterComponent title="Location to show">
        <TextFieldFilter
          value={inputs?.located as string}
          propertyName="located"
          onChange={handleChange}
          placeholder="Showing all locations"
        />
      </FilterComponent>

      <FilterComponent
        title={
          <>
            Media topics to{' '}
            <Text variant="overline" color="isFixed">
              show
            </Text>
          </>
        }
      >
        <MediaTopicsFilter mediatopics={inputs?.mediatopics as string[]} onChange={handleChange} />
      </FilterComponent>

      <FilterComponent
        title={
          <>
            Media topics to{' '}
            <Text variant="overline" color="isFixed">
              hide
            </Text>
          </>
        }
      >
        <MediaTopicsFilter
          mediatopics={inputs?.excludeMediatopics as string[]}
          onChange={handleChange}
          propertyName="excludeMediatopics"
          placeholder="Showing all media topics"
        />
      </FilterComponent>
    </VStack>
  );
}

export default memo(Filters);
