import { useCallback } from 'react';

import useDateTimeUtils from './useDateTimeUtils';

interface Session {
  mState: string;
  mProperties?: {
    sessionExpirationTime?: string;
  };
}
interface DateRange {
  startDate: string;
  endDate: string;
}

export type PublishedDateOptions = {
  anotherYear?: string;
  formatString?: string;
  today?: string;
  tomorrow?: string;
  yesterday?: string;
};

export type RelativeDateTimeOptions = {
  fillerString?: string;
  showRelative?: boolean;
  dateFormat?: string;
  timeFormat?: string;
};

export const defaultPublishedDateOptions = {
  anotherYear: 'ddd D MMM YY',
  formatString: 'D MMM[, ]HH:mm',
  today: '[Today, ]HH:mm',
  tomorrow: '[Tomorrow, ]HH:mm',
  yesterday: '[Yesterday, ]HH:mm',
} as const;

export const defaultRelativeDateTimeOptions = {
  fillerString: ' at ',
  showRelative: true,
  dateFormat: 'D MMM[.] GGGG HH:mm',
  timeFormat: 'HH:mm',
} as const;

const dateStringAbbreviation = {
  year: 'yr',
  month: 'mo',
  hour: 'hr',
  minute: 'min',
  second: 'sec',
};

const DATE_STRING_REGEX = /year|month|hour|minute|second/gi;

type DateType = Date | string | number;

const useCustomDateTimeUtils = () => {
  const {
    isBefore,
    startOfDay,
    isToday,
    isTomorrow,
    isYesterday,
    getMinutes,
    startOfMinute,
    format,
    isSameDay,
    setHours,
    setMinutes,
    setSeconds,
    differenceInMinutes,
    distanceInWordsStrict,
    isAfter,
    differenceInCalendarYears,
    differenceInCalendarDays,
  } = useDateTimeUtils();

  /**
   * Given a date in ISO format, and Updated Time returns updated ISO string with updated time
   *
   * @returns string representation of ISO updated date-time
   */
  const updateTimeOfISODate = useCallback((previousDate: Date, updatedTime: string) => {
    const [hour, minute, second] = updatedTime.split(':');
    let time = previousDate;
    time = setHours(time, parseInt(hour));
    time = setMinutes(time, parseInt(minute));
    if (second) time = setSeconds(time, parseInt(second));
    else time = setSeconds(time, 0);

    return time.toISOString();
  }, []);

  const isBeforeToday = useCallback(
    (date: string | number | Date) => isBefore(startOfDay(date), startOfDay(new Date())),
    [],
  );

  /**
   * Adds relative context to the date (today, tomorrow, or yesterday) for the user.
   * If the date is none of these shows the date in a user given format
   */
  const getRelativeDate = useCallback((date: DateType, dateFormat: string = 'ddd. D MMMM YYYY') => {
    if (isToday(date)) return 'Today';
    if (isTomorrow(date)) return 'Tomorrow';
    if (isYesterday(date)) return 'Yesterday';
    return format(date, dateFormat);
  }, []);

  const getRelativeDateTime = useCallback(
    (
      date: DateType,
      {
        timeFormat = 'HH:mm',
        dateFormat = 'D MMM[.] GGGG HH:mm',
        fillerString = ' at ',
        showRelative = true,
      }: RelativeDateTimeOptions,
    ) => {
      const timeString = format(date, timeFormat);
      if (!showRelative) return timeString;

      if (isToday(date)) return `Today${fillerString}${timeString}`;

      if (isTomorrow(date)) return `Tomorrow${fillerString}${timeString}`;

      if (isYesterday(date)) return `Yesterday${fillerString}${timeString}`;

      return `${format(date, dateFormat)}`;
    },
    [],
  );

  const getRelativeDateTimeRange = useCallback((date1: DateType, date2: DateType) => {
    const sameDay = isSameDay(date1, date2);

    const firstDatePart = getRelativeDateTime(date1, {
      fillerString: sameDay ? ',\n' : ',',
      showRelative: true,
    });
    const secondDatePart = getRelativeDateTime(date2, {
      fillerString: sameDay ? '' : ',',
      showRelative: !sameDay,
    });

    return `${firstDatePart}-${secondDatePart}`;
  }, []);

  const getDisplayStringForDateRange = useCallback((range: DateRange) => {
    if (!range) return 'No date selected';
    const { startDate, endDate } = range;
    if (isSameDay(startDate, endDate)) return getRelativeDate(endDate);
    return `${getRelativeDate(startDate)} - ${getRelativeDate(endDate)}`;
  }, []);

  /**
   * Formats a date into a human-readable relative time string
   * @returns Formatted string like "Just Now", "5 min. ago", "Today 14:30", etc.
   */
  const formatRelativeTime = useCallback((date: DateType, lineBreak = true): string => {
    const diff = differenceInMinutes(new Date(), date);
    if (diff < 5) {
      return `Just${lineBreak ? ' ' : '\n'}Now`;
    }
    if (diff >= 5 && diff < 45) {
      return `${diff} min.\nago`;
    }
    if (diff >= 45 && diff < 75) {
      return '1 hr.\nago';
    }
    if (isToday(date)) {
      return `Today\n${format(date, 'HH:mm')}`;
    }
    if (isYesterday(date)) {
      return `Yesterday\n${format(date, 'HH:mm')}`;
    }
    return `${format(date, 'DD MMM')}\n${format(date, 'HH:mm')}`;
  }, []);

  /**
   * Rounds the given date to the nearest minute (or number of minutes).
   * Rounds up when the given date is exactly between the nearest round minutes.
   */
  const roundToNearestMinutes = useCallback((date: DateType, options: { nearestTo: number }) => {
    const { nearestTo } = options;
    const roundedMinutes = Math.ceil(getMinutes(date) / nearestTo) * nearestTo;
    return setMinutes(startOfMinute(date), roundedMinutes);
  }, []);

  /**
   * Take an ISO string and return a boolean indicating if the date is at least one year before.
   */
  const isAtLeastOneYearBefore = (dateToCheck?: DateType) => {
    if (!dateToCheck) return false;

    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

    return new Date(dateToCheck).getTime() <= oneYearAgo.getTime();
  };

  const distanceInAbbreviatedWords = useCallback(
    (input: DateType) =>
      distanceInWordsStrict(input, new Date()).replace(
        DATE_STRING_REGEX,
        (matched) => dateStringAbbreviation[matched as keyof typeof dateStringAbbreviation],
      ),
    [],
  );

  const distanceInWords = useCallback((oldDate?: DateType) => {
    if (!oldDate) return '';
    const timeStr = distanceInWordsStrict(oldDate, new Date());
    if (timeStr.includes('second')) return 'Just now';
    return timeStr;
  }, []);

  const isValidSession = useCallback((session: Session | null): boolean => {
    if (!session) return false;
    const { mState } = session;

    if (mState !== 'active') return false;

    const sessionExpirationTime = session.mProperties?.sessionExpirationTime;

    if (!sessionExpirationTime) return false;

    const currentTime = new Date();
    return isAfter(new Date(sessionExpirationTime), currentTime);
  }, []);

  const isoToLocaleShort = useCallback((date?: DateType | null, year: boolean = false): string => {
    if (!date) return '';

    if (isToday(date)) {
      return format(date, 'hh:mm A');
    }

    if (isYesterday(date)) {
      return `Yesterday, ${format(date, 'hh:mm A')}`;
    }

    const dateFormat = year ? 'MMM DD, YYYY hh:mm A' : 'MMM DD hh:mm A';
    return format(date, dateFormat);
  }, []);

  const formatPublishedDate = useCallback(
    (publishedDate: DateType, options: PublishedDateOptions = defaultPublishedDateOptions) => {
      if (differenceInCalendarYears(new Date(), publishedDate) > 0)
        return `${format(publishedDate, options.anotherYear)}`;

      if (isToday(new Date(publishedDate))) return `${format(publishedDate, options.today)}`;

      if (isTomorrow(new Date(publishedDate))) return `${format(publishedDate, options.tomorrow)}`;

      if (isYesterday(new Date(publishedDate)))
        return `${format(publishedDate, options.yesterday)}`;

      return `${format(publishedDate, options.formatString)}`;
    },
    [],
  );

  const differenceInDays = useCallback((date: DateType) => {
    const value = differenceInCalendarDays(new Date(), date);
    if (value === 0) return '';
    if (value === -1) return ' (in 1 day)';
    if (value < -1) return ` (in ${-value} days)`;
    if (value === 1) return ' (1 day ago)';
    return ` (${value} days ago)`;
  }, []);

  return {
    isBeforeToday,
    getRelativeDate,
    getRelativeDateTime,
    getRelativeDateTimeRange,
    getDisplayStringForDateRange,
    updateTimeOfISODate,
    formatRelativeTime,
    roundToNearestMinutes,
    isAtLeastOneYearBefore,
    distanceInAbbreviatedWords,
    distanceInWords,
    isValidSession,
    isoToLocaleShort,
    formatPublishedDate,
    differenceInDays,
  };
};

export default useCustomDateTimeUtils;
