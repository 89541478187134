import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { SelectContent, SelectItem, SelectTrigger } from 'lib/select';
import transientOptions from 'theme/helpers';
import { dialogBoxShadow } from 'theme/utils/commonStyles';

export const Content = styled(SelectContent)`
  position: relative;
  z-index: 2000;
  min-width: 140px;
  overflow: hidden;
  border-radius: 6px;
  padding: 4px 0;
  border: 1px solid ${({ theme }) => theme.palette.dina.buttonBorderOutlined};
  background-color: ${({ theme }) => theme.palette.dina.surfaceCard};
  ${dialogBoxShadow};
`;

export const Trigger = styled(SelectTrigger, transientOptions)<{
  $backgroundColor?: string;
  $width?: string;
  $height?: string;
  $borderRadius?: string;
  $textColor?: string;
}>`
  ${({
    theme,
    $backgroundColor,
    $width = 'auto',
    $height = '16px',
    $textColor,
    $borderRadius = '4px',
  }) =>
    css`
      display: flex;
      width: ${$width};
      height: ${$height};
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      border-radius: ${$borderRadius};
      border: 1px solid ${$backgroundColor || theme.palette.dina.buttonBorderOutlined};
      box-sizing: border-box;
      padding-inline: 4px 0px;
      outline: none;
      ${theme.typography.dina.listItemLabel};
      color: ${$textColor ?? theme.palette.dina.mediumEmphasis};
      background-color: ${$backgroundColor || 'transparent'};
      white-space: nowrap;
      svg{
        path{
          fill: ${$textColor ?? theme.palette.dina.mediumEmphasis};
          fill-opacity: 1;
        }
      }
      &:hover {
        color: ${$textColor ?? theme.palette.dina.mediumEmphasis};
        filter: ${theme.palette.dina.onHover};
        background-color: ${$backgroundColor || theme.palette.dina.hoverOverlay};
      }
      &:focus {
        border-color: ${theme.palette.dina.buttonBorderOutlined};
        background-color:${$backgroundColor || theme.palette.dina.blackHoverOverlay}
        color: ${$textColor ?? theme.palette.dina.mediumEmphasis};
        filter: ${theme.palette.dina.onHover};
      }
    `}
`;

export const Item = styled(SelectItem)`
  ${({ theme }) =>
    css`
      position: relative;
      display: flex;
      width: auto;
      cursor: pointer;
      user-select: none;
      align-items: center;
      justify-content: flex-start;
      padding-block: 3px;
      padding-inline: 8px;
      ${theme.typography.dina.listItemLabel};
      outline: none;
      outline-color: transparent;
      outline-style: solid;
      outline-width: 1px;
      color: ${theme.palette.dina.mediumEmphasis};
      :hover {
        filter: ${theme.palette.dina.onHover};
        background-color: ${theme.palette.dina.hoverOverlay};
      }
      &[data-state='checked'] {
        color: ${theme.palette.dina.whiteHighEmphasis};
        background-color: ${theme.palette.dina.onSelected};
        svg {
          path {
            fill: ${theme.palette.dina.whiteHighEmphasis};
            fill-opacity: 1;
          }
        }
      }
    `}
`;
