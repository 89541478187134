import { useEffect, useState } from 'react';

import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import { ReactComponent as DraftIcon } from 'assets/icons/systemicons/draft_off.svg';
import { ReactComponent as FeedsIcon } from 'assets/icons/systemicons/HeaderNavbar/feeds_on.svg';
import { ReactComponent as Open } from 'assets/icons/systemicons/open.svg';
import { ReactComponent as OrderIcon } from 'assets/icons/systemicons/orders.svg';
import { IconButton } from 'components/buttons/IconButton';
import { PitchToStoryButton } from 'components/buttons/PitchToStoryButton';
import ScheduleDatePicker from 'components/createNewV3/datePicker/scheduleDatePicker';
import { DateRange } from 'components/mdfEditor/fields/date/DatePicker';
import Text from 'components/text/Text';
import useToast from 'components/toast/useToast';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useCustomDateTimeUtils from 'hooks/useCustomDateTimeUtils';
import usePublishDateUpdate from 'hooks/usePublishDateUpdate';
import { HStack } from 'layouts/box/Box';
import PersistentTheme from 'theme/persistentTheme';
import { Story } from 'types';
import { MemberType } from 'types/graphqlTypes';

import { Header, Icon, TitleInput } from './styled';

interface Props {
  member?: MemberType | null;
  handleOpenStory?: () => void;
  icon?: React.ReactNode;
  canOpenInTab?: boolean;
  actionButtons?: React.ReactNode;
  handleTitleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  title?: string;
  onClose: () => void;
}

const BaseHeader = ({
  member,
  handleOpenStory,
  icon = null,
  canOpenInTab,
  actionButtons = null,
  handleTitleChange,
  title,
  onClose,
}: Props) => {
  const { isoToLocaleShort } = useCustomDateTimeUtils();
  return (
    <Header>
      <HStack gap="4px" flexGrow={1}>
        {icon}
        {handleTitleChange ? (
          <TitleInput name="edit-title" value={title} onChange={handleTitleChange} />
        ) : (
          <Text truncate variant="h6" color="highEmphasis">
            {title}
          </Text>
        )}
      </HStack>

      <HStack gap="8px">
        {member && (
          <Text variant="caption" style={{ whiteSpace: 'nowrap' }}>
            Modified {isoToLocaleShort(member?.mUpdatedAt ?? member?.mCreatedAt)}
          </Text>
        )}
        {actionButtons}
        {canOpenInTab && (
          <IconButton onClick={handleOpenStory} title="Open in tab">
            <Open />
          </IconButton>
        )}

        <IconButton title="Close" onClick={onClose}>
          <Close />
        </IconButton>
      </HStack>
    </Header>
  );
};

interface StoryPitchHeaderType extends Pick<Props, 'onClose' | 'handleOpenStory'> {
  canCreateStory?: boolean;
  canUpdatePublishing: boolean;
  onOpen: () => void;
  isPitch: boolean;
  member: Story;
}

export const StoryPitchHeader = ({
  onClose,
  member,
  canUpdatePublishing,
  canCreateStory,
  onOpen,
  isPitch,
}: StoryPitchHeaderType) => {
  const [dateRange, setDateRange] = useState<DateRange | null>(null);

  const { isoToLocaleShort } = useCustomDateTimeUtils();
  const [checkUserRight] = useCheckUserRight();
  const canChangeScheduleTime = checkUserRight('story', 'schedule-story');
  const canSelectRange = checkUserRight('feature', 'story-date-range');

  const [updatePublishDate] = usePublishDateUpdate();
  const { errorToast } = useToast();

  useEffect(() => {
    setDateRange(
      member?.mPublishingAt
        ? { startDate: member.mPublishingAt, endDate: member.mPublishingEnd ?? null }
        : null,
    );
  }, [member?.mPublishingAt, member?.mPublishingEnd]);

  return (
    <PersistentTheme>
      <Header isPitch={isPitch} isStory={!isPitch}>
        <div style={{ flexShrink: 0 }}>
          {member && (
            <ScheduleDatePicker
              dateRange={dateRange}
              onDateRangeChange={(newDateRange) => {
                setDateRange(newDateRange);
                updatePublishDate(
                  member.mId,
                  newDateRange?.startDate ?? null,
                  newDateRange?.endDate ?? null,
                ).catch((err) => errorToast(err, 'Unable to change schedule'));
              }}
              disableUpdate={!(canChangeScheduleTime && canUpdatePublishing)}
              canSelectRange={canSelectRange}
              transparent
              hideCloseIcon
            />
          )}
        </div>
        <Text truncate variant="h6" color="highEmphasis" style={{ textAlign: 'center' }}>
          {member?.mTitle}
        </Text>
        <HStack gap="8px" overflow="visible">
          <Text variant="caption" style={{ whiteSpace: 'nowrap' }}>
            Modified {isoToLocaleShort(member?.mUpdatedAt ?? member?.mCreatedAt)}
          </Text>
          {canCreateStory && member && <PitchToStoryButton pitch={member} onComplete={onClose} />}
          <IconButton onClick={onOpen} title="Open in tab">
            <Open />
          </IconButton>
          <IconButton onClick={onClose} title="Close">
            <Close />
          </IconButton>
        </HStack>
      </Header>
    </PersistentTheme>
  );
};

interface OrderDrawerContentProps {
  order: MemberType;
  onClose: () => void;
  handleOpenOrder: () => void;
  title: string;
}

export const OrderHeader = ({
  order,
  onClose,
  handleOpenOrder,
  title,
}: Readonly<OrderDrawerContentProps>) => {
  return (
    <BaseHeader
      onClose={onClose}
      canOpenInTab
      handleOpenStory={handleOpenOrder}
      member={order}
      title={title}
      icon={
        <HStack gap="8px" overflow="visible">
          <Icon type="draft">
            <OrderIcon />
          </Icon>
        </HStack>
      }
    />
  );
};

type FeedHeaderProps = Pick<Props, 'onClose' | 'title'>;

export const FeedHeader = ({ onClose, title }: Readonly<FeedHeaderProps>) => {
  return (
    <BaseHeader
      onClose={onClose}
      title={title}
      icon={
        <HStack gap="8px" overflow="visible">
          <Icon>
            <FeedsIcon />
          </Icon>
        </HStack>
      }
    />
  );
};

interface DraftDrawerContentProps {
  draft: MemberType;
  onClose: () => void;
  title: string;
  setTitle: (title: string) => void;
}

export const DraftHeader = ({
  draft,
  onClose,
  title,
  setTitle,
}: Readonly<DraftDrawerContentProps>) => {
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const [checkUserRight] = useCheckUserRight();
  const canSelectRange = checkUserRight('feature', 'story-date-range');

  const { errorToast } = useToast();

  const [updatePublishDate] = usePublishDateUpdate();

  const [dateRange, setDateRange] = useState(
    draft?.mPublishingAt
      ? { startDate: draft.mPublishingAt, endDate: draft.mPublishingEnd ?? null }
      : null,
  );

  return (
    <BaseHeader
      onClose={onClose}
      member={draft}
      handleTitleChange={handleTitleChange}
      title={title}
      actionButtons={
        <ScheduleDatePicker
          dateRange={dateRange}
          onDateRangeChange={(newDateRange) => {
            setDateRange(newDateRange);
            updatePublishDate(
              draft.mId!,
              newDateRange?.startDate ?? null,
              newDateRange?.endDate ?? null,
            ).catch((err) => errorToast(err, 'Unable to change schedule'));
          }}
          disableUpdate={false}
          canSelectRange={canSelectRange}
          transparent
          hideCloseIcon
        />
      }
      icon={
        <Icon type="draft">
          <DraftIcon />
        </Icon>
      }
    />
  );
};
