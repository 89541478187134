import { MemberType } from 'types/graphqlTypes';

export const uniqMembers = (members: MemberType[]): MemberType[] => {
  const membersKeySet = new Set<string>();
  const returnMembers = members.filter((member) => {
    const key = String(member.mId) + String(member.mRefId);
    if (membersKeySet.has(key)) return false;
    membersKeySet.add(key);
    return true;
  });
  return returnMembers;
};
