import { useQuery } from '@apollo/client';

import GET_METADATA_FORMS from 'operations/queries/getMetadataForms';
import { MetadataFormType } from 'types/graphqlTypes';

interface GetMetadataForms {
  getMetadataForms: MetadataFormType[];
}

const useGetMetadataForms = () => {
  const { data, loading, error } = useQuery<GetMetadataForms>(GET_METADATA_FORMS, {
    variables: {
      input: {
        mId: 'form',
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  return [data?.getMetadataForms ?? ([] satisfies MetadataFormType[]), loading, error] as const;
};

export default useGetMetadataForms;
