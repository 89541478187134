import { useMemo } from 'react';
import { ApolloClient, useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { v4 } from 'uuid';

import { STANDARD_OPTION_LIST_KEYS } from 'api/commonKeys';
import {
  ChoiceOptionList,
  OptionList,
  OptionListRaw,
  TreeChoiceOptionList,
} from 'types/graphqlTypes';

export const GET_LISTS = gql`
  query GetLists {
    getOptionLists {
      ${STANDARD_OPTION_LIST_KEYS}
    }
  }
`;

export const generateIdForAlternatives = (rawList: OptionListRaw): OptionList => {
  return rawList.optionListType === 'choice'
    ? {
        ...rawList,
        optionListType: 'choice',
        alternatives: rawList.alternatives?.map((l) => ({ ...l, id: v4() })) ?? [],
      }
    : {
        ...rawList,
        optionListType: 'treechoice',
        treeAlternatives: rawList.treeAlternatives ?? [],
      };
};

export const generateForList = (rawLists: OptionListRaw[]): OptionList[] => {
  const lists: OptionList[] = [];
  rawLists.forEach((l) => lists.push(generateIdForAlternatives(l)));
  return lists;
};

export interface GetOptionLists {
  getOptionLists: OptionListRaw[];
}

export const getOptionLists = async (client: ApolloClient<object>) => {
  const { data, error, loading } = await client.query<GetOptionLists>({
    query: GET_LISTS,
    fetchPolicy: 'cache-first',
  });

  return { data: generateForList(data?.getOptionLists ?? []), error, loading };
};

export const useGetOptionLists = () => {
  const { data, error, loading, refetch } = useQuery<GetOptionLists>(GET_LISTS, {
    fetchPolicy: 'cache-first',
  });

  const lists = useMemo(() => {
    const allOptionLists = generateForList(data?.getOptionLists ?? []);
    const optionLists: ChoiceOptionList[] = [];
    const optionTrees: TreeChoiceOptionList[] = [];
    const values = {
      allOptionLists,
      optionLists,
      optionTrees,
    };
    for (const list of allOptionLists) {
      if (list.optionListType === 'choice') {
        values.optionLists.push(list);
      } else {
        values.optionTrees.push(list);
      }
    }
    return values;
  }, [data]);

  return {
    ...lists,
    error,
    loading,
    refetch,
  };
};
