import { useCallback } from 'react';

import useDateTimeUtils from 'hooks/useDateTimeUtils';

const useFormatDate = () => {
  const { isToday, format, isYesterday } = useDateTimeUtils();
  const formatDate = useCallback((date: string) => {
    if (isToday(date)) {
      return format(date, 'h:mm A');
    } else if (isYesterday(date)) {
      return format(date, 'h:mm A') + ' Yesterday';
    } else {
      return format(date, 'MMM d, YYYY h:mm a');
    }
  }, []);
  return { formatDate };
};

export default useFormatDate;
