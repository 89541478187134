import gql from 'graphql-tag';

export default gql`
  fragment currentInstanceMetaData on MemberType {
    mId
    mTitle
    mState
    mMetaData {
      key
      value
      manual
      autoValue
    }
    mProperties {
      __typename
      ... on PlatformType {
        platform
        platformKind
        account {
          accountUrl
          accountLogo
          accountTitle
          accountId
          accountRefId
          orderType
        }
      }
    }
  }
`;
