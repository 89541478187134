import React, { useState } from 'react';

import { Button, ButtonProps } from 'components/buttons';
import { LoadingButton } from 'components/buttons/LoadingButton';

interface DialogTwoStepButtonProps extends Omit<ButtonProps, 'onClick' | 'startIcon'> {
  /** Pre confirm icon */
  icon1?: React.ReactElement;
  /** Confirm icon */
  icon2?: React.ReactElement;
  /** Pre confirm label */
  label1?: string;
  /** Confirm label */
  label2?: string;
  /** Pre confirm button-usage */
  usage1?: ButtonProps['usage'];
  /** Confirm button-usage */
  usage2?: ButtonProps['usage'];
  loading?: boolean;
  /** Called when the confirm button is clicked, void or promise */
  onConfirm: () => void;
}

/** Button that requires a confirmation before the final action is taken. */
export function TwoStepButton({
  icon1,
  icon2,
  label1 = 'Confirm',
  label2 = 'Click again to confirm',
  usage1 = 'warning',
  usage2 = 'danger',
  disabled = false,
  loading = false,
  onConfirm,
  ...rest
}: Readonly<DialogTwoStepButtonProps>) {
  const [preConfirmed, setPreConfirmed] = useState(false);

  const handlePreConfirm = () => {
    setPreConfirmed(true);

    // Reset the pre-confirmed state after 5 seconds
    setTimeout(() => {
      setPreConfirmed(false);
    }, 5000);
  };

  return (
    <>
      {!preConfirmed ? (
        <Button
          startIcon={icon1}
          variant="contained"
          usage={usage1}
          width={180}
          height={32}
          onClick={handlePreConfirm}
          {...rest}
        >
          {label1}
        </Button>
      ) : (
        <LoadingButton
          loading={loading}
          startIcon={icon2}
          variant="contained"
          usage={usage2}
          width={180}
          height={32}
          onClick={onConfirm}
          disabled={disabled}
          wrap
          {...rest}
        >
          {label2}
        </LoadingButton>
      )}
    </>
  );
}
