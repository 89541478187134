import { useCallback, useMemo } from 'react';
import { keyBy } from 'lodash';

import { useUsers } from 'store';

/**
 * A hook that exposes getUser(userId), getUserTitle(userId) and getUsers(userId) callbacks
 * where userId is the id that is needed to be searched in the list of users
 *
 * @returns {{getUser,getUserTitle,getUsers}}
 */

const useGetUser = () => {
  const [users] = useUsers();

  const usersById = useMemo(() => keyBy(users, 'mId'), [users]);

  const getUser = useCallback((userId: string) => usersById[userId], [usersById]);
  const getUsers = useCallback(
    (userIds: string[]) => users.filter((user) => userIds.includes(user.mId)),
    [users],
  );

  const getUserTitle = useCallback((userId: string) => getUser(userId)?.mTitle, [getUser]);

  const getUserAvatarKey = useCallback((userId: string) => getUser(userId)?.mAvatarKey, [getUser]);

  return { getUser, getUserTitle, getUsers, getUserAvatarKey };
};

export default useGetUser;
