/* eslint-disable sort-imports */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { TableCell, TableRow } from '@material-ui/core';
import { keyBy } from 'lodash';

import { useGetOptionList } from 'api/optionLists/useGetOptionList';
import { ReactComponent as Delete } from 'assets/icons/systemicons/delete.svg';
import { ReactComponent as Edit } from 'assets/icons/systemicons/edit.svg';
import { ReactComponent as Settings } from 'assets/icons/systemicons/settings_off.svg';
import { ReactComponent as UserIcon } from 'assets/icons/systemicons/user.svg';
import { IconButton } from 'components/buttons';
import DateDefault from 'components/mdfEditor/fields/date/DateDefault';
import { DateRange, useDatePicker } from 'components/mdfEditor/fields/date/DatePicker';
import { InlineTagWrapper, Tag, TagText } from 'components/mdfEditor/fields/multiplechoice/styled';
import { RelationField } from 'components/mdfEditor/fields/relation/RelationField';
import TreeChoiceDefault from 'components/mdfEditor/fields/treechoice/TreeChoiceDefault';
import { UserField } from 'components/mdfEditor/fields/user/UserField';
import { isSingleArray } from 'components/mdfEditor/fields/utils';
import { StyledDragHandle } from 'components/mdfEditor/styled';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import { useTreeChoiceDialog } from 'components/treeChoiceDialog/TreeChoiceDialog';
import { isUnsavedMdfField, UnsavedMdfField } from 'features/mdf/mdf-utils';
import LWCheckbox from 'features/orderForm/components/LWCheckbox';
import LWSelect from 'features/orderForm/components/LWSelect';
import useDateTimeUtils from 'hooks/useDateTimeUtils';
import { Box } from 'layouts/box/Box';
import {
  Alternative,
  DefaultValue,
  FieldTypeEnum,
  LayoutSettings,
  MdfField,
} from 'types/graphqlTypes';

import { supportsAdditionalConfig } from './ConfigFieldDialog';

const CellStyle = ({ theme }: { theme: Theme }) => css`
  padding-block: 0;
  padding-inline: 4px;
  height: 40px;
  box-sizing: border-box;
  border-bottom: 1px solid ${theme.palette.dina.dividerLight};
  ${theme.typography.dina.listItemLabel};
  font-size: 12px;
`;

export const RowCell = styled(TableCell)`
  ${CellStyle};
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
`;

const TitleCell = styled(TableCell)`
  ${CellStyle};
  width: 30%;
`;

export const Input = styled('input')`
  ${({ theme }) => theme.typography.dina.caption};
  outline: none;
  margin-left: -3px;
  background-color: transparent;
  border: 1px solid transparent;
  height: 24px;
  border-radius: 2px;
  width: 100%;
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  :hover {
    border: 1px solid ${({ theme }) => theme.palette.dina.inputBorderResting};
    background-color: ${({ theme }) => theme.palette.dina.whiteHoverOverlay};
  }
  :focus {
    border: 1px solid ${({ theme }) => theme.palette.dina.onFocus};
    background-color: ${({ theme }) => theme.palette.dina.blackHoverOverlay};
  }
  ::placeholder {
    color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  }
`;

const userFieldSettings: LayoutSettings = {
  label: '',
  fieldId: 'user',
  visible: true,
  hint: '',
};

const searchFieldSettings: LayoutSettings = {
  label: '',
  fieldId: 'search',
  visible: true,
  hint: '',
};

const UserFriendlyLabel: Record<FieldTypeEnum, string> = {
  choice: 'Choice',
  multiplechoice: 'Multiple choice',
  checkbox: 'Checkbox',
  number: 'Number',
  link: 'Link',
  user: 'User/Team/Dept/Contact',
  text: 'Text',
  date: 'Date',
  treechoice: 'Tree choice',
  subtype: 'Sub type',
  relation: 'Relation',
};

interface RowProps {
  field: UnsavedMdfField | MdfField;
  onDeleteField: (id: string) => void;
  onShowConfig: (field: MdfField) => void;
  onEditAlternatives: (field: MdfField) => void;
  onUpdateType: (type: FieldTypeEnum, field: MdfField) => void;
  onUpdateRequired: (val: boolean, field: MdfField) => void;
  onUpdateDefaultValue: (val: DefaultValue, field: MdfField) => void;
  onShowEditFieldDialog: (field: MdfField) => void;
  onShowPermissionDialog: (fieldId: string) => void;
}

const fieldTypeOptions: Alternative[] = Object.values(FieldTypeEnum).map((val) => ({
  id: val,
  label: UserFriendlyLabel[val],
  value: val,
}));

export function FieldModelRow({
  field,
  onDeleteField,
  onShowConfig,
  onEditAlternatives,
  onUpdateType,
  onUpdateRequired,
  onUpdateDefaultValue,
  onShowEditFieldDialog,
  onShowPermissionDialog,
}: Readonly<RowProps>) {
  const { format } = useDateTimeUtils();
  const [, openPicker] = useDatePicker();
  const [, openTreeChoiceDialog] = useTreeChoiceDialog();
  const [localDefaultValue, setLocalDefaultValue] = useState<DefaultValue | null>(
    field.defaultValue.value ?? null,
  );
  const { fieldId, defaultValue, type, required, alternatives, systemDefault, optionListId } =
    field;
  const { optionList } = useGetOptionList(optionListId);
  const parsedDate = useMemo(() => {
    if (type === FieldTypeEnum.date && localDefaultValue)
      return format(localDefaultValue as string | Date, 'ddd, MMM D YYYY hh:mm A');
  }, [localDefaultValue, type]);

  const resolvedAlternatives = useMemo(
    () =>
      ((optionList?.optionListType === 'choice' && optionList.alternatives) || alternatives) ?? [],
    [optionList, alternatives],
  );

  const openDatePicker = useCallback(
    (ev: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      openPicker({
        anchorEl: ev.currentTarget,
        startValue: typeof defaultValue.value === 'string' ? defaultValue.value : undefined,
        selectDate: (val: DateRange) => {
          onUpdateDefaultValue(val.startDate, field);
        },
      });
    },
    [field, onUpdateDefaultValue],
  );

  const openTreeChoice = useCallback(() => {
    openTreeChoiceDialog({
      open: true,
      fieldLabel: `default value of '${field.fieldId}'`,
      selected: isSingleArray(field.defaultValue.value) ? field.defaultValue.value : [],
      setSelected: (value: string[] | []) => onUpdateDefaultValue(value, field),
      treeAlternatives: field.treeAlternatives,
      optionListId: field.optionListId,
    });
  }, [field, onUpdateDefaultValue]);

  useEffect(() => {
    setLocalDefaultValue(defaultValue.value ?? null);
  }, [defaultValue.value, field]);

  const DefaultValueComponent = useMemo(() => {
    switch (type) {
      case FieldTypeEnum.relation: {
        return (
          <RelationField
            editorId={field.fieldId}
            fieldModel={field}
            fieldSettings={null}
            defaultFieldSettings={searchFieldSettings}
            value={localDefaultValue ?? ''}
            setValue={(val) => onUpdateDefaultValue(val, field)}
            style={{ marginBottom: '0px', marginTop: '0px' }}
            errorMessage={undefined}
            view="default"
          />
        );
      }
      case FieldTypeEnum.treechoice: {
        const parsed = Array.isArray(localDefaultValue) ? (localDefaultValue as string[]) : [];
        return <TreeChoiceDefault onClick={openTreeChoice} choice={parsed} variant="caption" />;
      }
      case FieldTypeEnum.checkbox: {
        return (
          <LWCheckbox
            selected={Boolean(localDefaultValue)}
            setValue={() => onUpdateDefaultValue(!localDefaultValue, field)}
          />
        );
      }
      case FieldTypeEnum.user:
        return (
          <UserField
            editorId={field.fieldId}
            fieldModel={field}
            fieldSettings={null}
            defaultFieldSettings={userFieldSettings}
            value={localDefaultValue ?? ''}
            setValue={(val) => onUpdateDefaultValue(val, field)}
            style={{ marginBottom: '0px', marginTop: '0px' }}
            errorMessage={undefined}
            view="default"
          />
        );
      case FieldTypeEnum.text:
      case FieldTypeEnum.number:
      case FieldTypeEnum.link: {
        return (
          <Input
            type={type === FieldTypeEnum.number ? 'number' : 'text'}
            value={(localDefaultValue as string | number | null) ?? ''}
            onChange={(ev) => setLocalDefaultValue(ev.target.value)}
            onBlur={() => onUpdateDefaultValue(localDefaultValue, field)}
            placeholder="Set default value"
          />
        );
      }
      case FieldTypeEnum.subtype:
      case FieldTypeEnum.choice: {
        return (
          <LWSelect
            value={localDefaultValue as string}
            options={resolvedAlternatives ?? []}
            setValue={(val) => onUpdateDefaultValue(val, field)}
            allowNoValue
          />
        );
      }
      case FieldTypeEnum.date: {
        return <DateDefault date={parsedDate} onClick={openDatePicker} variant="caption" />;
      }
      case FieldTypeEnum.multiplechoice: {
        const parsed = Array.isArray(localDefaultValue) ? (localDefaultValue as string[]) : [];
        const alternativesByValue = keyBy(resolvedAlternatives ?? [], (a) => a.value);
        return (
          <Tooltip title="Edit default value">
            <InlineTagWrapper onClick={() => onShowEditFieldDialog(field)}>
              {parsed.length ? (
                parsed.map((t) => (
                  <Tag key={t} style={{ height: '21px', lineHeight: '21px', padding: '0 4px' }}>
                    <TagText>{alternativesByValue[t]?.label ?? t}</TagText>
                  </Tag>
                ))
              ) : (
                <Text variant="caption" color="mediumEmphasis">
                  Select a choice
                </Text>
              )}
            </InlineTagWrapper>
          </Tooltip>
        );
      }
      default:
        return <div>Unknown field type</div>;
    }
  }, [
    JSON.stringify(field),
    localDefaultValue,
    resolvedAlternatives,
    onUpdateDefaultValue,
    onUpdateType,
  ]);

  const { attributes, transform, transition, listeners, setNodeRef, setActivatorNodeRef } =
    useSortable({
      id: field.fieldId,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const typeDisabled = useMemo(() => {
    if (isUnsavedMdfField(field)) {
      return field.existsElseWhere || field.systemDefault === true;
    }
    return true;
  }, [field]);

  const typeTooltip = useMemo(() => {
    if (isUnsavedMdfField(field)) {
      if (field.systemDefault) {
        return 'System default type cannot be changed';
      }
      if (field.existsElseWhere) {
        return 'Field already exists in another schema - type is locked';
      }
    } else {
      return 'Field types cannot be changed after they have been saved';
    }
    return 'Note: Type cannot be changed after save.';
  }, [field]);

  return (
    <TableRow ref={setNodeRef} {...attributes} style={style}>
      <RowCell>
        <div style={{ display: 'flex', height: '24px', alignItems: 'center', gap: '2px' }}>
          <div style={{ display: 'inline-flex', height: '24px' }}>
            <StyledDragHandle
              {...listeners}
              // @ts-expect-error ref incompatible from library
              ref={setActivatorNodeRef}
            />
          </div>
          <div>{fieldId}</div>
        </div>
      </RowCell>
      <RowCell>
        <Box container alignItems="center" justifyContent="start">
          {[
            FieldTypeEnum.choice,
            FieldTypeEnum.multiplechoice,
            FieldTypeEnum.treechoice,
            FieldTypeEnum.subtype,
          ].includes(field.type) && (
            <IconButton
              usage="text"
              size={24}
              iconSize={20}
              onClick={() => onEditAlternatives(field)}
              title="Edit available options"
              disabled={systemDefault}
            >
              <Edit />
            </IconButton>
          )}
          <Tooltip title={typeTooltip}>
            <div>
              <LWSelect
                options={fieldTypeOptions}
                value={type}
                setValue={(t) => onUpdateType(t as FieldTypeEnum, field)}
                disabled={typeDisabled}
              />
            </div>
          </Tooltip>
        </Box>
      </RowCell>
      <TitleCell>{DefaultValueComponent}</TitleCell>
      <RowCell>
        <Tooltip
          title={
            field.type === FieldTypeEnum.checkbox
              ? 'Check box cannot be required, as it always implicitly has a value'
              : 'Mark as required - users are forced to apply a value to this field.'
          }
        >
          <span>
            <LWCheckbox
              disabled={field.type === FieldTypeEnum.checkbox}
              style={{ position: 'relative', left: '20px', top: '-1px' }}
              selected={required ?? false}
              setValue={() => onUpdateRequired(!(required ?? false), field)}
            />
          </span>
        </Tooltip>
      </RowCell>
      <RowCell style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          usage="text"
          size={24}
          iconSize={18}
          title="Additional field options"
          disabledTitle="No additional options available"
          onClick={() => {
            if (supportsAdditionalConfig(field)) {
              onShowConfig(field);
            }
          }}
          disabled={!supportsAdditionalConfig(field)}
        >
          <Settings />
        </IconButton>
        <IconButton
          usage="text"
          size={24}
          iconSize={18}
          title="Field permission"
          onClick={() => onShowPermissionDialog(field.fieldId)}
        >
          <UserIcon />
        </IconButton>
        <IconButton
          usage="text"
          size={24}
          iconSize={18}
          title="Delete field"
          disabledTitle="System defined fields are not deletable"
          onClick={() => onDeleteField(field.fieldId)}
          disabled={systemDefault}
        >
          <Delete />
        </IconButton>
      </RowCell>
    </TableRow>
  );
}
