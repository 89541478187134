import { atom, useAtom } from 'jotai';
import { Dictionary, keyBy } from 'lodash';
import { v4 as uuid } from 'uuid';

import { ReactComponent as Assignees } from 'assets/icons/search/assignees.svg';
import { ReactComponent as Rundown } from 'assets/icons/search/rundown.svg';
import { ReactComponent as Pitch } from 'assets/icons/systemicons/pitch_green.svg';
import { ReactComponent as Story } from 'assets/icons/systemicons/story_blue.svg';
import { FieldValue, Metadata } from 'types/forms/forms';
import {
  Alternative,
  FieldTypeEnum,
  LayoutSettings,
  Mdf,
  MdfField,
  ViewTypes,
} from 'types/graphqlTypes';

export type UnsavedMdfField = MdfField & {
  isUnsaved: true;
  existsElseWhere: boolean;
};

export const isUnsavedMdfField = (obj: UnsavedMdfField | MdfField): obj is UnsavedMdfField => {
  return (obj as UnsavedMdfField)?.isUnsaved === true;
};

export const createAlternative = (value: string, suffix?: string | number): Alternative => ({
  id: uuid(),
  value: suffix ? `${value}_${suffix}` : value,
  label: suffix ? `Visible label ${suffix}` : 'Visible label',
});

export const MdfIcons: Record<string, React.FC<React.SVGProps<SVGSVGElement>>> = {
  'contact-mdf': Assignees,
  'rundown-mdf': Rundown,
  'story-mdf': Story,
  'user-mdf': Assignees,
  'pitch-mdf': Pitch,
};

type SubTypeMap = Record<string, Record<string, MdfField>>;

const doSanitize = (
  metadata: Metadata,
  fields: Record<string, MdfField | undefined> | undefined,
  map: SubTypeMap,
) => {
  let sanitized: Metadata = {};
  for (const [key, value] of Object.entries(metadata)) {
    const field = fields?.[key];
    if (field) {
      if (field.type === FieldTypeEnum.subtype) {
        // Only process subtypes that have valid values
        if (field.alternatives?.find((alt) => alt.value === value)) {
          sanitized[key] = value;
          sanitized = {
            ...sanitized,
            ...doSanitize(metadata, map[value as string], map),
          };
        }
      } else {
        sanitized[key] = value;
      }
    }
  }
  return sanitized;
};

const getSubtypeFieldMap = (subTypesByLabel: Record<string, Mdf>): SubTypeMap => {
  const map: SubTypeMap = {};
  Object.entries(subTypesByLabel).forEach(([key, value]) => {
    map[key] = keyBy(value.fields, (f) => f.fieldId);
  });
  return map;
};

/**
 * Take care of sanitizing away metadata fields that do not conform to the model, where
 *
 * 1. Fields of provided MDF are valid,
 * 2. Fields of any selected subtype value are valid.
 *
 * Any value found to not match against a field model as described above will be sanitized
 * away by this function.
 *
 * @param metadata The metadata to sanitize
 * @param mdf The MDF to sanitize the metadata against
 * @param subTypesByLabel A map of possible subtype MDFs
 * @returns A new object with sanitized metadata
 */
export const sanitizeMetadata = (
  metadata: Metadata,
  mdf: Mdf,
  subTypesByLabel: Record<string, Mdf>,
): Metadata => {
  const subTypeFields = getSubtypeFieldMap(subTypesByLabel);
  const validMainFields: Record<string, MdfField> = keyBy(mdf.fields, (f) => f.fieldId);
  return doSanitize(metadata, validMainFields, subTypeFields);
};

export interface EditFieldValue {
  fieldId: string;
  mdf: Mdf;
  viewType: ViewTypes;
  startValue?: FieldValue | null;
  headerText: string;
  required?: boolean;
  confirmLabel?: string;

  /**
   * Provide true to ignore required & visible workflows, useful for example to allow
   * editing a default value without distracting a user from irrelevant workflows.
   */
  editMode?: boolean;
  onConfirm: (val: FieldValue) => void;
  onCancel?: () => void;
}

const editFieldDialog = atom<EditFieldValue | null>(null);

export const useEditFieldValueDialog = () => useAtom(editFieldDialog);

export const shouldShowField = (
  field: MdfField,
  defaultSettings: Dictionary<LayoutSettings>,
  overrides: Dictionary<LayoutSettings>,
  forceVisible?: boolean,
  canRead?: boolean,
) => {
  if (forceVisible) return true;
  if (field.required) return true;
  const settings = overrides[field.fieldId] ?? defaultSettings[field.fieldId];
  return (settings.visible ?? true) && canRead;
};

export const hasPermission = (permissions: string[], groups: string[]): boolean => {
  if (!permissions) return true;
  if (permissions.length === 0) return false;
  if (groups.length === 0) return false;
  return groups.some((value) => permissions.includes(value));
};

export const getSubMdf = (
  field: MdfField,
  metadata: Metadata,
  subTypesByLabel: Record<string, Mdf>,
): Mdf | undefined => {
  if (field.type !== FieldTypeEnum.subtype) return;
  const value = metadata[field.fieldId];
  if (typeof value === 'string' && subTypesByLabel[value]) {
    return subTypesByLabel[value];
  }
};

/** Contains information about a field with a given ID */
export interface ExistingFieldInfo {
  /** The type of the field */
  readonly fieldType: FieldTypeEnum;

  /** The label of the first schema containing a field with that ID */
  readonly mdfLabel: string;
}

/**
 * Collects information about which types are used for various field types from an array of MDFs.
 * @param mdfs The MDFs to collect information from
 * @returns    Mapping from field ID to {@link ExistingFieldInfo}
 */
export function getExistingFieldInfoMap(
  mdfs: readonly Mdf[],
): Readonly<Record<string, ExistingFieldInfo>> {
  const map: Record<string, ExistingFieldInfo> = {};
  mdfs.forEach((mdf) => {
    mdf.fields.forEach((f) => {
      if (!(f.fieldId in map)) {
        map[f.fieldId] = Object.freeze({ fieldType: f.type, mdfLabel: mdf.label });
      }
    });
  });
  return Object.freeze(map);
}
