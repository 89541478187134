import { useCallback, useState } from 'react';

import { useSelectedDate } from 'components/createStoryItems/store';
import DropdownMenu from 'components/dropdownMenu/DropdownMenu';
import CategorizedMenu from 'components/menu/createInstanceMenu/MenuItem/CategorizedMenu';
import PlatformIcons from 'components/menu/createInstanceMenu/PlatformIcons';
import useCustomDateTimeUtils from 'hooks/useCustomDateTimeUtils';
import useDateTimeUtils from 'hooks/useDateTimeUtils';
import { Platform } from 'types';
import { AccountType } from 'types/graphqlTypes';
import { getPlatformIcon, getPlatformLabel, PlatformData } from 'utils/instance/platform';

interface Props {
  item: Platform;
  setSelectedPlatform: (platform: PlatformData) => void;
}

type AccType = AccountType & {
  platformKind?: string;
  publishingAt?: string;
};

const date = new Date().toISOString();

export function DropdownSubLinearItems({ item, setSelectedPlatform }: Readonly<Props>) {
  const { mProperties } = item;
  const { isToday } = useDateTimeUtils();
  const { isBeforeToday } = useCustomDateTimeUtils();
  const { platformIcon, platform, accounts = [], platformKind } = mProperties;

  const [selectedDate, setSelectedDate] = useSelectedDate();
  const [open, setOpen] = useState(false);

  const Icon = PlatformIcons[platformIcon as string] ?? getPlatformIcon(platform, platformKind);
  const label = getPlatformLabel(platform, platformKind);

  const handleSelectPlatform = (account: AccType) => {
    setSelectedPlatform({
      platform,
      account,
      platformKind,
    });
    setOpen(false);
  };

  const disableDecrement = Boolean(
    selectedDate && (isToday(selectedDate) || isBeforeToday(selectedDate)),
  );

  const onDateChanged = useCallback(({ endDate }: { endDate: string }) => {
    setSelectedDate(endDate);
  }, []);

  return (
    <>
      <DropdownMenu.Sub key={platformKind ?? platform} open={open} onOpenChange={setOpen}>
        <DropdownMenu.SubTrigger>
          <Icon />
          {label}
        </DropdownMenu.SubTrigger>
        <DropdownMenu.Portal>
          <DropdownMenu.SubContent>
            <CategorizedMenu
              // @ts-expect-error Types needs to be handled in this file
              onDateChanged={onDateChanged}
              publishingAt={date}
              disableDecrement={disableDecrement}
              items={accounts}
              onClose={(account) => handleSelectPlatform(account as AccType)}
              dense
              darker
            >
              <Icon />
            </CategorizedMenu>
          </DropdownMenu.SubContent>
        </DropdownMenu.Portal>
      </DropdownMenu.Sub>
    </>
  );
}
