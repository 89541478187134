import styled from '@emotion/styled';

export const Placeholder = styled('span')`
  pointer-events: none;
  display: inline-block;
  width: 0px;
  white-space: nowrap;
  opacity: 0.333;
  user-select: none;
  font-weight: normal;
`;

Placeholder.defaultProps = {
  contentEditable: false,
};
