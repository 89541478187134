import { ChangeEvent, KeyboardEvent, memo, useCallback, useEffect, useRef, useState } from 'react';

import { ReactComponent as DeleteIcon } from 'assets/icons/systemicons/close_small.svg';
import { IconButton } from 'components/buttons';
import { SortableItemWrapper } from 'components/editMdfDialog/components/SortableItemWrapper';
import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import Tooltip from 'components/tooltip';
import { Alternative } from 'types/graphqlTypes';
import { getKeyboardShortcutText } from 'utils/keyboardShortcuts';

import { RowWrapper } from './editAlternativesItem-styled';

interface Props {
  option: Alternative;
  index: number;
  isDuplicateValue: (value: string, index: number) => boolean;
  onRemove: (alt: Alternative) => void;
  onChange: (
    index: number,
    updater: (prevValue: Alternative) => Alternative,
    addRowAfter: boolean,
  ) => void;
}

function EditAlternativeItem({
  option,
  isDuplicateValue,
  index,
  onChange,
  onRemove,
}: Readonly<Props>) {
  const [localLabel, setLocalLabel] = useState(option.label);
  const [localValue, setLocalValue] = useState(option.value);
  const [localError, setLocalError] = useState<string>('');
  const valueInputRef = useRef<HTMLInputElement | null>(null);

  const handleValueChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setLocalValue(e.target.value);
      setLocalError(isDuplicateValue(e.target.value, index) ? 'Duplicate value' : '');
    },
    [setLocalValue, setLocalError, isDuplicateValue],
  );

  function handleLabelBlur() {
    if (localLabel !== option.label) {
      onChange(index, (alt) => ({ ...alt, label: localLabel }), false);
    }
  }

  function handleValueBlur() {
    if (localValue !== option.value) {
      if (localError) {
        setLocalValue(option.value);
        setLocalError('');
      } else {
        onChange(index, (alt) => ({ ...alt, value: localValue }), false);
      }
    }
  }

  const handleValueKeydown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        if (localError) {
          setLocalValue(option.value);
          setLocalError('');
        } else if (localValue !== option.value) {
          onChange(index, (alt) => ({ ...alt, value: localValue }), e.shiftKey);
        } else if (e.shiftKey) {
          onChange(index, (alt) => alt, true);
        } else {
          return;
        }
        e.stopPropagation();
      } else if (e.key === 'Backspace' && e.shiftKey) {
        onRemove(option);
        e.preventDefault();
      }
    },
    [onChange, localError, localValue, option.value, index],
  );

  const handleLabelKeydown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        if (localLabel !== option.label) {
          onChange(index, (alt) => ({ ...alt, label: localLabel }), e.shiftKey);
        } else if (e.shiftKey) {
          onChange(index, (alt) => alt, true);
        } else {
          return;
        }
        e.stopPropagation();
      } else if (e.key === 'Backspace' && e.shiftKey) {
        onRemove(option);
        e.preventDefault();
      }
    },
    [onChange, localLabel, option, index],
  );

  useEffect(() => {
    if (option.label !== localLabel) {
      setLocalLabel(option.label);
    }
    if (option.value !== localValue) {
      setLocalValue(option.value);
    }
  }, [option]);

  return (
    <SortableItemWrapper key={option.id} item={option}>
      <RowWrapper>
        <StyledTextField
          variant="filled"
          value={localLabel}
          onChange={(ev) => setLocalLabel(ev.target.value)}
          onBlur={handleLabelBlur}
          onKeyDown={handleLabelKeydown}
          style={{ width: '250px' }}
        />
        <Tooltip title={localError}>
          <StyledTextField
            ref={valueInputRef}
            variant="filled"
            value={localValue}
            helperText={localError || undefined}
            onChange={handleValueChange}
            onBlur={handleValueBlur}
            onKeyDown={handleValueKeydown}
            error={!!localError}
            style={{ width: '250px' }}
          />
        </Tooltip>
        <IconButton
          title={`Remove option  (${getKeyboardShortcutText('Shift+Backspace')})`}
          height={35}
          width={24}
          iconSize={12}
          usage="text"
          onClick={() => onRemove(option)}
          tabIndex={-1}
        >
          <DeleteIcon />
        </IconButton>
      </RowWrapper>
    </SortableItemWrapper>
  );
}

export default memo(EditAlternativeItem);
