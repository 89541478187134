import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import groupBy from 'lodash/groupBy';

import useGetConversations from 'api/useGetConversations';
import { ReactComponent as Leave } from 'assets/icons/systemicons/ellipsis_menu/participate_leave.svg';
import { WarningDialog } from 'components/dialogs/CommonDialogs';
import Scrollbar from 'components/scrollbar';
import { NotificationContext } from 'contexts/NotificationContext';
import UserContext from 'contexts/UserContext';
import { Box, VStack } from 'layouts/box/Box';
import LEAVE_CONVERSATION from 'operations/mutations/leaveConversation';
import GET_CONVERSATIONS_OF_USER from 'operations/queries/getConversationsOfUser';
import { ConversationTypeEnum, MemberTypeEnum } from 'types/graphqlTypes';
import { Conversation } from 'types/messageHub';
import conversationTypes from 'utils/constants/conversationTypes';
import useLogger from 'utils/useLogger';

import useAssembleData from '../../hooks/useAssembleData';
import useGetConversationInfo from '../../hooks/useConversationInfo';
import { useConversationMolecule } from '../../store/conversation';

import Group from './Group';
import ListItem from './ListItem';
import LeftColumnSkeleton from './Skeleton';

const menuItems = [
  {
    title: 'Leave Conversation',
    action: 'leave-conversation',
    icon: <Leave />,
  },
];

const Notification = styled(Box)`
  border-top: 2px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

function LeftColumnContainer() {
  const logger = useLogger('Conversation list: leftColumn');
  const { assembleDepartmentData, assembleTeamData } = useAssembleData();
  const user = useContext(UserContext);
  const containerRef = useRef(null);
  const { getConversationInfo, getSortedConversations } = useGetConversationInfo();
  const { newConversation, setNewConversation } = useContext<{
    newConversation: Conversation;
    setNewConversation: (conversation: Conversation | undefined) => void;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  }>(NotificationContext);
  const { mId } = user;

  const { useCurrentConversation, useSetCurrentConversation } = useConversationMolecule();
  const currentConversation = useCurrentConversation();
  const setCurrentConversation = useSetCurrentConversation();

  const [dialog, setDialog] = useState<string | null>(null);
  const [dialogId, setDialogId] = useState<string | null>(null);

  const closeDialog = () => setDialog(null);

  const [leaveConversation] = useMutation(LEAVE_CONVERSATION);

  const { data, loading, error, refetch } = useGetConversations();

  useEffect(() => {
    if (newConversation) {
      void refetch();
      setNewConversation(undefined);
    }
  }, [newConversation, refetch, setNewConversation]);

  const handleLeaveConversation = useCallback(
    async (id: string | null) => {
      if (currentConversation?.mId === id) {
        setCurrentConversation();
      }

      await leaveConversation({
        variables: {
          input: {
            mId: id,
            mRefId: mId,
          },
        },
        update: (proxy) => {
          const conversations = proxy.readQuery<{ getConversationsOfUser: Conversation[] }>({
            query: GET_CONVERSATIONS_OF_USER,
            variables: {
              input: {
                mId,
              },
            },
          });
          const newConversations = conversations?.getConversationsOfUser.filter(
            ({ mId: _mId }) => _mId !== id,
          );

          proxy.writeQuery({
            query: GET_CONVERSATIONS_OF_USER,
            variables: {
              input: {
                mId,
              },
            },
            data: {
              getConversationsOfUser: newConversations,
            },
          });
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentConversation?.mId],
  );

  const confirmDialog = async () => {
    closeDialog();
    await handleLeaveConversation(dialogId);
  };

  const onMenuSelect = (action: string, id: string) => {
    if (action === 'leave-conversation') {
      setDialog(action);
      setDialogId(id);
    }
  };

  if (error) {
    logger.error(error);
    return <div>{error.message}</div>;
  }

  const getConversationsOfUser = data?.getConversationsOfUser || [];

  const teamData = getConversationsOfUser
    .filter((d) => d.mType === MemberTypeEnum.Team)
    .filter(Boolean);
  const departmentData = getConversationsOfUser
    .filter((d) => d.mType === MemberTypeEnum.Department)
    .filter(Boolean);

  const groupedData = groupBy(data?.getConversationsOfUser, 'convoType');
  const people = groupedData[conversationTypes.DIRECT] ?? [];
  const groups = groupedData[conversationTypes.GROUP] ?? [];
  const conversations = getSortedConversations([...people, ...groups]);

  const teams = assembleTeamData(teamData, true);
  const departments = assembleDepartmentData(departmentData, true);
  const channelConversations = getSortedConversations([...teams, ...departments]);
  const everyone = getConversationInfo({
    mId: 'messageAll',
    convoType: ConversationTypeEnum.All,
  });
  const notifications = getConversationInfo({
    mId: `${mId}_notifications`,
    convoType: ConversationTypeEnum.Notification,
  });

  if (data) {
    return (
      <>
        <VStack height="100%" minWidth="172px" ref={containerRef}>
          <Box flex="1" width="100%">
            <Scrollbar top={10} bottom={10}>
              <Group title="Direct messages" conversations={conversations} />
              <Group
                title="Channels"
                conversations={[everyone, ...channelConversations]}
                menuItems={menuItems}
                onMenuSelect={onMenuSelect}
              />
            </Scrollbar>
          </Box>
          <Notification width="100%">
            <ListItem key={notifications.mId} conversation={notifications} />
          </Notification>
        </VStack>
        <WarningDialog
          open={dialog === 'leave-conversation'}
          onClose={closeDialog}
          onClick={confirmDialog}
          title="Leave the Conversation?"
          // eslint-disable-next-line max-len
          message="When you leave a Conversation, you will no longer receive any notifications from it. Messages you have posted in this Conversation will remain visible for remaining participants."
          confirmLabel="Leave"
        />
      </>
    );
  }

  if (loading) return <LeftColumnSkeleton />;
}

export default LeftColumnContainer;
