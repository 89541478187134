import { useCallback } from 'react';

import useDateTimeUtils from 'hooks/useDateTimeUtils';

const useScheduleDateUtils = () => {
  const { isPast, isThisMinute } = useDateTimeUtils();
  /**
   * Returns true if the date indicates to publish now.
   * This is true for timestamps in the past or within the current minute
   * @param tstr - time as ISO string
   */
  const scheduleNow = useCallback((tstr: string) => {
    const date = new Date(tstr);
    return isPast(date) || isThisMinute(date);
  }, []);
  /**
   * Sets the time component of given timestamp
   * @param tiso - timestamp (ISO string)
   * @param tvalue - date with time information
   * @returns updated timestamp (ISO string)
   */
  const setHoursIsoString = useCallback((tiso: string, tvalue: Date) => {
    const ttime = new Date(tiso);
    const dValue = new Date(tvalue);
    ttime.setHours(
      dValue.getHours(),
      dValue.getMinutes(),
      dValue.getSeconds(),
      dValue.getMilliseconds(),
    );
    const ntime = isPast(ttime) ? new Date() : ttime;
    return ntime.toISOString();
  }, []);

  /**
   * Sets the date component of given timestamp
   * @param tiso - timestamp (ISO string)
   * @param tvalue - date with date information
   * @returns updated timestamp (ISO string)
   */
  const setDateIsoString = useCallback((tiso: string, tvalue: Date) => {
    const ttime = new Date(tiso);
    const dValue = new Date(tvalue);
    ttime.setFullYear(dValue.getFullYear());
    ttime.setMonth(dValue.getMonth());
    ttime.setDate(dValue.getDate());
    return ttime.toISOString();
  }, []);

  return { scheduleNow, setHoursIsoString, setDateIsoString };
};

export default useScheduleDateUtils;
