import { gql, useQuery } from '@apollo/client';

import { PanelType } from 'types';

interface GetPanels {
  getMemberFromId: PanelType[];
}

const GET_PANELS = gql`
  query GetPanels($input: GetMemberInput) {
    getMemberFromId(input: $input) {
      mTitle
      mId
      mRefId
      configs {
        type
        name
        key
        value
      }
    }
  }
`;

const useGetPanels = () => {
  const { data, error, loading } = useQuery<GetPanels>(GET_PANELS, {
    variables: {
      input: {
        mId: 'externalurl',
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  return [data?.getMemberFromId ?? ([] satisfies PanelType[]), loading, error] as const;
};

export default useGetPanels;
