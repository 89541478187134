import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Video } from 'assets/icons/systemicons/editor/video.svg';
import tooltipEnum from 'components/editor/constants/tooltipEnum';
import { useSlateStatic } from 'slate-react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import useFileUpload from 'hooks/useFileUpload';
import Tooltip from 'components/tooltip';
import { useEditorContext } from 'components/editor/EditorContext';
import insertVideoBlock from 'components/editor/components/audioVideo/utils/insertVideoBlock';
import insertVideo from 'components/editor/components/audioVideo/utils/insertVideo';
import normalizeOnListFocus from 'components/editor/utils/normalizeOnListFocus';
import transientOptions from 'theme/helpers';

const mimeTypes = ['video/mp4'];

const Container = styled('div', transientOptions)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  ${({ $disabled, theme }) =>
    $disabled
      ? css`
          cursor: auto;
          opacity: 0.36;
        `
      : ''}
`;

const VideoButton = ({ disabled }) => {
  const editor = useSlateStatic();
  const { update, isAllowed } = useEditorContext();

  const onFileUpload = useCallback(
    (file, fileUrl) => insertVideo(editor, update, file, fileUrl),
    [editor, update],
  );

  const uploadFile = useFileUpload(mimeTypes, onFileUpload, false, 'videoButton');

  const onMouseDown = useCallback(
    (event) => {
      if (disabled) return;
      event.preventDefault();
      normalizeOnListFocus(editor);
      if (isAllowed) {
        insertVideoBlock(editor, update);
      } else uploadFile();
    },
    [editor, isAllowed, update, uploadFile, disabled],
  );

  return (
    <Tooltip title={tooltipEnum.VIDEO}>
      <Container $disabled={disabled}>
        <Video onMouseDown={onMouseDown} />
      </Container>
    </Tooltip>
  );
};

VideoButton.propTypes = {
  disabled: PropTypes.bool,
};

VideoButton.defaultProps = {
  disabled: false,
};

export default memo(VideoButton);
