import { css } from '@emotion/react';
import styled from '@emotion/styled';

import transientOptions from 'theme/helpers';

export type TodaySelectorType = 'default' | 'cropped' | 'diamond';

export const ContainerWrapper = styled('div', transientOptions)<{ $containerType: string | null }>`
  ${({ theme, $containerType }) => {
    if ($containerType === 'container') {
      return css`
        width: 40px;
        height: 20px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
      `;
    }

    const commonContainer = css`
      width: 100%;
      background-color: ${theme.palette.dina.onSelected};
    `;

    if ($containerType === 'rightContainer') {
      return css`
        ${commonContainer}
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
      `;
    }

    return $containerType === 'middleContainer' ? commonContainer : '';
  }}
`;

export const RootWrapper = styled('div', transientOptions)<{
  $isToday: boolean;
  $isSelected: boolean;
  $disabled: boolean;
  $selectingRange: boolean;
  $todaySelectorType: TodaySelectorType;
  $rangePosition: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 59px;
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ $disabled }) => ($disabled ? 0.15 : 1)};

  &:hover {
    & * {
      color: ${({ $isSelected, $selectingRange, theme }) =>
        $isSelected || $selectingRange
          ? theme.palette.dina.whiteHighEmphasis
          : theme.palette.dina.highEmphasis};
    }

    ${({ $selectingRange, theme }) =>
      $selectingRange &&
      css`
        background-color: ${theme.palette.dina.onSelected};
        border-radius: 12px;
        width: 59px;
        ::before {
          border-color: transparent;
          background-color: transparent;
        }
      `}
  }

  ${({ theme, $isToday, $todaySelectorType, $isSelected, $rangePosition }) => {
    if ($isToday && $todaySelectorType === 'diamond' && !$isSelected) {
      return css`
        position: relative;
        ::before {
          content: '';
          position: absolute;
          width: 26px;
          height: 26px;
          border: 1px solid ${theme.palette.dina.onSelected};
          background-color: ${theme.palette.dina.blackHoverOverlay};
          border-radius: 2px;
          transform: rotate(45deg);
        }
      `;
    }

    if ($isToday && !$isSelected) {
      return css`
        background-color: ${theme.palette.dina.blackHoverOverlay};
        border: 1px solid ${theme.palette.dina.onSelected};
        border-radius: 12px;
        box-sizing: border-box;
      `;
    }

    if ($isSelected) {
      const baseSelected = css`
        background-color: ${theme.palette.dina.onSelected};
      `;

      switch ($rangePosition) {
        case 'left':
          return css`
            ${baseSelected}
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
          `;
        case 'right':
          return css`
            ${baseSelected}
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
          `;
        case 'middle':
          return baseSelected;
        default:
          return css`
            ${baseSelected}
            border-radius: 12px;
          `;
      }
    }
  }}
`;
