/* eslint-disable no-nested-ternary */
import React, { memo, useRef, useState } from 'react';

import { ReactComponent as VideoIcon } from 'assets/images/search/video_small.svg';
import { ReactComponent as ImageIcon } from 'assets/images/search/photo_on_small.svg';
import { ReactComponent as AudioIcon } from 'assets/images/search/audio_small.svg';
import { ReactComponent as FileIcon } from 'assets/images/search/document_small.svg';
import { ReactComponent as FolderIcon } from 'assets/images/search/folder_small.svg';

import { ReactComponent as LargeVideoIcon } from 'assets/images/search/video_on.svg';
import { ReactComponent as LargeImageIcon } from 'assets/images/search/photo_on.svg';
import { ReactComponent as LargeAudioIcon } from 'assets/images/search/audio_on.svg';
import { ReactComponent as LargeFileIcon } from 'assets/images/search/document_on.svg';
import { ReactComponent as LargeFolderIcon } from 'assets/images/search/folder_on.svg';

import { humanReadableFileSize } from 'utils/fileSizeUtils';
import {
  ItemWrapper,
  Thumbnail,
  ThumbnailWrapper,
  HoverThumbnail,
  Details,
  Title,
  Created,
  Slider,
  TimeCode,
  TypeIcon,
  ThumbnailOverlay,
  PlaceholderThumbnail,
} from './styled';
import getTimeString from 'utils/getTimeString';
import useDateTimeUtils from 'hooks/useDateTimeUtils';

const THUMB_WIDTH = 100;
const STRIP_FRAME_WIDTH = 130;

const itemTypeToIconMap = {
  audio: <AudioIcon />,
  video: <VideoIcon />,
  image: <ImageIcon />,
  file: <FileIcon />,
  folder: <FolderIcon />,
};

const itemTypeToLargeIconMap = {
  audio: <LargeAudioIcon />,
  video: <LargeVideoIcon />,
  image: <LargeImageIcon />,
  file: <LargeFileIcon />,
  folder: <LargeFolderIcon />,
};

function MimirItem({ item, onClick }) {
  const { format: fnsFormat } = useDateTimeUtils();
  const [isHovering, setIsHovering] = useState(false);
  const itemType = item?.itemType;
  const title = item?.metadata?.formData?.default_title ?? item?.name ?? '<unknown>';
  const date = new Date(item?.metadata?.formData?.default_createdOn ?? item?.createdOn);
  const created = fnsFormat(date, 'DD MMMM YYYY');
  const size = item?.mediaSize ? humanReadableFileSize(item.mediaSize) : undefined;
  const typeIcon = itemTypeToIconMap[itemType] ?? <FileIcon />;
  const largeTypeIcon = itemTypeToLargeIconMap[itemType] ?? <LargeFileIcon />;
  const src =
    item?.livePreviewLocation?.streamUrl ?? item?.thumbnailStrip ?? item?.thumbnail ?? undefined;
  const mediaDuration = item?.mediaDuration ? getTimeString(item?.mediaDuration) : undefined;
  const wrapper = useRef();
  const slider = useRef();
  const videoScrubber = useRef();
  const hasThumbStrip = item?.thumbnailStrip !== undefined;

  const onMouseMove = (ev) => {
    setStripPosition(ev.clientX);
  };

  const setStripPosition = (mouseXPos) => {
    const { current: sliderCur } = slider;
    const { current: scrubberCur } = videoScrubber;
    const { current: wrapperCur } = wrapper;

    if (!sliderCur || !scrubberCur || !wrapperCur) return;

    const leftPos = mouseXPos - wrapperCur.getBoundingClientRect().left;
    const normalized = Math.max(Math.min(leftPos, THUMB_WIDTH), 1);

    // How many px the slider should scrub before showing the next frame
    const pxPerFrame = THUMB_WIDTH / STRIP_FRAME_WIDTH;

    // The slider can just be position absolutely according to the position
    sliderCur.style.left = `${normalized}px`;
    const stripLeftPos =
      Math.min(Math.ceil(normalized / pxPerFrame), STRIP_FRAME_WIDTH - 1) * THUMB_WIDTH;
    scrubberCur.style.left = `-${stripLeftPos}px`;
  };

  return (
    <ItemWrapper onClick={onClick}>
      <ThumbnailWrapper
        $width={THUMB_WIDTH}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onMouseMove={onMouseMove}
        onMouseOut={() => setStripPosition(0)}
        ref={wrapper}
      >
        {hasThumbStrip && isHovering && <Slider className="slider" ref={slider} />}
        {hasThumbStrip ? (
          <HoverThumbnail
            src={src}
            $imgFactor={100 / 130}
            className="video-scrubber"
            ref={videoScrubber}
          />
        ) : src ? (
          <Thumbnail $src={src} />
        ) : (
          <PlaceholderThumbnail $type={itemType}>{largeTypeIcon}</PlaceholderThumbnail>
        )}
        {(!isHovering || !hasThumbStrip) && (
          <ThumbnailOverlay>
            <TypeIcon>{typeIcon}</TypeIcon>
            {mediaDuration && <TimeCode>{mediaDuration}</TimeCode>}
          </ThumbnailOverlay>
        )}
      </ThumbnailWrapper>
      <Details>
        <Title>{title}</Title>
        <Created>Created: {created}</Created>
        {size && <Created>Size: {size}</Created>}
      </Details>
    </ItemWrapper>
  );
}

export default memo(MimirItem);
