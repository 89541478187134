import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

import { ReactComponent as Archive } from 'assets/icons/systemicons/archive.svg';
import { ReactComponent as Photo } from 'assets/icons/systemicons/coverPhoto.svg';
import { ReactComponent as More } from 'assets/icons/systemicons/more_vertical.svg';
import { ReactComponent as Refresh } from 'assets/icons/systemicons/refresh.svg';
import { ReactComponent as Asset } from 'assets/icons/systemicons/upload.svg';
import { IconButton } from 'components/buttons';
import ListItem from 'components/ellipsisDropdown/listItem-view';
import Popover from 'components/popover/Popover';
import Switch from 'components/switch/Switch';
import Text from 'components/text';
import ToggleButtons from 'components/toggleButtons';
import UploadMediaInput from 'components/uploadMediaInput';
import useDateTimeUtils from 'hooks/useDateTimeUtils';
import useGetArchiveTime from 'hooks/useGetArchiveTime';
import useSetStorySync from 'hooks/useSetStorySync';
import useSettingsValue from 'hooks/useSettingsValue';
import memberTypes from 'operations/memberTypes';
import UPDATE_STORY from 'operations/mutations/updateStory';
import { Story } from 'types';
import { MemberTypeEnum } from 'types/graphqlTypes';
import fontSizeList from 'utils/constants/fontSizeList';

import {
  useIsDescriptionShown,
  useIsTimelineShown,
  useStoryEditorFontSize,
} from '../store/toolbar';

import { FontSizeContainer, IconWrapper, ItemsWrapper } from './styled';

const { ARCHIVED_STORY, ARCHIVED_RESTRICTED_STORY } = memberTypes;

interface DropdownProps {
  story: Story;
  isPitch: boolean;
  canUpdateStory: boolean;
  isDescriptionSettingsHidden: boolean;
}

const Dropdown = ({
  story,
  isPitch,
  canUpdateStory,
  isDescriptionSettingsHidden,
}: DropdownProps) => {
  const { differenceInCalendarDays } = useDateTimeUtils();
  const [updateStory] = useMutation(UPDATE_STORY);

  const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null);
  const [getAutoArchiveTime] = useGetArchiveTime();
  const [editorFontSize, setEditorFontSize] = useStoryEditorFontSize();
  const [getSettingsValue] = useSettingsValue();

  const [setSync] = useSetStorySync();

  //default will be removed later
  const liveUSyncProviderId = getSettingsValue('app.syncProvider.liveU.id') as string;
  const liveUParentProviderId = getSettingsValue(
    'app.syncProvider.liveU.parentProviderId',
  ) as string;

  const pitchSyncEnabled = getSettingsValue('app.enabledPitchSync') === 'true';
  const syncEnabled = isPitch ? pitchSyncEnabled : true;

  const liveUProviderEnabled =
    Array.isArray(story?.mSyncProviders) &&
    story?.mSyncProviders.find(
      (p) => p.mId === liveUSyncProviderId || p.mId === liveUParentProviderId,
    ) &&
    liveUSyncProviderId;

  const uploadComplete = (val: boolean) => {
    if (val === true) {
      setAnchorEl(null);
    }
  };

  const [isTimelineShown, setIsTimelineShown] = useIsTimelineShown();
  const toggleTimeline = () => setIsTimelineShown(!isTimelineShown);

  const [isDescriptionShown, setIsDescriptionShown] = useIsDescriptionShown();
  const toggleDescription = () => setIsDescriptionShown(!isDescriptionShown);

  const getActiveProviders = (activeProviders: string) => {
    const providers = (activeProviders ? [activeProviders] : []).filter(
      (m) => m !== liveUSyncProviderId,
    );
    return providers.length ? providers : [];
  };

  const [syncActive, setSyncActive] = useState(getActiveProviders(story?.mSyncActive ?? ''));
  // think about more generic solution
  const [isLiveUSyncActive, setIsLiveUSyncActive] = useState(
    story?.mSyncActive?.includes(liveUSyncProviderId),
  );

  const [archiveTime, setArchiveTime] = useState<number | null | undefined>(story?.ttl);

  useEffect(() => {
    if (story?.mSyncActive) {
      setSyncActive(getActiveProviders(story.mSyncActive));
      setIsLiveUSyncActive(story.mSyncActive?.includes(liveUSyncProviderId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [story?.mSyncActive]);

  useEffect(() => {
    setArchiveTime(story?.ttl ?? null);
  }, [story?.ttl]);

  if (!story) return null;

  const handleSynchronizeStateChange = async (val: string[]) => {
    const currentSyncState = new Set(story.mSyncActive);
    let deactivated = false;
    if (val.length > 0) {
      val.forEach((v) => currentSyncState.delete(v));
      deactivated = true;
    } else {
      currentSyncState.add('*');
    }
    const providers = Array.from(currentSyncState);
    setSyncActive(providers);
    await setSync(story.mId, providers, deactivated);
  };

  const handleLiveUSynchronizeStateChange = async () => {
    const currentSyncState = new Set(story.mSyncActive);
    let deactivated = false;
    if (isLiveUSyncActive) {
      currentSyncState.delete(liveUSyncProviderId);
      deactivated = true;
    } else {
      currentSyncState.add(liveUSyncProviderId);
    }

    await setSync(story.mId, Array.from(currentSyncState), deactivated);
  };

  const handleAutoArchiveChange = async (isAutoArchive: boolean) => {
    const changedArchiveTime =
      isAutoArchive && story?.mPublishingAt ? getAutoArchiveTime(story?.mPublishingAt) : null;
    setArchiveTime(changedArchiveTime);
    await updateStory({
      variables: {
        input: {
          mId: story?.mId,
          ttl: changedArchiveTime,
        },
      },
    });
  };

  const getDaysLeftToArchive = () => {
    const archiveTimestamp = story.ttl ?? getAutoArchiveTime(story.mPublishingAt ?? '');
    const daysLeftToArchive = differenceInCalendarDays(
      new Date(archiveTimestamp! * 1000),
      new Date(),
    );
    return daysLeftToArchive;
  };

  const listItems = [
    <FontSizeContainer key="fontSize">
      <ToggleButtons
        list={fontSizeList}
        label="Editor Font Size"
        selectedValue={editorFontSize}
        onChange={setEditorFontSize}
      />
    </FontSizeContainer>,
    <ListItem
      key="show-timeline"
      maxHeight={36}
      text="Show Story Timeline"
      disabled={isPitch}
      iconChild={
        <Switch
          selected={!isPitch && isTimelineShown}
          onClick={toggleTimeline}
          disabled={isPitch}
        />
      }
    />,
    <ListItem
      key="show-description"
      maxHeight={36}
      text="Show Story Description"
      disabled={isDescriptionSettingsHidden}
      iconChild={
        <Switch
          selected={!isDescriptionSettingsHidden && isDescriptionShown}
          onClick={toggleDescription}
          disabled={isDescriptionSettingsHidden}
        />
      }
    />,
    <ListItem
      key="photo"
      maxHeight={36}
      text="Upload Cover Photo"
      firstChild={<Photo />}
      iconChild={
        <UploadMediaInput
          mId={story.mId}
          coverPhoto
          onComplete={uploadComplete}
          memberType={MemberTypeEnum.Story}
          inputRef={undefined}
        />
      }
      disabled={!canUpdateStory}
      excludeDivider
    />,
    <ListItem
      key="assets"
      maxHeight={36}
      text="Upload Media Assets"
      firstChild={<Asset />}
      iconChild={
        <UploadMediaInput
          mId={story?.mId ?? ''}
          onComplete={uploadComplete}
          memberType={MemberTypeEnum.Story}
          inputRef={undefined}
        />
      }
      disabled={!canUpdateStory}
    />,
    <ListItem
      key="synchronize"
      maxHeight={36}
      text="Synchronize"
      firstChild={<Refresh />}
      iconChild={
        <Switch
          selected={syncActive.length > 0}
          onClick={() => handleSynchronizeStateChange(syncActive)}
          disabled={!syncEnabled}
        />
      }
      disabled={!canUpdateStory || !syncEnabled}
      excludeDivider
    />,
    liveUProviderEnabled && (
      <ListItem
        key="liveu-synchronize"
        maxHeight={36}
        text="Sync with LiveU"
        firstChild={<Refresh />}
        iconChild={
          <Switch
            selected={isLiveUSyncActive}
            onClick={() => handleLiveUSynchronizeStateChange()}
            disabled={!syncEnabled}
          />
        }
        disabled={!canUpdateStory || !syncEnabled}
        excludeDivider
      />
    ),
    <ListItem
      key="auto-archive-story"
      maxHeight={36}
      text="Auto Archive Story"
      firstChild={<Archive />}
      textLabel="(Beta)"
      disabled={isPitch ?? [ARCHIVED_STORY, ARCHIVED_RESTRICTED_STORY].includes(story?.mType)}
      excludeDivider
      iconChild={
        <>
          {!isPitch && archiveTime !== null && (
            <Text variant="captionRegular" as="span">
              Days till <br />
              archive: {getDaysLeftToArchive()}
            </Text>
          )}
          <Switch
            selected={!isPitch && archiveTime !== null}
            onClick={handleAutoArchiveChange}
            disabled={
              !canUpdateStory ||
              isPitch ||
              [ARCHIVED_STORY, ARCHIVED_RESTRICTED_STORY].includes(story?.mType)
            }
          />
        </>
      }
    />,
  ];

  return (
    <IconWrapper>
      <Popover anchorEl={anchorEl} onClose={() => setAnchorEl(null)} position="bottom">
        <ItemsWrapper>{listItems}</ItemsWrapper>
      </Popover>
      <IconButton
        dark
        title="More options"
        usage="text"
        size={32}
        iconSize={18}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
      >
        <More className="skipOverride" />
      </IconButton>
    </IconWrapper>
  );
};

export default Dropdown;
