import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import isNull from 'lodash/isNull';

import initialValues from 'components/editor/constants/initialValues';
import { ActionTypesEnum } from 'components/editor/constants/types/actionTypes';
import variants from 'components/editor/constants/types/editorVariants';
import { UpdateInput } from 'components/editor/types';
import useToast from 'components/toast/useToast';
import UserContext from 'contexts/UserContext';
import useCreateAsset, { AssetInput } from 'hooks/useCreateAsset';
import useDebouncedCallback from 'hooks/useDebouncedCallback';
import useGetUser from 'hooks/useGetUser';
import useTextStorage from 'hooks/useTextStorage';
import { usePolicies } from 'store';
import { Asset, DailyNote } from 'types';
import { EditorValue } from 'types/editor';
import { DailyNoteInput, MemberTypeEnum } from 'types/graphqlTypes';
import { getAssetData, getFileAssetData } from 'utils/assetData';
import checkUserRight from 'utils/checkUserRight';

import useCreateDailyNote from '../api/useCreateDailyNote';
import useUpdateDailyNote from '../api/useUpdateDailyNote';
import { useCurrentDailyNote, useSelectedDailyNoteDate } from '../store';

import useGetUTCDate from './useGetUTCDate';

export interface SaveProps {
  dailyNote: DailyNote;
  editorValue: EditorValue | null;
  shouldReleaseLock: boolean;
}

const useDailyNoteEditor = (data?: DailyNote, mId = 'dailyNote') => {
  const [entity, setEntity] = useCurrentDailyNote();
  const { getUTCDateString } = useGetUTCDate();

  const [createAssetMutation] = useCreateAsset();

  const { updateDailyNote } = useUpdateDailyNote();
  const { createDailyNote } = useCreateDailyNote();

  const { getUserTitle } = useGetUser();
  const { mId: currentUserId } = useContext(UserContext);

  const [policies] = usePolicies();
  const [selectedDate] = useSelectedDailyNoteDate();

  const [content, setContent] = useState<EditorValue | null>(
    initialValues(variants.DAILYNOTE, undefined, undefined, false),
  );
  const [shouldResetSelection, setShouldResetSelection] = useState(false);
  const [writeLock, setWriteLock] = useState(false);
  const [readLock, setReadLock] = useState(false);
  const [lockedByUser, setLockedByUser] = useState<string>('Someone');
  const [isSavingContent, setIsSavingContent] = useState(false);
  const [locking, setLocking] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [restoringContent, setRestoringContent] = useState<EditorValue | null>(null);

  const entityRef = useRef<DailyNote | null>();
  const editorValueRef = useRef<EditorValue | null>(null);
  const writeLockRef = useRef(writeLock);
  const initialContentRef = useRef<EditorValue | null>(null);
  const { toast } = useToast();

  const utcDateString = getUTCDateString(selectedDate);
  const { mRefId, mContentKey } = entity || {
    mContentKey: `dailyNote/${utcDateString}/content.data"`,
    mRefId: utcDateString,
  };
  const {
    data: textContent,
    loading,
    refetch,
  } = useTextStorage(mContentKey, !mContentKey || writeLock);
  const memoizedTextContent = useMemo(() => textContent, [textContent]);
  const canUpdate = useMemo(() => checkUserRight(policies, 'dailyNote', 'access'), [policies]);

  const createAsset = useCallback(
    async (assetData: AssetInput) => {
      const asset = getAssetData(MemberTypeEnum.DailyNote, assetData);
      const result = await createAssetMutation(MemberTypeEnum.DailyNote, asset, false, undefined);

      return result;
    },
    [createAssetMutation],
  );

  const onAssetInsert = useCallback(
    async (file: File) => {
      const assetData = getFileAssetData(MemberTypeEnum.DailyNote, file);
      const sourceData = {
        mId: assetData.mId,
        mRefId: assetData.mRefId,
        src: '',
      };

      try {
        const result = await createAssetMutation(
          MemberTypeEnum.DailyNote,
          assetData,
          false,
          undefined,
        );
        const { createAssets: assets } = result.data as { createAssets: Asset[] };
        if (assets?.[0]) {
          sourceData.src = assets[0].mContentKey;
        }
      } catch (e) {
        toast({
          title: 'Error',
          description: `There was an error inserting the asset. ${JSON.stringify(e)}`,
          type: 'error',
        });
      }
      return sourceData;
    },
    [createAssetMutation, toast],
  );

  const onResetEditorValue = useCallback((newValue: EditorValue) => {
    if (newValue) {
      setContent({ ...newValue });
      editorValueRef.current = newValue;
      setShouldResetSelection(true);
    } else if (isNull(newValue)) {
      setContent(null);
      editorValueRef.current = null;
      setShouldResetSelection(true);
    }
  }, []);

  const setInitialValue = useCallback(
    (initialContent?: EditorValue) => {
      const initialValue = initialContent
        ? { ...initialContent }
        : initialValues(variants.DAILYNOTE, undefined, undefined, false);

      onResetEditorValue(initialValue);
      initialContentRef.current = initialValue;
    },
    [onResetEditorValue],
  );

  const onUpdateLock = useCallback(
    (lockedId?: string | null) => {
      if (lockedId) {
        setWriteLock(lockedId === currentUserId);
        setReadLock(lockedId !== currentUserId);
        if (lockedId !== currentUserId) {
          const newLockedByUser = getUserTitle(lockedId) ?? 'Someone';
          setLockedByUser(newLockedByUser);
        }
      } else {
        window.requestAnimationFrame(() => {
          setWriteLock(false);
          setReadLock(false);
          setLockedByUser('');
        });
      }
    },
    [currentUserId, getUserTitle],
  );

  const onForceUnlock = useCallback(async () => {
    try {
      setLocking(true);
      const input: DailyNoteInput = {
        mId: mId ?? 'dailyNote',
        mRefId: mRefId ?? utcDateString,
        locked: undefined,
        audit: {
          source: 'useDailyNoteEditor: onForceUnlock',
        },
      };
      const result = await updateDailyNote(input);
      setEntity(result);
      onUpdateLock(result?.locked);
      setLocking(false);
    } catch (e) {
      onUpdateLock(null);
      setLocking(false);
    }
  }, [mId, mRefId, onUpdateLock, setEntity, updateDailyNote, utcDateString]);

  const onLocking = useCallback(async () => {
    try {
      setLocking(true);
      const input: DailyNoteInput = {
        mId: mId ?? 'dailyNote',
        mRefId: mRefId ?? utcDateString,
        locked: currentUserId,
        audit: entity
          ? {
              source: 'useDailyNoteEditor: onLocking',
            }
          : undefined,
      };
      const result = entity ? await updateDailyNote(input) : await createDailyNote(input);
      setEntity(result);
      onUpdateLock(result?.locked);
      setLocking(false);
      return result?.locked;
    } catch (error) {
      onUpdateLock(null);
      setLocking(false);
      return null;
    }
  }, [
    createDailyNote,
    currentUserId,
    entity,
    mId,
    mRefId,
    onUpdateLock,
    setEntity,
    updateDailyNote,
    utcDateString,
  ]);

  const onFocusEditor = useCallback(async () => {
    if (canUpdate && !writeLock && !readLock && !locking) {
      const locked = await onLocking();
      if (locked) {
        refetch();
      }
    }
  }, [canUpdate, writeLock, readLock, locking, onLocking, refetch]);

  const saveAll = useCallback(
    async ({ dailyNote, editorValue, shouldReleaseLock = false }: SaveProps) => {
      if (
        loading ||
        !dailyNote ||
        !dailyNote.locked ||
        dailyNote.mRefId !== entityRef.current?.mRefId
      )
        return;

      setIsSavingContent(true);

      await updateDailyNote({
        mId: dailyNote.mId,
        mRefId: dailyNote.mRefId,
        content: JSON.stringify(editorValue),
        ...(shouldReleaseLock ? { locked: undefined } : { locked: dailyNote.locked }),
        audit: {
          source: 'useDailyNoteEditor: onSavePress',
          message: shouldReleaseLock ? 'releaseLock' : 'autosave',
        },
      });

      if (shouldReleaseLock) {
        onResetEditorValue(editorValueRef.current as EditorValue);
        initialContentRef.current = editorValueRef.current;
        onUpdateLock(null);
      }
      setIsSavingContent(false);
    },
    [loading, onResetEditorValue, onUpdateLock, updateDailyNote],
  );

  const onDebouncedSave = (props: SaveProps) => saveAll(props);
  const [debouncedSave, cancelDebouncedCallback] = useDebouncedCallback(onDebouncedSave, 15000);

  const onSavePress = useCallback(async () => {
    if (!entity) return;
    cancelDebouncedCallback();

    await saveAll({
      dailyNote: entity,
      editorValue: editorValueRef.current,
      shouldReleaseLock: true,
    });
  }, [cancelDebouncedCallback, entity, saveAll]);

  const onCancelPress = useCallback(async () => {
    if (!entity) return;
    setIsCancelled(true);
    cancelDebouncedCallback();
    const initialValue = isNull(initialContentRef.current)
      ? initialValues(variants.DAILYNOTE, undefined, undefined, false)
      : initialContentRef.current;

    onResetEditorValue(initialValue);
    await updateDailyNote({
      mId: entity.mId,
      mRefId: entity.mRefId,
      content: JSON.stringify(initialValue),
      isCancelEvent: true,
      audit: {
        source: 'useDailyNoteEditor: onCancelPress',
      },
    });

    onUpdateLock(null);
    setIsCancelled(false);
  }, [cancelDebouncedCallback, entity, onResetEditorValue, onUpdateLock, updateDailyNote]);

  const onCheckVersionRestorability = useCallback(async () => {
    if (!entity) return false;

    if (entity?.locked === currentUserId) return true;

    const locked = await onLocking();

    return locked === currentUserId;
  }, [currentUserId, entity, onLocking]);

  const onRestoreVersion = useCallback(async (newContent: EditorValue) => {
    setRestoringContent(newContent);
  }, []);

  const onChangeContent = useCallback(
    (newContent: EditorValue) => {
      if (writeLockRef.current && entityRef.current) {
        editorValueRef.current = newContent;
        void debouncedSave({
          dailyNote: entityRef.current,
          editorValue: newContent,
          shouldReleaseLock: false,
        });
      }
    },
    [debouncedSave],
  );

  const onEditorUpdate = (input: UpdateInput) => {
    const { type, payload } = input;
    if (!entity) return;

    if (type === ActionTypesEnum.CHANGE) onChangeContent(payload);
    if (type === ActionTypesEnum.COMMIT_UPDATE) {
      saveAll({
        dailyNote: entity,
        editorValue: editorValueRef.current,
        shouldReleaseLock: false,
      }).then(
        () => {},
        () => {},
      );
    }
    if (type === ActionTypesEnum.CREATE_ASSET) {
      const { asset } = payload;
      return createAsset(asset as AssetInput);
    }
    if (type === ActionTypesEnum.ASSET_INSERT) {
      const { file } = payload;
      return onAssetInsert(file);
    }
    return null;
  };

  const beforeunloadFn = useCallback(async (e?: BeforeUnloadEvent) => {
    e?.preventDefault();
    delete e?.returnValue;

    if (
      entityRef.current &&
      writeLockRef.current &&
      entityRef.current?.locked === currentUserId &&
      editorValueRef.current
    ) {
      setIsSavingContent(true);
      cancelDebouncedCallback();

      await updateDailyNote({
        mId: entityRef.current.mId,
        mRefId: entityRef.current.mRefId,
        content: JSON.stringify(editorValueRef.current),
        audit: {
          source: 'useDailyNoteEditor: beforeunloadFn',
        },
      });
      setIsSavingContent(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setEntity(data);
  }, [data, setEntity]);

  useEffect(() => {
    entityRef.current = entity;
    onUpdateLock(entity?.locked);
  }, [entity, onUpdateLock]);

  useEffect(() => {
    writeLockRef.current = writeLock;
  }, [writeLock]);

  useEffect(() => {
    if (entity && restoringContent && !loading && writeLockRef.current) {
      onResetEditorValue(restoringContent);
      saveAll({ dailyNote: entity, editorValue: restoringContent, shouldReleaseLock: false }).then(
        () => {},
        () => {},
      );
      setRestoringContent(null);
    }
  }, [entity, loading, onResetEditorValue, onSavePress, restoringContent, saveAll]);

  useLayoutEffect(() => {
    if (!mContentKey) {
      /** needed to override existing content for non existent entity */
      setInitialValue();
    } else if (memoizedTextContent && entityRef.current?.mContentKey === mContentKey) {
      /** update with new content */
      setInitialValue(memoizedTextContent);
    } else {
      setInitialValue();
    }
  }, [memoizedTextContent, mContentKey, setInitialValue]);

  return {
    loading,
    content,
    writeLock,
    readLock,
    lockedByUser,
    locking,
    isCancelled,
    isSavingContent,
    shouldResetSelection,
    canUpdate,
    onChangeContent,
    onEditorUpdate,
    onSavePress,
    onCancelPress,
    onFocusEditor,
    beforeunloadFn,
    refetchContent: refetch,
    lockedId: entity?.locked,
    cancelDebouncedCallback,
    onForceUnlock,
    onCheckVersionRestorability,
    onRestoreVersion,
  };
};

export default useDailyNoteEditor;
