/* eslint-disable @typescript-eslint/no-use-before-define */
import { useCallback } from 'react';

import useDateTimeUtils from 'hooks/useDateTimeUtils';

const ONE_DAY = 86400;
const HOURS_IN_DAY = 24;
const MINUTES_IN_HOUR = 60;
const SECONDS_IN_MINUTE = 60;

// Optimized time parsing regex
const TIME_PATTERNS = {
  withSeparator: /^(([^0-9]+))?([0-9]?[0-9])(\W+([0-5][0-9]?))?(\W+([0-5][0-9]))?(([^0-9]*))$/,
  withoutSeparator: /^(([^0-9]+))?([0-9]?[0-9])(([0-5][0-9]))?(([0-5][0-9]))?(([^0-9]*))$/,
};

const normalizeTimeString = (timeString: string): string => {
  // Quick normalization of time string
  let normalized = timeString.toLowerCase().replace(/[\s.]/g, '');

  // Add 'm' to a/p if missing
  if (normalized.endsWith('a') || normalized.endsWith('p')) {
    normalized += 'm';
  }

  return normalized;
};

const parseTimeComponents = (
  timeStr: string,
): {
  hour: number;
  minutes: number;
  seconds: number;
  meridiem: string;
} | null => {
  // Select appropriate regex based on separator
  const pattern = /\W/.exec(timeStr) ? TIME_PATTERNS.withSeparator : TIME_PATTERNS.withoutSeparator;

  const match = pattern.exec(timeStr);
  if (!match) return null;

  // Extract time components
  const hour = parseInt(match[3], 10);
  const minutes = match[5] ? parseInt(match[5], 10) : 0;
  const seconds = match[7] ? parseInt(match[7], 10) : 0;
  const meridiem = (match[2] || match[9] || '').trim();

  return { hour, minutes, seconds, meridiem };
};

const convertTo24HourFormat = (hour: number, meridiem: string): number => {
  // Handle 12-hour to 24-hour conversion
  if (hour > 12) return hour; // Already 24-hour format

  const isPm = meridiem.toLowerCase() === 'pm';

  if (hour === 12) {
    return isPm ? 12 : 0;
  }

  return hour + (isPm ? 12 : 0);
};

const stringToInt = (timeString: string | number | null): number | null => {
  // Early returns for non-string or empty inputs
  if (timeString == null) return null;
  if (typeof timeString !== 'string') return Number(timeString);

  // Special 'now' handling
  if (timeString.toLowerCase() === 'now') {
    return inputToInt(new Date());
  }

  // Normalize and validate input
  const normalizedTime = normalizeTimeString(timeString);
  if (!normalizedTime) return null;

  // Parse time components
  const components = parseTimeComponents(normalizedTime);
  if (!components) return null;

  const { hour, minutes, seconds, meridiem } = components;

  // Convert to 24-hour format
  const hours = convertTo24HourFormat(hour, meridiem);

  // Calculate total seconds
  let timeInt = hours * SECONDS_IN_MINUTE * MINUTES_IN_HOUR + minutes * SECONDS_IN_MINUTE + seconds;

  // Normalize time to within a day
  timeInt %= ONE_DAY;

  return timeInt;
};

const inputToInt = (input: string | number | Date): number | null => {
  if (input == null) return null;

  if (typeof input === 'number') return input;

  if (typeof input === 'string') {
    return stringToInt(input);
  }

  if (input instanceof Date) {
    return (
      input.getHours() * SECONDS_IN_MINUTE * MINUTES_IN_HOUR +
      input.getMinutes() * SECONDS_IN_MINUTE +
      input.getSeconds()
    );
  }

  return null;
};

const round = (seconds: number | null, interval: number): number | null => {
  if (seconds == null || typeof interval !== 'number') return seconds;

  const minuteInterval = interval * SECONDS_IN_MINUTE;
  const offset = seconds % minuteInterval;

  // Intelligent rounding logic
  return offset >= minuteInterval / 2 ? seconds + (minuteInterval - offset) : seconds - offset;
};

const useStringToTime = () => {
  const { format: fnsFormat } = useDateTimeUtils();

  const intToTime = useCallback((timeInt: number | null, format: 12 | 24 = 24): string | null => {
    if (timeInt == null) return null;

    const hours = Math.floor(timeInt / (SECONDS_IN_MINUTE * MINUTES_IN_HOUR)) % HOURS_IN_DAY;
    const minutes = Math.floor((timeInt / SECONDS_IN_MINUTE) % MINUTES_IN_HOUR);
    const seconds = timeInt % SECONDS_IN_MINUTE;

    const time = new Date(1971, 0, 2, hours, minutes, seconds, 0);

    if (isNaN(time.getTime())) return null;

    return format === 12 ? fnsFormat(time, 'hh:mma') : fnsFormat(time, 'HH:mm');
  }, []);

  const stringToTime = useCallback(
    (input: Date | string | number, interval = 15, format: 12 | 24 = 24): string | null =>
      intToTime(round(inputToInt(input), interval), format),
    [intToTime],
  );

  return { stringToTime };
};

export default useStringToTime;
