import { FormEvent, MouseEventHandler, useRef, useState } from 'react';
import { v4 } from 'uuid';

import { ReactComponent as ImportIcon } from 'assets/icons/systemicons/import.svg';
import { ConfirmDialog } from 'components/dialogs/CommonDialogs';
import useToast from 'components/toast/useToast';

import Button from './button';

interface Props {
  confirmMessage?: string;
  title?: string;
  disabled?: boolean;
  onImportAsync: (file: File) => Promise<void>;
  width?: string | number;
  height?: string | number;
  reportError?: (problem: string) => void;
}

function ImportButton({
  disabled,
  title,
  confirmMessage,
  onImportAsync,
  width,
  height,
  reportError,
}: Readonly<Props>) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { errorToast, toast } = useToast();
  const [confirming, setConfirming] = useState(false);
  // We key the file input element to restore it after it is used
  // (otherwise we get the input event if selecting the same file twice)
  const [fileInputKey, setFileInputKey] = useState(v4());

  const onImportClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (confirmMessage) {
      setConfirming(true);
    } else {
      inputRef?.current?.click();
    }
  };

  const onConfirmed = () => {
    setConfirming(false);
    inputRef?.current?.click();
  };

  const onImport = (event: FormEvent<HTMLInputElement>) => {
    const files = (event.target as HTMLInputElement).files;
    if (!files?.length) return;
    onImportAsync(files[0]).then(
      () => {
        toast({
          title: 'Import complete',
          description: `Successfully imported from ${files[0].name}`,
          type: 'success',
        });
        setFileInputKey(v4());
      },
      (e: unknown) => {
        const problem =
          `Failed importing from ${files[0].name}.` + (e instanceof Error ? `\n${e.message}` : '');
        if (reportError) {
          reportError(problem);
        } else {
          errorToast(e instanceof Error ? e : 'unknown error', problem);
        }
        setFileInputKey(v4());
      },
    );
  };

  return (
    <>
      <Button
        width={width}
        height={height}
        variant="outlined"
        usage="outlined"
        title={title}
        onClick={onImportClick}
        disabled={disabled}
      >
        <ImportIcon className="skipOverride" />
        Import
      </Button>
      <input
        key={fileInputKey}
        id="upload"
        accept="application/json"
        type="file"
        style={{ display: 'none' }}
        onInput={onImport}
        ref={inputRef}
        disabled={disabled}
      />
      <ConfirmDialog
        open={confirming}
        onClose={() => {
          setConfirming(false);
        }}
        onClick={onConfirmed}
        title={'Import?'}
        confirmLabel="Import"
        message={confirmMessage ?? 'Are you sure you want to import?'}
      />
    </>
  );
}

export default ImportButton;
