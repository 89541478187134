import { useCallback } from 'react';

import useDateTimeUtils from 'hooks/useDateTimeUtils';

const useDateSelectorUtils = () => {
  const { getISOWeek, addWeeks, isThisMonth, getISODay } = useDateTimeUtils();

  const getWeekNumbers = useCallback((time: string) => {
    const weekNumbers = [...Array(6).keys()].map((a) => getISOWeek(addWeeks(time, a)));
    return weekNumbers;
  }, []);

  const selectCalendarHeaderStyle = useCallback((index: number) => {
    const date = new Date();
    if (isThisMonth(date) && getISODay(date) === index + 1) return 'cellTextToday';
    if (index > 4) return 'cellTextHoliday';
    return 'cellText';
  }, []);

  const getCellColor = (index: number) => {
    const date = new Date();
    if (isThisMonth(date) && getISODay(date) === index + 1) return 'onSelected';
    if (index > 4) return 'datePickerHoliday';
    return 'mediumEmphasis';
  };

  return {
    getCellColor,
    getWeekNumbers,
    selectCalendarHeaderStyle,
  };
};

export default useDateSelectorUtils;
