import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { getDefaultLayoutSettings } from 'components/editMdfDialog/utils';
import Tooltip from 'components/tooltip';
import { useTreeChoiceDialog } from 'components/treeChoiceDialog/TreeChoiceDialog';
import { FieldValue } from 'types/forms/forms';

import { CellProps } from '../fields';
import { InlineTagWrapper } from '../multiplechoice/styled';

import TreeChoiceDefault from './TreeChoiceDefault';

function ChoiceCell({
  fieldModel,
  fieldSettings,
  setValue,
  value,
  disableEdit,
}: Readonly<CellProps>) {
  const [localValue, setLocalValue] = useState<FieldValue>(value);
  const [, openTreeChoiceDialog] = useTreeChoiceDialog();
  const parsed: string[] = useMemo(() => {
    return Array.isArray(localValue) ? (localValue as string[]) : [];
  }, [localValue]);
  const { hint } = fieldSettings ?? getDefaultLayoutSettings(fieldModel);

  useEffect(() => setLocalValue(value), [value]);

  const openTreeChoice = useCallback(() => {
    openTreeChoiceDialog({
      open: true,
      fieldLabel: fieldSettings?.label ?? fieldModel.fieldId,
      selected: parsed,
      setSelected: (v: string[] | []) => {
        setValue(v);
        setLocalValue(v);
      },
      treeAlternatives: fieldModel.treeAlternatives ?? [],
      optionListId: fieldModel.optionListId,
    });
  }, [parsed]);

  return (
    <Tooltip title={disableEdit ? 'You are not allowed to edit this field' : hint}>
      <InlineTagWrapper onClick={openTreeChoice} onKeyDown={() => {}}>
        <TreeChoiceDefault onClick={() => {}} choice={parsed} />
      </InlineTagWrapper>
    </Tooltip>
  );
}

export default memo(ChoiceCell);
