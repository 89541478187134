import styled from '@emotion/styled';

import { TabsList, TabsRoot, TabsTrigger } from 'lib/tabs';
import transientOptions from 'theme/helpers';

export const Root = styled(TabsRoot, transientOptions)<{ $direction?: 'horizontal' | 'vertical' }>`
  display: flex;
  height: 100%;
  flex-direction: ${({ $direction }) => ($direction === 'vertical' ? 'column' : 'row')};
`;

export const List = styled(TabsList)`
  display: flex;
  flex-shrink: 0;
  height: 40px;
  width: 100%;
`;

export const Trigger = styled(TabsTrigger)`
  all: unset;
  font-family: inherit;
  ${({ theme }) => theme.typography.dina.tabLabel};
  color: ${({ theme }) => theme.palette.dina.tabResting};
  width: auto;
  height: 100%;
  padding: 0 8px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.palette.dina.tabSelected};
  }
  &[data-state='active'] {
    color: ${({ theme }) => theme.palette.dina.tabSelected};
    box-shadow: inset 0 -2px 0 0 ${({ theme }) => theme.palette.dina.tabSelected};
  }
`;
