import { useCallback, useEffect, useState } from 'react';
import {
  type ColumnFiltersState,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  type GroupingState,
  OnChangeFn,
  type SortingState,
  useReactTable,
  type VisibilityState,
} from '@tanstack/react-table';

import { useLocalStorage } from 'hooks/useLocalStorage';

import { DinaTable, DinaTableOptions } from './types';

export interface StorageState {
  columnVisibility?: VisibilityState;
  grouping?: GroupingState;
  sorting?: SortingState;
  expanded?: ExpandedState;
}

function arraysHaveSameValues(array1: string[], array2: string[]) {
  if (array1.length !== array2.length) return false;
  return array1.toSorted().toString() === array2.toSorted().toString();
}

/** Generates a tanstack table instance */
export function useDataTable<TData>({
  storageKey = undefined,
  columns,
  initialColumnVisibility = {},
  ...props
}: DinaTableOptions<TData>) {
  // ─── Local Storage ───────────────────────────────────────────────────
  const [storedState, setStoredState] = useLocalStorage<StorageState>({
    key: storageKey,
    defaultValue: {},
    dispatchEvent: storageKey === 'storyhub-dayView',
  });

  // Use the stored state if it exists.
  const initialVisibility = storedState?.columnVisibility ?? initialColumnVisibility;

  // ─── Table States ────────────────────────────────────────────────────
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(initialVisibility);
  const [sorting, setSorting] = useState<SortingState>(storedState?.sorting ?? []);
  const [expanded, setExpanded] = useState<ExpandedState>(storedState?.expanded ?? true);
  const [grouping, setGrouping] = useState<GroupingState>(storedState?.grouping ?? []);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [rowSelection, setRowSelection] = useState({});

  // ─── Event Handlers ──────────────────────────────────────────────────

  // Visibility
  const onColumnVisibilityChange: OnChangeFn<VisibilityState> = useCallback(
    (updaterOrValue) => {
      const newVisibility =
        typeof updaterOrValue === 'function'
          ? (updaterOrValue as (old: VisibilityState) => VisibilityState)(columnVisibility)
          : updaterOrValue;

      setColumnVisibility(newVisibility);
      if (storageKey) setStoredState({ ...storedState, columnVisibility: newVisibility });
    },
    [columnVisibility, setStoredState, storageKey, storedState],
  );

  // Grouping
  const onGroupingChange: OnChangeFn<GroupingState> = useCallback(
    (updaterOrValue) => {
      const newGrouping =
        typeof updaterOrValue === 'function'
          ? (updaterOrValue as (old: GroupingState) => GroupingState)(grouping)
          : updaterOrValue;

      setGrouping(newGrouping);
      if (storageKey) setStoredState({ ...storedState, grouping: newGrouping });
    },
    [grouping, setStoredState, storageKey, storedState],
  );

  // Expanded
  const onExpandedChange: OnChangeFn<ExpandedState> = (updaterOrValue) => {
    const newExpanded =
      typeof updaterOrValue === 'function'
        ? (updaterOrValue as (old: ExpandedState) => ExpandedState)(expanded)
        : updaterOrValue;

    setExpanded(newExpanded);
    if (storageKey) setStoredState({ ...storedState, expanded: newExpanded });
  };

  // Sorting
  const onSortingChange: OnChangeFn<SortingState> = useCallback(
    (updaterOrValue) => {
      const newSorting =
        typeof updaterOrValue === 'function'
          ? (updaterOrValue as (old: SortingState) => SortingState)(sorting)
          : updaterOrValue;

      setSorting(newSorting);
      if (storageKey) setStoredState({ ...storedState, sorting: newSorting });
    },
    [setStoredState, sorting, storageKey, storedState],
  );

  // ─── Listener for Storage Change  ─────────────────────────────────────
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'storyhub-dayView') {
        const newValue = event.newValue;
        if (!newValue) return;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const changedStoredState = JSON.parse(newValue) as StorageState;
        const changedColumnVisibility = changedStoredState?.columnVisibility;
        if (changedColumnVisibility) {
          const changed = Object.keys(changedColumnVisibility).filter(
            (visibility) => changedColumnVisibility[visibility] === false,
          );
          const current = Object.keys(columnVisibility).filter(
            (visibility) => columnVisibility[visibility] === false,
          );
          if (!arraysHaveSameValues(changed, current)) {
            onColumnVisibilityChange(changedColumnVisibility);
          }
        }
        const changedSorting = changedStoredState?.sorting;
        if (changedSorting) {
          if (JSON.stringify(changedSorting) !== JSON.stringify(sorting)) {
            onSortingChange(changedSorting);
          }
        }
        const changedGrouping = changedStoredState?.grouping;
        if (changedGrouping) {
          if (JSON.stringify(changedGrouping) !== JSON.stringify(grouping)) {
            onGroupingChange(changedGrouping);
          }
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [
    columnVisibility,
    grouping,
    onColumnVisibilityChange,
    onGroupingChange,
    onSortingChange,
    sorting,
  ]);

  // ─── React Table ─────────────────────────────────────────────────────
  const table: DinaTable<TData> = useReactTable({
    columns,
    state: {
      sorting,
      columnVisibility,
      columnFilters,
      grouping,
      rowSelection,
      expanded,
    },
    // Column resize
    columnResizeMode: 'onChange',
    enableColumnResizing: true,
    // Row selection
    enableRowSelection: false,
    enableMultiRowSelection: false,
    onRowSelectionChange: setRowSelection,
    // Sorting
    onSortingChange: onSortingChange,
    getSortedRowModel: getSortedRowModel(),
    // Column visibility
    onColumnVisibilityChange: onColumnVisibilityChange,
    // Filtering
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getCoreRowModel: getCoreRowModel(),
    // Grouping
    onGroupingChange: onGroupingChange,
    autoResetExpanded: false,
    onExpandedChange: onExpandedChange,
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    // debugTable: true,
    enableKeyboardShortcuts: true,
    exportTable: () => {},
    ...props,
  });

  return { table };
}
