import { useEffect, useRef } from 'react';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { isEmpty } from 'lodash';

import useDateTimeUtils from 'hooks/useDateTimeUtils';

export interface UnreadChatCount {
  number: number;
  timeStamp: string; // Used for TTL purposes
  lastFocused?: string; // Used for unread line in chat
  firstUnreadMessageId?: string; // Used to draw a "new" line
}

const unreadChatNotification = atomWithStorage<Record<string, UnreadChatCount>>(
  'unreadStoryRundownChat',
  {},
);

export const useUnreadChatNotification = () => useAtom(unreadChatNotification);

/**
 * Runs once for every Dina load. Checks if there are any unread chat states older than
 * 7 days in local storage, and removes them.
 */
export const useCleanChat = () => {
  const { differenceInCalendarDays } = useDateTimeUtils();
  const [unreadChatState, setUnreadChatState] = useUnreadChatNotification();
  const hasFired = useRef(false);

  useEffect(() => {
    if (!hasFired.current && !isEmpty(unreadChatState)) {
      const copy = { ...unreadChatState };
      const today = new Date();
      let appliedChanges = false;
      for (const [memberId, chatState] of Object.entries(unreadChatState)) {
        const days = differenceInCalendarDays(today, chatState.timeStamp);
        if (days >= 7) {
          delete copy[memberId];
          appliedChanges = true;
        }
      }

      if (appliedChanges) {
        setUnreadChatState(copy);
      }

      hasFired.current = true;
    }
  }, [unreadChatState]);
};
