import { memo, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';

import { getDefaultLayoutSettings } from 'components/editMdfDialog/utils';
import Tooltip from 'components/tooltip';
import { useEditFieldValueDialog } from 'features/mdf/mdf-utils';
import { EmptyValueWrapper } from 'features/orderForm/styled';

import { CellProps } from '../fields';
import { isSingleArray } from '../utils';

import { ChoiceTags } from './ChoiceTags';

function MultipleChoiceCell({
  fieldModel,
  value,
  setValue,
  disableEdit,
  mdf,
  fieldSettings,
}: Readonly<CellProps>) {
  const [, showEditFieldDialog] = useEditFieldValueDialog();
  const { hint, colors = {} } = fieldSettings ?? getDefaultLayoutSettings(fieldModel);

  const showFieldEditor = useCallback(() => {
    if (!disableEdit) {
      showEditFieldDialog({
        startValue: value,
        fieldId: fieldModel.fieldId,
        headerText: `Edit ${fieldSettings?.label ?? fieldModel.fieldId}`,
        viewType: 'default',
        mdf,
        onConfirm: (v) => setValue(v),
      });
    }
  }, [showEditFieldDialog, setValue, disableEdit, mdf, fieldModel, fieldSettings, value]);

  if (isSingleArray(value) && !isEmpty(value)) {
    return (
      <Tooltip title={disableEdit ? 'You are not allowed to edit this field' : hint}>
        <span style={{ overflow: 'auto', width: '100%' }}>
          <ChoiceTags
            colors={colors}
            fieldModel={fieldModel}
            value={value}
            onClick={showFieldEditor}
          />
        </span>
      </Tooltip>
    );
  }
  return (
    <Tooltip title={disableEdit ? 'You are not allowed to edit this field' : hint}>
      <EmptyValueWrapper onClick={() => showFieldEditor()}>Set value</EmptyValueWrapper>
    </Tooltip>
  );
}

export default memo(MultipleChoiceCell);
