import { useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import range from 'lodash/range';
import PropTypes from 'prop-types';

import Scrollbar from 'components/scrollbar';
import Text from 'components/text/Text';
import { Flex, VStack } from 'layouts/box/Box';

// import { ButtonWrapper, RootWrapper, Years } from './styled';

const currentYear = new Date().getFullYear();

const StyledVStack = styled(VStack)`
  overflow: visible;
  height: 100%;
  width: 100%;
  border-left: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

const StyledFlex = styled(Flex)<{ selected: boolean }>`
  cursor: pointer;
  user-select: none;
  width: 100%;
  height: 28px;
  padding-inline: 8px;
  justify-content: flex-start;
  :hover {
    background-color: ${({ selected, theme }) =>
      selected ? theme.palette.dina.onSelected : theme.palette.dina.hoverOverlay};
  }
  ${({ selected, theme }) =>
    selected
      ? css`
          background-color: ${theme.palette.dina.onSelected};
          span {
            color: ${theme.palette.dina.whiteHighEmphasis};
          }
        `
      : ''}
`;

interface YearSelectorProps {
  year: number;
  onYearSelect: (year: number) => void;
}

const YearSelector = ({ year, onYearSelect }: Readonly<YearSelectorProps>) => {
  const years = useMemo(() => range(1990, Math.max(currentYear + 5, year), 1), [year]);

  useEffect(() => {
    const el = document.getElementById(`ys-${year}`);
    el?.scrollIntoView({ block: 'center' });
  }, [year]);

  return (
    <StyledVStack overflow="visible">
      <Scrollbar top={5} bottom={5}>
        {years.map((y) => (
          <StyledFlex
            id={`ys-${y}`}
            key={y}
            selected={year === y}
            onClick={() => {
              onYearSelect(y);
            }}
          >
            <Text
              variant="button"
              color="mediumEmphasis"
              style={{ width: '100%', textAlign: 'center' }}
            >
              {y}
            </Text>
          </StyledFlex>
        ))}
      </Scrollbar>
    </StyledVStack>
  );
};

YearSelector.propTypes = {
  year: PropTypes.number,
  onYearSelect: PropTypes.func,
};
YearSelector.defaultProps = {
  year: new Date().getFullYear(),
  onYearSelect: () => {},
};

export default YearSelector;
