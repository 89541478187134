import { memo, useCallback } from 'react';

import ArrowNavigator from 'components/arrowNavigator';
import { TimeVariant } from 'utils/planningViews';

import useTimeVariants from './useTimeVariants';

type TimeNavigatorProps = {
  timeVariant: TimeVariant;
  time?: Date | string;
  onChange: (date: string) => void;
  buttonHeight?: number;
  buttonWidth?: number;
  tooltipTitle?: { decrement?: string; increment?: string; reset?: string };
  disabled?: boolean | { decrement?: boolean; increment?: boolean; reset?: boolean };
  dark?: boolean;
};

function TimeNavigator({
  timeVariant,
  time = new Date().toISOString(),
  onChange,
  buttonHeight = 32,
  buttonWidth = 32,
  tooltipTitle = { reset: 'Today' },
  disabled = false,
  dark = false,
}: Readonly<TimeNavigatorProps>) {
  const { getTimeVariantFunc } = useTimeVariants();
  const handleChange = useCallback(
    (changedTime: Date) => {
      onChange(changedTime.toISOString());
    },
    [onChange],
  );

  const handleReset = useCallback(() => {
    handleChange(new Date());
  }, [handleChange]);

  const handleIncrement = useCallback(() => {
    handleChange(getTimeVariantFunc(timeVariant).increment(time, 1));
  }, [handleChange, timeVariant, time]);

  const handleDecrement = useCallback(() => {
    handleChange(getTimeVariantFunc(timeVariant).decrement(time, 1));
  }, [handleChange, timeVariant, time]);

  return (
    <ArrowNavigator
      handleDecrement={handleDecrement}
      handleIncrement={handleIncrement}
      handleReset={handleReset}
      tooltipTitle={tooltipTitle}
      buttonHeight={buttonHeight}
      buttonWidth={buttonWidth}
      disabled={disabled}
      dark={dark}
    />
  );
}

export default memo(TimeNavigator);
