import { useMemo } from 'react';
import { Text, View } from '@react-pdf/renderer';
import keyBy from 'lodash/keyBy';

import { getSubMdf, hasPermission, shouldShowField } from 'features/mdf/mdf-utils';
import useSettingsValue from 'hooks/useSettingsValue';
import { FieldTypeEnum } from 'types/graphqlTypes';

import { getMdfFieldComponent } from '../docs/utils';
import { styles } from '../styles';
import { OrderProps } from '../types';
import { getOrderStatusString } from '../utils/getOrderStatusString';

import { Block } from './Block';
import { MemberField } from './mdfBlocks';

export const Order = ({
  order,
  groups,
  getMemberTitle,
  getMember,
  index,
  subMdfs,
  subOrders,
  relationMembers,
  contacts,
  mdfsSeparated,
  optionLists,
}: OrderProps) => {
  const { metadata, mdf } = order;
  const subTypes = keyBy(subMdfs, (subMdf) => subMdf.label);
  const oddIndex = index % 2 !== 0;

  const [getSettingsValue] = useSettingsValue();
  const contactFieldsToPrint =
    getSettingsValue('app.contactFieldsToPrint', 'app.contactFieldsToPrint', 'all')?.toString() ??
    'all';

  const settingsMap = keyBy(mdf.views.default, (setting) => setting.fieldId);

  const visibleFields = mdf.fields?.filter((f) =>
    shouldShowField(
      f,
      settingsMap,
      settingsMap,
      false,
      hasPermission(mdf?.permissions?.read[f.fieldId], groups),
    ),
  );

  const backgroundColor = useMemo(() => {
    if (order.mStatus === 'closed') return '#E4F5E3';
    if (order.mStatus === 'in_progress') return '#FFF5BA';
    return oddIndex ? '#eee' : '#ddd';
  }, [oddIndex, order.mStatus]);

  const createdByUser = getMember(order.mCreatedById);
  const assigneeUser = getMember(order.mAssignee ?? '');

  return (
    <View style={{ padding: 4, display: 'flex', gap: 4, flexDirection: 'column' }}>
      <Text style={[styles.title, { fontSize: 14 }]}>Task {index + 1}</Text>
      <View
        style={{
          borderRadius: 4,
          backgroundColor,
        }}
      >
        <View style={styles.fieldWrapper}>
          <Text style={styles.fieldLabel}>Created By</Text>
          <View style={{ padding: 8 }}>
            <MemberField member={createdByUser} />
          </View>
          <View style={styles.horizontalLine} />
        </View>
        <View style={styles.fieldWrapper}>
          <Text style={styles.fieldLabel}>Assignee</Text>
          <View style={{ padding: 8 }}>
            {assigneeUser ? <MemberField member={assigneeUser} /> : <Text>No assignee</Text>}
          </View>
          <View style={styles.horizontalLine} />
        </View>
        <View style={styles.fieldWrapper}>
          <Text style={styles.fieldLabel}>Status</Text>
          <Text style={styles.fieldContent}>{getOrderStatusString(order.mStatus)}</Text>
          <View style={styles.horizontalLine} />
        </View>
        {visibleFields?.map((field, fIndex) => {
          if (field.type === FieldTypeEnum.subtype) {
            const subMdf = getSubMdf(field, metadata, subTypes);

            return (
              <View key={field.fieldId} style={{ margin: '8px' }}>
                <Block
                  fields={subMdf?.fields}
                  layoutSettings={subMdf?.views.default}
                  permissions={subMdf?.permissions}
                  metadata={metadata}
                  blockTitle={metadata[field.fieldId]?.toString()}
                  subMdfs={subMdfs}
                  relationMembers={relationMembers}
                  contacts={contacts}
                  mdfsSeparated={mdfsSeparated}
                  groups={groups}
                  optionLists={optionLists}
                />
              </View>
            );
          }

          return getMdfFieldComponent({
            field,
            settingsMap,
            metadata,
            showBottomDivider: fIndex !== visibleFields.length - 1,
            getMemberTitle,
            getMember,
            relationMembers,
            contacts,
            contactFieldsToPrint,
            subMdfs,
            groups,
            mdfsSeparated,
            optionLists,
          });
        })}

        {subOrders?.length && (
          <View style={{ padding: 8 }}>
            <View style={{ border: '1px solid #000', borderRadius: 4 }}>
              <View style={[styles.header, { padding: '4px 8px 0', marginBottom: 0 }]}>
                <Text style={[styles.title, { fontSize: 14 }]}>Sub Tasks</Text>
              </View>
              <View style={styles.horizontalLine} />

              {subOrders.map((subOrder, sOIndex) => {
                return (
                  <Order
                    key={subOrder.mId}
                    order={subOrder}
                    getMemberTitle={getMemberTitle}
                    groups={groups}
                    index={sOIndex}
                    getMember={getMember}
                    subMdfs={subMdfs}
                    relationMembers={relationMembers}
                    contacts={contacts}
                    mdfsSeparated={mdfsSeparated}
                    optionLists={optionLists}
                  />
                );
              })}
            </View>
          </View>
        )}
      </View>
    </View>
  );
};
