import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';

import { ReactComponent as ClearAll } from 'assets/icons/search/clear_all.svg';
import Popover from 'components/dialogs/PopoverBuilder';
import Tooltip from 'components/tooltip';
import { Color } from 'features/reusableStyled';
import { Box } from 'layouts/box/Box';
import { colors } from 'utils/constants/colors';

const StyledClearAll = styled(ClearAll)`
  width: 24px;
  cursor: pointer;
  :hover path {
    fill-opacity: 1;
  }
`;

const Trigger = styled(Popover.Trigger)`
  all: unset;
`;

const Content = styled(Popover.Content)`
  min-width: 100px;
`;

const ColorComponent = ({
  color,
  selected,
  onChoice,
}: {
  color: string;
  selected: boolean;
  onChoice: (value: string) => void;
}) => {
  const onClick = () => onChoice(color);
  return <Color $size={24} key={color} $color={color} onClick={onClick} $selected={selected} />;
};

interface Props {
  children: React.ReactElement;
  selectedColor?: string;
  onColorChoice: (val: string) => void;
  onClearColor: () => void;
}

export function PopoverColors({
  children,
  onColorChoice,
  onClearColor,
  selectedColor,
}: Readonly<Props>) {
  const [colorsOpen, setColorsOpen] = useState(false);

  const doColorChoice = useCallback(
    (color: string) => {
      onColorChoice(color);
      setColorsOpen(false);
    },
    [onColorChoice, setColorsOpen],
  );

  const onClearAll = () => {
    onClearColor();
    setColorsOpen(false);
  };

  const onOpenChange = (val: boolean) => setColorsOpen(val);

  return (
    <Popover open={colorsOpen} onOpenChange={onOpenChange}>
      <Trigger>{children}</Trigger>
      <Content>
        <Box container flexDirection="row" flexWrap="wrap" gap="4px" maxWidth="270px">
          <Tooltip title="Clear color">
            <StyledClearAll onClick={onClearAll} />
          </Tooltip>
          {Object.keys(colors).map((color) => (
            <ColorComponent
              key={color}
              color={color}
              selected={selectedColor === color}
              onChoice={doColorChoice}
            />
          ))}
        </Box>
      </Content>
    </Popover>
  );
}
