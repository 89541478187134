import useCustomDateTimeUtils from 'hooks/useCustomDateTimeUtils';
import { FeedItem as FeedItemInterface } from 'types/graphqlTypes';

import { FeedDate, FeedFooter, ItemDescription, ItemTitle } from '../styled';

const FeedItemFooter = ({ item }: { item: FeedItemInterface }) => {
  const { isoToLocaleShort } = useCustomDateTimeUtils();
  return (
    <FeedFooter>
      <div>{item.provider},</div>
      <FeedDate>{isoToLocaleShort(item.mPublishedAt)}</FeedDate>
    </FeedFooter>
  );
};

export const FeedItem = ({ item }: { item: FeedItemInterface }) => {
  return (
    <>
      <ItemTitle>{item.mTitle}</ItemTitle>
      <ItemDescription>{item.mDescription ?? 'No additional information'}</ItemDescription>
      <FeedItemFooter item={item} />
    </>
  );
};
