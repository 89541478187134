import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import UPDATE_GROUP_PERMISSIONS from 'operations/mutations/updateGroupPermissions';
import { Platform } from 'types';
import {
  GroupPermissionType,
  GroupPolicy,
  PermissionInput,
  UpdateGroupPolicyInput,
} from 'types/graphqlTypes';
import getIdentifier from 'utils/instance/getAccountIdentifier';

export function useFixPlatformPermissions() {
  const [updateGroupPermissions] = useMutation<{
    updateGroupPermissions: { groupPermissions: GroupPolicy[] };
  }>(UPDATE_GROUP_PERMISSIONS);

  const fix = useCallback(
    async (groupPermissions?: GroupPermissionType[], platforms?: Platform[]) => {
      if (!groupPermissions?.length || !platforms?.length) return groupPermissions;

      const payload: UpdateGroupPolicyInput[] = [];

      groupPermissions.forEach((groupPermission) => {
        const foundPlatformResource = groupPermission.mProperties?.policies?.find(
          (p) => p.resourceName === 'platform',
        );
        if (!foundPlatformResource) return;

        const newPermissions: PermissionInput[] = [];
        platforms.forEach((p) => {
          const { mTitle, mProperties } = p;
          const { platform, accounts = [] } = mProperties || {};
          accounts.forEach((account) => {
            const oldIdentifier = getIdentifier(mTitle, account.accountTitle);
            const oldIdentifierFound = foundPlatformResource.permissions?.find(
              (per) => per.action === oldIdentifier,
            );
            if (!oldIdentifierFound) return;

            const newIdentifier = getIdentifier(platform, account.accountTitle);
            const newIdentifierFound = foundPlatformResource.permissions?.find(
              (per) => per.action === newIdentifier,
            );
            if (newIdentifierFound) return; // already fixed
            newPermissions.push({ action: newIdentifier, access: oldIdentifierFound.access });
          });
        });

        if (newPermissions.length === 0) return groupPermissions;

        payload.push({
          mId: groupPermission.mId,
          mRefId: groupPermission.mRefId,
          mTitle: groupPermission.mTitle!,
          mProperties: {
            __typename: 'GroupPolicy',
            policies: [{ resourceName: 'platform', permissions: newPermissions }],
          },
        });
      });

      if (payload.length === 0) return groupPermissions;

      const result = await updateGroupPermissions({
        variables: {
          input: { payload },
        },
      });

      return groupPermissions.map(
        (groupPermission) =>
          result?.data?.updateGroupPermissions?.groupPermissions.find(
            ({ mRefId }) => mRefId === groupPermission.mRefId,
          ) || groupPermission,
      );
    },
    [updateGroupPermissions],
  );

  return fix;
}
