/* eslint-disable max-len */
import React, { useMemo } from 'react';

import { useGetTypedOptionList } from 'api/optionLists/useGetOptionList';
import Text from 'components/text/Text';
import LWSelect from 'features/orderForm/components/LWSelect';
import { MdfField } from 'types/graphqlTypes';

interface Props {
  setValue: (val: string) => void;
  value: string;
  fieldModel: MdfField;
  disabled?: boolean;
  style?: React.CSSProperties;
  allowNoValue?: boolean;
}

function LWChoiceField({
  setValue,
  fieldModel,
  value: alternativeValue,
  disabled,
  style,
  allowNoValue = false,
}: Readonly<Props>) {
  const { optionListId } = fieldModel;
  const { optionList } = useGetTypedOptionList(optionListId, 'choice');
  const alternatives = useMemo(
    () => optionList?.alternatives ?? fieldModel.alternatives ?? [],
    [optionList, fieldModel],
  );

  if (!alternatives?.length) {
    return (
      <Text variant="captionItalic" color="mediumEmphasis">
        No options
      </Text>
    );
  }

  return (
    <LWSelect
      disabled={disabled}
      style={style}
      value={alternativeValue}
      setValue={setValue}
      options={alternatives}
      allowNoValue={allowNoValue}
    />
  );
}

export default LWChoiceField;
