import { forwardRef } from 'react';

import { TabsContent } from 'lib/tabs';

import { List, Root, Trigger } from './styled';

interface Tab {
  title: string;
  id: string;
  content?: React.ReactElement;
}

interface TabsProps {
  tabs?: Tab[];
  children?: React.ReactNode;
  activeTab?: string;
  defaultTab?: string;
  setActiveTab?: (tab: string) => void;
  direction?: 'horizontal' | 'vertical';
}

function Tabs({
  children,
  defaultTab,
  activeTab,
  setActiveTab,
  direction = 'horizontal',
}: Readonly<TabsProps>) {
  return (
    <Root
      defaultValue={defaultTab}
      value={activeTab ?? defaultTab}
      $direction={direction}
      onValueChange={setActiveTab}
    >
      {children}
    </Root>
  );
}

interface TabListProps {
  children: React.ReactNode;
}

const TabsList = forwardRef<HTMLDivElement, TabListProps>(({ children, ...rest }, ref) => (
  <List ref={ref} {...rest}>
    {children}
  </List>
));

TabsList.displayName = 'TabsList';

function TabsTrigger({ title, id }: Readonly<Tab>) {
  return <Trigger value={id}>{title}</Trigger>;
}

const TabsNamespace = Object.assign(Tabs, {
  List: TabsList,
  Trigger: TabsTrigger,
  Content: TabsContent,
});

export default TabsNamespace;
