import { useCallback, useRef } from 'react';
import styled from '@emotion/styled';

import { ReactComponent as Add } from 'assets/icons/systemicons/add.svg';
import { ReactComponent as Delete } from 'assets/icons/systemicons/delete.svg';
import { IconButton } from 'components/buttons/icon';
import { PopoverColors } from 'components/editMdfDialog/components/PopoverColors';
import Input from 'components/input/Input';
import Scrollbar from 'components/scrollbar';
import Text from 'components/text';
import Tooltip from 'components/tooltip';
import { Color } from 'features/reusableStyled';
import { Center, Flex, HStack, VStack } from 'layouts/box/Box';
import {
  Priority,
  useChangedPriorities,
  usePriorities,
} from 'screens/main/components/header/navbar/settings/atomsTs';

const HeaderText = styled(Text)`
  width: 136px;
  padding-left: 8px;
`;

const Header = styled(Flex)`
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  height: 40px;
  justify-content: flex-start;
  padding: 0 12px;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.dina.dividerLight}`};
`;

const SubHeader = styled(HStack)`
  height: 32px;
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.dina.dividerLight}`};
`;

const PriorityWrapper = styled(HStack)`
  width: 100%;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.dina.dividerLight}`};
`;

const COLOR_HEX_REGEX = /^#([0-9a-f]{3}){1,2}$/i;

const ColorTextInput = ({
  color,
  id,
  onUpdate,
}: {
  color: string;
  id: string;
  onUpdate: (value: string, id: string) => void;
}) => {
  const ref = useRef<HTMLInputElement>(null);

  const updateValue = (value: string) => {
    if (COLOR_HEX_REGEX.exec(value) || value === '') {
      onUpdate(value, id);
    } else if (ref.current) {
      ref.current.value = color;
    }
  };

  const onBlur = () =>
    ref.current?.value &&
    (COLOR_HEX_REGEX.exec(ref.current.value) || ref.current.value === '') &&
    onUpdate(ref.current.value, id);

  return <Input ref={ref} initialValue={color} updateValue={updateValue} onBlur={onBlur} />;
};

const PriorityItem = ({
  priority,
  showButtons,
  insertPriority,
  removePriority,
  onUpdateLabel,
  onColorChoice,
  onClearColor,
  disableRemoveButton,
}: {
  priority: Priority;
  showButtons: boolean;
  insertPriority: () => void;
  removePriority: () => void;
  onUpdateLabel: (id: string, label: string) => void;
  onColorChoice: (newColor: string, id: string) => void;
  onClearColor: (id: string) => void;
  disableRemoveButton: boolean;
}) => {
  return (
    <PriorityWrapper>
      <HStack height="40px" flexGrow={1}>
        <Tooltip title="Select color">
          <Center width="40px" height="40px" flexShrink={0}>
            <PopoverColors
              onClearColor={() => onClearColor(priority.id)}
              onColorChoice={(newColor) => onColorChoice(newColor, priority.id)}
            >
              <Color $size={16} $color={priority.color} />
            </PopoverColors>
          </Center>
        </Tooltip>
        <Center width="96px" flexShrink={0} padding="0 4px">
          <ColorTextInput
            color={priority.color ?? ''}
            id={priority.id}
            onUpdate={(value) => onColorChoice(value, priority.id)}
          />
        </Center>
        <Center padding="0 4px" width="300px">
          <Input
            initialValue={priority.label}
            updateValue={(value) => onUpdateLabel(priority.id, value)}
          />
        </Center>
      </HStack>
      {showButtons && (
        <HStack padding="0 16px">
          <IconButton
            width={36}
            usage="text"
            onClick={removePriority}
            disableEnhancedIconOpacity
            disabled={disableRemoveButton}
            title="Remove priority"
          >
            <Delete />
          </IconButton>
          <IconButton
            width={36}
            usage="text"
            onClick={insertPriority}
            disableEnhancedIconOpacity
            title="Insert Priority"
          >
            <Add />
          </IconButton>
        </HStack>
      )}
    </PriorityWrapper>
  );
};

function PriorityDetails() {
  const [savedPriorities] = usePriorities();

  const [priorities, setPriorities] = useChangedPriorities();

  const insertPriority = useCallback(() => {
    const savedPriority = savedPriorities[priorities.length];
    setPriorities((pre) => [
      ...pre,
      savedPriority ?? { id: `prio${pre.length}`, label: `P${pre.length}`, color: '' },
    ]);
  }, [priorities.length, savedPriorities, setPriorities]);

  const removePriority = useCallback(() => {
    setPriorities((pre) => {
      if (pre.length === 1) return pre;
      return pre.slice(0, -1);
    });
  }, [setPriorities]);

  const onColorChoice = useCallback(
    (newColor: string, id: string) => {
      setPriorities((pre) =>
        pre.map((priority) => {
          if (priority.id !== id) return priority;
          return { ...priority, color: newColor };
        }),
      );
    },
    [setPriorities],
  );

  const onClearColor = useCallback(
    (id: string) => {
      setPriorities((pre) =>
        pre.map((priority) => {
          if (priority.id !== id) return priority;
          return { ...priority, color: '' };
        }),
      );
    },
    [setPriorities],
  );

  const onUpdateLabel = useCallback(
    (id: string, label: string) => {
      setPriorities((pre) =>
        pre.map((priority) => {
          if (priority.id !== id) return priority;
          return { ...priority, label };
        }),
      );
    },
    [setPriorities],
  );

  return (
    <VStack alignItems="flex-start" justifyContent="flex-start" width="100%" height="100%">
      <Header>
        <Text variant="h7" color="highEmphasis">
          Priority
        </Text>
      </Header>
      <SubHeader height="32px">
        <HeaderText variant="overline">Color</HeaderText>
        <Text variant="overline">Priority label</Text>
      </SubHeader>
      <VStack flexGrow={1} flexShrink={0} width="100%" padding="0 0 2px 0">
        <Scrollbar top={10} bottom={10}>
          {priorities?.map((p, index) => (
            <PriorityItem
              key={p.id}
              priority={p}
              showButtons={index === priorities.length - 1}
              disableRemoveButton={index === 0}
              insertPriority={insertPriority}
              removePriority={removePriority}
              onUpdateLabel={onUpdateLabel}
              onColorChoice={onColorChoice}
              onClearColor={onClearColor}
            />
          ))}
        </Scrollbar>
      </VStack>
    </VStack>
  );
}

export default PriorityDetails;
