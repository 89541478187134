import { useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useUnreadChatNotification } from 'store/chat';
import useGetMessages from 'api/useGetMessages';
import Divider from 'components/divider';
import LoadingIndicator from 'components/loadingIndicator';
import MessageInput from 'components/messageInput';
import UserContext from 'contexts/UserContext';
import useCreateMessage from 'hooks/useCreateMessage';
import useUpdateConvoReadAt from 'hooks/useUpdateConvoReadAt';
import { useUsers } from 'store';
import { useRightHidden, useRightSelection } from 'store/sidebar';
import conversationTypes from 'utils/constants/conversationTypes';
import useLogger from 'utils/useLogger';

import Messages from './messages-view';

const RootWrapper = styled('div')`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel2};
`;

const MessageInputWrapper = styled('div')`
  margin: 8px;
  z-index: 1;
`;

const MessageContainer = styled('div')`
  position: relative;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
  padding-bottom: 8px;
`;

const supportedConversationTypes = [
  conversationTypes.STORY,
  conversationTypes.PITCH,
  conversationTypes.RUNDOWN,
  conversationTypes.SPACE,
  conversationTypes.TEAM,
  conversationTypes.DEPARTMENT,
];

const MessagesContainer = ({ mId, convoType, replacementPanel }) => {
  const logger = useLogger('Story/Rundown Chat');
  const [unreadChatState, setUnreadChatState] = useUnreadChatNotification();
  const user = useContext(UserContext);
  const [users] = useUsers();
  const [createMessage] = useCreateMessage();
  const [updateConvoReadAt] = useUpdateConvoReadAt();
  const [rightSelection] = useRightSelection();
  const [rightHidden] = useRightHidden();

  const handleDeleteMessage = async (mRefId) => {
    await createMessage(mId, '', convoType, mRefId, 'DELETE');
  };

  const handleCreateMessage = async (newMessage) => {
    await createMessage(mId, JSON.stringify(newMessage), convoType);
  };

  const handleUpdateMessage = async (mRefId, mContent) => {
    await createMessage(mId, JSON.stringify(mContent), convoType, mRefId, 'UPDATE');
  };

  useEffect(() => {
    updateConvoReadAt(mId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mId]);

  const markAsRead = () => {
    if (unreadChatState[mId]) {
      setUnreadChatState((prevValue) => {
        const copy = { ...prevValue };
        delete copy[mId];
        return copy;
      });
    }
  };

  const {
    messages,
    initialLoading: loading,
    error,
    fetchMore,
    refetch,
    hasMore,
  } = useGetMessages(mId ?? '');

  useEffect(() => {
    if (!rightHidden && rightSelection === 'chat') {
      void refetch();
    }
  }, [rightSelection, rightHidden, refetch]);

  if (!mId || !supportedConversationTypes.includes(convoType)) return <div />;

  if (error) {
    logger.error(error);
    return <div />;
  }

  return (
    <RootWrapper>
      <MessageContainer>
        {loading && <LoadingIndicator />}
        <Messages
          messages={messages}
          userId={user.mId}
          hasMore={hasMore}
          onLoadMore={fetchMore}
          onDeleteMessage={handleDeleteMessage}
          onUpdateMessage={handleUpdateMessage}
          suggestedUsers={users}
        />
      </MessageContainer>
      <Divider />
      {replacementPanel ?? (
        <MessageInputWrapper>
          <MessageInput onFocus={markAsRead} onSend={handleCreateMessage} users={users} />
        </MessageInputWrapper>
      )}
    </RootWrapper>
  );
};

MessagesContainer.propTypes = {
  /** mId of the story/pitch/rundown */
  mId: PropTypes.string,
  /** supported conversation types (i.e. 'story', 'pitch', 'rundown', 'space') */
  convoType: PropTypes.string,
  /** Draws in place of the input field if so provided */
  replacementPanel: PropTypes.node,
};

MessagesContainer.defaultProps = {
  mId: '',
  convoType: '',
  replacementPanel: null,
};

export default MessagesContainer;
