import { gql } from '@apollo/client';

export const SEARCH = gql`
  query search($input: SearchItemInput) {
    searchItem(input: $input) {
      total
      nextToken
      items {
        mId
        highlight
        mAvatarKey
        mPlannedDuration
        mPriority
        mRefId
        mSecId
        metadata
        mTertRefId
        mFormId
        mdfId
        mType
        mTitle
        mDescription
        mResourceType
        mUpdatedAt
        mCreatedAt
        mCreatedById
        mPublishingAt
        mState
        itemType
        mIcon
        mAssignedMembers {
          mId
          mType
        }
        mProperties {
          ... on PlatformType {
            id
            platform
            platformKind
            account {
              id
              accountTitle
              accountId
            }
          }
        }
        totalInstances
      }
    }
  }
`;

export const SEARCH_STORY_HUB_DEFAULT = gql`
  query search($input: SearchItemInput) {
    searchItem(input: $input) {
      total
      nextToken
      items {
        mId
        highlight
        mRefId
        mTitle
        mDescription
        mCreatedAt
        mCreatedById
        mUpdatedAt
        mState
        mPublishingAt
        mPublishingEnd
        mPriority
        mStoryId
        mTemplateId
        mType
        mAssignedMembers {
          mId
          mType
        }
        mIcon
        metadata
        mdfId
        mProperties {
          ... on PlatformType {
            id
            platform
            platformKind
            account {
              id
              accountId
              accountTitle
            }
          }
        }
      }
    }
  }
`;

export const SEARCH_STORY_HUB_HOUR = gql`
  query search($input: SearchItemInput) {
    searchItem(input: $input) {
      total
      nextToken
      items {
        mId
        mRefId
        highlight
        mTitle
        mCreatedAt
        mCreatedById
        mUpdatedAt
        mState
        mPublishingAt
        mPlannedDuration
        mPriority
        mType
        mStoryId
        mAssignedMembers {
          mId
          mType
        }
        mIcon
        metadata
        mProperties {
          ... on PlatformType {
            id
            platform
            platformKind
            account {
              id
              accountId
              accountTitle
            }
          }
        }
      }
    }
  }
`;

export const SEARCH_STORY_HUB_INSTANCES = gql`
  query search($input: SearchItemInput) {
    searchItem(input: $input) {
      total
      nextToken
      items {
        mId
        mRefId
        highlight
        mTitle
        mState
        mPublishingAt
        mType
        mStoryId
        mTemplateId
        mIcon
        mProperties {
          __typename
          ... on PlatformType {
            id
            platform
            platformKind
            provider {
              id
              requestId
              state
              message
              embeddedEndpoint
              previewEndpoint
              updateEndpoint
            }
            account {
              id
              accountUrl
              accountTitle
              accountId
              accountRefId
              orderType
            }
          }
        }
        mAssignedMembers {
          mId
          mType
        }
        metadata
      }
    }
  }
`;

export const SEARCH_CONTACTS = gql`
  query searchContacts($input: SearchItemInput) {
    searchItem(input: $input) {
      total
      nextToken
      items {
        mId
        mRefId
        metadata
        mdfId
        mType
        mTitle
        mDescription
        mAvatarKey
      }
    }
  }
`;

export const SEARCH_STORY_HUB_AGGREGATION = gql`
  query search($input: SearchItemInput) {
    searchItem(input: $input) {
      total
      nextToken
      items {
        mId
        mRefId
      }
      aggregations {
        fieldName
        buckets {
          stringKey
          total
          items {
            mId
            highlight
            mRefId
            mTitle
            mCreatedAt
            mCreatedById
            mUpdatedAt
            mState
            mPublishingAt
            mPublishingEnd
            mStoryId
            mTemplateId
            mType
            mAssignedMembers {
              mId
              mType
            }
            metadata
            mProperties {
              ... on PlatformType {
                id
                platform
                platformKind
                account {
                  id
                  accountId
                  accountTitle
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const Queries = {
  default: SEARCH,
  storyHub: SEARCH_STORY_HUB_DEFAULT,
  storyHubInstances: SEARCH_STORY_HUB_INSTANCES,
  storyHubHour: SEARCH_STORY_HUB_HOUR,
  storyHubMonth: SEARCH_STORY_HUB_AGGREGATION,
  contacts: SEARCH_CONTACTS,
};
