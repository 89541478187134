import styled from '@emotion/styled/macro';
import { Typography } from '@material-ui/core';

export const HeaderWrapper = styled('div')`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid ${({ theme }) => theme.palette.dina.buttonBorderOutlined};
`;

export const ComponentWrapper = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const TextWrapper = styled(Typography)`
  ${({ theme }) => theme.typography.dina.h7};
  cursor: pointer;
  user-select: none;
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
`;

export const TimePickerComponentWrapper = styled('div')`
  display: flex;
`;

export const TimePickerWrapper = styled('div')`
  height: 28px;
  width: 65px;
  margin: 1px 0px 0px 2px;
`;
