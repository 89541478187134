import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Box } from '@material-ui/core';

import { useGetTypedOptionList } from 'api/optionLists/useGetOptionList';
import EditTreeChoiceAlternatives from 'components/editMdfDialog/components/EditTreeChoiceAlternatives';
import { createTree, TreeNode } from 'components/editMdfDialog/utils';
import LoadingIndicator from 'components/loadingIndicator';
import { TreeChoiceOptionList } from 'types/graphqlTypes';

interface OptionTreeProps {
  listId: string;
  label: string;
  changedOptions: readonly TreeNode[] | undefined;
  onChange: (
    updatedOptions: readonly TreeNode[],
    originalOptions: readonly TreeNode[],
    originalList: TreeChoiceOptionList,
  ) => void;
}

export function TreeOptionListComponent({
  listId,
  label,
  changedOptions,
  onChange,
}: Readonly<OptionTreeProps>) {
  const { optionList, loading, error } = useGetTypedOptionList(listId, 'treechoice', true);
  const origTree = useMemo(
    () => createTree(optionList?.treeAlternatives ?? []),
    [optionList?.treeAlternatives],
  );

  const options = changedOptions || origTree;
  const optionsRef = useRef(options);
  const updateOptions = useCallback(
    (arg: readonly TreeNode[] | ((prev: readonly TreeNode[]) => readonly TreeNode[])) => {
      const updatedOptions = typeof arg === 'function' ? arg(optionsRef.current) : arg;
      onChange(updatedOptions, origTree, optionList);
    },
    [onChange, origTree],
  );

  useEffect(() => {
    optionsRef.current = options;
  }, [options]);

  if (loading) {
    return (
      <div>
        <LoadingIndicator />
      </div>
    );
  }
  if (error) {
    return <div>Something went wrong: {error.message}</div>;
  }
  if (!optionList) {
    return <div>Failed to retrieve options</div>;
  }

  return (
    <Box maxWidth="600px" height="100%" padding="10px" maxHeight="100%">
      <EditTreeChoiceAlternatives
        tree={options}
        updateTree={updateOptions}
        isExternal={false}
        name={label}
      >
        <span />
      </EditTreeChoiceAlternatives>
    </Box>
  );
}
