import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { capitalize } from 'lodash';

import { ReactComponent as CloseIcon } from 'assets/icons/systemicons/close.svg';
import { Button } from 'components/buttons';
import CheckBox from 'components/checkbox';
import QuickSearch from 'components/command/QuickSearch';
import AssignMembers from 'components/createNewV3/assignMember';
import { LoadingButtonIndicator } from 'components/loadingIndicator';
import { DateRange } from 'components/mdfEditor/fields/date/DatePicker';
import { MdfEditor } from 'components/mdfEditor/MdfEditor';
import SplitBar from 'components/split';
import Tabs from 'components/tabs/contained';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import UserCtx from 'contexts/UserContext';
import { CommandToolbarProps } from 'features/command/command-types';
import { PrioritySelect } from 'features/storyHub/components/prioritySelect/PrioritySelect';
import useCheckUserRight from 'hooks/useCheckUserRight';
import { CreateStoryInput } from 'hooks/useCreateStory';
import useMetadata from 'hooks/useMetadata';
import { Box, HStack, VStack } from 'layouts/box/Box';
import { AssignedMember } from 'types';
import { Metadata } from 'types/forms/forms';
import { Mdf, MemberType, MemberTypeEnum, SearchItemTypeEnum } from 'types/graphqlTypes';

import DatePickerButton from './datePicker/scheduleDatePicker';

import {
  ButtonsWrapper,
  CheckBoxLabel,
  CheckBoxWithOptions,
  CheckBoxWrapper,
  CloseButton,
  ContentWrapper,
  Footer,
  Header,
  MetadataWrapper,
  RestrictedInfo,
  RestrictedText,
  WarningIcon,
  Wrapper,
} from './styled';

type TabValues = 'story' | 'pitch';

interface Tab {
  label: string;
  id: TabValues;
}

const tabs: Tab[] = [
  { label: 'Story', id: 'story' },
  { label: 'Pitch', id: 'pitch' },
];

const tabLabels = tabs.map((tab) => tab.label);

interface CheckboxWithLabelProps {
  selected: boolean;
  onClick: (val: boolean) => void;
  label: string;
  disabled?: boolean;
}

export const CheckboxWithLabel = ({
  selected,
  onClick,
  label,
  disabled,
}: CheckboxWithLabelProps) => {
  const onClickHandler = useCallback(() => onClick(!selected), [onClick, selected]);
  return (
    <CheckBoxWrapper onClick={onClickHandler} role="presentation">
      <CheckBox disabled={disabled} selected={selected} disableHoverEffect size={24} />
      <CheckBoxLabel disabled={disabled}>{label}</CheckBoxLabel>
    </CheckBoxWrapper>
  );
};

interface CreateNewProps {
  disableTypeSwitch: boolean;
  canCreate: boolean;
  doCreate: (props: CreateStoryInput) => Promise<void>;
  onCancel: () => void;
  hideRestrictedOption: boolean;
  loading: boolean;
  type: TabValues;
  setType: (val: TabValues) => void;
  mdf: Mdf | undefined;
  preselectedMember?: AssignedMember;
  preselectedMetadata?: {
    metadata: Metadata;
  };
  scheduleDate?: string;
  mTitle?: string;
  mContent?: string;
}

const pluralLabel: Record<TabValues, string> = {
  story: 'stories',
  pitch: 'pitches',
};

const pane1Style = {
  minWidth: '300px',
  maxWidth: '80%',
};

const pane2Style = {
  minWidth: '10%',
  maxWidth: '70%',
};

const quickSearchToolbarStates: CommandToolbarProps = {
  sortBy: 'createdAt',
  order: 'desc',
  mTypes: [SearchItemTypeEnum.pitch, SearchItemTypeEnum.story],
  rangeBy: null,
  statusFilter: [],
  mdfId: null,
  defaultMdfId: null,
  createdByIds: [],
  assignedIds: [],
  isFiltering: false,
};

const styleProps = { fullWidth: true };

const CreateNew = ({
  disableTypeSwitch,
  canCreate,
  doCreate,
  onCancel,
  loading,
  hideRestrictedOption,
  preselectedMember,
  preselectedMetadata,
  scheduleDate,
  mTitle,
  mContent,
  type,
  setType,
  mdf,
}: CreateNewProps) => {
  const [keepOpen, setKeepOpen] = useState(false);
  const [showCreated, setShowCreated] = useState(false);
  const [openStory, setOpenStory] = useState(true);
  const publishingTimes = { startDate: scheduleDate ?? new Date().toISOString(), endDate: null };
  const [priority, setPriority] = useState<string>();

  const { metadata, errorMap, errorTooltip, updateFieldValues } = useMetadata(
    mdf,
    preselectedMetadata?.metadata,
    'story_create',
  );

  // type useContext/UserContext
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const { attributes: user } = useContext(UserCtx) as unknown as { attributes: AssignedMember };
  const [title, setTitle] = useState('');
  const [restricted, setRestricted] = useState(false);
  const [scheduled, setScheduled] = useState<DateRange | null>(publishingTimes);
  const [submitting, setSubmitting] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState<AssignedMember[]>([
    ...(preselectedMember ? [preselectedMember] : []),
    { ...user, isCreator: true },
  ]);

  const [messageAssignees, setMessageAssignees] = useState('');
  const [checkUserRight] = useCheckUserRight();
  const canSelectRange = checkUserRight('feature', 'story-date-range');
  const canSelectMetadata = (mdf?.fields ?? []).length > 0;

  const titleErrorTooltip = !title?.trim() ? 'Title is required' : '';
  const disabledTooltip = !canCreate
    ? `You do not have permission to create ${pluralLabel[type]}`
    : '';

  const disableCreate = Boolean(errorTooltip ?? titleErrorTooltip ?? disabledTooltip);

  useEffect(() => {
    setTitle(mTitle?.trim() ?? '');
  }, []);

  const handleCreate = useCallback(() => {
    if (submitting || !title.trim().length) return;
    setSubmitting(true);
    const { startDate, endDate } = scheduled ?? { startDate: undefined, endDate: undefined };
    const assignedMembers = selectedMembers.map(({ mId, mType }) => ({ mId, mType }));
    if (title)
      void doCreate({
        type,
        keepOpen,
        openStory,
        mId: user.mId,
        mCreatedById: user.mId,
        mTitle: title.trim(),
        isRestricted: restricted,
        mPublishingAt: startDate,
        mPublishingEnd: endDate ?? undefined,
        mAssignedMembers: assignedMembers,
        messageAssign: messageAssignees,
        metadata: JSON.stringify(metadata),
        mContent: mContent ?? undefined,
        mPriority: type === 'story' ? priority : undefined,
      }).finally(() => {
        setSubmitting(false);
        setShowCreated(true);
        setTimeout(() => {
          setShowCreated(false);
        }, 2000);
      });
  }, [
    doCreate,
    keepOpen,
    mContent,
    messageAssignees,
    metadata,
    openStory,
    priority,
    restricted,
    scheduled,
    selectedMembers,
    submitting,
    title,
    type,
    user.mId,
  ]);

  const toggleRestriction = () => setRestricted((pre) => !pre);

  const onKeyDown = useCallback(
    (ev: React.KeyboardEvent) => {
      if (ev.key === 'Enter' && !disableCreate) {
        handleCreate();
      }
    },
    [disableCreate, handleCreate],
  );

  const onTabChange = useCallback(
    (label: string) => {
      const selectedTab = tabs.find((t) => t.label === label) as Tab;
      setType(selectedTab.id);
    },
    [setType],
  );

  const activeTab = type === 'story' ? tabLabels[0] : tabLabels[1];

  const onChangePriority = (priorityValue: string) => {
    setPriority(priorityValue === 'unset' ? undefined : priorityValue);
  };

  const priorityItem = useMemo(
    () => ({ mType: type as MemberTypeEnum, mPriority: priority } as MemberType),
    [priority, type],
  );

  const RestrictedToolTip = useMemo(
    () => (
      <RestrictedInfo>
        <WarningIcon className="skipOverride" />
        <RestrictedText>
          Only you and assigned members
          <br /> can access this {type}.
        </RestrictedText>
      </RestrictedInfo>
    ),
    [type],
  );

  const MainStoryLayout = useMemo(() => {
    return (
      <ContentWrapper>
        {!disableTypeSwitch && (
          <Tabs
            activeBackground={type === 'pitch' ? '#0BAA32' : undefined}
            tabs={tabLabels}
            setActiveTab={onTabChange}
            activeTab={activeTab}
            styleProps={styleProps}
          />
        )}
        <Text variant="overline" color="highEmphasis">
          {capitalize(type)} title
        </Text>
        <QuickSearch
          autoFocus
          doPreviewOnSelect
          allowClear
          searchString={title}
          setSearchString={setTitle}
          onKeyDown={onKeyDown}
          titleErrorTooltip={titleErrorTooltip}
          toolbarState={quickSearchToolbarStates}
        />
        <HStack flexWrap="wrap" gap="8px" margin="8px 0 0 0 ">
          <VStack gap="2px">
            <Text variant="overline" color="highEmphasis">
              Schedule date
            </Text>
            <DatePickerButton
              dateRange={scheduled}
              onDateRangeChange={setScheduled}
              canSelectRange={canSelectRange}
              disableUpdate={false}
              transparentBackground
            />
          </VStack>
          {type === 'story' && (
            <VStack gap="2px">
              <Text variant="overline" color="highEmphasis">
                Priority
              </Text>
              <PrioritySelect
                item={priorityItem}
                onChange={onChangePriority}
                height="32px"
                borderRadius="6px"
              />
            </VStack>
          )}
        </HStack>
        {!hideRestrictedOption && (
          <CheckBoxWithOptions>
            <Tooltip title={RestrictedToolTip}>
              <Box width="150px">
                <CheckboxWithLabel
                  selected={restricted}
                  disabled={false}
                  onClick={toggleRestriction}
                  label="Restrict access"
                />
              </Box>
            </Tooltip>
          </CheckBoxWithOptions>
        )}
        <CheckBoxWithOptions>
          <Text variant="overline" color="highEmphasis">
            Members
          </Text>
          <AssignMembers
            assignedMembers={selectedMembers}
            setAssignedMembers={setSelectedMembers}
            messageAssignees={messageAssignees}
            setMessageAssignees={setMessageAssignees}
            placeholder="Type an optional message to new members.."
          />
        </CheckBoxWithOptions>
      </ContentWrapper>
    );
  }, [
    disableTypeSwitch,
    type,
    onTabChange,
    activeTab,
    title,
    onKeyDown,
    titleErrorTooltip,
    scheduled,
    canSelectRange,
    priorityItem,
    hideRestrictedOption,
    RestrictedToolTip,
    restricted,
    selectedMembers,
    messageAssignees,
  ]);

  const MetadataLayout = useMemo(
    () => (
      <MetadataWrapper>
        {mdf && (
          <MdfEditor
            fields={mdf.fields}
            defaultLayoutSettings={mdf.views.default}
            layoutSettings={mdf.views.story_create}
            view="story_create"
            metadata={metadata}
            permissions={mdf.permissions}
            updateFieldValue={updateFieldValues}
            errorMap={errorMap}
          />
        )}
      </MetadataWrapper>
    ),
    [errorMap, mdf, metadata, updateFieldValues],
  );

  const toggleOpenStory = () => setOpenStory((pre) => !pre);
  const toggleKeepOpen = () => setKeepOpen((pre) => !pre);

  return (
    <Wrapper>
      <Header className="dragHandler">
        Create new {submitting && '- creating...'} {type}
        {showCreated && !submitting && '- created!'}
        <CloseButton onClick={onCancel}>
          <CloseIcon />
        </CloseButton>
      </Header>
      {canSelectMetadata ? (
        <SplitBar
          style={undefined}
          split={undefined}
          primary="first"
          pane1Style={pane1Style}
          pane2Style={pane2Style}
        >
          {MainStoryLayout}
          {MetadataLayout}
        </SplitBar>
      ) : (
        MainStoryLayout
      )}
      <Footer>
        <Box container margin="0 0 0 -3px">
          <Tooltip title="Keep dialog open to create more stories/pitches">
            <span>
              <CheckboxWithLabel
                selected={keepOpen}
                disabled={false}
                onClick={toggleKeepOpen}
                label="Keep open"
              />
            </span>
          </Tooltip>
          <Tooltip title="Opens new story/pitch in a tab">
            <Box margin="0 0 0 5px">
              <CheckboxWithLabel
                selected={openStory}
                disabled={false}
                onClick={toggleOpenStory}
                label="Open in tab"
              />
            </Box>
          </Tooltip>
        </Box>
        <ButtonsWrapper>
          <Button
            title={errorTooltip ?? titleErrorTooltip ?? disabledTooltip}
            ariaLabel={`Create ${type}`}
            disabled={Boolean(errorTooltip ?? titleErrorTooltip ?? disabledTooltip) || submitting}
            onClick={handleCreate}
            usage={type}
            variant="contained"
            size="sm"
            width={100}
          >
            {loading ? <LoadingButtonIndicator /> : `Create ${type}`}
          </Button>
        </ButtonsWrapper>
      </Footer>
    </Wrapper>
  );
};

export default memo(CreateNew);
