import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import useStyles from './footer-styles';
import scheduleOptions from '../../utils/scheduleOptions';
import useInstanceMdf from 'features/instance/hooks/useInstanceMdf';

const { UNPUBLISH, UPDATE, UNSCHEDULE, SCHEDULE } = scheduleOptions;

const getScheduleStyles = (publishNow, confirmPublish, selectedOption) => {
  if (selectedOption === UNSCHEDULE) return ['okButton', 'Unschedule'];
  if (selectedOption === SCHEDULE || selectedOption === UNPUBLISH) {
    const publishLabel = publishNow ? 'Publish Now' : 'Schedule';
    const label = selectedOption === SCHEDULE ? publishLabel : 'Unpublish';

    const isSchedule = !publishNow && selectedOption === SCHEDULE;
    return confirmPublish
      ? ['confirmPublishButton', `Confirm ${label}`]
      : [isSchedule ? 'scheduleButton' : 'publishButton', label];
  }
  if (selectedOption === UPDATE) return ['okButton', 'Update'];
  return ['scheduleButton', 'Re-publish'];
};

const Footer = ({
  handleCancel,
  isLinear,
  selectedOption,
  handleOK,
  publishNow,
  confirmPublish,
  shouldDisablePublish,
}) => {
  const classes = useStyles();
  const { errorMap } = useInstanceMdf();
  const [className, buttonText] = getScheduleStyles(publishNow, confirmPublish, selectedOption);
  const isErrorMapEmpty = Object.keys(errorMap).length === 0;

  return (
    <div className={classes.buttonContainer}>
      <Button className={classes.cancelButton} onClick={handleCancel}>
        Cancel
      </Button>
      {!isLinear && (
        <Button
          className={classes[className]}
          onClick={handleOK}
          disabled={!isErrorMapEmpty && selectedOption !== 'unschedule'}
        >
          {buttonText}
        </Button>
      )}
      {isLinear && (
        <Button
          className={classes.scheduleButton}
          onClick={handleOK}
          disabled={!shouldDisablePublish}
        >
          Schedule
        </Button>
      )}
    </div>
  );
};

Footer.propTypes = {
  handleCancel: PropTypes.func,
  isLinear: PropTypes.bool,
  selectedOption: PropTypes.string,
  handleOK: PropTypes.func,
  publishNow: PropTypes.bool,
  confirmPublish: PropTypes.bool,
  shouldDisablePublish: PropTypes.bool,
};

Footer.defaultProps = {
  handleCancel: () => {},
  isLinear: false,
  selectedOption: 'unschedule',
  handleOK: () => {},
  publishNow: false,
  confirmPublish: false,
  shouldDisablePublish: false,
};

export default Footer;
