/**
 * This filed contains various utility function to simplify navigating through a tree implemented
 * using UL and LI elements where all LI elements contains an INPUT element for editing the node
 * and possibly an UL (must be in the chain of last child element) if it has expanded children.
 * @module
 */

/**
 * Gets the closest LI element enclosing a given element
 * @param el The element to find the enclosing LI element for (or `null`)
 * @returns  The enclosing LI element or `null` if no such element (or if `el` is `null`)
 */
export function getEnclosingListItem(el: HTMLElement | null): HTMLLIElement | null {
  do {
    el = el?.parentElement ?? null;
  } while (el && el.tagName !== 'LI');
  return el as HTMLLIElement | null;
}

type HTMLDivOrLIElement = HTMLLIElement | HTMLDivElement;

/**
 * Focuses the INPUT element of an LI element that is related to a given LI element
 * @param li     The given LI element or `null` (nothing will be focused if `null`)
 * @param locate A function that can locate the related LI element from the given LI element
 *               (should return `null` if not able to locate it)
 * @param defer  Set to true to defer the location and focusing (in order to for items to be
 *               rendered in DOM)
 */
export function focusInputOfRelatedListItem(
  li: HTMLDivOrLIElement | null,
  locate: (li: HTMLDivOrLIElement) => Element | null,
  defer: boolean,
) {
  if (!li) return;
  const origin = li;
  function focus() {
    const el = locate(origin);
    if (el?.tagName !== 'LI') return;
    const input = el.getElementsByTagName('input').item(0);
    input?.focus();
    input?.select();
  }
  if (defer) {
    setTimeout(focus, 0);
  } else {
    focus();
  }
}

/**
 * Focuses the input of the next LI element
 * @param li    The LI element or `null` (nothing will be focused if `null`)
 * @param defer  Set to true to defer the location and focusing (in order to for items to be
 *               rendered in DOM)
 */
export function focusNextListItem(li: HTMLLIElement | null, defer: boolean) {
  focusInputOfRelatedListItem(li, (el) => el.nextElementSibling, defer);
}

function getContainedUList(el: HTMLDivOrLIElement): HTMLUListElement | null {
  let ul = el.lastElementChild;
  while (ul && ul.tagName !== 'UL') {
    ul = ul.lastElementChild;
  }
  return ul as HTMLUListElement | null;
}

/**
 * Focuses the input of the last direct child LI element
 * @param li    The parent LI element or `null` (nothing will be focused if `null`)
 * @param defer  Set to true to defer the location and focusing (in order to for items to be
 *               rendered in DOM)
 */
export function focusLastChildListItem(li: HTMLDivOrLIElement | null, defer: boolean) {
  focusInputOfRelatedListItem(li, (el) => getContainedUList(el)?.lastElementChild ?? null, defer);
}

/**
 * Focuses the input of the first direct child LI element
 * @param li    The parent LI element or `null` (nothing will be focused if `null`)
 * @param defer  Set to true to defer the location and focusing (in order to for items to be
 *               rendered in DOM)
 */
export function focusFirstChildListItem(li: HTMLDivOrLIElement | null, defer: boolean) {
  focusInputOfRelatedListItem(li, (el) => getContainedUList(el)?.firstElementChild ?? null, defer);
}

/**
 * Focuses the input of a sibling LI element or possibly of the parent LI element (to ensure
 * something is focused after deleting a tree node).
 * Will try to focus the sibling before if any then the sibling after if any and finally the parent
 * if allowed.
 * @param li          The LI of the tree node to be deleted.
 * @param allowParent Set to `true` to allow focusing the parent LI (set to `false` if LI to be
 *                    deleted is a root level LI)
 */
export function focusSiblingOrParentBeforeDelete(li: HTMLLIElement | null, allowParent: boolean) {
  focusInputOfRelatedListItem(
    li,
    (el) =>
      el.previousElementSibling ??
      el.nextElementSibling ??
      (allowParent ? getEnclosingListItem(li) : null),
    false,
  );
}
