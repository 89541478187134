import { useMemo, useState } from 'react';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';

import useDeleteUser from 'api/userManagement/useDeleteUser';
import useGetUsers from 'api/userManagement/useGetUsers';
import useUserStateChange from 'api/userManagement/useUserStateChange';
import { ReactComponent as SSOUser } from 'assets/icons/systemicons/aws_singleSignOn.svg';
import { ConfirmDialog, DeleteDialog } from 'components/dialogs/CommonDialogs';
import LoadingIndicator from 'components/loadingIndicator';
import SearchbarPositions from 'components/searchTable/constants/searchbarPositions';
import SearchTableView from 'components/searchTable/searchTable-view';
import useToast from 'components/toast/useToast';
import useCustomDateTimeUtils from 'hooks/useCustomDateTimeUtils';
import { getParseMetadata } from 'utils/getParsedMetadata';

import Actions from './components/actions';
import columns from './constants/columns';

import { BlankDiv, EmailWrapper, ErrorWrapper, RootWrapper } from './styled';

interface RowType {
  action: EmotionJSX.Element;
  rowId: string;
  searchByEmail: string;
  searchByGroup: string;
  email: EmotionJSX.Element;
  status: string;
  disabledAt: string | Date;
  searchByDisableAt: string;
  lastLogin: string | Date;
}

interface UserValues {
  id: string;
  mRefId: string;
  username: string;
  email: string;
  notListed: boolean;
}

function DisabledUserList() {
  const { isAtLeastOneYearBefore, isoToLocaleShort } = useCustomDateTimeUtils();
  const [open, setOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [values, setValues] = useState<UserValues | undefined>(undefined);

  const [updateUserStatus, loadingUpdate] = useUserStateChange();
  const [deleteUser, loadingDelete] = useDeleteUser();
  const { errorToast } = useToast();
  const { data, error, loading: fetching } = useGetUsers('disabled_user');

  const onConfirmRestore = async () => {
    const { id, username, mRefId } = values ?? {};
    if (id && username && mRefId)
      await updateUserStatus('restore', id, mRefId, username, setOpen, errorToast);
  };

  const onConfirmDelete = async () => {
    const { id, username, mRefId } = values ?? {};
    if (id && username && mRefId)
      await deleteUser(id, mRefId, username, setOpenDeleteDialog, errorToast);
  };

  const restoreText = `Are you sure you want to restore the user "${values?.email}"? 
  The user can use Dina after restore`;

  const deleteText = `Are you sure you want to delete the user "${values?.email}"? 
  The user will be deleted permanently and can not be restored`;

  const memoizedRows = useMemo(() => {
    const users = data?.getUsers;
    const rows: Array<RowType> = [];

    users?.forEach((item) => {
      const { mId, user, mRefId, mMetaData, mLastLogin, metadata, disabledAt } = item;
      if (!user || !mId || !mRefId || !mMetaData) return rows;

      const groups = mMetaData.find((attr) => attr.key === 'groups')?.value ?? '';
      const { status } = user;
      const parsedMetadata = getParseMetadata(metadata);
      const { username, notListed, email } = parsedMetadata ?? {};

      if (typeof email !== 'string' || typeof username !== 'string') return rows;
      const isHidden = Boolean(notListed);

      rows.push({
        rowId: mId,
        searchByEmail: email,
        searchByGroup: groups,
        email: (
          <EmailWrapper>
            {status === 'EXTERNAL_PROVIDER' ? <SSOUser /> : <BlankDiv />}
            {email}
          </EmailWrapper>
        ),
        status: status ?? 'NOT_SPECIFIED',
        lastLogin: mLastLogin
          ? isoToLocaleShort(mLastLogin, isAtLeastOneYearBefore(mLastLogin))
          : 'Not logged in yet',
        disabledAt: isoToLocaleShort(disabledAt, isAtLeastOneYearBefore(disabledAt)),
        searchByDisableAt: disabledAt ?? 'NOT_SPECIFIED',
        action: (
          <Actions
            setOpen={setOpen}
            setValues={setValues}
            setOpenDeleteDialog={setOpenDeleteDialog}
            isHidden={isHidden}
            mId={mId}
            mRefId={mRefId}
            username={username}
            email={email}
            status={status}
          />
        ),
      });
    });

    return rows;
  }, [data, setOpen, setOpenDeleteDialog, setValues]);

  if (data) {
    return (
      <RootWrapper>
        <SearchTableView
          usageType="settings"
          columns={columns}
          rows={memoizedRows}
          defaultSelect={false}
          showTitle={false}
          showSearchIcon
          searchbarPosition={SearchbarPositions.TOP}
          selectable={false}
          tabs={undefined}
          setSelectedItems={undefined}
          timelineItems={undefined}
          storyInformationForTimeline={undefined}
          resourceTimelineDropdownItems={undefined}
          selectedType={undefined}
          onChangeType={undefined}
          showAvailableResourceOnly={undefined}
          setShowAvailableResourceOnly={undefined}
          shouldDisableTypeFilter={undefined}
        />
        <ConfirmDialog
          open={open}
          onClose={() => setOpen(false)}
          onClick={onConfirmRestore}
          title="Restore User"
          message={restoreText}
          confirmLabel="Restore"
          loading={loadingUpdate}
        />
        <DeleteDialog
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          onClick={onConfirmDelete}
          title="Delete User"
          message={deleteText}
          confirmLabel="Delete"
          loading={loadingDelete}
        />
      </RootWrapper>
    );
  }

  if (fetching) return <LoadingIndicator />;
  if (error || !data) return <ErrorWrapper>Error loading disabled users</ErrorWrapper>;
  return null;
}

export default DisabledUserList;
