import { useEffect, useMemo, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { keyBy } from 'lodash';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { ReactComponent as ArrowDown } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_down.svg';
import Text from 'components/text/Text';
import { useGetMdfIcon } from 'features/mdf/useGetMdfIcon';
import { HStack } from 'layouts/box/Box';
import { useEditorCommands } from 'store';
import { Mdf, MemberTypeEnum } from 'types/graphqlTypes';
import { uniqueMdfFilter } from 'utils/mdf/utils';

import { StyledOptionWrapper, StyledPopper, StyledTextField } from './fields/choice/styled';

import { MdfDropdownWrapper, SelectWrapper } from './styled';

export interface EditorProps {
  value: string | null;
  systemOnly?: boolean;
  customOnly?: boolean;
  onSelect?: (selected: string | null) => void;
  onSelectMdf?: (selected: Mdf | null) => void;
  style?: React.CSSProperties;
  showEditIcon?: boolean;
  error?: boolean;
  disableClearable?: boolean;
  hideLabel?: boolean;
  placeholder?: string;
  header?: string;
}

/**
 * This component is a drop-down displaying the available MDF-schemas
 * @param props The {@link EditorProps} for the dropdown
 * @returns     The drop-down component
 */
export function MdfDropdown({
  value,
  onSelect,
  onSelectMdf,
  style,
  error,
  disableClearable,
  placeholder,
  hideLabel,
  systemOnly,
  customOnly,
  header = 'Select a schema',
}: Readonly<EditorProps>) {
  const getMdfIcon = useGetMdfIcon();
  const [selectedValue, setSelectedValue] = useState<Mdf | null>(null);
  const { mdfs, mdfsSeparated, mdfsByMType } = useGetMdfs({ all: true });
  const [editorCommands] = useEditorCommands();

  const availableMdfs = useMemo(() => {
    if (systemOnly) {
      const systemMdfs = Object.values(mdfsByMType).filter(uniqueMdfFilter);
      const blockMdfs: Mdf[] = [];
      const keyedByMdfId = keyBy(editorCommands, (cmd) => cmd.mSecId);
      const mdfsKeyed = keyBy(mdfs, (mdf) => mdf.id);
      for (const [mdfId, command] of Object.entries(keyedByMdfId)) {
        if (command.mResourceType === MemberTypeEnum.Mdf && mdfsKeyed[mdfId]) {
          blockMdfs.push(mdfsKeyed[mdfId]);
        }
      }
      return [...systemMdfs, ...mdfsSeparated.instances, ...blockMdfs];
    } else if (customOnly) {
      return mdfsSeparated.custom;
    }
    return mdfs;
  }, [systemOnly, mdfs, mdfsByMType, mdfsSeparated, editorCommands]);

  useEffect(() => {
    if (value !== selectedValue?.id) {
      const mdf = mdfs.find((m) => m.id === value);
      if (mdf) {
        setSelectedValue(mdf);
      } else {
        setSelectedValue(null);
      }
    }
  }, [value, mdfs, selectedValue]);

  const renderInput = (params: object) => (
    <StyledTextField
      {...params}
      variant="filled"
      error={error}
      placeholder={placeholder ?? 'Select schema'}
    />
  );

  const renderOption = (option: Mdf) => {
    const Icon = getMdfIcon(option);
    return (
      <StyledOptionWrapper $selected={value === option.id}>
        <HStack gap="6px" alignItems="center">
          {Icon && <Icon style={{ width: '16px' }} />} <div>{option.label}</div>
        </HStack>
      </StyledOptionWrapper>
    );
  };

  return (
    <MdfDropdownWrapper style={style}>
      {!hideLabel && <Text variant="overline">{header}</Text>}
      <SelectWrapper>
        <Autocomplete
          openOnFocus
          fullWidth
          blurOnSelect
          disableClearable={disableClearable}
          noOptionsText="No schemas available"
          selectOnFocus={false}
          options={availableMdfs}
          value={selectedValue}
          getOptionSelected={(mdf: Mdf, v: Mdf) => {
            return mdf.id === v.id;
          }}
          onChange={(_ev, mdf) => {
            if (onSelect) {
              onSelect(mdf?.id ?? null);
            }
            if (onSelectMdf) {
              onSelectMdf(mdf);
            }
          }}
          renderInput={renderInput}
          renderOption={renderOption}
          PopperComponent={StyledPopper}
          getOptionLabel={(mdf) => mdf?.label}
          popupIcon={<ArrowDown />}
        />
      </SelectWrapper>
    </MdfDropdownWrapper>
  );
}
