import { memo } from 'react';

import { InputField } from 'components/input/InputField';

import { FilterCommonTypes } from './types';

interface TextFieldFilterProps extends FilterCommonTypes {
  value?: string;
  propertyName: string;
  placeholder?: string;
}

/** Feed text field filter */
function TextFieldFilter({
  value = '',
  onChange,
  propertyName,
  placeholder = 'Search..',
}: Readonly<TextFieldFilterProps>) {
  const handleChange = (val: string) => {
    onChange({
      name: propertyName,
      value: val,
    });
  };

  return (
    <InputField
      height={32}
      placeholder={placeholder}
      value={value}
      onChange={(event) => handleChange(event.target.value)}
    />
  );
}

export default memo(TextFieldFilter);
