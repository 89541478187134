import { useCallback, useMemo } from 'react';
import { useContextMenu } from 'react-contexify';
import { TableMeta } from '@tanstack/react-table';

import { useUpdateBoardColumns } from 'api/gridAndKanban/useUpdateBoardColumns';
import useUpdateMetadata from 'api/useUpdateMetadata';
import useOpenMember from 'components/contextMenu/useOpenMember';
import useToast from 'components/toast/useToast';
import { ErrorMap, getErrorMap } from 'hooks/useMdfErrorMap';
import { Metadata, NewFieldValue } from 'types/forms/forms';
import { ParsedMemberType } from 'types/members';

interface UseGetTableMetaProps {
  boardId: string;
  groupId: string;
}

const canSave = (mdToSave: Metadata, errorMap: ErrorMap) => {
  for (const key of Object.keys(mdToSave)) {
    if (errorMap[key]) return false;
  }
  return true;
};

const emptyStateText = 'Drag stories, pitches, rundowns or instances here to get started';

/** Enriches the grid widget table with additional functionality. */
export function useGetTableMeta({ boardId, groupId }: UseGetTableMetaProps) {
  const { show } = useContextMenu({ id: `gridItemMenu-${boardId}` });
  const { removeMember } = useUpdateBoardColumns(boardId);
  const updateMetadata = useUpdateMetadata();
  const { errorToast } = useToast();
  const { openItem } = useOpenMember();

  // Show the context menu on right click.
  const onContextMenu = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>, member: ParsedMemberType) => {
      show({
        event,
        props: {
          member,
        },
      });
    },
    [show],
  );

  // Remove the member from the grid.
  const onRemove = useCallback(
    (member: ParsedMemberType) => {
      if (!member.mId || !groupId) return;
      void removeMember(member.mId, groupId);
    },
    [groupId],
  );

  /** Update individual metadata fields */
  const updateData = useCallback((member: ParsedMemberType, val: NewFieldValue[]) => {
    const updates: Metadata = {};
    for (const update of val) {
      updates[update.fieldId] = update.value;
    }

    const updatedErrorMap = member.validFieldMap
      ? getErrorMap(member.validFieldMap, { ...member.metadata, ...updates })
      : {};

    if (canSave(updates, updatedErrorMap) && member.mId && member.mRefId && member.mType) {
      updateMetadata(
        member.mId,
        member.mRefId,
        updates,
        member.metadata,
        member.mType,
        member.mdf?.id,
        member.mTitle,
      ).catch(errorToast);
    }
  }, []);

  const meta: TableMeta<ParsedMemberType> = useMemo(() => {
    return {
      rowMeta: {
        onContextMenu,
      },
      updateData,
      onRemove,
      onOpen: openItem,
      emptyStateText,
    };
  }, [onContextMenu, updateData]);

  return meta;
}
