import { memo, useCallback, useMemo } from 'react';

import { Color } from 'features/reusableStyled';
import { HStack } from 'layouts/box/Box';
import { Select, SelectPortal, SelectValue } from 'lib/select';
import { usePriorities } from 'screens/main/components/header/navbar/settings/atomsTs';
import useUpdatePriority from 'screens/storyHub/api/useUpdatePriority';
import { ParsedMemberType, Priority } from 'types';
import { MemberType } from 'types/graphqlTypes';
import { colors } from 'utils/constants/colors';
import { isStory } from 'utils/member/mTypes';

import { Content, Item, Trigger } from './styled';

const stopPropagation = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
  event.stopPropagation();

const SelectItemComponent = ({
  value,
  color,
  label,
}: {
  value: string;
  color?: string;
  label: string;
}) => (
  <Item value={value}>
    <HStack gap="12px">
      <Color $size={16} $color={color} />
      {label}
    </HStack>
  </Item>
);
const SelectItem = memo(SelectItemComponent);

const PrioritySelectComponent = ({
  item,
  onChange,
  width,
  height,
  borderRadius,
}: Readonly<{
  item: MemberType | ParsedMemberType;
  onChange?: (value: string) => void | Promise<void>;
  width?: string;
  height?: string;
  borderRadius?: string;
}>) => {
  const [priorities] = usePriorities();

  const priority = useMemo(() => {
    const foundPriority = priorities.find((p) => p.id === item.mPriority);
    return (
      foundPriority ??
      ({
        id: item.mPriority ?? '',
        label: item.mPriority ?? '',
        color: undefined,
      } satisfies Priority)
    );
  }, [item.mPriority, priorities]);

  return (
    <Select
      value={priority.id ?? undefined}
      onValueChange={onChange}
      defaultValue=""
      aria-label="priority-select"
    >
      <Trigger
        onClick={stopPropagation}
        onDoubleClick={stopPropagation}
        $backgroundColor={priority.color}
        $textColor={colors[priority.color ?? '']?.text ?? undefined}
        $width={width}
        $height={height}
        $borderRadius={borderRadius}
      >
        <SelectValue placeholder="Prio...">{priority.label}</SelectValue>
      </Trigger>
      <SelectPortal>
        <Content>
          {priorities.map((p) => (
            <SelectItem key={p.id} value={p.id} label={p.label} color={p.color} />
          ))}
          <SelectItem value="unset" label="No priority" />
        </Content>
      </SelectPortal>
    </Select>
  );
};

const PrioritySelect = memo(PrioritySelectComponent);
export { PrioritySelect };

const PriorityComponent = ({
  item,
  width,
  height,
  borderRadius,
}: Readonly<{
  item: MemberType | ParsedMemberType;
  width?: string;
  height?: string;
  borderRadius?: string;
}>) => {
  const { updatePriority } = useUpdatePriority();

  const onValueChange = useCallback(
    (priorityValue: string) => {
      if (!item.mPriority && priorityValue === 'unset') return;
      updatePriority(item, priorityValue === 'unset' ? null : priorityValue);
    },
    [item, updatePriority],
  );

  const isStoryType = useMemo(() => isStory(item.mType ?? ''), [item.mType]);
  if (!isStoryType) return null;

  return (
    <PrioritySelect
      item={item}
      onChange={onValueChange}
      width={width}
      height={height}
      borderRadius={borderRadius}
    />
  );
};

export default memo(PriorityComponent);
