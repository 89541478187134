import { useCallback } from 'react';

import { isDateRange } from 'api/search';
import useCustomDateTimeUtils from 'hooks/useCustomDateTimeUtils';
import { type AssignedMember } from 'types';
import { type FieldValue } from 'types/forms/forms';
import { FieldTypeEnum, LayoutSettings, MdfField } from 'types/graphqlTypes';

const useGetChipLabel = () => {
  const { isoToLocaleShort } = useCustomDateTimeUtils();

  const getPrettyValue = useCallback(
    (
      value: FieldValue,
      allMembersKeyed: Record<string, AssignedMember>,
      field:
        | (MdfField & {
            formId: string;
            settings: LayoutSettings;
          })
        | undefined,
      fieldId: string,
    ) => {
      switch (field?.type) {
        case FieldTypeEnum.date: {
          if (isDateRange(value)) {
            return `${isoToLocaleShort(value.startDate)} - ${isoToLocaleShort(value.endDate)}`;
          }
          break;
        }
        case FieldTypeEnum.multiplechoice: {
          if (Array.isArray(value)) {
            return value.join(', ');
          }
          break;
        }
        case FieldTypeEnum.treechoice: {
          if (Array.isArray(value)) {
            return value.join(' ▸ ');
          }
          break;
        }
        case FieldTypeEnum.relation:
          return 'relation';
        case FieldTypeEnum.user: {
          if (typeof value === 'string') {
            const member = allMembersKeyed[value];
            if (member) {
              return member;
            }
          }
        }
      }
      return `${fieldId}: ${value as string}`;
    },
    [],
  );

  const getChipLabel = useCallback(
    (
      key: string,
      value: FieldValue,
      allMembersKeyed: Record<string, AssignedMember>,
      field:
        | (MdfField & {
            formId: string;
            settings: LayoutSettings;
          })
        | undefined,
    ) => {
      const fieldId = `${field?.settings.label ?? key}`;
      if (field?.type === FieldTypeEnum.relation) {
        return fieldId;
      }
      return getPrettyValue(value, allMembersKeyed, field, fieldId);
    },
    [getPrettyValue],
  );

  return { getChipLabel };
};

export default useGetChipLabel;
