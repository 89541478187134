import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add_small.svg';
import { ReactComponent as DropdownIcon } from 'assets/icons/systemicons/arrows/arrow_drop_down_discreet_unboxed.svg';
import { ReactComponent as Close } from 'assets/icons/systemicons/close_small.svg';
import { Button, IconButton } from 'components/buttons';
import CreateStoryItems from 'components/createStoryItems/CreateStoryItems';
import Popover from 'components/popover';
import ContentTabDropdown from 'features/content/ContentTabDropdown';
import { moveArrayItem } from 'utils/arrayUtils';

import { useStoryMolecule } from './store/story';
import { useStoryPaneMolecule } from './store/storyPane';
import usePanes from './useStoryPanes';

import { TabsContainer, TabsList, TabsTrigger, Text, TriggerWrapper } from './styled';

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};

const transformOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const Tabs = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { storyPanes, addStoryPane, removeStoryPane } = usePanes();
  const { useStoryTabsValue } = useStoryMolecule();
  const { usePaneIndexValue } = useStoryPaneMolecule();
  const tabs = useStoryTabsValue();
  const paneIndex = usePaneIndexValue();
  const [localTabs, setLocalTabs] = useState([...tabs]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [contentAnchorEl, setContentAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { width } = ref.current?.getBoundingClientRect() ?? {};

  const maxVisibleTabs = useMemo(() => {
    if (!width) return 0;
    const visibleTabs = Math.floor(width / 110);
    if (visibleTabs >= tabs.length) setLocalTabs([...tabs]);
    return visibleTabs;
  }, [tabs, width]);

  useEffect(() => {
    setLocalTabs([...tabs]);
  }, [tabs, setLocalTabs]);

  useEffect(() => {
    if (maxVisibleTabs > 0 && maxVisibleTabs < tabs.length) {
      const currentPane = storyPanes[paneIndex];
      const selectedIndex = localTabs.findIndex((t) => t.label === currentPane.tab);
      if (selectedIndex >= maxVisibleTabs) {
        moveArrayItem(localTabs, selectedIndex, maxVisibleTabs - 1);
      }
    }
  }, [localTabs, maxVisibleTabs, paneIndex, storyPanes, tabs]);

  const handleDropDownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleContentDropDownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setContentAnchorEl(event.currentTarget);
  };

  const handleDropDownClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleContentDropDownClose = useCallback(() => {
    setContentAnchorEl(null);
  }, []);

  return (
    <TabsList>
      <CreateStoryItems key="createStoryItems" paneIndex={paneIndex} />
      <TabsContainer ref={ref}>
        {(localTabs.length > maxVisibleTabs ? localTabs.slice(0, maxVisibleTabs) : localTabs).map(
          (tab) => (
            <TabsTrigger key={tab.label} value={tab.label} asChild>
              <Text>
                {tab.label}{' '}
                {tab.label === 'content' && storyPanes[paneIndex].tab === 'content' && (
                  <IconButton
                    usage="text"
                    variant="discreet"
                    onClick={handleContentDropDownClick}
                    size={24}
                    iconSize={24}
                    title={'More options'}
                  >
                    <DropdownIcon />
                  </IconButton>
                )}
              </Text>
            </TabsTrigger>
          ),
        )}
        {localTabs.length > maxVisibleTabs && (
          <Button usage="text" width={50} height={24} onClick={handleDropDownClick}>
            +{localTabs.length - maxVisibleTabs}
            <DropdownIcon />
          </Button>
        )}
        <Popover
          id={paneIndex}
          open={!!anchorEl}
          onClose={handleDropDownClose}
          anchorEl={anchorEl}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
        >
          <TriggerWrapper>
            {(localTabs.length > maxVisibleTabs
              ? localTabs.slice(maxVisibleTabs, localTabs.length)
              : localTabs
            ).map((tab) => (
              <TabsTrigger
                key={tab.label + paneIndex}
                value={tab.label}
                onClick={handleDropDownClose}
                asChild
              >
                <Text>{tab.label}</Text>
              </TabsTrigger>
            ))}
          </TriggerWrapper>
        </Popover>
        <Popover
          id={paneIndex + 'content'}
          open={!!contentAnchorEl}
          onClose={handleContentDropDownClose}
          anchorEl={contentAnchorEl}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
        >
          <ContentTabDropdown onClose={handleContentDropDownClose} />
        </Popover>
      </TabsContainer>
      {storyPanes.length > 1 && (
        <IconButton
          title="Remove split pane"
          size={28}
          iconSize={12}
          usage="text"
          onClick={() => removeStoryPane(paneIndex)}
        >
          <Close />
        </IconButton>
      )}
      {storyPanes.length < 4 && storyPanes.length - 1 === paneIndex && (
        <IconButton
          title="Add new split pane"
          size={28}
          iconSize={14}
          usage="text"
          onClick={() => addStoryPane(storyPanes[storyPanes.length - 1].tab)}
        >
          <AddIcon />
        </IconButton>
      )}
    </TabsList>
  );
};

export default Tabs;
