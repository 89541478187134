/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useCallback, useState } from 'react';
import CsvDownload from 'react-csv-downloader';
import { ApolloClient, ApolloError, ApolloQueryResult, useApolloClient } from '@apollo/client';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { getInput, Queries } from 'api/search';
import { SearchParameters, SearchResult } from 'api/search/types';
import { ReactComponent as WarningIconComponent } from 'assets/icons/systemicons/warning.svg';
import { Button } from 'components/buttons';
import { LoadingButtonIndicator } from 'components/loadingIndicator';
import { CloseIcon } from 'components/orderFormDialog/styled';
import Text from 'components/text';
import useToast from 'components/toast/useToast';
import { CsvKeyValue } from 'features/command/command-types';
import { generateCSVFromMembers } from 'features/command/command-utils';
import { HStack } from 'layouts/box/Box';
import { MemberType, SearchItemInput } from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

import {
  ExportConfirmActions,
  ExportConfirmMessage,
  ExportConfirmWrapper,
  ExportDialogHeader,
  ExportWarningMessage,
} from './styled';

const MAX_PAGE_SIZE = 500; // page limit in search API is 500

interface SearchExportProps {
  searchParams: SearchParameters;
  total: number;
  onClose: () => void;
}

const getPaginatedQueries = (
  client: ApolloClient<object>,
  input: SearchItemInput,
  totalItems: number,
  pageSize = MAX_PAGE_SIZE,
) => {
  const promises = [];
  // chunk requests according to MAX_PAGE_SIZE
  for (let i = 0; i < Math.ceil(totalItems / pageSize); i++) {
    promises.push(
      client.query<SearchResult>({
        query: Queries.default,
        variables: {
          input: { ...input, from: i * pageSize },
        },
      }),
    );
  }
  return promises;
};

const getConfirmMessage = (total: number) => {
  if (total >= 10000) {
    return (
      <ExportWarningMessage>
        <WarningIconComponent />
        <Text variant="listItemLabel">
          The current search contains more that 10 000 items, only the the first 10 000 items will
          be exported.
        </Text>
      </ExportWarningMessage>
    );
  }
  return (
    <Text variant="listItemLabel">
      This action will export {total} items to a CSV (Comma separated values) file.
    </Text>
  );
};

const SearchExport = ({ searchParams, total, onClose }: SearchExportProps) => {
  const { mdfs } = useGetMdfs({ all: true });
  const [pending, setPending] = useState(false);
  const client = useApolloClient();
  const { errorToast, toast } = useToast();
  const logger = useLogger('SearchExport button');

  const handleCancel = () => {
    onClose();
  };

  const onError = (err: unknown) => {
    errorToast(err, 'Error exporting data');
    if (err instanceof ApolloError) {
      logger.error('Error exporting data', err);
    } else {
      logger.error(`Error exporting data: ${err}`);
    }
    setPending(false);
  };

  const handleGetCsvData = useCallback(async (): Promise<CsvKeyValue[]> => {
    setPending(true);

    const {
      searchString,
      searchableKey,
      toolbarState,
      metadataFilter = {},
      mdfId,
      forceMetadataMTypes,
    } = searchParams;

    const input = getInput(
      searchString,
      searchableKey,
      toolbarState,
      MAX_PAGE_SIZE,
      metadataFilter,
      mdfId,
      forceMetadataMTypes,
      undefined,
      undefined,
    );

    const promises: Promise<ApolloQueryResult<SearchResult>>[] = getPaginatedQueries(
      client,
      input,
      total,
    );

    const results = await Promise.all(promises);

    const items: MemberType[] = results.flatMap((page) => {
      const { data, error } = page;
      if (error) {
        onError(error);
        return [];
      }
      return data?.searchItem?.items ?? [];
    });

    const details = generateCSVFromMembers(items, mdfs);
    toast({
      title: 'Export successfull',
      type: 'success',
      description: `${details.length} items exported as CSV`,
    });

    setPending(false);
    onClose();

    return details;
  }, [client, mdfs, onClose, searchParams, toast, total]);

  return (
    <ExportConfirmWrapper>
      <ExportDialogHeader>
        <Text variant="h7">Export to CSV</Text>
        <CloseIcon onClick={handleCancel} />
      </ExportDialogHeader>
      <ExportConfirmMessage>{getConfirmMessage(total)}</ExportConfirmMessage>
      <ExportConfirmActions>
        {!pending && (
          <Button
            variant="outlined"
            onClick={handleCancel}
            autoWidth
            usage="outlined"
            height={32}
            autoFocus
          >
            Cancel
          </Button>
        )}
        <CsvDownload
          extension=".csv"
          separator=";"
          disabled={pending}
          datas={handleGetCsvData}
          handleError={onError}
          filename={`Dina-export-${new Date().toISOString()}.csv`}
          style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
        >
          {pending ? (
            <Button height={32} autoWidth usage="text" variant="outlined" disabled>
              <HStack>
                <LoadingButtonIndicator
                  inline
                  style={{
                    marginRight: '8px',
                  }}
                />
                <Text variant="listItemLabel">Exporting data</Text>
              </HStack>
            </Button>
          ) : (
            <Button height={32} autoWidth usage="cta">
              <Text variant="listItemLabel">Export to CSV</Text>
            </Button>
          )}
        </CsvDownload>
      </ExportConfirmActions>
    </ExportConfirmWrapper>
  );
};

export default SearchExport;
