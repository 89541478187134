import React, { memo, useCallback, useEffect, useRef } from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import sortBy from 'lodash/sortBy';

import { ReactComponent as Add } from 'assets/icons/systemicons/plus_small.svg';
import { ReactComponent as SortDown } from 'assets/icons/systemicons/sort_down.svg';
import { Button, ExportButton } from 'components/buttons';
import ImportButton from 'components/buttons/ImportButton';
import OptionListItem from 'components/editMdfDialog/components/EditAlternativeItem';
import Scrollbar from 'components/scrollbar';
import { createAlternative } from 'features/mdf/mdf-utils';
import { HStack, VStack } from 'layouts/box/Box';
import { Alternative } from 'types/graphqlTypes';

import { ButtonContainer, ChoiceOptionsWrapper, FakeInput } from '../styled';
import { getAlternativesFromJson } from '../utils';

interface Props {
  readonly options: Alternative[];
  readonly updateOptions: React.Dispatch<React.SetStateAction<Alternative[]>>;
  readonly isExternal: boolean;
  readonly name: string;
  children: React.ReactElement;
}

function EditAlternatives({ options, updateOptions, isExternal, name, children }: Readonly<Props>) {
  const disableReason = isExternal
    ? 'External lists can only be edited from the list itself'
    : undefined;

  const selfRef = useRef<HTMLDivElement | null>(null);
  const optionsRef = useRef(options);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  /**
   * Eventually focuses the label in the row at a given index. (Defers it slightly in case the row
   * is not yet there)
   * @param index The zero based index of the row to be deleted or a negative number to count from
   *              end (-1 focuses the last row)
   */
  function deferFocusRowLabel(index = -1) {
    setTimeout(() => {
      const listItems = selfRef.current?.getElementsByTagName('LI');
      if (listItems?.length) {
        if (index < 0) {
          index = listItems.length + index;
        }
        if (index < 0 || index >= listItems.length) return;
        const listItem = listItems.item(index) as HTMLLIElement;
        const inputEl = (listItem.getElementsByTagName('INPUT')?.[0] ??
          null) as HTMLInputElement | null;
        inputEl?.focus();
        inputEl?.select();
      }
    }, 0);
  }

  const isDuplicateValue = useCallback(
    (value: string, index: number) => {
      const predicate = (alt: Alternative) => alt.value === value;
      const pos = optionsRef.current.findIndex(predicate);
      if (pos < 0) return false;
      if (pos !== index) return true;
      const lastPos = optionsRef.current.findLastIndex(predicate);
      return lastPos > pos;
    },
    [optionsRef],
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      const oldIndex = options.findIndex((item) => item.id === active.id);
      const newIndex = options.findIndex((item) => item.id === over?.id);
      const updatedOrder = arrayMove(options, oldIndex, newIndex);
      updateOptions(updatedOrder);
    },
    [options, updateOptions],
  );

  const onImportAsync = useCallback(
    async (file: File) => {
      const fileText = await file.text();
      const alternatives = getAlternativesFromJson(JSON.parse(fileText));
      updateOptions(alternatives);
    },
    [options],
  );

  function createNewUniqueOption(startSuffix?: number) {
    let suffix = startSuffix ?? optionsRef.current.length;
    let option: Alternative;
    do {
      option = createAlternative('value', ++suffix);
    } while (isDuplicateValue(option.value, -1));
    return option;
  }

  const addOption = useCallback(() => {
    updateOptions((prevValue) => {
      return [...prevValue, createNewUniqueOption()];
    });
    deferFocusRowLabel();
  }, [updateOptions]);

  const removeOption = useCallback(
    (alternative: Alternative) => {
      updateOptions((prevValue) => {
        const index = prevValue.findIndex((alt) => alt.id === alternative.id);
        const result = index >= 0 ? prevValue.toSpliced(index, 1) : prevValue;
        if (index >= 0 && result.length) {
          deferFocusRowLabel(Math.min(index, result.length - 1));
        }
        return result;
      });
    },
    [updateOptions],
  );

  const doSortAlphabetically = useCallback(
    (opt: Alternative[]) => {
      updateOptions(sortBy(opt, ['label']));
    },
    [updateOptions],
  );

  const handleOptionChange = useCallback(
    (index: number, updater: (alt: Alternative) => Alternative, addAfter: boolean) => {
      updateOptions((prevValue) => {
        const updated = updater(prevValue[index]);
        return prevValue.toSpliced(
          index,
          1,
          ...(addAfter ? [updated, createNewUniqueOption(index)] : [updated]),
        );
      });
      if (addAfter) {
        deferFocusRowLabel(index + 1);
      }
    },
    [updateOptions],
  );

  useEffect(() => {
    optionsRef.current = options;
  }, [options]);
  return (
    <VStack ref={selfRef} height="100%" overflow="hidden" width="100%" maxHeight="100%">
      <HStack
        justifyContent="space-between"
        flex="0 0 auto"
        width="100%"
        padding="0 0 10px"
        alignItems="end"
      >
        {children}
        <ButtonContainer>
          <ExportButton
            width={90}
            height={32}
            getExportData={() => ({
              fileName: `${name}-options.json`,
              content: JSON.stringify(options.map((a) => ({ label: a.label, value: a.value }))),
            })}
          />
          <ImportButton
            title={disableReason ?? 'Import options from file'}
            confirmMessage={
              options.length
                ? 'Existing options will be overwritten. Are you sure you want to import?'
                : undefined
            }
            onImportAsync={onImportAsync}
            disabled={isExternal}
            width={90}
            height={32}
          />
          <Button
            width={90}
            height={32}
            variant="outlined"
            usage="outlined"
            title={disableReason ?? 'Sort options'}
            onClick={() => doSortAlphabetically(options)}
            disabled={isExternal}
          >
            <SortDown className="skipOverride" />
            Sort
          </Button>
          <Button
            width={90}
            height={32}
            variant="outlined"
            usage="outlined"
            onClick={addOption}
            title={disableReason ?? 'Add option'}
            disabled={isExternal}
          >
            <Add className="skipOverride" />
            Add
          </Button>
        </ButtonContainer>
      </HStack>
      <ChoiceOptionsWrapper>
        <Scrollbar>
          {isExternal ? (
            <VStack>
              {options.map((alt) => (
                <HStack key={alt.id} width="100%" gap="20px" margin="4px 0 0 0 ">
                  <FakeInput>{alt.label}</FakeInput>
                  <FakeInput>{alt.value}</FakeInput>
                </HStack>
              ))}
            </VStack>
          ) : (
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext items={options} strategy={verticalListSortingStrategy}>
                {options.map((alt, i) => (
                  <OptionListItem
                    key={alt.id}
                    index={i}
                    option={alt}
                    isDuplicateValue={isDuplicateValue}
                    onChange={handleOptionChange}
                    onRemove={removeOption}
                  />
                ))}
              </SortableContext>
            </DndContext>
          )}
        </Scrollbar>
      </ChoiceOptionsWrapper>
    </VStack>
  );
}

export default memo(EditAlternatives);
