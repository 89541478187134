import { useContext, useState } from 'react';
import { FetchResult, useMutation } from '@apollo/client';
import { debounce, isEmpty, keyBy } from 'lodash';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { ReactComponent as ArrowRight } from 'assets/icons/systemicons/arrows/disclosurearrow_right.svg';
import { useSelectedDate } from 'components/createStoryItems/store';
import CategorizedMenu from 'components/menu/createInstanceMenu/MenuItem/CategorizedMenu';
import PlatformIcons from 'components/menu/createInstanceMenu/PlatformIcons';
import Popover from 'components/popover/Popover';
import Scrollbar from 'components/scrollbar/scrollbar';
import ConfigContext from 'contexts/configContext';
import useCustomDateTimeUtils from 'hooks/useCustomDateTimeUtils';
import useDateTimeUtils from 'hooks/useDateTimeUtils';
import { MenuInfoWrapper, MenuItemText } from 'lib/Menu/MenuItem';
import CREATE_INSTANCE from 'operations/mutations/createInstance';
import { useStoryMolecule } from 'screens/storyV2/store/story';
import { useStoryPaneMolecule } from 'screens/storyV2/store/storyPane';
import usePanes from 'screens/storyV2/useStoryPanes';
import { AtomDateRange, usePolicies } from 'store';
import { Instance, Platform, PlatformAccount } from 'types';
import { CreateInstanceInput, Mdf, MemberTypeEnum, RundownOrderTypeEnum } from 'types/graphqlTypes';
import checkUserRight from 'utils/checkUserRight';
import { UNTITLED_STORY } from 'utils/constants';
import getEmptyMetadataForForm from 'utils/getEmptyMetadata';
import getIdentifier from 'utils/instance/getAccountIdentifier';
import { getPlatformIcon, getPlatformLabel } from 'utils/instance/platform';
import updateStoryInstanceCache from 'utils/instance/updateStoryInstanceCache';
import { getDefaultValues } from 'utils/mdf/utils';
import { CustomFields, getTwitterMetaKey } from 'utils/metadata';
import preventDefaultAndPropagation from 'utils/preventDefaultAndStopPropagation';

import { MenuContainer, StyledMenuItem, SvgHoverWrapper } from './styled';

interface Props {
  platform: Platform;
  closeMenu: () => void;
  paneIndex?: number;
}

interface InstanceProperties {
  __typename: 'PlatformType';
  platform: string;
  account: {
    accountUrl?: string;
    accountLogo?: string;
    accountTitle: string;
    accountId: string | null;
    orderType?: RundownOrderTypeEnum;
  };
  platformKind?: string;
}

/**
 * TODO - Address duplicate code between this file and `useCreateInstance` hook that
 * appears to do the same thing.
 */

const getInstanceMdf = (properties: InstanceProperties, instanceMdfs: Mdf[]) => {
  if (!properties || !instanceMdfs) return;

  const byId = keyBy(instanceMdfs, (m) => m.id);
  const { platform, platformKind, account } = properties;
  if (platform === 'linear') {
    return platformKind === 'audio' ? byId['linear-audio'] : byId[platform];
  }
  return byId[platformKind ?? ''] ?? byId[account?.accountId ?? ''] ?? byId[platform ?? ''];
};

function PlatformItem({ platform, closeMenu, paneIndex }: Readonly<Props>) {
  const { isToday } = useDateTimeUtils();
  const { isBeforeToday } = useCustomDateTimeUtils();
  const { updateStoryPane } = usePanes();
  const { mProperties, mTitle } = platform;
  const { platform: platformName, platformKind, accounts, platformIcon = '' } = mProperties;
  const { metadataForms } = useContext(ConfigContext);
  const { mdfsSeparated } = useGetMdfs({ all: true });
  const [policies] = usePolicies();
  const canScheduleInstance = checkUserRight(policies, 'instance', 'schedule');
  const { useStory } = useStoryMolecule();
  const { useCreatingInstance, useNewlyAddedInstance } = useStoryPaneMolecule();
  const [story] = useStory();
  const [selectedDate, setSelectedDate] = useSelectedDate();
  const [, setCreatingInstance] = useCreatingInstance();
  const [, setNewlyAddedInstance] = useNewlyAddedInstance();
  const [, setToggleRender] = useState(false);

  const blankMetaData = getEmptyMetadataForForm(metadataForms[0]);

  const [selected, setSelected] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const filteredAccounts =
    canScheduleInstance || mTitle !== 'current rundowns'
      ? (accounts as PlatformAccount[])
      : // eslint-disable-next-line react/prop-types
        (accounts as PlatformAccount[]).filter((account) => account.accountTitle === 'Unassigned');

  const Icon = PlatformIcons[platformIcon] ?? getPlatformIcon(platformName, platformKind);

  const label = getPlatformLabel(platformName, platformKind);

  const handlePlatformClick: React.MouseEventHandler<HTMLLIElement> = (e) => {
    setAnchorEl(e.currentTarget);
    setSelected(true);
  };

  const handleCloseSubMenu = () => {
    setAnchorEl(null);
    setSelected(false);
  };

  const [createInstanceForStory] = useMutation(CREATE_INSTANCE, {
    update: (proxy, mutationResult: Omit<FetchResult<{ createInstance: Instance }>, 'context'>) => {
      const { createInstance } = mutationResult.data as { createInstance: Instance };
      updateStoryInstanceCache(proxy, story?.mId as string, createInstance);
      setNewlyAddedInstance(createInstance);
      updateStoryPane(paneIndex ?? 0, 'instances', createInstance.mRefId);
      handleCloseSubMenu();
      closeMenu();
    },
  });

  const handleAccountClick = debounce((account: PlatformAccount) => {
    if (!platform || !platformName || !account || !story) return;
    handleCloseSubMenu();
    closeMenu();
    const canCreateInstances = checkUserRight(
      policies,
      'platform',
      getIdentifier(platformName, account.accountTitle),
    );

    if (!canCreateInstances) return;
    setCreatingInstance(true);
    updateStoryPane(paneIndex ?? 0, 'instances');

    const platformProperties: InstanceProperties = {
      __typename: 'PlatformType',
      platform: platformName,
      account: {
        accountUrl: account.accountUrl,
        accountLogo: account.accountLogo,
        accountTitle: account.accountTitle,
        accountId: account.accountId,
      },
      platformKind:
        (platformKind as string) ??
        (account.variant && account.variant !== platformName ? account.variant : undefined),
    };

    const mdf = getInstanceMdf(platformProperties, mdfsSeparated.instances);
    const defaultMetadataValues = mdf
      ? getDefaultValues({}, mdf, mdfsSeparated.subTypes)
      : undefined;

    const title = story.mTitle === UNTITLED_STORY ? '' : story.mTitle;

    let input: CreateInstanceInput = {
      mId: story.mId,
      mTitle: title,
      mDescription: '',
      mStoryId: story.mId,
      mProperties: platformProperties,
      mMetaData: [{ key: CustomFields.STORY_TITLE, value: title }],
      ...(!isEmpty(defaultMetadataValues) && { metadata: JSON.stringify(defaultMetadataValues) }),
      ...(story?.mType === MemberTypeEnum.ResStory ? { isRestricted: true } : {}),
    };

    if (platformName === 'linear')
      input.mProperties!.account!.accountId = account.accountId ?? null;

    if (platformName === 'twitter') {
      input = {
        ...input,
        mMetaData: [
          ...(input?.mMetaData ?? []),
          {
            key: getTwitterMetaKey(blankMetaData),
            value: '1',
          },
        ],
      };
    }

    createInstanceForStory({
      variables: {
        input,
      },
    }).then(
      () => {},
      () => {},
    );
  }, 300);

  const onDateChanged = (e: AtomDateRange) => {
    setSelectedDate((e.endDate as unknown as string) || null);
  };

  return (
    <>
      <StyledMenuItem selected={selected} onClick={handlePlatformClick}>
        <Icon />
        <MenuItemText $isTitle primary={label.toLowerCase() === 'cms' ? 'CMS' : label} />
        {filteredAccounts.length && (
          <MenuInfoWrapper>
            <MenuItemText primary={filteredAccounts.length} />
            <SvgHoverWrapper>
              <ArrowRight />
            </SvgHoverWrapper>
          </MenuInfoWrapper>
        )}
      </StyledMenuItem>
      {filteredAccounts.length && (
        <Popover
          noMargin
          onClose={handleCloseSubMenu}
          anchorEl={anchorEl}
          position="right"
          style={{ zIndex: 1301 }}
        >
          <Scrollbar autoHeight autoHeightMin={0} autoHeightMax="60vh" top={8} bottom={8}>
            {platformName === 'linear' ? (
              <MenuContainer>
                <CategorizedMenu
                  onDateChanged={onDateChanged}
                  publishingAt={selectedDate ?? new Date().toISOString()}
                  disableDecrement={
                    isToday(selectedDate ?? new Date().toISOString()) ||
                    isBeforeToday(selectedDate ?? new Date().toISOString())
                  }
                  items={filteredAccounts}
                  onClose={(account) => {
                    handleAccountClick(account);
                  }}
                  onDoubleClick={preventDefaultAndPropagation}
                  setToggleRender={setToggleRender}
                  dense
                  darker
                >
                  <Icon />
                </CategorizedMenu>
              </MenuContainer>
            ) : (
              <>
                {filteredAccounts.map((account) => (
                  <StyledMenuItem
                    key={`${account.accountTitle} ${account.accountUrl}`}
                    $type="account"
                    $isUnassigned={account.isUnassigned && filteredAccounts.length > 1}
                    onClick={() => {
                      handleAccountClick(account);
                    }}
                    onDoubleClick={preventDefaultAndPropagation}
                    disabled={
                      !checkUserRight(
                        policies,
                        'platform',
                        getIdentifier(platformName, account.accountTitle),
                      )
                    }
                  >
                    <Icon />
                    <MenuItemText $isUnassigned={account.isUnassigned}>
                      {account.accountTitle}
                    </MenuItemText>
                  </StyledMenuItem>
                ))}
              </>
            )}
          </Scrollbar>
        </Popover>
      )}
    </>
  );
}

export default PlatformItem;
