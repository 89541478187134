import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';

import useToast from 'components/toast/useToast';

const UPDATE_PRIORITIES = gql`
  mutation UpdatePriorities($input: UpdateMemberInput) {
    updateMember(input: $input) {
      mId
      mRefId
      mType
      metadata
    }
  }
`;

const useUpdatePriorities = () => {
  const [updatePriority] = useMutation(UPDATE_PRIORITIES);
  const { errorToast } = useToast();

  const update = useCallback(
    (metadata: string) => {
      updatePriority({
        variables: {
          input: {
            mId: 'priority',
            mRefId: 'priority',
            mType: 'config',
            metadata,
          },
        },
      }).catch((err) => {
        errorToast(err);
      });
    },
    [updatePriority],
  );

  return update;
};

export default useUpdatePriorities;
