/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import useIntersectionObserver from '@react-hook/intersection-observer';
import { debounce, throttle } from 'lodash';

import { useGetOrderForms } from 'api/order_forms/useGetOrderForms';
import { SearchParameters, useSearch } from 'api/search';
import { ReactComponent as SidebarActive } from 'assets/icons/systemicons/side_preview.svg';
import useOpenMember from 'components/contextMenu/useOpenMember';
import { ClearIcon } from 'components/createNewV3/styled';
import { LoadingButtonIndicator } from 'components/loadingIndicator';
import { MemberIcon } from 'components/memberIcon/MemberIcon';
import Text from 'components/text/Text';
import useToast from 'components/toast/useToast';
import Tooltip, { TooltipPlacement } from 'components/tooltip/Tooltip';
import { CommandToolbarProps } from 'features/command/command-types';
import { StyledTextField } from 'features/reusableStyled';
import useCustomDateTimeUtils from 'hooks/useCustomDateTimeUtils';
import { HStack, VStack } from 'layouts/box/Box';
import { useEditorCommandsKeyed } from 'store';
import { useSetPreview } from 'store/preview';
import { MemberType, MemberTypeEnum } from 'types/graphqlTypes';
import { EditorCommandConfigType } from 'types/memberTypes/editorCommands';
import { OrderFormMemberType } from 'types/memberTypes/order_form';
import { getHighlightedTitle } from 'utils/member/member-utils';

import { StyledPopper } from '../addMember/styled';

interface QuickSearchProps {
  searchString: string;
  disableInput?: boolean;
  placeholderText?: string;
  disablePreview?: boolean;
  helperText?: string;
  setSearchString: (val: string) => void;
  onKeyDown: (ev: React.KeyboardEvent) => void;
  titleErrorTooltip: string | undefined;
  toolbarState: CommandToolbarProps;
  onBlur?: () => void;
  onSelect?: (val: MemberType) => void;
  searchParams?: SearchParameters;
  doEmptySearches?: boolean;
  doPreviewOnSelect?: boolean;
  autoFocus?: boolean;
  allowClear?: boolean;
}

const IntersectionObserver = styled('div')`
  height: 20px;
  width: 100%;
  pointer-events: none;
`;

const StyledVStack = styled(VStack)`
  background: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
`;

const ItemTitle = styled('div')`
  ${({ theme }) => theme.typography.dina.listItemLabel};
  width: 100%;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledHStack = styled(HStack)<{ $inlined?: boolean }>`
  cursor: pointer;
  height: 32px;
  position: relative;
  flex-shrink: 0;
  em {
    font-style: normal;
    color: ${({ theme }) => theme.palette.dina.statusSearchMatchBG};
  }
  .sidebar {
    display: none;
    position: absolute;
    background: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
    right: 3px;
  }
  &:hover {
    background: ${({ theme, $inlined }) =>
      $inlined ? 'transparent' : theme.palette.dina.hoverOverlay};
    .sidebar {
      display: block;
    }
  }
`;

const ItemDate = styled(Text)`
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px;
`;

export function SearchHit({
  item,
  commandById,
  forms,
  inlined = false,
  onClick,
  onPreviewClick,
}: Readonly<{
  item: MemberType;
  commandById: Record<string, EditorCommandConfigType | undefined>;
  forms: Record<string, OrderFormMemberType | undefined>;
  inlined?: boolean;
  iconPreview?: boolean;
  onClick: () => void;
  onPreviewClick?: () => void;
}>) {
  const { isoToLocaleShort } = useCustomDateTimeUtils();
  const onMouseDown = useCallback(
    (
      ev: React.MouseEvent<HTMLElement, MouseEvent> | React.MouseEvent<SVGSVGElement, MouseEvent>,
    ) => {
      ev.preventDefault();
      ev.stopPropagation();
      onPreviewClick?.();
    },
    [onPreviewClick],
  );

  return (
    <Tooltip title={onPreviewClick ? 'Preview' : ''} placement={TooltipPlacement.RIGHT}>
      <StyledHStack
        width="100%"
        padding="1px 3px"
        gap="6px"
        onMouseDown={onClick}
        $inlined={inlined}
      >
        <MemberIcon member={item} />
        <VStack width="100%" justifyContent="start">
          <ItemTitle dangerouslySetInnerHTML={getHighlightedTitle(item, forms, commandById)} />
          <ItemDate variant="caption">{`${isoToLocaleShort(item.mCreatedAt, true)}`}</ItemDate>
        </VStack>
        {onPreviewClick && (
          <SidebarActive
            className="sidebar"
            style={{ zIndex: 100 }}
            onMouseDown={onMouseDown}
            onClick={(ev) => {
              ev.stopPropagation();
              ev.preventDefault();
            }}
          />
        )}
      </StyledHStack>
    </Tooltip>
  );
}

const isSideBarItem = (item: MemberType) => {
  return [
    MemberTypeEnum.Contact,
    MemberTypeEnum.User,
    MemberTypeEnum.Team,
    MemberTypeEnum.Department,
  ].includes(item.mType!);
};

export default function QuickSearch({
  searchString,
  disablePreview,
  autoFocus,
  disableInput,
  setSearchString,
  onKeyDown,
  onBlur,
  onSelect,
  titleErrorTooltip,
  doPreviewOnSelect,
  toolbarState,
  searchParams,
  helperText,
  placeholderText,
  doEmptySearches,
  allowClear,
}: Readonly<QuickSearchProps>) {
  const { errorToast } = useToast();
  const [inputRef, setInputRef] = useState<HTMLDivElement | null>(null);
  const { isIntersecting } = useIntersectionObserver(inputRef, { pollInterval: 2000 });
  const [editorCommands] = useEditorCommandsKeyed();
  const { keyedOrderForms } = useGetOrderForms();
  const { openItem } = useOpenMember();
  const setPreview = useSetPreview();
  const [searchValue, setSearchValue] = useState(searchString);
  const [focused, setFocused] = useState(false);
  const ref = useRef<HTMLInputElement | null>(null);
  const throttled = useRef(
    throttle((newValue: string) => setSearchValue(newValue), 1000, { trailing: true }),
  );
  const debounced = useRef(debounce((newValue: string) => setSearchValue(newValue), 300));

  const storedSearchString = searchParams?.searchString ?? '';

  const { items, loading, handleLoadMore, hasMore } = useSearch({
    skip: !focused || (doEmptySearches ? false : searchValue.length < 3),
    perPagelimit: 10,
    searchableKey: 'mTitle',
    toolbarState,
    searchString: searchValue ? `${storedSearchString} ${searchValue}` : storedSearchString,
    metadataFilter: searchParams?.metadataFilter,
    mdfId: searchParams?.mdfId,
  });

  useEffect(() => {
    if (isIntersecting && !loading) {
      handleLoadMore().catch(errorToast);
    }
  }, [isIntersecting, loading]);

  const clearText = () => {
    setSearchString('');
    if (ref.current) ref.current.focus();
  };

  const doPreview = useCallback(
    (item: MemberType) => {
      if (isSideBarItem(item)) {
        openItem(item);
      } else {
        setPreview(item);
      }
    },
    [openItem, setPreview],
  );

  const onClick = useCallback(
    (item: MemberType) => {
      if (doPreviewOnSelect) {
        doPreview(item);
      } else {
        onSelect?.(item);
      }
    },
    [onSelect, doPreviewOnSelect],
  );

  useEffect(() => {
    debounced.current(searchString);
    throttled.current(searchString);
  }, [searchString]);

  return (
    <div>
      <StyledTextField
        ref={ref}
        fullWidth
        disabled={disableInput}
        autoFocus={autoFocus}
        error={Boolean(titleErrorTooltip)}
        variant="filled"
        placeholder={placeholderText ?? 'Type title here...'}
        helperText={helperText}
        value={searchString}
        style={{ marginTop: '2px' }}
        onChange={(ev) => setSearchString(ev.target.value)}
        onFocus={(ev) => {
          ev.currentTarget.select();
          setFocused(true);
        }}
        onBlur={() => {
          setFocused(false);
          onBlur?.();
        }}
        onKeyDown={onKeyDown}
        InputProps={{
          endAdornment: loading ? (
            <LoadingButtonIndicator
              inline
              size={16}
              style={{ position: 'absolute', right: '8px' }}
            />
          ) : (
            allowClear && <ClearIcon onClick={clearText} />
          ),
          disableUnderline: true,
        }}
      />
      <StyledPopper
        anchorEl={ref.current}
        open={(doEmptySearches || items.length > 0) && focused}
        style={{ width: ref.current?.clientWidth }}
      >
        <StyledVStack
          gap="4px"
          justifyContent="start"
          alignItems="start"
          width="100%"
          maxHeight="300px"
          overflow="auto"
        >
          {items.map((item) => (
            <SearchHit
              onClick={() => onClick(item)}
              item={item}
              key={`${item.mId}:${item.mRefId}`}
              onPreviewClick={disablePreview ? undefined : () => doPreview(item)}
              commandById={editorCommands}
              forms={keyedOrderForms}
            />
          ))}
          {hasMore && <IntersectionObserver ref={setInputRef} />}
        </StyledVStack>
      </StyledPopper>
    </div>
  );
}
