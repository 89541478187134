import styled from '@emotion/styled';

export const Wrapper = styled('div')`
  ${({ theme }) => theme.typography.dina.listItemLabel};
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
`;

export const StartAdornment = styled('span')`
  position: absolute;
  left: 0;
  padding-left: 8px;
`;

export const EndAdornment = styled('div')`
  position: absolute;
  right: 0;
  padding-right: 8px;
`;

export const Input = styled('input')<{
  startAdornment?: boolean;
  endAdornment?: boolean;
  height?: number | string;
}>`
  height: ${({ height }) => height ?? 36}px;
  ${({ theme }) => theme.typography.dina.listItemLabelMedium};
  width: 100%;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.palette.dina.borderResting};
  padding-block: 8px;
  background-color: ${({ theme }) => theme.palette.dina.inputBackground};
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  padding-left: ${({ startAdornment }) => (startAdornment ? '32px' : '8px')};
  padding-right: ${({ endAdornment }) => (endAdornment ? '32px' : '8px')};
  ::placeholder {
    color: ${({ theme }) => theme.palette.dina.watermark};
  }
  :focus-visible {
    outline: none;
    border-color: ${({ theme }) => theme.palette.dina.inputBorderFocused};
    background: ${({ theme }) => theme.palette.dina.inputBackgroundFocused};
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
