import { useCallback } from 'react';

import useDateTimeUtils from 'hooks/useDateTimeUtils';

type DateTimeType = Date | string | null;

const useSanitizeDates = () => {
  const { startOfDay, endOfDay } = useDateTimeUtils();

  const sanitizeDates = useCallback(
    (startDate?: DateTimeType, endDate?: DateTimeType): { from: string; to: string } => {
      const now = new Date();

      const parseDate = (date?: DateTimeType): Date => {
        return typeof date === 'string' ? new Date(date) : date ?? now;
      };

      const startTime = parseDate(startDate || endDate);
      const endTime = parseDate(endDate || startDate);

      const sanitizedStartDate = startOfDay(startTime).toISOString();
      const sanitizedEndDate = endOfDay(endTime).toISOString();

      return { from: sanitizedStartDate, to: sanitizedEndDate };
    },
    [],
  );

  return { sanitizeDates };
};

export default useSanitizeDates;
