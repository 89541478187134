const getKeyString = (event: React.KeyboardEvent<HTMLInputElement>) => {
  const { code, ctrlKey, shiftKey, altKey, metaKey } = event;
  const ctrl = ctrlKey ? 'ctrl+' : '';
  const shift = shiftKey ? 'shift+' : '';
  const alt = altKey ? 'alt+' : '';
  const meta = metaKey ? 'meta+' : '';
  const keyCode = code?.toLowerCase()?.replace(/key|digit/gi, '');
  const eventKey = `${ctrl}${shift}${alt}${meta}${keyCode}`;
  return eventKey;
};

const validateShortCut = (event: React.KeyboardEvent<HTMLInputElement>, shortCut: string) => {
  if (getKeyString(event) === shortCut) return true;
  return false;
};

/**
 * `true` if on a Mac, otherwise `false` (Windows/Linux)
 */
export const isMac = navigator.userAgent.toLowerCase().includes('mac');

/**
 * Gets the text to display for a keyboard shortcut from the shortcut as used on Mac.
 * The following replacements will be made if running on Windows or Linux
 * * `Cmd` => `Ctrl`
 * * `Option` => `Alt`
 * @param macShortcut The shortcut text on Mac
 * @returns           The shortcut text on the used operating system
 */
export function getKeyboardShortcutText(macShortcut: string) {
  return isMac ? macShortcut : macShortcut.replace('Cmd', 'Ctrl').replace('Option', 'Alt');
}

export { validateShortCut, getKeyString };
