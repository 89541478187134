import { useEffect, useMemo, useState } from 'react';
import { atom, useAtom } from 'jotai';

import { useCreateInstance } from 'api/instance/useCreateInstance';
import { publishingPoints as publishingPointIcons } from 'assets/icons/publishingPoints';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_down.svg';
import { Button } from 'components/buttons';
import { useSelectedDate } from 'components/createStoryItems/store';
import Dialog from 'components/dialogs/DialogBuilder';
import DropdownMenu from 'components/dropdownMenu/DropdownMenu';
import { TextArea } from 'components/input/TextArea';
import Text from 'components/text';
import useFilterPlatforms from 'hooks/useFilterPlatforms';
import useGetPlatforms from 'hooks/useGetPlatforms';
import { VStack } from 'layouts/box/Box';
import { useCurrentTabType } from 'store/tabs';
import { getPlatform, PlatformData } from 'utils/instance/platform';

import { DropdownSubItems } from './DropdownSubItems';
import { DropdownSubLinearItems } from './DropdownSubLinearItems';

export interface AddInstance {
  text: string | null;
  onCancel?: () => void;
}

const addInstanceAtom = atom<AddInstance>({ text: null });

export const useAddInstanceDialog = () => useAtom(addInstanceAtom);

export function AddInstanceDialog() {
  const [currentTabType] = useCurrentTabType();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useAddInstanceDialog();
  const [text, setText] = useState('');
  const [selectedDate] = useSelectedDate();
  const { platforms, platformVariants } = useGetPlatforms(
    selectedDate ? new Date(selectedDate) : new Date(),
  );
  const { createInstance } = useCreateInstance();
  const { filterPlatforms } = useFilterPlatforms();
  const [selectedPlatform, setSelectedPlatform] = useState<PlatformData | undefined>();

  const isStoryTab = currentTabType === 'story';

  const platform = useMemo(
    () => getPlatform(platforms, selectedPlatform?.platform ?? '', selectedPlatform?.platformKind),
    [selectedPlatform, platforms],
  );

  const platformVariant = useMemo(() => {
    const variant = selectedPlatform?.account.variant ?? selectedPlatform?.platform;
    return variant ? platformVariants[variant] : undefined;
  }, [selectedPlatform, platformVariants]);

  useEffect(() => {
    if (state.text !== null) {
      setOpen(true);
      setText(state.text);
      setSelectedPlatform(undefined);
    }
  }, [state]);

  const onConfirm = async (isCancel: boolean) => {
    if (isCancel) {
      setState({ text: null });
      state?.onCancel?.();
    } else {
      try {
        setLoading(true);
        await createInstance(selectedPlatform, text, platform, platformVariant);
      } finally {
        setLoading(false);
      }
    }
    setOpen(false);

    setSelectedPlatform(undefined);
  };

  const iconKey = selectedPlatform?.platformKind ?? selectedPlatform?.platform ?? 'default';
  const iconSrc = publishingPointIcons[iconKey];

  const instanceItems = filterPlatforms(platforms).filter(
    (item) => item?.mProperties?.platform !== 'task' && item?.mProperties?.accounts?.length,
  );

  const taskItems = filterPlatforms(platforms).filter(
    (item) => item?.mProperties?.platform === 'task' && item?.mProperties?.accounts?.length,
  );

  return (
    <Dialog open={open} onClose={() => void onConfirm(true)}>
      <Dialog.Header showCloseIcon>Create instance from text</Dialog.Header>
      <Dialog.Body>
        <Text variant="overline" color="highEmphasis">
          Choose Instance type
        </Text>
        <VStack gap="8px" margin="4px 0 0 0" overflow="visible">
          <DropdownMenu>
            <DropdownMenu.Trigger>
              <Button variant="outlined" usage="outlined" width={300} align="left">
                <img src={iconSrc} alt="publishing-point" />
                <span style={{ flexGrow: 1 }}>
                  {selectedPlatform?.account?.accountTitle ?? 'Choose an instance type...'}
                </span>
                {!selectedPlatform && <ArrowDownIcon />}
              </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
              <DropdownMenu.Content>
                <Text variant="captionSmall">Create Instance</Text>
                {instanceItems.map((item) => {
                  const { mProperties } = item;
                  const isLinear = mProperties.platform === 'linear';

                  if (isLinear) {
                    return (
                      <DropdownSubLinearItems
                        key={mProperties.platformKind ?? mProperties.platform}
                        item={item}
                        setSelectedPlatform={setSelectedPlatform}
                      />
                    );
                  }

                  return (
                    <DropdownSubItems
                      key={mProperties.platformKind ?? mProperties.platform}
                      item={item}
                      setSelectedPlatform={setSelectedPlatform}
                    />
                  );
                })}

                <DropdownMenu.Separator />
                <Text variant="captionSmall">Create Task</Text>
                {taskItems.map((item) => {
                  return (
                    <DropdownSubItems
                      key={item.mProperties.platform}
                      item={item}
                      setSelectedPlatform={setSelectedPlatform}
                    />
                  );
                })}
              </DropdownMenu.Content>
            </DropdownMenu.Portal>
          </DropdownMenu>
          <TextArea value={text} onChange={(e) => setText(e.target.value)} autoFocus rows={10} />
        </VStack>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton label="Cancel" />
        <Button
          size="md"
          disabled={!isStoryTab || !selectedPlatform?.platform || loading}
          onClick={() => void onConfirm(false)}
        >
          {loading ? 'Creating' : 'Create'}
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
}
