import { gql } from '@apollo/client';

export default gql`
  mutation CreateStoryForMember($input: CreateStoryForMemberInput) {
    createStoryForMember(input: $input) {
      mId
      mRefId
      mTitle
      mDescription
      mContentKey
      mThumbnailUrl
      mCreatedAt
      mUpdatedAt
      mState
      mPublishingAt
      mPublishingEnd
      mType
      isRestricted
      metadata
      mPriority
      mdfId
      mMetaData {
        key
        value
      }
      mAssignedMembers {
        mId
        mType
      }
      mPriority
      mContent
    }
  }
`;
