import { GroupPropType } from 'types/groupPermissions/permissions';

import { BodyWrapper, SettingsDetailsWrapper, SettingsMenuWrapper } from '../../../styled';

import UsersAndGroupsDetails from './details';
import UsersAndGroupsMenu from './menu';

const UsersAndGroups = (props: GroupPropType) => {
  const { groupPolicies, instanceTypes, kanbanStates } = props;
  return (
    <BodyWrapper>
      <SettingsMenuWrapper>
        <UsersAndGroupsMenu groupPolicies={groupPolicies} />
      </SettingsMenuWrapper>
      <SettingsDetailsWrapper>
        <UsersAndGroupsDetails
          groupPolicies={groupPolicies}
          instanceTypes={instanceTypes}
          kanbanStates={kanbanStates}
        />
      </SettingsDetailsWrapper>
    </BodyWrapper>
  );
};

export default UsersAndGroups;
