import { CellContext } from '@tanstack/react-table';

import Text from 'components/text/Text';
import useCustomDateTimeUtils from 'hooks/useCustomDateTimeUtils';
import { MemberType } from 'types/graphqlTypes';

type DateCellProps = Pick<CellContext<MemberType, string>, 'getValue'>;

export default function DateCell({ getValue }: Readonly<DateCellProps>) {
  const { isoToLocaleShort } = useCustomDateTimeUtils();
  const date = isoToLocaleShort(getValue());

  return (
    <Text variant="listItemLabel" truncate>
      {date}
    </Text>
  );
}
