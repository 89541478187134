import { useCallback } from 'react';

import { SearchDateRange } from 'api/search';
import useDateTimeUtils from 'hooks/useDateTimeUtils';
import { BUCKET_DATE_FORMAT } from 'screens/storyHub/store/storyHub';
import { BucketResponse, MemberType } from 'types/graphqlTypes';

export const DATE_FORMAT = 'YYYY-MM-DD';

export type GroupedByDateData = {
  [x: string]: MemberType[];
};

const useGroupDate = () => {
  const { eachDay, format, startOfDay, isValid } = useDateTimeUtils();

  const getBucketKey = useCallback((mPublishingAt?: string) => {
    if (!mPublishingAt) return;
    if (!isValid(new Date(mPublishingAt))) return;
    return format(startOfDay(mPublishingAt), BUCKET_DATE_FORMAT);
  }, []);

  const getEachDaysInDateRange = useCallback(
    (dateRange: SearchDateRange) => eachDay(dateRange.from, dateRange.to),
    [],
  );

  const getGroupedByDateData = useCallback(
    (items: MemberType[], dateRange: SearchDateRange) => {
      const groups = {} as GroupedByDateData;
      getEachDaysInDateRange(dateRange).forEach((date) => {
        groups[format(date, DATE_FORMAT)] = [];
      });
      items.forEach((item) => {
        const groupKey = format(item.mPublishingAt as string, DATE_FORMAT);
        if (groups[groupKey]) groups[groupKey].push(item);
      });
      return groups;
    },
    [getEachDaysInDateRange],
  );

  const getGroupedByDateBuckets = useCallback(
    (buckets: BucketResponse[], dateRange: SearchDateRange) => {
      const responses = {} as Record<string, BucketResponse>;
      getEachDaysInDateRange(dateRange).forEach((date) => {
        const bucketKey = getBucketKey(date.toISOString());
        if (!bucketKey) return;
        const bucket: BucketResponse = {
          stringKey: bucketKey,
          items: [],
          total: 0,
        };
        responses[bucketKey] = bucket;
      });
      buckets.forEach((bucket) => {
        if (bucket.stringKey) responses[bucket.stringKey] = bucket;
      });
      return responses;
    },
    [getEachDaysInDateRange],
  );

  return { getGroupedByDateBuckets, getGroupedByDateData, getBucketKey };
};

export default useGroupDate;
