import { useCallback } from 'react';

import { ReactComponent as ExportIcon } from 'assets/icons/systemicons/export.svg';
import useToast from 'components/toast/useToast';

import Button from './button';

/**
 * Holds information about what to be exported
 */
export interface ExportData {
  /** The file name of the data (including file extension) or empty to export to new tab */
  fileName: string;
  /** The data to be exported */
  content: string;
  /** The type of the data (defaults to `application/json`) */
  type?: string;
}

interface Props {
  /** Optional tooltip text for the button */
  title?: string;
  /** Set to `true` to disable the whole button */
  disabled?: boolean;
  /** Function to retrieve information about what to export */
  getExportData: () => ExportData;
  /** Optional width of the button (if omitted, defaults to default button width) */
  width?: string | number;
  /** Optional height of the button (if omitted, defaults to default button width) */
  height?: string | number;
}

function ExportButton({ title, disabled, getExportData, width, height }: Readonly<Props>) {
  const { toast, errorToast } = useToast();
  const onExport = useCallback(() => {
    try {
      const data = getExportData();
      const url = URL.createObjectURL(
        new Blob([data.content], { type: data.type ?? 'application/json' }),
      );
      if (data.fileName) {
        // Replace all illegal file name characters with underscores
        const fileName = data.fileName.replace(/[/\\?%*:|"<>]/g, '_');
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        toast({
          title: 'Download complete',
          description: `'${fileName}' has been downloaded`,
          type: 'success',
        });
        setTimeout(() => {
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        }, 0);
      } else {
        window.open(url, '_blank')?.focus();
        setTimeout(() => {
          URL.revokeObjectURL(url);
        }, 5000);
      }
    } catch (e) {
      errorToast(
        e instanceof Error ? e : 'unknown error',
        'Export failed!' + (e instanceof Error ? `\n${e.message}` : ''),
      );
    }
  }, [getExportData]);
  return (
    <Button
      width={width}
      height={height}
      disabled={disabled}
      variant="outlined"
      usage="outlined"
      title={title}
      onClick={onExport}
    >
      <ExportIcon className="skipOverride" />
      Export
    </Button>
  );
}

export default ExportButton;
