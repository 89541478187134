import { keyBy } from 'lodash';

import {
  Alternative,
  FieldTypeEnum,
  LayoutSettings,
  Mdf,
  MdfField,
  MdfPermission,
} from 'types/graphqlTypes';

import { NameMappings } from './conversion';
import { UnsavedMdfField } from './mdf-utils';

/**
 * Updates an immutable array by returning a new array if one or more items change.
 * @param items       The array to be updated
 * @param itemUpdater A function that updates an item in the array.
 *                    Returns the same item, a new item, or `undefined` to remove the item
 * @returns           The updated array (will be the same as the incoming array if all items are
 *                    unchanged)
 */
function updateImmutableArray<T>(
  items: readonly T[],
  itemUpdater: (item: T) => T | undefined,
): T[] {
  let itemsForJson: T[] | undefined = undefined;
  const count = items.length;
  for (let index = 0; index < count; ++index) {
    const item = items[index];
    const itemForJson = itemUpdater(item);
    if (itemForJson !== item) {
      itemsForJson = items.toSpliced(index, count - index, ...(itemForJson ? [itemForJson] : []));
      break;
    }
  }
  if (!itemsForJson) {
    return items as T[];
  }
  for (let index = itemsForJson.length; index < count; ++index) {
    const itemForJson = itemUpdater(items[index]);
    if (itemForJson) itemsForJson.push(itemForJson);
  }
  return itemsForJson;
}

/**
 * Updates an immutable record by returning a new object if one or more of its properties change.
 * @param obj         The record to be updated
 * @param itemUpdater A function that updates a value in the record.
 *                    Returns the same item, a new item, or `undefined` to remove the property
 * @returns           The updated record (will be the same as the incoming record if all properties
 *                    are unchanged)
 */
function updateImmutableRecord<T, R extends Record<string, T>>(
  obj: Readonly<R>,
  valueUpdater: (value: T, key: string) => T,
): R {
  let objForJson: Record<string, T> | undefined = undefined;
  const entries = Object.entries(obj) as [string, T][];
  const count = entries.length;
  let index = 0;

  // Loop over the source properties until the update produces a change
  for (; index < count; ++index) {
    const [key, value] = entries[index];
    const valueForJson = valueUpdater(value, key);
    if (valueForJson !== value) {
      objForJson = Object.fromEntries(entries.toSpliced(index, count - index)) as Record<string, T>;
      if (valueForJson) objForJson[entries[index][0]] = valueForJson;
      break;
    }
  }

  if (!objForJson) {
    // All props were unchanged
    return obj;
  }

  for (; index < count; ++index) {
    const [key, value] = entries[index];
    const valueForJson = valueUpdater(value, key);
    if (valueForJson) objForJson[key] = valueForJson;
  }
  return objForJson as R;
}

function updateImmutableObjectProp<T extends object, K extends keyof T>(
  obj: Readonly<T>,
  propName: K,
  updatedValue: T[K],
): T {
  return updatedValue === obj[propName] ? obj : { ...obj, [propName]: updatedValue };
}

function updateImmutableObjectOptionalProp<T extends object, K extends keyof T>(
  obj: Readonly<T>,
  propName: K,
  updatedValue: T[K] | undefined,
): T {
  if (updatedValue === obj[propName]) return obj;
  const result: T = { ...obj };
  if (propName in obj && updatedValue === undefined) {
    delete result[propName];
  } else if (updatedValue !== undefined) {
    result[propName] = updatedValue;
  }
  return result;
}

function deletePropertyIfPresent<T extends Record<string, unknown>>(
  obj: Readonly<T>,
  propName: keyof T,
): T {
  if (propName in obj) delete obj[propName];
  return obj;
}

function arraysAreEqual<T>(first: readonly T[], second: readonly T[]): boolean {
  return first.length === second.length && first.every((item, index) => item === second[index]);
}

/**
 * Converts MDF to a JSON string. Does the necessary conversions to make sure the resulting JSON can
 * be imported.
 *
 * The following is done with the MDF before converting to JSON:
 * * Remove `id` properties of items in `field.alternatives`.
 *
 * @param mdf          The MDF to be converted to JSON
 * @param nameMappings Various mappings from id to name that can be used that external objects that
 *                     we reference still exist
 */
export function mdfToJson(mdf: Mdf, nameMappings: NameMappings): string {
  const { subtypes, optionLists, optionTrees } = nameMappings;
  const mdfFieldsById = keyBy(mdf.fields, (f) => f.fieldId);
  /**
   * We don't want the 'id` (or`icon`) 'property of the {@link MdfField} in the JSON.
   * In addition we remap the value of
   * @param field The field to serialized
   * @returns     The serializable field (will be the same as `field` if `field` does contains
   *              `id` nor `icon` properties)
   */
  function getMdfFieldForJson(field: MdfField): MdfField {
    /**
     * Fix an {@link Alternative} so that it can be exported as JSON.
     * @param alt The alternative to be fixed
     * @returns   The fixed alternative
     */
    function getMdfAlternativeForJson(alt: Alternative): Alternative {
      if (!alt.icon && !alt.id && field.type !== FieldTypeEnum.subtype) return alt;
      alt = deletePropertyIfPresent(deletePropertyIfPresent({ ...alt }, 'id'), 'icon');
      if (field.type === FieldTypeEnum.subtype) {
        if (alt.label === alt.value && !(alt.value in subtypes)) {
          // Ensure that we use the subtype ID as alternative value and that the subtype exists
          const value = Object.keys(subtypes).find((key) => subtypes[key] === alt.label);
          if (value === undefined) {
            throw new Error(
              // eslint-disable-next-line max-len
              `The field '${field.fieldId}' uses a subtype named '${alt.label}' that does not exist.`,
            );
          }
          alt.value = value;
        } else if (!(alt.value in subtypes)) {
          // Ensure that the used subtype exits
          throw new Error(
            // eslint-disable-next-line max-len
            `The field '${field.fieldId}' uses a subtype named '${alt.label}' that does not exist.`,
          );
        } else if (alt.label !== subtypes[alt.value]) {
          // If the subtype has been renamed, update the alternative's label
          alt.label = subtypes[alt.value];
        }
      }
      return alt;
    }

    const alternatives = field.alternatives
      ? updateImmutableArray(field.alternatives, getMdfAlternativeForJson)
      : undefined;
    let defaultValue = field.defaultValue;
    if (
      field.type === FieldTypeEnum.subtype &&
      typeof defaultValue.value === 'string' &&
      !(defaultValue.value in subtypes)
    ) {
      // Make sure that the default value use the subtype ID and not its label
      const value = Object.keys(subtypes).find((key) => subtypes[key] === field.defaultValue.value);
      if (value !== undefined) {
        defaultValue = { value };
      }
    }
    const relevantOptionListNames =
      field.type === FieldTypeEnum.treechoice ? optionTrees : optionLists;
    const optionListId =
      field.optionListId && field.optionListId in relevantOptionListNames
        ? field.optionListId
        : undefined;
    if ('isUnsaved' in field) {
      field = { ...field };
      delete (field as Partial<UnsavedMdfField>).isUnsaved;
      delete (field as Partial<UnsavedMdfField>).existsElseWhere;
    }
    return updateImmutableObjectOptionalProp(
      updateImmutableObjectOptionalProp(
        updateImmutableObjectOptionalProp(field, 'alternatives', alternatives),
        'defaultValue',
        defaultValue,
      ),
      'optionListId',
      optionListId,
    );
  }

  const fields = updateImmutableArray(mdf.fields, getMdfFieldForJson);

  function updateLayoutSettings(settings: Readonly<LayoutSettings>): LayoutSettings | undefined {
    const field = mdfFieldsById[settings.fieldId];
    if (!field) return undefined;
    if (!settings.colors) return settings;
    if (!field.alternatives || !Object.keys(settings.colors)) {
      // Remove the colors property
      // eslint-disable-next-line no-unused-vars, unused-imports/no-unused-vars
      const { colors, ...result } = settings;
      return result;
    }
    // Remove colors for not existing alternatives
    let colors = updateImmutableRecord(settings.colors, (color, altLabel) =>
      field.alternatives?.find((alt) => alt.label === altLabel) ? color : undefined,
    );
    const updatedField = fields.find((f) => f.fieldId === settings.fieldId);
    if (updatedField?.alternatives) {
      const newLabels = updatedField.alternatives.map((alt) => alt.label);
      const oldLabels = field.alternatives.map((alt) => alt.label);
      if (!arraysAreEqual(newLabels, oldLabels)) {
        const newColors: Record<string, string> = {};
        Object.entries(colors).forEach(([label, color]) => {
          const index = oldLabels.indexOf(label);
          if (index >= 0 && index < newLabels.length) {
            newColors[newLabels[index]] = color;
          }
        });
        colors = newColors;
      }
    }
    return updateImmutableObjectProp(settings, 'colors', colors);
  }
  function updateView(view: readonly Readonly<LayoutSettings>[]): Readonly<LayoutSettings>[] {
    return updateImmutableArray(view, updateLayoutSettings);
  }
  const views = updateImmutableRecord(mdf.views, updateView);

  // It seems like we don't remove fields from permissions when fields are deleted from schemas.
  // Therefore we must remove them here to make sure permissions mentions fields that don't exist.
  function removeUnusedFields(groupsPerField: MdfPermission['read']): MdfPermission['read'] {
    const fieldIds = Object.keys(groupsPerField);
    const existingFieldIds = fieldIds.filter((id) => id in mdfFieldsById);
    return existingFieldIds.length === fieldIds.length
      ? groupsPerField
      : Object.fromEntries(existingFieldIds.map((id) => [id, groupsPerField[id]]));
  }
  const permissions = updateImmutableRecord(mdf.permissions, removeUnusedFields);

  return JSON.stringify(
    updateImmutableObjectProp(
      updateImmutableObjectProp(updateImmutableObjectProp(mdf, 'fields', fields), 'views', views),
      'permissions',
      permissions,
    ),
  );
}
