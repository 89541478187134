import { useApolloClient } from '@apollo/client';
import useMoveInstanceToRundown from 'hooks/useMoveInstanceToRundown';
import GET_RUNDOWN from 'operations/queries/getRundown';
import useUnscheduleInstance from 'hooks/useUnscheduleInstance';
import useCustomDateTimeUtils from './useCustomDateTimeUtils';

const useScheduleLinearInstance = (setSchedule, setCurrentDestination) => {
  const client = useApolloClient();

  const { isBeforeToday } = useCustomDateTimeUtils();
  const [moveInstanceToRundown] = useMoveInstanceToRundown();
  const [, unscheduleRundownInstances] = useUnscheduleInstance();

  const shouldCopy = async (storyInstance, sourceRundown, currentDestination) => {
    const skip = !(sourceRundown.mId && sourceRundown.mRefId);

    if (skip) return false;

    const { data } = await client.query({
      query: GET_RUNDOWN,
      variables: {
        input: {
          mId: sourceRundown.mId,
          mRefId: sourceRundown.mId,
        },
      },
    });

    const { mOrder, mPublishingAt: sourcePublishingAt } = (data && data.getRundown) || {};
    const isInMOrder = !mOrder ? false : mOrder.find((val) => val === storyInstance.mId);

    const copy = isInMOrder && isBeforeToday(sourcePublishingAt);

    if (copy) {
      setSchedule && setSchedule(sourcePublishingAt);
      setCurrentDestination({
        ...currentDestination,
        publishingTime: sourcePublishingAt,
      });
    }

    return copy;
  };

  const scheduleLinearInstance = async (
    storyInstance,
    sourceRundown,
    targetRundown,
    currentDestination,
  ) => {
    if (!targetRundown) return;

    if (!targetRundown.mId && !targetRundown.mRefId) {
      const { mProperties } = storyInstance;
      const { account } = mProperties;
      const { orderType } = account;
      unscheduleRundownInstances(storyInstance, null, null, orderType, sourceRundown.mId);
      return;
    }

    if (isBeforeToday(targetRundown.mPublishingAt)) return;

    const instanceId = storyInstance.mId;
    // Always copy instances that are in mOrder of past rundowns, otherwise move
    const copy = await shouldCopy(storyInstance, sourceRundown, currentDestination);

    const source = sourceRundown.mId && sourceRundown.mRefId ? sourceRundown : null;
    try {
      await moveInstanceToRundown(instanceId, source, targetRundown, copy);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  return [scheduleLinearInstance];
};

export default useScheduleLinearInstance;
