import React, { memo } from 'react';

import { ReactComponent as PhotoIcon } from 'assets/icons/systemicons/editor/photo_small.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/systemicons/editor/video_small.svg';
import { elementTypes } from 'components/editor/constants';
import { PlatformStructure } from 'types/graphqlTypes';

import { ToolbarContainer } from '../../styled';
import BlockButton from '../blockButton';
import { ButtonGroup } from '../ButtonGroup';
import TextButton from '../textButton';

const { IMAGE, VIDEO, PHOTO_GALLERY } = elementTypes;

const cmsVariants = {
  IFRAME: 'iframe',
  BLOCKS: 'blocks',
};

function WithContainer({
  children,
  skipContainer,
}: {
  children: React.ReactNode;
  skipContainer?: boolean;
}) {
  return skipContainer ? children : <ToolbarContainer>{children}</ToolbarContainer>;
}

const CmsToolbar = ({
  platformStructure,
  skipContainer,
}: {
  platformStructure?: PlatformStructure;
  skipContainer?: boolean;
}) => {
  const {
    variant = cmsVariants.IFRAME,
    sections = [],
    allowVideoInPhotogallery,
  } = platformStructure ?? {};
  return (
    <WithContainer skipContainer={skipContainer}>
      {variant === cmsVariants.BLOCKS && Array.isArray(sections) && sections?.length > 0 ? (
        (sections ?? []).map((section) => (
          <ButtonGroup key={section.name}>
            {(section.blocks ?? []).map((block) => (
              <BlockButton
                key={block}
                type={block}
                tooltipType={
                  block === PHOTO_GALLERY && allowVideoInPhotogallery ? 'GALLERY' : block
                }
              />
            ))}
          </ButtonGroup>
        ))
      ) : (
        <ButtonGroup>
          <TextButton action={undefined} icon={<VideoIcon />} label="Add Video" type={VIDEO} />
          <TextButton action={undefined} icon={<PhotoIcon />} label="Add Photo" type={IMAGE} />
        </ButtonGroup>
      )}
    </WithContainer>
  );
};

export default memo(CmsToolbar);
