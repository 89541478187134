import { format, isToday, isYesterday } from 'date-fns';

export const isoToLocaleShort = (date?: string | null | number, year: boolean = false): string => {
  if (!date) return '';

  if (isToday(date)) return format(date, 'hh:mm A');

  if (isYesterday(date)) return `Yesterday, ${format(date, 'hh:mm A')}`;

  const dateFormat = year ? 'MMM DD, YYYY hh:mm A' : 'MMM DD hh:mm A';
  return format(date, dateFormat);
};
