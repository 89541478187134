import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDrop } from 'react-dnd';
import { isEqual } from 'lodash';
import { useSlate } from 'slate-react';

import { useGetInstanceBlockMdf } from 'api/mdf/useGetMdf';
import defaultThumbnail from 'assets/images/default/defaultThumbnail.png';
import { DeleteDialog } from 'components/dialogs/CommonDialogs';
import addMedia from 'components/editor/components/audioVideo/utils/addMedia';
import DragAndDrop from 'components/editor/components/dragAndDrop';
import { useEditorContext } from 'components/editor/EditorContext';
import { Update } from 'components/editor/types';
import { removeBlock } from 'components/editor/utils';
import updateBlock from 'components/editor/utils/updateBlock';
import { LoadingButtonIndicator } from 'components/loadingIndicator';
import { MdfEditor } from 'components/mdfEditor/MdfEditor';
import useGetSignedUrl from 'hooks/useGetSignedUrl';
import useMetadata from 'hooks/useMetadata';
import useStorageImage from 'hooks/useStorageImage';
import { Box } from 'layouts/box/Box';
import { BlockElement } from 'types';
import { Metadata } from 'types/forms/forms';
import { getThumbnailKey } from 'utils/mediaKey';
import { DroppedAsset } from 'utils/normalizeAssetData';
import { mediaTypes } from 'utils/rundownItemTypes';

import { SupportedBlockTypes } from '../elementComponents';

import ImageActions from './ImageActions';

import {
  ImageBlock,
  LeftSideWrapper,
  MdfWrapper,
  PlaceholderZone,
  StyledImg,
  StyledText,
} from './styled';

/**
 * TODO - This is Dina asset, find correct type later.
 */
interface AssetData {
  assetType: 'image';
  mId?: string;
  mRefId?: string;
  mAssetId?: string;
  metadata?: Metadata;
  src?: string;
  proxy?: string;
}

const BaseImage = ({ attributes, children, element }: Readonly<BlockElement>) => {
  const { mdf } = useGetInstanceBlockMdf(element?.type as SupportedBlockTypes | undefined);
  const [addingImage, setAddingImage] = useState(false);
  const [showMetadata, setShowMetadata] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const [showAssetDelete, setShowAssetDelete] = useState(false);
  const data = (element?.data ?? {}) as AssetData;
  const editor = useSlate();
  const { update, doLock, isLockedByAnotherUser } = useEditorContext();
  const storedMetadata = useMemo(() => {
    if (Array.isArray(data?.metadata)) return {};
    return data?.metadata ?? {};
  }, [data]);

  const hasAsset = data.mRefId !== undefined;
  const key = data ? data.src || getThumbnailKey(data.mId, data.mRefId) : null;
  useGetSignedUrl(key, !key);
  const {
    data: thumbnailData,
    error: storageError,
    loading: storageLoading,
  } = useStorageImage(key ?? undefined, !key);
  const s3ThumbUrl = storageError && !storageLoading ? defaultThumbnail : thumbnailData;

  const { metadata, errorMap, updateFieldValues } = useMetadata(
    mdf,
    storedMetadata,
    'default',
    undefined,
    data.mRefId ?? undefined,
  );

  useEffect(() => {
    if (!isEqual(metadata, storedMetadata)) {
      const updatedData = {
        ...data,
        metadata: metadata,
      };
      updateBlock(editor, element, updatedData, update as Update, false, undefined);
    }
  }, [metadata, data, storedMetadata, update]);

  const proxyUrl = s3ThumbUrl ?? element?.data?.proxy ?? '';

  const [{ isOver }, drop] = useDrop({
    accept: [mediaTypes.CLIP],
    canDrop: (item: { type: string; payload: DroppedAsset }) => item.payload.itemType === 'image',
    drop(item: { type: string; payload: DroppedAsset }) {
      if (item.payload.itemType === 'image') {
        doLock?.();
        setAddingImage(true);
        addMedia(editor, element, item.payload, update as Update)
          .catch(() => {}) // TODO - some proper error handling.
          .finally(() => {
            setAddingImage(false);
          });
      }
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
      overId: monitor.getItem<{ id: string }>(),
    }),
  });

  const placeHolderText = useMemo(() => {
    if (addingImage) return 'Adding..  ';
    return isOver ? 'Release to add' : 'Drop image here';
  }, [addingImage, isOver]);

  const doRemoveAsset = useCallback(() => {
    const updatedData = {
      metadata: metadata,
    };
    doLock?.();
    updateBlock(editor, element, updatedData, update as Update, false, undefined);
    setShowAssetDelete(false);
  }, [updateBlock, setShowAssetDelete, editor, metadata]);

  const doRemoveBlock = useCallback(() => {
    doLock?.();
    removeBlock(editor, element, update as Update);
    setShowDelete(false);
  }, [updateBlock, setShowDelete, editor]);

  return (
    <ImageBlock contentEditable={false} {...attributes}>
      <DragAndDrop element={element} isDragDisabled={false}>
        {children}
        <Box container alignItems="start" justifyContent="start" maxHeight="240px">
          <LeftSideWrapper className="left-side-wrapper">
            {hasAsset ? (
              <StyledImg $collapsed={!showMetadata} $src={proxyUrl} draggable={false} />
            ) : (
              <PlaceholderZone
                $isOver={isOver}
                ref={(ref) => {
                  drop(ref);
                }}
              >
                <StyledText variant="listItemLabel" style={{ margin: 'auto' }}>
                  {placeHolderText}
                  {addingImage && (
                    <LoadingButtonIndicator
                      inline
                      size={16}
                      style={{ position: 'relative', top: '3px' }}
                    />
                  )}
                </StyledText>
              </PlaceholderZone>
            )}
            <ImageActions
              element={element}
              showMetadata={showMetadata}
              setShowMetadata={setShowMetadata}
              hasAsset={hasAsset}
              isLockedByAnotherUser={!!isLockedByAnotherUser}
              onRemoveAssetClick={() => setShowAssetDelete(true)}
              onRemoveBlockClick={() => setShowDelete(true)}
            />
          </LeftSideWrapper>
          {mdf && showMetadata && (
            <MdfWrapper className="mdf-wrapper">
              <MdfEditor
                fields={mdf.fields}
                defaultLayoutSettings={mdf.views.default}
                layoutSettings={[]}
                metadata={metadata ?? {}}
                permissions={mdf.permissions}
                view="default"
                errorMap={errorMap}
                updateFieldValue={(val) => {
                  doLock?.();
                  updateFieldValues(val);
                }}
              />
            </MdfWrapper>
          )}
        </Box>
      </DragAndDrop>
      <DeleteDialog
        open={showDelete}
        onClose={() => setShowDelete(false)}
        onClick={doRemoveBlock}
        title="Remove block"
        message="Are you sure you want to remove the image block?"
        confirmLabel="Delete"
      />
      <DeleteDialog
        open={showAssetDelete}
        onClose={() => setShowAssetDelete(false)}
        onClick={doRemoveAsset}
        title="Remove asset"
        message="Are you sure you want to remove the image? 
        You will be able to add a different image."
        confirmLabel="Delete"
      />
    </ImageBlock>
  );
};

export default memo(BaseImage);
