import { memo, useCallback, useMemo, useState } from 'react';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';

import { useGetTypedOptionList } from 'api/optionLists/useGetOptionList';
import { ReactComponent as ArrowDown } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_down.svg';
import Text from 'components/text';
import Tooltip, { TooltipPlacement } from 'components/tooltip/Tooltip';
import { Color } from 'features/reusableStyled';
import { Alternative } from 'types/graphqlTypes';

import { FieldHeader } from '../../styled';
import { FieldProps } from '../fields';

import {
  SelectWrapper,
  StyledOption,
  StyledOptionWrapper,
  StyledPopper,
  StyledTextField,
} from './styled';

interface InputProps {
  value: string;
}

function ChoiceField({
  fieldModel,
  value,
  setValue,
  fieldSettings,
  defaultFieldSettings,
  style,
  errorMessage,
  placeholder,
  disableEdit,
  hideLabel,
  disableClearable,
  autoFocus,
  colorKey,
  onBlur,
}: Readonly<
  FieldProps & { onBlur?: () => void; colorKey?: keyof Alternative; disableClearable?: boolean }
>) {
  const [tooltipPlacement, setTooltipPlacement] = useState<TooltipPlacement>(
    TooltipPlacement.BOTTOM,
  );

  const { fieldId, required, optionListId } = fieldModel;
  const { label, hint, colors = {} } = fieldSettings ?? defaultFieldSettings;
  const { optionList } = useGetTypedOptionList(optionListId, 'choice');
  const colorLookupKey: keyof Alternative = colorKey ?? 'label';

  const alternatives = useMemo(
    () => optionList?.alternatives ?? fieldModel.alternatives ?? [],
    [optionList, fieldModel],
  );

  const selectedValue = useMemo(() => {
    return alternatives.find((opt) => opt.value === value) ?? null;
  }, [value, alternatives]);

  const onFocus = useCallback(() => {
    setTooltipPlacement(TooltipPlacement.TOP);
  }, []);

  const doOnBlur = useCallback(() => {
    setTooltipPlacement(TooltipPlacement.BOTTOM);
    onBlur?.();
  }, [onBlur]);

  const renderInput = (params: AutocompleteRenderInputParams) => {
    const inputValue = (params.inputProps as InputProps).value;
    const color = colors[inputValue];
    return (
      <StyledTextField
        {...params}
        variant="filled"
        placeholder={disableEdit ? '' : placeholder ?? 'Select'}
        error={Boolean(errorMessage)}
        helperText={errorMessage?.length ? errorMessage : undefined}
        InputProps={{
          ...params.InputProps,
          autoFocus: autoFocus,
          onClick: (ev) => ev.stopPropagation(),
          startAdornment: color ? (
            <Color $color={color} $size={12} style={{ marginLeft: '4px' }} />
          ) : undefined,
        }}
      />
    );
  };

  const renderOption = (alternative: Alternative) => (
    <StyledOptionWrapper $selected={selectedValue?.value === alternative.value}>
      <StyledOption container flexDirection="row" gap="4px" justifyContent="start">
        {colors[alternative[colorLookupKey] ?? ''] && (
          <Color $color={colors[alternative[colorLookupKey] ?? '']} $size={12} />
        )}
        <Text variant="listItemLabel" truncate color="highEmphasis">
          {alternative.label ?? alternative.value ?? 'Invalid option!'}
        </Text>
      </StyledOption>
    </StyledOptionWrapper>
  );

  return (
    <Tooltip title={hint || ''} placement={tooltipPlacement}>
      <SelectWrapper key={fieldId} style={style}>
        {!hideLabel && <FieldHeader>{label}</FieldHeader>}
        <Autocomplete
          onFocus={onFocus}
          onBlur={doOnBlur}
          openOnFocus
          disableClearable={required || disableClearable}
          fullWidth
          blurOnSelect
          autoHighlight
          noOptionsText="No options available"
          selectOnFocus={false}
          options={alternatives}
          value={selectedValue}
          getOptionSelected={(alternative: Alternative, v: Alternative) => {
            if (!v) return false;
            return alternative.value === v.value;
          }}
          onChange={(_ev, alternative) => setValue(alternative?.value ?? null)}
          renderInput={renderInput}
          renderOption={renderOption}
          PopperComponent={StyledPopper}
          getOptionLabel={(alternative) => alternative?.label ?? alternative?.value}
          popupIcon={!disableEdit && <ArrowDown />}
          ListboxProps={{
            onClick: (ev: React.MouseEvent) => ev.stopPropagation(),
          }}
        />
      </SelectWrapper>
    </Tooltip>
  );
}

export default memo(ChoiceField);
