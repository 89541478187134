import { useLayoutEffect, useState } from 'react';
import { Rnd } from 'react-rnd';
import styled from '@emotion/styled/macro';

import DialogBuilder from 'components/dialogs/DialogBuilder';

export const StyledRnd = styled(Rnd)`
  outline: none;
  border-radius: 6px;
  overflow: hidden;
  background: ${({ theme }) => theme.palette.dina.surfaceCard};
  box-shadow: 0px 0px 25px 0px ${({ theme }) => theme.palette.dina.boxShadowDarker},
    0px 0px 8px 0px ${({ theme }) => theme.palette.dina.boxShadowDarker},
    0px 12px 24px 4px ${({ theme }) => theme.palette.dina.boxShadowDarker};
`;

interface DraggableDialogProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  initialPosition?: { x: number; y: number };
  containerDimensions?: { height: number; width: number };
  dimension: React.CSSProperties;
  setDimension: React.Dispatch<
    React.SetStateAction<{
      width: string;
      height: string;
    }>
  >;
  allowResize?: boolean;
  minWidth?: number;
  minHeight?: number;
  style?: React.CSSProperties;
}

function DraggableDialog({
  open,
  children,
  onClose,
  initialPosition,
  containerDimensions,
  allowResize = true,
  minWidth,
  minHeight,
  dimension,
  setDimension,
  style,
}: Readonly<DraggableDialogProps>) {
  const { x: initialX, y: initialY } = initialPosition ?? { x: 0, y: 0 };

  const [currentPosition, setCurrentPosition] = useState<{ x: number; y: number }>({
    x: initialX,
    y: initialY,
  });
  const { x, y } = currentPosition;

  useLayoutEffect(() => {
    const halfHeight = window.innerHeight / 2 - 310;
    const halfWidth = window.innerWidth / 2 - 310;
    if (!initialPosition) setCurrentPosition({ x: halfWidth, y: halfHeight });
  }, []);

  // Calculate the children's height and width
  useLayoutEffect(() => {
    if (containerDimensions) {
      const { height, width } = containerDimensions;

      // Check if the children exceed the window bounds
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      const newY = y + height > windowHeight ? windowHeight - height : y;
      const newX = x + width > windowWidth ? windowWidth - width : x;

      // Update the currentPosition state
      setCurrentPosition({ x: newX, y: newY });
    }
  }, [children, containerDimensions, x, y]);

  return (
    <DialogBuilder
      open={open}
      onClose={onClose}
      modal={false}
      className="dnd"
      style={{ width: '0px', height: '0px', ...style }}
      onInteractOutside={(ev) => ev.preventDefault()}
      onPointerDownOutside={(ev) => ev.preventDefault()}
    >
      <StyledRnd
        size={{
          width: dimension?.width ?? 'auto',
          height: dimension?.height ?? 'auto',
        }}
        minHeight={minHeight}
        minWidth={minWidth}
        maxHeight="90vh"
        position={{ x, y }}
        onDragStop={(_1, position: { x: number; y: number }) => {
          setCurrentPosition({
            x: position.x,
            y: position.y,
          });
        }}
        onResizeStop={(e, direction, ref) => {
          if (allowResize) {
            setDimension({
              width: ref.style.width,
              height: ref.style.height,
            });
            const rect = ref.getBoundingClientRect();
            setCurrentPosition({
              x: rect.x,
              y: rect.y,
            });
          }
        }}
        dragHandleClassName="dragHandler"
        bounds="window"
      >
        {children}
      </StyledRnd>
    </DialogBuilder>
  );
}

export default DraggableDialog;
