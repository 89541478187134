import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as StoryMarketOn } from 'assets/icons/systemicons/storymarket_small_on.svg';
import fallbackImage from 'assets/images/default/defaultThumbnail.png';
import memberTypes from 'operations/memberTypes';
import useCustomDateTimeUtils from 'hooks/useCustomDateTimeUtils';
import { onImageLoadingError } from 'utils/image/imageUtils';
import { getPlatformIcon } from 'utils/instance/platform';

import {
  Item,
  PitchThumbnailWrapper,
  StoryItem,
  TextWrapper,
  Thumbnail,
  Title,
  Updated,
} from './styled';

function ListItem({ children, onClick, selected, type, onDoubleClick = undefined }) {
  const isPitch = type === memberTypes.PITCH;

  return (
    <StoryItem
      $isPitch={isPitch}
      $selected={selected}
      role="button"
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      <Item>{children}</Item>
    </StoryItem>
  );
}

ListItem.Thumbnail = function ListItemThumbnail({ src, type, platform = undefined }) {
  if (platform) {
    const PlatformIcon = getPlatformIcon(platform);

    return (
      <PitchThumbnailWrapper>
        <PlatformIcon className="skipOverride" />
        <Thumbnail
          loading="lazy"
          alt="Pitch thumbnail"
          src={src || fallbackImage}
          onError={onImageLoadingError}
        />
      </PitchThumbnailWrapper>
    );
  }

  if (type !== memberTypes.PITCH) {
    return (
      <Thumbnail
        loading="lazy"
        alt="Story thumbnail"
        src={src || fallbackImage}
        onError={onImageLoadingError}
      />
    );
  }

  return (
    <PitchThumbnailWrapper>
      <StoryMarketOn />
      <Thumbnail
        loading="lazy"
        alt="Pitch thumbnail"
        src={src || fallbackImage}
        onError={onImageLoadingError}
      />
    </PitchThumbnailWrapper>
  );
};

ListItem.Text = function ListItemText({ children }) {
  return <TextWrapper>{children}</TextWrapper>;
};

ListItem.Title = function ListItemTitle({ children }) {
  return <Title>{children}</Title>;
};

ListItem.Updated = function ListItemUpdated({ children }) {
  const { distanceInWords } = useCustomDateTimeUtils();
  const time = distanceInWords(children);
  return <Updated>{time}</Updated>;
};

ListItem.propTypes = {
  /** Children */
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]).isRequired,
  /** Children */
  onClick: PropTypes.func,
  /** Children */
  selected: PropTypes.bool,
  /** Children */
  type: PropTypes.string,
};

ListItem.defaultProps = {
  onClick: () => {},
  selected: false,
  type: 'story',
};

export default ListItem;
