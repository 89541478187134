import ICONS from 'assets/icons/icons';
import { IconButton } from 'components/buttons';
import Icon from 'components/icon';
import { DinaTable } from 'features/grids/common/types';

interface ClearButtonProps<TData> {
  table: DinaTable<TData>;
}

export function ClearButton<TData>({ table }: Readonly<ClearButtonProps<TData>>) {
  return (
    <IconButton
      type="reset"
      title={'Clear filter'}
      variant="outlined"
      usage="outlined"
      onClick={() => table.resetColumnFilters()}
      height={32}
    >
      <Icon icon={ICONS.CLEAR} height={24} width={24} />
    </IconButton>
  );
}
