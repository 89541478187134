import useSettingsValue from 'hooks/useSettingsValue';
import MainSettingsView from 'utils/settings/schemas/mainSettingsView';

import useDateTimeUtils from './useDateTimeUtils';

/**
 * A hook that calculates timestamp in epoch to archive a story
 */
const useGetArchiveTime = () => {
  const { addDays, isAfter } = useDateTimeUtils();
  const [getSettingsValue] = useSettingsValue();

  const getDefaultArchiveDays = () => {
    const properties = MainSettingsView.categories?.[0]?.properties;
    const numberOfDays = properties['app.autoArchiveAfterDays']?.default;
    return numberOfDays;
  };

  const getAutoArchiveTime = (mPublishingAt: string) => {
    const calcTTLFromDate = isAfter(mPublishingAt, new Date()) ? mPublishingAt : new Date();
    const numberOfDays =
      (getSettingsValue('app.autoArchiveAfterDays') as string) ?? getDefaultArchiveDays();
    if (Number(numberOfDays) > 0) {
      const daysInFutureFromNow = addDays(calcTTLFromDate, Number(numberOfDays));
      const archiveTime = Math.round(daysInFutureFromNow.getTime() / 1000);
      return archiveTime;
    }
  };

  return [getAutoArchiveTime];
};

export default useGetArchiveTime;
