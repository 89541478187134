import { useQuery } from '@apollo/client';

import GET_SETTINGS from 'operations/queries/getSettings';
import { MemberType } from 'types/graphqlTypes';

export interface GetSettings {
  generalSettings: Pick<MemberType, 'mId' | 'mRefId' | 'mMetaData'>;
  userSettings: Pick<MemberType, 'mId' | 'mRefId' | 'mMetaData'>;
}

const useGetSettings = (userId: string) => {
  const { data, loading, error } = useQuery<GetSettings>(GET_SETTINGS, {
    variables: {
      generalSettingsInput: { mId: 'settings', mRefId: 'general' },
      userSettingsInput: { mId: 'settings', mRefId: userId },
    },
    fetchPolicy: 'cache-and-network',
  });

  return [data, loading, error] as const;
};

export default useGetSettings;
