import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FormControlLabel } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import List from '@material-ui/core/List';
import ListItemComponent from '@material-ui/core/ListItem';

import { HStack } from 'layouts/box/Box';
import {
  TabsContent as RTabsContent,
  TabsList as RTabsList,
  TabsRoot as RTabsRoot,
  TabsTrigger as RTabsTrigger,
} from 'lib/tabs';
import transientOptions from 'theme/helpers';

/* Story View styled */
export const Container = styled('div', transientOptions)<{ $imageUrl: string }>`
  padding: 12px;
  position: relative;
  height: 100%;
  width: 100%;
  background: ${({ theme, $imageUrl }) => `linear-gradient(
      180deg,
      ${theme.palette.dina.backgroundResting} 0%,
      ${theme.palette.dina.surfaceAppBackgroundNavLevel1} 100%
    ),
    url(${$imageUrl})`};
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TabContainer = styled('div')`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

export const TabsRoot = styled(RTabsRoot)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const StyledStoryView = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.dina.backgroundResting};
  overflow: hidden;
`;

export const TabsList = styled(RTabsList)`
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  background: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  padding-right: 4px;
`;

export const TabsContainer = styled('div')`
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const TriggerWrapper = styled('div')`
  padding: 8px;
  display: flex;
  flex-direction: column;
`;

export const TabsTrigger = styled(RTabsTrigger)`
  ${({ theme }) => theme.typography.dina.listItemLabel}
  height: 40px;
  width: fit-content;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  :hover {
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
  &[data-state='active'] {
    ${({ theme }) => css`
      color: ${theme.palette.dina.highEmphasis};
      box-shadow: inset 0 -2px 0 0 ${theme.palette.dina.highEmphasis};
    `};
  }
`;

export const Text = styled('span')`
  cursor: pointer;
  text-decoration: none;
  text-transform: capitalize;
  user-select: none;
`;

export const ContentContainer = styled('div')`
  height: calc(100% - 40px);
  display: flex;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background: ${({ theme }) => theme.palette.dina.surfaceCard};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25), 0px 2px 2px rgba(0, 0, 0, 0.15);
`;

export const TabsContent = styled(RTabsContent)`
  position: relative;
  outline: none;
  flex-grow: 1;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  overflow: hidden;
  &[data-state='active'] {
    display: flex;
  }
`;

/* Title and Description */
export const TitleDescriptionContainer = styled('div')`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TitleThumbnailWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ThumbnailInput = styled('label')`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0px;
  :hover {
    & > :first-child {
      & path {
        fill-opacity: 1;
      }
    }
    & > :last-child {
      ${({ theme }) => theme.palette.dina.highEmphasis}
    }
  }
  :focus {
    outline: none;
  }
`;

/* Instances styled */

export const InstancesViewWrapper = styled('div')`
  display: flex;
  height: 100%;
  width: 100%;
  flex: 1;
  background: ${({ theme }) => theme.palette.dina.surfaceCard};
  border-top: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const DividerPanel = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;

  border-left: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  border-right: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};

  :hover {
    cursor: pointer;

    svg {
      path {
        fill: ${({ theme }) => theme.palette.dina.whiteHighEmphasis};
        fill-opacity: 1;
      }
    }
  }
`;

export const StyledInstancesList = styled(List, transientOptions)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  transition: width 0.3s ease-in;
  container-type: inline-size;
`;

export const ListWrapper = styled('div')`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const ListScrollContainer = styled('div', transientOptions)<{ height: number }>`
  position: relative;
  height: ${({ height }) => `${height}px`};
  width: '100%';
`;

export const SelectedIndicator = styled(HStack)`
  border-top: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  background-color: ${({ theme }) => theme.palette.dina.backgroundHover};
`;

export const StyledEmptyInstancesList = styled('div')<{
  $shouldTakeFullWidth?: boolean;
}>`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  width: ${({ $shouldTakeFullWidth }) => ($shouldTakeFullWidth ? '100%' : 0)};
  transition: width 0.3s ease-in;
`;

export const InstanceListItem = styled(ListItemComponent, transientOptions)<{
  $isInstanceItemVariant: boolean;
}>`
  &.MuiListItem-root {
    ${({ theme }) => css`
      background-color: ${theme.palette.dina.surfaceAppBackgroundNavLevel2};
      border-bottom: 1px solid ${theme.palette.dina.dividerLight};
    `}
    padding: 0 8px 0 0;
    transform: translate3d(0, 0, 0);
    flex-direction: column;

    :last-child {
      border-bottom: none;
    }
    :hover {
      background-color: ${({ theme }) => theme.palette.dina.storyCardHover};
    }
  }
  &.Mui-selected,
  &.Mui-selected:hover,
  &.Mui-selected:focus {
    background-color: ${({ $isInstanceItemVariant, theme }) =>
      theme.palette.dina[$isInstanceItemVariant ? 'onFocusOpacity' : 'onFocus']};
  }
`;

/** Overlay Spinner */
export const MUIBackdrop = styled(Backdrop)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 101;
  background-color: transparent;
  width: 100%;
  pointer-events: none;
  height: 100%;
`;

export const Spinner = styled('img')`
  width: 64px;
  height: 64px;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  width: max-content;
  margin-left: 0;
  height: 24px;

  .MuiFormControlLabel-label {
    ${({ theme }) => theme.typography.dina.listItemLabel};
    font-size: 14px;
    margin-left: 6px;
  }
`;
