import Text from 'components/text/Text';

import { ContainerWrapper, RootWrapper, TodaySelectorType } from './styled';

const getTextColor = (
  isSelected: boolean,
  isToday: boolean,
  todaySelectorType: TodaySelectorType,
  isWeekend: boolean,
  isOtherMonth: boolean,
) => {
  if (isSelected) return 'whiteHighEmphasis';
  if (isToday && todaySelectorType === 'diamond') return 'highEmphasis';
  if (isOtherMonth) return 'disabled';
  if (isWeekend) return 'datePickerHoliday';
  return 'mediumEmphasis';
};

interface DatePickerProps {
  text: string;
  isToday: boolean;
  isSelected: boolean;
  isOtherMonth: boolean;
  isWeekend: boolean;
  todaySelectorType: TodaySelectorType;
  rangePosition: string;
  disabled: boolean;
  selectingRange: boolean;
}

function DatePicker({
  text,
  isToday,
  isSelected,
  isOtherMonth,
  isWeekend,
  todaySelectorType = 'diamond',
  rangePosition,
  disabled,
  selectingRange,
}: Readonly<DatePickerProps>) {
  const containerType =
    isToday && todaySelectorType === 'cropped' && !isSelected ? 'container' : null;

  return (
    <ContainerWrapper $containerType={containerType}>
      <RootWrapper
        $isToday={isToday}
        $isSelected={isSelected}
        $todaySelectorType={todaySelectorType}
        $rangePosition={rangePosition}
        $disabled={disabled}
        $selectingRange={selectingRange}
      >
        <Text
          variant="listItemGroupHeadersLabel"
          color={getTextColor(isSelected, isToday, todaySelectorType, isWeekend, isOtherMonth)}
        >
          {text}
        </Text>
      </RootWrapper>
    </ContainerWrapper>
  );
}

export default DatePicker;
