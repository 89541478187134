import { Dictionary, keyBy } from 'lodash';

import { isMiniMemberArray } from 'components/mdfEditor/fields/relation/relation-utils';
import { getSubMdf, hasPermission, shouldShowField } from 'features/mdf/mdf-utils';
import { MiniMember } from 'types/forms/forms';
import { FieldTypeEnum, Mdf, MdfField, MemberTypeEnum, Metadata } from 'types/graphqlTypes';

export const findRelationMembersInMetadata = (
  mdf: Mdf | undefined,
  metadata: Metadata,
  groups: string[],
  subTypes: Dictionary<Mdf>,
): MiniMember[] => {
  if (!mdf?.fields || !mdf.views?.default) return [];

  const relationMembers = new Map<string, MiniMember>();
  const settingsMap = keyBy(mdf.views.default, 'fieldId');

  const hasReadPermission = (fieldId: string) =>
    hasPermission(mdf.permissions?.read[fieldId], groups);

  const processRelationField = (field: MdfField) => {
    const fieldValue = metadata[field.fieldId];
    if (isMiniMemberArray(fieldValue)) {
      fieldValue.forEach((member) => {
        if (member.type !== MemberTypeEnum.Contact) {
          if (!relationMembers.has(member.id)) {
            relationMembers.set(member.id, member);
          }
        }
      });
    }
  };

  const processSubtypeField = (field: MdfField) => {
    const subMdf = getSubMdf(field, metadata, subTypes);
    if (subMdf) {
      const subRelationMembers = findRelationMembersInMetadata(subMdf, metadata, groups, subTypes);
      subRelationMembers.forEach((subRelationMember) => {
        if (!relationMembers.has(subRelationMember.id)) {
          relationMembers.set(subRelationMember.id, subRelationMember);
        }
      });
    }
  };

  for (const field of mdf.fields) {
    if (
      !shouldShowField(field, settingsMap, settingsMap, false, hasReadPermission(field.fieldId))
    ) {
      continue;
    }

    switch (field.type) {
      case FieldTypeEnum.relation:
        processRelationField(field);
        break;
      case FieldTypeEnum.subtype:
        processSubtypeField(field);
        break;
    }
  }

  return Array.from(relationMembers.values());
};
