import { useState } from 'react';
import { Tweet as ReactTweet } from 'react-tweet';
import styled from '@emotion/styled';

import { ReactComponent as RefreshIcon } from 'assets/icons/systemicons/refresh_small.svg';
import { ResourceDetails } from 'hooks/useResourceDetails';
import getTweetIdFromURL from 'utils/twitter/getTweetIdFromURL';

const Iframe = styled('iframe')`
  width: 100%;
  height: 100%;
  border: none;
`;
const PreviewWrapper = styled('div')`
  width: 100%;
  height: 100%;
  border: none;
`;

const InputWrapper = styled('input')`
  display: absolute;
  position: absolute;
  left: 25px;
  width: 100%;
  height: 25px;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  color: transparent;
  border: 1px solid transparent;

  &:hover {
    position: relative;
    background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
    color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
    border: none;
    height: 20px;
    width: 100%;
    border-radius: 4px;
  }
`;

const RefreshIconWrapper = styled('div')`
  position: absolute;
  background-color: transparent;
  height: 20px;
  height: 20px;
  border-radius: 4px;
 

  &:hover {
    background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
    color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
    cursor: 
    position: relative;
    
  }
`;

interface PreviewProps {
  resourceDetails?: ResourceDetails;
}

interface ContentProps {
  resourceDetails?: ResourceDetails;
  refreshToken?: string;
}
function Content({ resourceDetails, refreshToken }: Readonly<ContentProps>) {
  const { resource } = resourceDetails || {};
  const mUpdatedAt = resource?.mUpdatedAt || 'no version';
  const previewUrl =
    resource?.mProperties?.provider?.previewEndpoint + `/?dinatimestamp=${mUpdatedAt}`;
  const embeddedUrl = resource?.mProperties?.provider?.embeddedEndpoint;
  const platform = resource?.mProperties?.platform?.toUpperCase();
  if (!resource?.mProperties?.provider?.previewEndpoint) {
    return <div>Preview not available</div>;
  }

  switch (platform) {
    case 'FACEBOOK':
      return (
        <Iframe
          name="preview"
          id="preview"
          key="preview" // Use key to force re-render
          title="preview"
          src={embeddedUrl + '/?dinatimestamp=' + mUpdatedAt + refreshToken}
        />
      );
    case 'CMS':
      return (
        <Iframe
          name="preview"
          id="preview"
          key={'preview' + refreshToken} // Use key to force re-render
          title="preview"
          src={previewUrl + '/?dinatimestamp=' + mUpdatedAt + refreshToken}
        />
      );
    case 'TWITTER':
      return (
        <ReactTweet id={getTweetIdFromURL(resource?.mProperties?.provider?.previewEndpoint)} />
      );
    default:
      return <div>preview not available</div>;
  }
}

function Preview({ resourceDetails }: Readonly<PreviewProps>) {
  const url =
    resourceDetails?.resource.mProperties?.provider?.previewEndpoint +
    '/?dinatimestamp=' +
    resourceDetails?.resource.mUpdatedAt;
  const [refreshToken, setRefreshToken] = useState<string>('');
  const handleInputWrapperClick = () => {
    window.open(url, '_blank');
  };
  function onRefreshClick() {
    setRefreshToken(new Date().getTime().toString());
  }

  return (
    <PreviewWrapper>
      <RefreshIconWrapper onClick={onRefreshClick} title="Refresh">
        <RefreshIcon style={{ all: 'inherit' }} />
      </RefreshIconWrapper>

      <InputWrapper title="GoTo" type="button" value={url} onClick={handleInputWrapperClick} />

      <Content resourceDetails={resourceDetails} refreshToken={refreshToken}></Content>
    </PreviewWrapper>
  );
}

export default Preview;
