import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';

import { MetadataFormType } from 'types/graphqlTypes';

const LINKS = gql`
  query GetLinks($input: GetMemberInput) {
    getMetadataForms(input: $input) {
      mId
      mRefId
      mProperties {
        __typename
        ... on MetadataFormType {
          fields {
            id
            name
            description
            value
            type
          }
        }
      }
    }
  }
`;

interface GetLinks {
  getMetadataForms: MetadataFormType[];
}

const useGetLinks = () => {
  const { data, loading, error } = useQuery<GetLinks>(LINKS, {
    variables: {
      input: {
        mId: 'links',
        mRefId: 'links',
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const links = useMemo(() => {
    return data?.getMetadataForms?.find((l) => l.mRefId === 'links');
  }, [data]);

  return [links, loading, error] as const;
};

export default useGetLinks;
