import { MouseSensor, PointerSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';

interface InputSensors {
  activationDistance?: number;
}

function useInputSensors(props: Readonly<InputSensors>) {
  const { activationDistance = 50 } = props ?? {};
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: activationDistance,
    },
  });

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: activationDistance,
    },
  });

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      distance: activationDistance,
    },
  });

  const sensors = useSensors(mouseSensor, pointerSensor, touchSensor);

  return [sensors];
}

export default useInputSensors;
