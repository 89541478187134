/* eslint-disable import/prefer-default-export */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Auth } from '@aws-amplify/auth';
import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';

import { useAuthContext } from 'contexts/AuthContext';
import UserCtx from 'contexts/UserContext';
import NotificationCtx from 'contexts/NotificationContext';
import BreakingFeedCtx from 'contexts/BreakingFeedContext';
import { getMemberQuery } from 'operations/queryVariables';
import GET_USER from 'operations/queries/getUser';
import initialQueries from 'operations/global-functions/initialQueries';
import { getSignInUser } from 'features/auth';
import { useUserData } from 'screens/main/hooks';
import InitialLoadingScreen from 'screens/loading';
import { useUserBookmarks } from 'store/bookmarks';
import { keyBy } from 'lodash';

const ErrorWrapper = styled('div')`
  color: white;
  position: fixed;
  top: 30%;
  left: 25%;
  font-size: 25px;
`;

function ProtectedProvider({ children }) {
  const context = useAuthContext();
  const [initialLoading, setInitialLoading] = useState(true);
  const signedInUser = getSignInUser(context);
  const [, setBookmarks] = useUserBookmarks();

  initialQueries(signedInUser.mId, signedInUser.groups, setInitialLoading);
  const [userDataError] = useUserData(signedInUser.mId);

  const { data, error, loading } = useQuery(GET_USER, {
    variables: getMemberQuery(signedInUser.mId),
    onCompleted: (d) => {
      const bookmarks = (d.getMember.mBookmarks ?? []).map(({ bookmarkedType, bookmarkedId }) => ({
        bookmarkedType,
        bookmarkedId,
      }));
      const keyedBookmarks = keyBy(bookmarks, (bookmark) => bookmark.bookmarkedId);
      setBookmarks(keyedBookmarks);
    },
  });

  if (error) {
    return <div> {error} </div>;
  }

  if (userDataError) {
    // eslint-disable-next-line no-console
    console.warn('Failed loading user data', userDataError);
  }

  if (!initialLoading && !data?.getMember) {
    Auth.signOut();
    return (
      <ErrorWrapper>
        Something went wrong! Please reload your browser and sign in again.
      </ErrorWrapper>
    );
  }

  if (initialLoading || loading) {
    return <InitialLoadingScreen />;
  }

  signedInUser.attributes = data?.getMember ?? {};

  return (
    <UserCtx.Provider value={signedInUser}>
      <NotificationCtx>
        <BreakingFeedCtx>{children}</BreakingFeedCtx>
      </NotificationCtx>
    </UserCtx.Provider>
  );
}

ProtectedProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProtectedProvider;
