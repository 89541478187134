import { useContext } from 'react';
import { useApolloClient } from '@apollo/client';
import groupBy from 'lodash/groupBy';

import UserContext from 'contexts/UserContext';
import useCreateConversation from 'hooks/useCreateConversation';
import GET_CONVERSATIONS_OF_USER from 'operations/queries/getConversationsOfUser';
import useAssembleData from 'screens/main/components/header/navbar/messageHub/hooks/useAssembleData';
import { MemberTypeEnum } from 'types/graphqlTypes';
import { AssignedMember } from 'types/members';
import { Conversation } from 'types/messageHub';
import conversationTypes from 'utils/constants/conversationTypes';

interface FetchConversations {
  getConversationsOfUser: Conversation[];
}

const useCreateConvo = () => {
  const { assembleDepartmentData, assemblePeopleData, assembleTeamData } = useAssembleData();
  const [createConversation] = useCreateConversation();
  const user = useContext(UserContext);
  const client = useApolloClient();

  const fetchConversations = async () => {
    return client.query<FetchConversations>({
      query: GET_CONVERSATIONS_OF_USER,
      variables: {
        input: {
          mId: user.mId,
        },
      },
      fetchPolicy: 'cache-first',
    });
  };

  const createConvo = async (member: AssignedMember, onOk: (convo: Conversation) => void) => {
    let convoData = [];

    const { data } = await fetchConversations();
    const groupedData = groupBy(data?.getConversationsOfUser, 'convoType');

    if (member.mType === ('department' as MemberTypeEnum)) {
      convoData = assembleDepartmentData([member]);
    } else if (member.mType === ('team' as MemberTypeEnum)) {
      convoData = assembleTeamData([member]);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      convoData = assemblePeopleData([member], user.mId, groupedData[conversationTypes.DIRECT]);
    }

    await createConversation(convoData, onOk);
  };

  return { createConvo };
};

export default useCreateConvo;
