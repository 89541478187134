import { useCallback } from 'react';

import useDateTimeUtils from 'hooks/useDateTimeUtils';

const useRollingDateUtils = () => {
  const { subDays, startOfDay, endOfDay, startOfISOWeek, endOfISOWeek, addWeeks, addDays } =
    useDateTimeUtils();
  const getRollingDateTime = useCallback((rollingDate: string) => {
    switch (rollingDate) {
      case '-14days': {
        const today = new Date();
        const startDate = subDays(today, 13);
        return [startOfDay(startDate).toISOString(), endOfDay(today).toISOString()];
      }
      case '-7days': {
        const today = new Date();
        const startDate = subDays(today, 6);
        return [startOfDay(startDate).toISOString(), endOfDay(today).toISOString()];
      }
      case 'yesterday': {
        const yesterday = subDays(new Date(), 1);
        return [startOfDay(yesterday).toISOString(), endOfDay(yesterday).toISOString()];
      }
      case 'today': {
        const today = new Date();
        return [startOfDay(today).toISOString(), endOfDay(today).toISOString()];
      }
      case 'this-week': {
        const thisWeek = new Date();
        return [startOfISOWeek(thisWeek).toISOString(), endOfISOWeek(thisWeek).toISOString()];
      }
      case 'next-week': {
        const nextWeek = addWeeks(new Date(), 1);
        return [startOfISOWeek(nextWeek).toISOString(), endOfISOWeek(nextWeek).toISOString()];
      }
      case '+7days': {
        const today = new Date();
        const endDate = addDays(today, 6);
        return [startOfDay(today).toISOString(), endOfDay(endDate).toISOString()];
      }
      case '+14days': {
        const today = new Date();
        const endDate = addDays(today, 13);
        return [startOfDay(today).toISOString(), endOfDay(endDate).toISOString()];
      }
      case '+30days': {
        const today = new Date();
        const endDate = addDays(today, 29);
        return [startOfDay(today).toISOString(), endOfDay(endDate).toISOString()];
      }
      case '+90days': {
        const today = new Date();
        const endDate = addDays(today, 89);
        return [startOfDay(today).toISOString(), endOfDay(endDate).toISOString()];
      }
      case 'all-time': {
        return [startOfDay(new Date(1970)).toISOString(), endOfDay(new Date()).toISOString()];
      }
      default:
        break;
    }
  }, []);

  return { getRollingDateTime };
};

export default useRollingDateUtils;
