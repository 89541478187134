import { memo } from 'react';
import capitalize from 'lodash/capitalize';
import intersectionBy from 'lodash/intersectionBy';

import { ReactComponent as JoinSrc } from 'assets/icons/systemicons/ellipsis_menu/participate_join.svg';
import Avatar, { AvatarProps, AvatarVariant } from 'components/avatar/Avatar';
import Tooltip from 'components/tooltip/Tooltip';
import { useAllMembers } from 'store';
import { AssignedMember } from 'types';
import { MemberTypeEnum } from 'types/graphqlTypes';

import { AssignButton, Container, OverflowText, TooltipText } from './styled';

interface TooltipWrapperProps {
  members: AssignedMember[];
  maxNameToShow?: number;
  children: React.ReactElement;
}

interface AvatarGroupProps
  extends Pick<AvatarProps, 'size' | 'borderWidth' | 'borderColor'>,
    Pick<TooltipWrapperProps, 'maxNameToShow'> {
  members: AssignedMember[];
  maxAvatarToShow?: number;
  onClick?: () => void;
  dark?: boolean;
  disabled?: boolean;
}

const getVisibleMembers = (
  overflow: number,
  assignedMembers: AssignedMember[],
  maxAvatarToShow: number,
) => {
  if (overflow) {
    return assignedMembers.slice(0, maxAvatarToShow);
  }
  return assignedMembers;
};

function TooltipWrapper({ children, members, maxNameToShow = 10 }: TooltipWrapperProps) {
  const overflow = Math.max(0, members.length - maxNameToShow);
  const visibleMembers = overflow > 0 ? members.slice(0, maxNameToShow) : members;
  const title = visibleMembers
    .map((member) =>
      member.mType === ('user' as MemberTypeEnum)
        ? member.mTitle
        : `${member.mTitle} (${capitalize(member.mType)})`,
    )
    .join('\n');
  const overflowText = overflow > 0 ? `\n...and ${overflow} more` : '';

  return maxNameToShow ? (
    <Tooltip
      title={
        <TooltipText variant="caption" color="whiteHighEmphasis">
          {title + overflowText}
        </TooltipText>
      }
    >
      {children}
    </Tooltip>
  ) : (
    children
  );
}

function AvatarGroup({
  members,
  size = 32,
  borderWidth = 1.5,
  borderColor,
  maxAvatarToShow = 3,
  maxNameToShow,
  onClick,
  dark,
  disabled,
}: Readonly<AvatarGroupProps>) {
  members ??= [];
  const [allMembers] = useAllMembers();
  const assignedMembers = intersectionBy(allMembers, members, 'mId');
  const overflow = Math.max(0, members.length - maxAvatarToShow);
  const visibleMembers = getVisibleMembers(overflow, assignedMembers, maxAvatarToShow);

  return (
    <>
      <TooltipWrapper members={assignedMembers} maxNameToShow={maxNameToShow}>
        <Container $size={size} $overflow={overflow} onClick={onClick} $dark={dark}>
          {visibleMembers.map((member) => (
            <Avatar
              key={member.mId}
              variant={member.mType as AvatarVariant}
              size={size}
              imageKey={member.mAvatarKey}
              title={member.mTitle}
              borderWidth={borderWidth}
              borderColor={borderColor}
            />
          ))}
          {overflow > 0 && (
            <OverflowText variant="caption" color={dark ? 'whiteMediumEmphasis' : 'mediumEmphasis'}>
              +{overflow}
            </OverflowText>
          )}
        </Container>
      </TooltipWrapper>
      {onClick && (
        <Tooltip title={disabled ? '' : 'Assign'}>
          <AssignButton $size={size} onClick={onClick} $disabled={disabled} id="assign-button">
            <JoinSrc className={dark ? 'skipOverride' : ''} />
          </AssignButton>
        </Tooltip>
      )}
    </>
  );
}

export default memo(AvatarGroup);
